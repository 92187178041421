<template>
  <SmDialog
    v-model:visibility="visible"
    :title="i18n.t('editPriceModel')"
    class="dialog"
    size="medium">
    <SmDialogMessage
      :message="dialogMessage"
      :visible="dialogVisibilty"
      :type="dialogType"
      class="mb-3" />

    <SmDialogMessage
      v-if="props.isFallback"
      type="warning"
      :message="i18n.t('infoIsFallback')"
      class="mb-3" />

    <div style="padding-right: 12px">
      <PriceInformationForm
        ref="form"
        v-model:price-information="localPriceInformation"
        :is-fallback="props.isFallback" />
    </div>

    <template #footer>
      <div class="submit-buttons">
        <SmButton size="small" outline @click="handleCancel">
          {{ i18n.t('general.cancel') }}
        </SmButton>
        <SmButton size="small" @click="handleSave">
          {{ i18n.t('general.save') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import PriceInformationForm from './PriceInformationForm.vue'
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { usePriceInformationStore } from '@/stores/priceInformationStore'
  import { DialogMessageTypes } from './sm/SmDialogMessage.vue'
  import { BillingUnit, PriceInformation } from '@/client'

  const props = defineProps<{
    visible: boolean
    priceInformation: PriceInformation
    licenseModelId: string | undefined
    method: 'create' | 'edit'
    isFallback: boolean
  }>()
  const emit = defineEmits<{
    saved: []
    'update:visible': [visible: boolean]
    cancelled: []
  }>()

  const i18n = useI18n()
  const priceInformationStore = usePriceInformationStore()
  const form = ref<ComponentExposed<typeof PriceInformationForm> | null>()
  const localPriceInformation = ref(props.priceInformation)
  const dialogMessage = ref('')
  const dialogType = ref<DialogMessageTypes>('error')
  const dialogVisibilty = ref(false)
  const createdDone = ref(false)

  const visible = computed({
    get: () => props.visible,
    set: (val) => emit('update:visible', val),
  })

  if (props.isFallback) {
    localPriceInformation.value.billing_unit = BillingUnit.QUOTA
    localPriceInformation.value.quota = 0
  }

  async function handleSave() {
    if (!form.value) return

    if (!form.value.form.validate()) {
      dialogMessage.value = i18n.t('validationFailed')
      dialogType.value = 'error'
      dialogVisibilty.value = true
      return
    }

    if (props.licenseModelId) {
      if (props.method === 'edit' || createdDone.value) {
        priceInformationStore
          .updatePriceInformation(props.licenseModelId, form.value.form.fields)
          .then(() => {
            // dialogMessage.value = i18n.t('successfullySaved')
            // dialogType.value = 'success'
            // dialogVisibilty.value = true

            emit('saved')
          })
          .catch(() => {
            dialogMessage.value = i18n.t(
              'dialogs.licenceModelEditDialog.licenseModelForm.alerts.savePriceModelError'
            )
            dialogType.value = 'error'
            dialogVisibilty.value = true
          })
      } else if (props.method === 'create' || props.method === 'fallback') {
        priceInformationStore
          .createPriceInformation(props.licenseModelId, form.value.form.fields)
          .then((response) => {
            // dialogMessage.value = i18n.t(
            //   'dialogs.licenceModelEditDialog.licenseModelForm.alerts.createdPriceModel'
            // )
            // dialogType.value = 'success'
            // dialogVisibilty.value = true

            if (response && form.value)
              form.value.form.fields._id = response._id

            createdDone.value = true

            emit('saved')
          })
          .catch(() => {
            dialogMessage.value = i18n.t(
              'dialogs.licenceModelEditDialog.licenseModelForm.alerts.createdPriceModelError'
            )
            dialogType.value = 'error'
            dialogVisibilty.value = true
          })
      }
    }
  }

  function handleCancel() {
    visible.value = false
    createdDone.value = false
    dialogVisibilty.value = false
    emit('cancelled')
  }

  watch([visible], () => {
    if (!visible.value) {
      handleCancel()
    }
  })
</script>

<style scoped>
  .submit-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
</style>
