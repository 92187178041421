<template>
  <!-- settings-container defined in parent -->
  <div class="flex flex-col gap-5 p-4 pb-5 pt-5">
    <SmDialogMessage
      class="w-full"
      :message="smMessage.message"
      :visible="smMessage.visible"
      :type="smMessage.type" />

    <!-- Name -->
    <div class="flex flex-col gap-1">
      <span class="font-medium text-gray-500">{{ i18n.t('name') }}</span>
      <div class="flex gap-2">
        <SmInput
          v-model="currentUser.display_name"
          size="medium"
          outline
          design="form" />
        <SmButton
          v-if="initialDisplayName !== currentUser.display_name"
          :loading="requestLoading"
          @click="uploadUserData">
          {{ i18n.t('save') }}
        </SmButton>
      </div>
    </div>

    <!-- E-Mail -->
    <div class="flex flex-col gap-1">
      <span class="font-medium text-gray-500">{{ i18n.t('email') }}</span>
      <SmInput
        v-model="currentUser.email"
        size="medium"
        outline
        disabled
        design="form" />
    </div>

    <!-- Currency -->
    <div class="flex flex-col gap-1">
      <span class="font-medium text-gray-500">{{
        i18n.t('currencyLabel')
      }}</span>
      <SmSelect
        v-model:selection="currentCurrency"
        :options="currencyOptions"
        searchable
        @change="(v) => handleCurrencyChange(v)">
        <!-- Trigger -->
        <template #trigger="{ items }">
          <div v-if="items.length > 0" class="flex items-center gap-2">
            <div>
              <Icon
                scale="0.8"
                :icon="currencyCodeToIconName(items[0].value.i18nKey) || ''" />
            </div>

            {{ i18n.t(`currency.${items[0].value.i18nKey}`) }}

            ({{ items[0].value.currencySymbol }})
          </div>
        </template>

        <!-- Item -->
        <template #item="{ item }">
          <div>
            <Icon
              scale="0.8"
              :icon="currencyCodeToIconName(item.value.i18nKey) || ''" />
          </div>

          {{ i18n.t(`currency.${item.value.i18nKey}`) }}

          ({{ item.value.currencySymbol }})
        </template>
      </SmSelect>
    </div>

    <!-- Language -->
    <div class="flex flex-col gap-1">
      <span class="font-medium text-gray-500">{{ i18n.t('language') }}</span>
      <SmSelect
        v-model:selection="currentLanguage"
        :options="languageOptions"
        searchable
        @change="(v) => handleLanguageChange(v)">
        <!-- Trigger -->
        <template #trigger="{ items }">
          <div v-if="items.length > 0" class="flex items-center gap-2">
            <div>
              <Icon
                scale="0.8"
                :icon="`circle-flags:${items[0].value.countryCode}` || ''" />
            </div>

            {{ i18n.t(items[0].value.i18n) }}
          </div>
        </template>

        <!-- Item -->
        <template #item="{ item }">
          <div>
            <Icon
              scale="0.8"
              :icon="`circle-flags:${item.value.countryCode}` || ''" />
          </div>

          {{ i18n.t(item.value.i18n) }}
        </template>
      </SmSelect>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import {
    CurrencyCode,
    PreferenceLanguages,
    UserProfileService,
  } from '@/client'
  import { Icon } from '@iconify/vue'

  import { getApiErrorMessage } from '@/common/util/apiError'
  import { getCsrfToken, getErrorMessage } from '@/common/util/oryUtil'
  import { turnFullnameIntoFirstAndLastName } from '@/common/util/userName'
  import { useSessionStore } from '@/stores/sessionStore'
  import SmInput from './sm/SmInput.vue'
  import SmButton from './sm/SmButton.vue'
  import { currencies } from '@/common/util/currency'
  import SmSelect from './sm/SmSelect.vue'
  import { currencyCodeToCountryCode } from '@/common/util/currencyUtil'
  import { usePreferenceStore } from '@/stores/preferenceStore'

  const sessionStore = useSessionStore()
  const prefernceStore = usePreferenceStore()
  const i18n = useI18n()
  const loading = ref(true)
  const requestLoading = ref(false)

  // Language related

  interface LanguageOption {
    value: (typeof languageOptions)[keyof typeof languageOptions]
    label: string
  }

  const languageOptions = [
    {
      label: 'de',
      value: {
        countryCode: 'de',
        i18n: 'german',
        languageCode: 'de',
      },
    },
    {
      label: 'en',
      value: {
        countryCode: 'gb',
        i18n: 'english',
        languageCode: 'en',
      },
    },
    {
      label: 'fr',
      value: {
        countryCode: 'fr',
        i18n: 'french',
        languageCode: 'fr',
      },
    },
  ]

  const currentLanguage = ref([languageOptions[1]])

  async function setLanguage(language: PreferenceLanguages) {
    await prefernceStore.setPreference('language', language)
    currentLanguage.value =
      languageOptions.filter(
        (r) => r.value.languageCode === prefernceStore.preferences?.language
      ) || languageOptions[1]
    i18n.locale.value = language
  }

  onMounted(async () => {
    await prefernceStore.getPreferences()
    currentLanguage.value =
      languageOptions.filter(
        (r) => r.value.languageCode === prefernceStore.preferences?.language
      ) || languageOptions[1]
  })

  function handleLanguageChange(v: LanguageOption[]) {
    setLanguage(v[0].label)
  }

  // Currency related

  interface CurrencyOption {
    value: (typeof currencies)[keyof typeof currencies]
    label: string
  }

  const currencyOptions = computed(() => {
    return Object.values(currencies).map((r) => {
      return { value: r, label: r.i18nKey }
    })
  })

  function currencyCodeToIconName(c: string): string {
    if (c == 'BTC') return 'cryptocurrency-color:btc'
    return `circle-flags:${currencyCodeToCountryCode(c)}`
  }

  function handleCurrencyChange(newCurrency: CurrencyOption[] | undefined) {
    if (!newCurrency) return
    prefernceStore.setPreference(
      'currency_code',
      newCurrency[0].label as CurrencyCode
    )
  }

  const currentCurrency = ref([
    {
      label: 'EUR',
      value: currencies.EUR,
    },
  ])

  onMounted(() => {
    watch(
      () => prefernceStore.preferences?.currency_code,
      () => {
        if (!prefernceStore.preferences) return
        currentCurrency.value = [
          {
            label: prefernceStore.preferences?.currency_code,
            value: currencies[prefernceStore.preferences?.currency_code],
          },
        ]
      },
      { immediate: true }
    )
  })

  // USER RELATED

  const smMessage: Ref<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }> = ref({ message: '', type: 'success', visible: false })

  type OryFormData = {
    email: string
    name: { first: string; last: string }
  }

  let currentUser = unref(sessionStore.currentUser) || {
    email: '',
    display_name: '',
  }

  const initialDisplayName = new String(currentUser.display_name).toString()

  if (sessionStore.currentUser) loading.value = false

  async function uploadUserData() {
    smMessage.value.visible = false
    if (!currentUser) return

    const displayName = currentUser.display_name
    const transformedName = turnFullnameIntoFirstAndLastName(displayName)
    const firstName = transformedName.firstname
    const lastName = transformedName.lastname

    const oryRequestData: OryFormData = {
      email: currentUser.email,
      name: { first: firstName, last: lastName },
    }

    requestLoading.value = true

    const updateFlow = await sessionStore.frontend.createBrowserSettingsFlow()
    const updateFlowID = updateFlow.data.id

    const csrfToken = getCsrfToken(updateFlow.data.ui.nodes)

    const sendSuccess = () => {
      smMessage.value.message = i18n.t('successfullySaved')
      smMessage.value.type = 'success'
      smMessage.value.visible = true
    }
    const sendError = (message: string) => {
      smMessage.value.message = message
      smMessage.value.type = 'error'
      smMessage.value.visible = true
    }

    const updateOryProfile = (data: OryFormData) => {
      return sessionStore.frontend.updateSettingsFlow({
        flow: updateFlowID,
        updateSettingsFlowBody: {
          csrf_token: csrfToken,
          method: 'profile',
          traits: data,
        },
      })
    }

    // first update the ory profile, and then update the backend profile
    // if ory update succeeds, but backend update fails, we revert the ory changes
    updateOryProfile(oryRequestData)
      .then(() => {
        UserProfileService.updateUserProfileUserPatch({
          requestBody: {
            first_name: firstName,
            last_name: lastName,
            display_name: displayName,
          },
        }).then(
          () => {
            sendSuccess()
          },
          (error) => {
            sendError(
              getApiErrorMessage(error, i18n as ReturnType<typeof useI18n>)
            )
            // if the backend request fails, we need to revert the changes in the ory profile
            const initialNameTransformed =
              turnFullnameIntoFirstAndLastName(initialDisplayName)
            updateOryProfile({
              email: currentUser.email,
              name: {
                first: initialNameTransformed.firstname,
                last: initialNameTransformed.lastname,
              },
            })
          }
        )
      })
      .catch((error) => {
        sendError(
          getErrorMessage(error.data, i18n as ReturnType<typeof useI18n>)
        )
      })
      .finally(() => {
        requestLoading.value = false
      })
  }

  watch(
    () => sessionStore.currentUser,
    () => {
      if (sessionStore.currentUser) {
        currentUser = unref(sessionStore.currentUser)
        loading.value = false
      }
    },
    { immediate: true }
  )
</script>

<style scoped></style>
