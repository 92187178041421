<template>
  <div style="width: 100%">
    <div class="sm-input" :class="classes" @click="focus">
      <textarea
        v-if="type === 'area'"
        :value="modelValue"
        :disabled="props.disabled"
        rows="2"
        @input="changeValue"
        @change="handleChange"></textarea>
      <input
        v-else
        ref="input"
        required
        :value="modelValue"
        :autocomplete="props.autoComplete"
        :type="type"
        :disabled="props.disabled"
        @input="changeValue"
        @change="handleChange" />
      <label :class="{ 'value-set': modelValue }">
        <slot name="label">
          {{ props.label }}
        </slot>
      </label>

      <!-- Hide/Show Password -->
      <div
        v-if="props.type === 'password'"
        class="sm-input-icon"
        :style="{ width: iconWidth }"
        @click="passwordVisible = !passwordVisible">
        <Transition name="slide-up" mode="out-in">
          <v-icon v-if="passwordVisible" name="md-visibilityoff-round" />
          <v-icon v-else name="md-visibility-round" />
        </Transition>
      </div>
    </div>

    <!-- Password Strength -->
    <SmPasswordStrength
      v-if="props.type === 'password' && props.showPasswordStrength"
      :password="modelValue" />

    <!-- Error Message -->
    <el-collapse-transition>
      <div v-if="validationResult" class="sm-input-error">
        <slot name="error">{{ validationResult.message }}</slot>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script setup lang="ts">
  import {
    notEmpty,
    type ValidationResult,
    type ValidationRule,
  } from './SmInput/SmInputValidator'
  import SmPasswordStrength from './SmInput/SmPasswordStrength.vue'

  // Refs
  const input = ref<HTMLInputElement>()

  // Types
  type InputType = 'text' | 'password' | 'email' | 'number' | 'area'
  type InputSize = 'large' | 'medium' | 'small'
  type InputDesign = 'single' | 'form'

  // Emits
  const emit = defineEmits(['update:modelValue', 'change', 'input'])

  // Props
  const props = defineProps({
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<InputType>,
      default: 'text',
    },
    size: {
      type: String as PropType<InputSize>,
      default: 'large',
    },
    design: {
      type: String as PropType<InputDesign>,
      default: 'single',
    },
    outline: {
      type: Boolean,
      default: false,
    },
    showPasswordStrength: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: Array as PropType<ValidationRule[]>,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autoComplete: {
      type: String,
      default: 'off',
    },
  })

  // Refs
  const validationResult = ref<ValidationResult>(null)

  // Value change
  function changeValue(event: Event) {
    const target = event.target as HTMLInputElement
    if (validationResult.value) {
      validationResult.value = validate()
    }
    emit('update:modelValue', target.value)
    emit('input', target.value)
  }

  // Native change event
  function handleChange(event: Event) {
    validationResult.value = validate()
    if (!event.target) return
    const target = event.target as HTMLInputElement
    emit('change', target.value)
  }

  function resetValidation() {
    validationResult.value = null
  }

  // Classes
  const classes = computed(() => {
    return {
      'sm-input-wrapper-error': validationResult.value, // Error message
      [`sm-input-${props.size}`]: true, // Size (large, medium, small)
      [`${props.type}`]: true, // Type (text, password, email, number)
      'sm-input-outline': props.outline, // Outline
      'sm-input-disabled': props.disabled, // Disabled
      'rounded-lg': props.design == 'single',
      'border-gray-300 rounded': props.design == 'form',
      'cursor-not-allowed': props.disabled,
    }
  })
  const passwordVisible = ref(false)

  // Space for the icon (hide/show password)
  const iconWidth = computed(() => {
    if (props.type === 'password') {
      return '1.5rem'
    } else {
      return '0px'
    }
  })

  const type = computed(() => {
    if (props.type === 'password') {
      if (passwordVisible.value) {
        return 'text'
      } else {
        return 'password'
      }
    } else {
      return props.type
    }
  })

  const inputHeight = computed(() => {
    if (props.size === 'large') {
      return '2.5rem'
    } else if (props.size === 'medium') {
      return '1.2rem'
    } else if (props.size === 'small') {
      return '1rem'
    }
    return '2.5rem'
  })

  // ****** Validation rules ******
  function validate() {
    const rules: ValidationRule[] = [...props.validator]

    // Required
    if (props.required) {
      rules.push(notEmpty)
    }

    // Custom rules
    for (const rule of rules) {
      const result = rule(props.modelValue)
      if (result && result.status === 'error') {
        return result
      }
    }
    return null
  }

  const valid = computed(() => {
    return validationResult.value === null
  })

  // Helper
  function focus() {
    input?.value?.focus()
  }

  watch(
    () => props.modelValue,
    (newValue) => {
      if (newValue && validationResult.value) {
        validationResult.value = validate()
      }
    }
  )

  // Auto focus
  onMounted(() => {
    if (props.autoFocus) {
      focus()
    }
  })

  defineExpose({
    validate,
    validationResult,
    valid,
    focus,
    resetValidation,
  })
</script>

<style lang="scss">
  $input-height: v-bind(inputHeight);
  $font-size: 1rem;
  $input-padding-horizontal: 0.85rem;
  $input-padding-vertical: 0.5rem;
  $input-padding: $input-padding-vertical $input-padding-horizontal;

  html.dark .sm-input {
    background-color: var(--sm-elevation);
  }

  .sm-input {
    box-sizing: content-box;
    position: relative;
    // width: calc(100% - $input-padding-horizontal * 2);
    height: calc($input-height);
    border-radius: var(--border-radius);
    transition: box-shadow 0.1s ease-in-out;
    cursor: text;

    padding: $input-padding;

    &-large {
      border: 1px solid var(--sm-cta);

      input {
        font-size: $font-size;

        &:focus {
          border-color: var(--sm-primary);
        }

        & ~ label.value-set,
        &:focus ~ label {
          color: var(--sm-primary);
          top: 0;
          transform: translate(0.3rem, -50%);
          font-size: 0.75rem;
          background-color: var(--sm-elevation);
          border-radius: 0.25rem;
        }
      }
    }

    &-medium,
    &-small {
      box-shadow: var(--shadow);
      padding-top: 8px;
      padding-bottom: 8px;

      &:focus-within {
        box-shadow: var(--shadow-focus);
      }

      // Label

      // Dont show label if disabled
      &.sm-input-disabled {
        label {
          opacity: 0;
        }
        color: var(--el-text-color-placeholder);
      }

      label {
        transition: transform 0.1s ease-in-out;
      }

      input {
        transition: all 0.5s ease-in-out;
      }

      input,
      textarea {
        &:focus {
          border-color: var(--sm-primary);
        }

        & ~ label.value-set,
        &:focus ~ label {
          transform: translate(1.5rem, -50%);
          opacity: 0;
        }
      }
    }

    &-outline {
      border: 1px solid var(--sm-cta);
      box-shadow: none;

      &:focus-within {
        box-shadow: none;
      }

      input {
        &:focus {
          border-color: var(--sm-primary);
        }
      }

      &.sm-input-disabled {
        background-color: rgb(245, 247, 250);
      }
    }

    input {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: $input-padding-horizontal;
      border: none;

      outline: none;
      transition: all 0.2s ease-in-out;
      width: calc(100% - $input-padding-horizontal * 2 - v-bind(iconWidth));
      background-color: transparent;

      box-sizing: border-box;

      &:disabled {
        cursor: not-allowed;
      }
    }

    label {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      padding: 0 $input-padding-horizontal;
      pointer-events: none;
      transition: all 0.2s ease-in-out;
      color: var(--sm-contrast-muted);
    }

    &.area {
      height: unset;

      textarea {
        display: block;
        width: 100%;
        max-height: 200px;
        min-height: $input-height;
        background: unset;

        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }

  .sm-input:focus-within {
    border-color: var(--sm-primary);
  }

  .sm-input-wrapper-error:not(:focus-within) {
    border-color: var(--sm-magenta);

    label {
      color: var(--sm-magenta) !important;
    }
  }

  .sm-input-icon {
    position: absolute;
    top: 0;
    right: 0.5rem;
    height: calc($input-height + $input-padding-vertical * 2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--el-text-color-secondary);
    transition: all 0.2s ease-in-out;

    &:hover {
      color: var(--sm-primary);
    }
  }

  .sm-input-error {
    width: 100%;
    text-align: left;
    color: var(--sm-magenta);
    font-size: 0.75rem;
    transition: all 0.2s ease-in-out;
    opacity: 1;
  }
</style>
