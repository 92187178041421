<script setup lang="ts">
  import { Session } from '@ory/client'
  import { useI18n } from 'vue-i18n'
  import Bowser from 'bowser'

  import { sendToast } from '@/components/sm/SmNotification'
  import { useSessionStore } from '@/stores/sessionStore'

  import { Column } from './sm/SmTable.types'
  import { displayDate } from '@/common/util/timeUtils'

  const i18n = useI18n()
  const sessionStore = useSessionStore()

  // **************************************
  // Sessions
  // **************************************
  const activeSessions = ref<Session[]>([])

  const sessionTableColumns: Column<Session>[] = [
    {
      key: 'authenticated_at',
      label: 'Date',
      width: '130px',
      sortable: false,
    },
    {
      key: 'user_agent',
      label: 'User Agent',
      width: 1,
      sortable: false,
    },
    {
      key: 'ip_address',
      label: 'IP Address',
      width: 1,
      sortable: false,
    },
    {
      key: 'location',
      label: 'Location',
      width: '100px',
      sortable: false,
    },
    {
      key: 'actions',
      label: '',
      width: '30px',
      sortable: false,
    },
  ]

  function getActiveSessions() {
    activeSessions.value = []
    sessionStore.frontend.listMySessions().then((sessions) => {
      activeSessions.value = activeSessions.value.concat(sessions.data)
      sendToast(i18n.t('sessionRefreshedSuccess'), undefined, 'success')
    })
    if (sessionStore.currentOrySession)
      activeSessions.value.push(sessionStore.currentOrySession)
    else
      sessionStore.frontend.toSession().then((response) => {
        activeSessions.value.push(response.data)
      })
  }

  function deleteSession(sessionId: string) {
    if (sessionId == sessionStore.currentOrySession?.id) return
    sessionStore.frontend
      .disableMySession({ id: sessionId })
      .then(() => {
        getActiveSessions()
      })
      .then(() => {
        sendToast(i18n.t('sessionDeletedSuccess'), undefined, 'success')
      })
      .catch(() => {
        sendToast(i18n.t('sessionDeletedError'), undefined, 'error')
      })
  }

  // User Agent
  const iconMappingOS = {
    macOS: 'fa-apple',
    Windows: 'fa-windows',
    Android: 'fa-android',
    iOS: 'fa-apple',
    Linux: 'fa-linux',
  }

  const iconMappingBrowser = {
    Chrome: 'fa-chrome',
    Firefox: 'fa-firefox',
    Safari: 'fa-safari',
    Edge: 'fa-edge',
    Opera: 'fa-opera',
  }

  const userAgentCache = new Map<string, Bowser.Parser.ParsedResult>()

  function parseUserAgent(userAgent?: string) {
    if (!userAgent) return undefined
    if (userAgentCache.has(userAgent)) {
      return userAgentCache.get(userAgent)
    }

    const parser = Bowser.getParser(userAgent)
    const result = parser.getResult()
    userAgentCache.set(userAgent, result)
    return result
  }

  onMounted(() => {
    getActiveSessions()
  })
</script>

<template>
  <!-- Sessions -->
  <div class="settings-group">
    <div class="header-with-icon">
      <!-- Header -->
      <h3>{{ i18n.t('activeSessions') }} ({{ activeSessions.length }})</h3>
      <div class="refresh-wrapper">
        <v-icon
          name="md-refresh-round"
          class="fill-contrast"
          scale="1.2"
          @click="getActiveSessions" />
      </div>
    </div>

    <p style="text-align: justify">{{ i18n.t('activeSessionsDesc') }}</p>

    <SmTable
      :data="activeSessions"
      :columns="sessionTableColumns"
      key-field="id">
      <!-- Login Date -->
      <template #authenticated_at="{ row }">
        {{
          displayDate(new Date(row.authenticated_at ?? ''), {
            dateStyle: 'short',
            timeStyle: 'short',
          })
        }}
      </template>

      <!-- User Agent -->
      <template #user_agent="{ row }">
        <div class="user-agent-icons">
          <v-icon
            v-if="
              parseUserAgent(row.devices && row.devices[0].user_agent)?.os.name
            "
            :name="
              // @ts-ignore-next-line, because undefined check is provided in the v-if
              iconMappingOS[parseUserAgent(row.devices[0].user_agent).os.name]
            " />

          <v-icon
            v-if="
              row.devices &&
              parseUserAgent(row.devices[0].user_agent)?.browser.name
            "
            :name="
              iconMappingBrowser[
                // @ts-ignore-next-line, because undefined check is provided in the v-if
                parseUserAgent(row.devices[0].user_agent).browser.name
              ]
            " />
        </div>
        <TextMultiline
          v-if="row.devices && parseUserAgent(row.devices[0].user_agent)">
          {{
            //@ts-ignore-line
            parseUserAgent(row.devices[0].user_agent).browser.name
          }}
          {{
            //@ts-ignore-line
            parseUserAgent(row.devices[0].user_agent).browser.version
          }}
          {{
            //@ts-ignore-line
            parseUserAgent(row.devices[0].user_agent).os.name
          }}
          {{
            //@ts-ignore-line
            parseUserAgent(row.devices[0].user_agent).os.version
          }}
        </TextMultiline>
      </template>

      <!-- IP Address -->
      <template #ip_address="{ row }">
        <TextMultiline>
          <span v-if="row.devices">
            {{ row.devices[0].ip_address }}
          </span>
        </TextMultiline>
      </template>

      <!-- Location -->
      <template #location="{ row }">
        <TextMultiline>
          <span v-if="row.devices">
            {{ row.devices[0].location }}
          </span>
        </TextMultiline>
      </template>

      <!-- Action -->
      <template #actions="{ row }">
        <div style="padding-right: 10px">
          <SmTooltip :content="i18n.t('disconnectSession')">
            <v-icon
              name="md-webassetoff-round"
              :opacity="row.id == sessionStore.currentOrySession?.id ? 0.2 : 1"
              fill="black"
              scale="1.1"
              @click="deleteSession(row.id)" />
          </SmTooltip>
        </div>
      </template>
    </SmTable>
  </div>
</template>

<style scoped>
  .header-with-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .user-agent-icons {
    display: flex;
    gap: 3px;
    margin-right: 3px;
  }

  .refresh-wrapper:hover {
    cursor: pointer;
  }
</style>
