<template>
  <DeleteConfirmDialog
    v-model:visible="deleteDialogVisible"
    :title="i18n.t('deleteGroup')"
    :sub-title="i18n.t('deleteGroupText', { name: accountGroup?.name })"
    @delete="handleDeleteConfirm"
    @cancel="deleteDialogVisible = false" />

  <SmDialog
    v-model:visibility="visibility"
    :title="mode == 'add' ? i18n.t('addGroup') : i18n.t('editGroup')"
    :size="dialogSizes[currentStep]">
    <!-- Close button -->
    <template v-if="mode == 'edit'" #close>
      <div style="display: flex; gap: 0.6rem; align-items: center">
        <v-icon
          name="md-delete-round"
          class="trash-icon"
          scale="1.1"
          @click="handleDelete" />
        <SmButtonClose @close="visibility = false" />
      </div>
    </template>

    <!-- Meta -->
    <GroupAddForm
      v-if="currentStep === 'form'"
      ref="groupAddFormRef"
      v-model:account-group="accountGroup"
      @next="next" />

    <!-- Software and License -->
    <GroupAddSoftware
      v-if="'license' === currentStep"
      v-model:account-group="accountGroup"
      v-model:selection="softwareSelection"
      v-model:step="currentStep"
      :all-software-licenses="allSoftwareLicenses"
      :all-price-informations="allPriceInformations" />

    <!-- Changes Overview -->
    <GroupAddFinish
      v-if="false"
      :account-group="accountGroup"
      :selection="softwareSelection" />

    <!-- Footer -->
    <template #footer>
      <div class="dialog-footer">
        <div>
          <div
            v-if="currentStep !== 'form'"
            class="dialog-footer-left footer-button"
            @click="prev">
            <v-icon
              name="md-arrowforwardios-round"
              scale="1.1"
              flip="horizontal" />

            <div class="progress-button-text">
              {{ i18n.t('back') }}
            </div>
          </div>
        </div>

        <div class="dialog-footer-right footer-button" @click="next">
          <div v-if="currentStep !== 'license'" class="progress-button-text">
            {{ i18n.t('next') }}
          </div>
          <div v-else class="progress-button-text">
            {{ i18n.t('save') }}
          </div>
          <v-icon name="md-arrowforwardios-round" scale="1.1" />
        </div>
      </div>
    </template>
  </SmDialog>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { SelectionState } from '@/components/GroupAddSoftware.vue'

  import {
    AccountGroup,
    AccountGroupIn,
    AccountGroupUpdateIn,
    PriceInformation,
    SoftwareLicense,
    SoftwarePermissionUpdate,
    SoftwarePermission_Output,
    UserGroupsService,
  } from '@/client'
  import { DialogSize } from './sm/SmDialog.vue'
  import type GroupAddForm from './GroupAddForm.vue'
  import { ApplicationStore } from '@/stores/application.store'

  const i18n = useI18n()
  const emit = defineEmits(['groupsChanged'])
  const groupAddFormRef = ref<InstanceType<typeof GroupAddForm> | null>(null)
  const groupSyncActive = ref(false)

  function checkSyncGroupSelection() {
    if (!groupAddFormRef.value) return true
    return groupAddFormRef.value.checkForm()
  }

  const visibility = defineModel<boolean>('visibility')

  const props = defineProps({
    allPriceInformations: {
      type: Array as PropType<PriceInformation[]>,
      required: true,
    },
    allSoftwareLicenses: {
      type: Array as PropType<SoftwareLicense[]>,
      required: true,
    },
    mode: {
      type: String as PropType<'add' | 'edit'>,
      default: 'add',
    },
  })

  const accountGroup = defineModel('accountGroup', {
    type: Object as () => AccountGroupUpdateIn | AccountGroup | AccountGroupIn,
    default: () => ({
      name: '',
      notes: '',
      color: '',
      config: {},
      accounts: [],
      permission: {
        software_permissions: {},
      },
      data_source: {
        data_source_id: '',
        external_id: '',
      },
    }),
  })

  //   Steps
  const steps = ['form', 'license'] as const
  type Steps = (typeof steps)[number]

  const dialogSizes: Record<Steps, DialogSize> = {
    form: 'medium',
    license: 'large',
  } as const

  const currentStep: Ref<Steps> = ref('form')
  const softwareSelection = ref<SelectionState>({})

  function getCurrentStep() {
    return steps.indexOf(currentStep.value)
  }

  function saveGroup() {
    deleteNullValuesFromSoftwareSelection()
    updateGroupPermissions()

    UserGroupsService.postAccountGroupApiV1ManagementGroupsPost({
      requestBody: accountGroup.value as AccountGroupIn,
    })
      .then(() => {
        softwareSelection.value = {}
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        emit('groupsChanged')
      })
  }

  function updateAccountGroup() {
    updateGroupPermissions()

    if (!('_id' in accountGroup.value))
      return console.error('Try to update a group without an ID')

    UserGroupsService.patchAccountGroupApiV1ManagementGroupsAccountGroupIdPatch(
      {
        accountGroupId: (accountGroup.value as AccountGroup)._id,
        requestBody: accountGroup.value,
      }
    )
      .then(() => {
        visibility.value = false
        softwareSelection.value = {}
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        emit('groupsChanged')
      })
  }

  function hasDataSource(
    obj: AccountGroupUpdateIn | AccountGroup | AccountGroupIn
  ): obj is AccountGroup | AccountGroupIn {
    return 'data_source' in obj
  }

  function next() {
    const isSyncGroupSelectionInvalid = checkSyncGroupSelection() === false
    const isAccountGroupNameInvalid =
      !accountGroup.value?.name || accountGroup.value.name.trim() === ''
    const isDataSourceInvalid =
      groupSyncActive.value &&
      hasDataSource(accountGroup.value) &&
      (!accountGroup.value.data_source?.data_source_id ||
        !accountGroup.value.data_source?.external_id)

    if (
      isSyncGroupSelectionInvalid ||
      isAccountGroupNameInvalid ||
      isDataSourceInvalid
    ) {
      return groupAddFormRef.value?.formRef?.validate()
    }

    const currentStepIndex = getCurrentStep()
    if (currentStep.value === 'license') {
      if (props.mode === 'add') {
        saveGroup()
      } else {
        updateAccountGroup()
      }
      visibility.value = false
    } else if (currentStepIndex < steps.length - 1) {
      currentStep.value = steps[currentStepIndex + 1]
    }
  }

  function prev() {
    const currentStepIndex = getCurrentStep()

    if (currentStepIndex > 0) {
      currentStep.value = steps[currentStepIndex - 1]
    }
  }

  // #region Delete
  const deleteDialogVisible = ref(false)
  function handleDelete() {
    deleteDialogVisible.value = true
  }

  function handleDeleteConfirm() {
    if (!('_id' in accountGroup.value))
      return console.error('Try to delete a group without an ID')

    UserGroupsService.deleteAccountGroupApiV1ManagementGroupsAccountGroupIdDelete(
      {
        accountGroupId: accountGroup.value._id,
      }
    )
      .then(() => {
        visibility.value = false
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        emit('groupsChanged')
        deleteDialogVisible.value = false
      })
  }
  // #endregion

  watch(
    visibility,
    () => {
      currentStep.value = 'form'
    },
    { immediate: true }
  )

  function deleteNullValuesFromSoftwareSelection() {
    Object.entries(softwareSelection.value).forEach(
      ([softwareId, subAccountEntries]) => {
        if (subAccountEntries == null) {
          delete softwareSelection.value[softwareId]
          return
        }
        Object.entries(subAccountEntries).forEach(
          ([subAccountId, softwareLicenseEntries]) =>
            Object.entries(softwareLicenseEntries).forEach(
              ([softwareLicenseId, value]) => {
                if (value == null)
                  delete softwareSelection.value[softwareId]![subAccountId]![
                    softwareLicenseId
                  ]
              }
            )
        )
      }
    )
  }

  function updateGroupPermissions() {
    const _softwarePermission = convertSelectionToSoftwarePermission(
      softwareSelection.value
    )
    if (!accountGroup.value.permission) {
      accountGroup.value.permission = {
        software_permissions: {},
      }
    }
    accountGroup.value.permission.software_permissions = _softwarePermission
  }

  const { data: allApplications } = ApplicationStore.useApplications()
  function convertSelectionToSoftwarePermission(selection: SelectionState) {
    const _accountGroupPermissions: Record<
      string,
      SoftwarePermissionUpdate | null
    > = {}

    for (const [softwareId, subAccountEntries] of Object.entries(selection)) {
      if (subAccountEntries == null) {
        _accountGroupPermissions[softwareId] = null
        continue
      }
      _accountGroupPermissions[softwareId] = {
        sub_account_permissions: {},
      }
      // No licenses chosen -> set dummy value for the patch request to work
      if (Object.keys(subAccountEntries).length == 0) {
        const app = allApplications.value.find((a) => a._id == softwareId)
        if (!app) continue // should not happen
        const dummySubAccountId = Object.keys(app.sub_accounts)[0]
        _accountGroupPermissions[softwareId]!.sub_account_permissions![
          dummySubAccountId
        ] = {
          auto_assign: true,
          auto_unassign: true,
        }
      }
      for (const [subAccountId, softwareLicenseEntries] of Object.entries(
        subAccountEntries
      )) {
        _accountGroupPermissions[softwareId]!.sub_account_permissions![
          subAccountId
        ] = {
          auto_assign: true,
          auto_unassign: true,
          software_license_permissions: {},
        }

        for (const [softwareLicenseId, value] of Object.entries(
          softwareLicenseEntries
        )) {
          // null to delete Permissions
          if (value == null) {
            _accountGroupPermissions[softwareId]!.sub_account_permissions![
              subAccountId
            ]!.software_license_permissions![softwareLicenseId] = null
          } else
            _accountGroupPermissions[softwareId]!.sub_account_permissions![
              subAccountId
            ]!.software_license_permissions![softwareLicenseId] = {
              auto_assign: true,
              auto_unassign: true,
            }
        }
      }
    }
    return _accountGroupPermissions
  }

  watch(
    accountGroup,
    () => {
      if (!accountGroup.value.permission?.software_permissions) return

      softwareSelection.value = convertSoftwarePermissionToSelection(
        (accountGroup.value as AccountGroup).permission.software_permissions
      )
    },
    { immediate: true }
  )

  function convertSoftwarePermissionToSelection(
    softwarePermission: Record<string, SoftwarePermission_Output>
  ) {
    const _selection: SelectionState = {}

    for (const [softwareId, softwareEntry] of Object.entries(
      softwarePermission
    )) {
      _selection[softwareId] = {}
      for (const [subAccountId, subAccountEntry] of Object.entries(
        softwareEntry.sub_account_permissions || {}
      )) {
        _selection[softwareId]![subAccountId] = {}
        for (const [softwareLicenseId] of Object.entries(
          subAccountEntry.software_license_permissions || {}
        )) {
          const softwareLicense = props.allSoftwareLicenses.find(
            (l) => l._id == softwareLicenseId
          )
          if (softwareLicense)
            _selection[softwareId]![subAccountId][softwareLicenseId] = {
              ...softwareLicense,
            }
        }
      }
    }
    return _selection
  }

  function reset() {
    accountGroup.value = {
      name: '',
      accounts: [],
      permission: {
        software_permissions: {},
      },
      color: {
        r: 0,
        g: 0,
        b: 0,
      },
    }
    softwareSelection.value = {}
    currentStep.value = 'form'
  }

  watch(visibility, () => {
    if (!visibility.value) {
      reset()
    }
  })
</script>

<style scoped lang="scss">
  .dialog-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .dialog-footer-left {
    display: flex;
    align-items: center;
  }

  .dialog-footer-right {
    display: flex;
    align-items: center;
    gap: 0.6rem;
  }

  .footer-button {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &-next {
      right: 20px;
    }

    &-prev {
      left: 0px;
    }

    &:hover {
      scale: 1.05;
    }

    svg {
      fill: var(--sm-contrast);
    }
  }
</style>
