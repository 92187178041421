<template>
  <div class="data-source-card sm-card">
    <div class="data-source-header">
      <div style="height: 100%">
        <h3 v-if="'display_name' in datasource">
          {{ datasource.display_name }}
        </h3>
        <h3 v-else>{{ datasource.name }}</h3>
        <span v-if="'subtitle' in datasource" class="no-data">{{
          datasource.subtitle
        }}</span>
      </div>

      <div class="data-source-media">
        <img :src="`https://logos.saasmetrix.io/${datasource.name}.png`" />
      </div>
    </div>
    <p>{{ description }}</p>

    <div class="data-source-footer">
      <!-- Connect Buttons -->
      <img
        v-if="
          !connected &&
          datasource.name === 'GoogleSSO' &&
          isSSOProvider(datasource)
        "
        v-require-permission="CompanyAccessRole.ADMIN"
        style="height: 2.5rem; padding: 8px; cursor: pointer"
        src="https://logos.saasmetrix.io/btn_google_signin_light_normal_web@2x.png"
        @click="
          handleSSOConnectButtonClick(datasource as SoftwareInformation)
        " />
      <SmButton
        v-else-if="!connected && isSSOProvider(datasource)"
        @click="handleSSOConnectButtonClick(datasource as SoftwareInformation)">
        {{ i18n.t('connect') }}
      </SmButton>
      <SmButton
        v-else-if="!connected"
        @click="
          handleDataSourceConnectButtonClick(
            datasource as DataSourceInformation
          )
        ">
        {{ i18n.t('connect') }}
      </SmButton>

      <!-- Show Button -->
      <SmButton v-else outline @click="handleViewClick">
        {{ i18n.t('view') }}
      </SmButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import {
    CompanyAccessRole,
    DataSource,
    DataSourceAuthorizationService,
    DataSourceInformation,
    SoftwareAuthorizationService,
    SoftwareInformation,
    SoftwareOut,
    SoftwareTypes,
  } from '@/client'
  import { useGlobalStore } from '@/stores/globalStore'
  import { uuid4 } from '@sentry/utils'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  const router = useRouter()
  const i18n = useI18n()
  const store = useGlobalStore()

  const props = defineProps<{
    datasource: SoftwareInformation | DataSourceInformation
    connected: boolean
    connection?: SoftwareOut | DataSource
  }>()

  function handleViewClick() {
    if (isSSOProvider(props.datasource)) {
      handleSSOViewClick()
    } else {
      handleDataSourceViewClick()
    }
  }

  const description = computed(() => {
    return i18n.te(`datasources.${props.datasource.name}`)
      ? i18n.t(`datasources.${props.datasource.name}`)
      : props.datasource.description_short
  })
  // ##############################
  // #  SSO Provider
  // ##############################

  function handleSSOConnectButtonClick(ssoProvider: SoftwareInformation) {
    // Currently we have OAUTH and Token Datasources
    if (ssoProvider.type === SoftwareTypes.OAUTH) {
      openOAuthPage(ssoProvider.name)

      // Connect a Token integration
    } else if (
      ssoProvider.type === SoftwareTypes.TOKEN ||
      ssoProvider.type === SoftwareTypes.BOT
    ) {
      const query: Record<string, string> = {
        softwareName: ssoProvider.name,
        softwareType: ssoProvider.type,
        accessToken: store.getJWT() as string,
      }

      if (ssoProvider.custom_authorization_flow_required) {
        query['customAuthorizationFlow'] = 'true'
      }

      const routeData = router.resolve({
        path: 'authorize',
        query: query,
      })
      window.open(routeData.href, '_blank')
    }
  }

  function openOAuthPage(softwaerName: string) {
    SoftwareAuthorizationService.getApiV1OauthOauthLinkGet({
      softwareName: softwaerName,
      readOnly: false,
    }).then((result) => {
      window.open(result, '_blank')
    })
  }

  function isSSOProvider(
    datasource: SoftwareInformation | DataSourceInformation
  ) {
    return 'sso_provider' in datasource && datasource.sso_provider
  }

  function handleSSOViewClick() {
    if (props.connection) {
      router.push('/applications/' + props.connection._id)
    }
  }

  // ##############################
  // #  HR-Tool
  // ##############################
  const oAuthButtonLoading = ref(false)
  function handleDataSourceConnectButtonClick(
    dataSource: DataSourceInformation
  ) {
    // Token
    if (dataSource.type?.toLowerCase() === 'token') {
      const identifier = uuid4()

      const query = {
        softwareName: dataSource.integration_id,
        softwareType: dataSource.type,
        accessToken: store.getJWT(),
        identifier: identifier,
      }

      const routeData = router.resolve({
        path: '/authorize',
        query,
      })

      window.open(routeData.href, '_blank')

      // OAuth
    } else if (dataSource.type.toLowerCase() === 'oauth') {
      oAuthButtonLoading.value = true

      DataSourceAuthorizationService.getOauthUrlApiV1DataSourceOauthOauthUrlGet(
        {
          integrationId: dataSource.integration_id,
        }
      ).then((response) => {
        oAuthButtonLoading.value = false
        window.open(response, '_blank')
      })
    }
  }

  function handleDataSourceViewClick() {
    router.push('/data-sources/' + props.connection?._id)
  }
</script>

<style scoped lang="scss">
  .sm-card {
    background-color: var(--sm-elevation);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    padding: 16px;
    margin-bottom: 20px;
  }

  .data-source-card {
    display: flex;
    flex-direction: column;
    overflow: visible; // Make shadow visible

    gap: 10px;
  }

  .data-source-media {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    width: 43px;
    height: 43px;
    min-width: 43px;

    padding: 7px;

    box-shadow: var(--shadow);

    background-color: #ffffff;

    & > img {
      display: block;
      max-width: 28px;
      max-height: 28px;
      width: auto;
      height: auto;

      border-radius: 10%;
    }
  }

  .data-source-header {
    display: flex;
    gap: 5px;

    justify-content: space-between;
    align-items: center;
  }

  .data-source-footer {
    margin-top: auto;
    padding-top: 10px;

    display: flex;
    justify-content: center;
  }

  p {
    color: grey;
    margin-top: 0;
  }

  .data-source-card {
    grid-row: span 2;
  }

  p {
    font-size: 14px;
  }

  .datasource {
    display: grid;
    grid-template-rows: auto 1fr;

    .datasource-content {
      overflow-y: auto;
    }

    .fade-out {
      position: absolute;
      width: 100%;
      height: 1.5rem;

      background: linear-gradient(
        0deg,
        var(--sm-background) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      bottom: 20px;
    }
  }
</style>
