<template>
  <Teleport to="#overlay">
    <transition name="transparency" appear>
      <div
        v-if="visibility"
        :class="backgroundClasses"
        @click="visibility = !visibility"></div>
    </transition>
  </Teleport>

  <Teleport to="#overlay">
    <transition name="fade" appear>
      <div v-if="visibility" :class="[classes, props.class]">
        <div class="sm-drawer__header">
          <div class="sm-drawer__header__title">
            <slot name="title">
              <h1>{{ title }}</h1>
            </slot>
          </div>
          <div class="sm-drawer__header__close">
            <slot name="close">
              <SmButtonClose @close="visibility = !visibility" />
            </slot>
          </div>
        </div>
        <div class="sm-drawer__body">
          <slot></slot>
        </div>
        <div class="sm-drawer__footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
  import SmButtonClose from './SmButtonClose.vue'

  const props = defineProps({
    visibility: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    level: {
      type: Number,
      default: 1,
    },
    class: {
      type: String,
      default: '',
    },
  })

  const emit = defineEmits(['update:visibility'])

  const visibility = computed({
    get: () => props.visibility,
    set: (value) => emit('update:visibility', value),
  })

  const classes = computed(() => {
    return {
      'sm-drawer': true,
      'sm-drawer--level-1': props.level === 1,
      'sm-drawer--level-2': props.level === 2,
      'sm-drawer--level-3': props.level === 3,
    }
  })

  const backgroundClasses = computed(() => {
    return {
      'sm-drawer-background': true,
      'sm-drawer-background--level-1': props.level === 1,
      'sm-drawer-background--level-2': props.level === 2,
      'sm-drawer-background--level-3': props.level === 3,
    }
  })
</script>

<style lang="scss" scoped>
  $padding: 20px;
  $drawer-height: 95vh;
  $drawer-padding-right: 15px;

  :deep(h1, h2) {
    margin: 0;
    line-height: 1;
  }

  /* BEGIN Transitions */
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.2s ease;
    transform: translateX(0px);
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
    transform: translateX(150px);
  }

  .transparency-enter-active,
  .transparency-leave-active {
    transition: all 0.2s ease;
  }

  .transparency-enter-from,
  .transparency-leave-to {
    opacity: 0;
  }
  /* END Transitions */

  /* Drawer Style */
  .sm-drawer {
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1504;
    display: flex;
    flex-direction: column;
    padding: calc($padding / 2) $padding;

    @media screen and (min-width: 576px) {
      right: $drawer-padding-right;
      top: calc(50% - $drawer-height / 2);
      width: clamp(200px, 10vw, 300px);
      height: $drawer-height;
      border-radius: var(--border-radius);

      // Drawer width
      &--level-1 {
        width: clamp(500px, 45vw, 600px);
        z-index: 1502;
      }
      &--level-2 {
        width: clamp(400px, 30vw, 500px);
        z-index: 1504;
      }
      &--level-3 {
        width: clamp(300px, 20vw, 400px);
        z-index: 1506;
      }
    }

    // Glasmorphium
    background: var(--sm-elevation);
    box-shadow: 0 8px 32px 0 rgba(51, 51, 55, 0.37);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(8px);

    &__body {
      margin: 0px auto;

      height: calc(100% - 2 * $padding);
      width: calc(100% - $padding);

      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }

    &__header {
      width: calc(100% - $padding);
      margin: 0px auto;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      // Headline (h1, settings icon)
      &__title {
        position: relative;

        display: flex;
        flex: 1;
        overflow: hidden;
        flex-direction: row;
        align-items: center;
        margin-right: auto;
        gap: 1rem;

        &::before {
          // Fade out on the right side
          content: '';
          position: absolute;
          top: 0;
          right: 0;

          width: 80px;
          height: 100%;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            var(--sm-elevation)
          );
        }
      }
      &__close {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
      }
    }

    &__footer {
      width: calc(100% - $padding);
      margin: 0px auto calc($padding / 2);
      padding-top: 1.2rem;

      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
  /* Drawer Style */

  .sm-drawer-background {
    position: absolute;
    z-index: 1500;

    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);

    // Level (changes the z-index)
    &--level-1 {
      z-index: 1501;
    }
    &--level-2 {
      z-index: 1503;
    }
    &--level-3 {
      z-index: 1505;
    }
  }
</style>
