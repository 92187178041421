<template>
  <BackgroundjobAbortDialog
    v-model:visible="tasksJobAbortDialogVisible"
    v-model:job-id="tasksJobToAbort"
    @aborted="(newValue: possibleJobs) => updateJob(newValue)" />

  <DeleteConfirmDialog
    v-model:visible="tasksJobPauseAllDialogVisible"
    :title="i18n.t('pauseAllJobs')"
    :loading="pauseAllLoading"
    :delete-btn-label="i18n.t('pauseAllJobs')"
    @cancel="tasksJobPauseAllDialogVisible = false"
    @delete="pauseAllJobs()">
    <span>{{ i18n.t('pauseAllJobsConfirmation') }}</span>
  </DeleteConfirmDialog>

  <BackgroundjobScheduleDialog
    v-model:visible="tasksJobScheduleDialogVisible"
    v-model:job-id="tasksJobToSchedule"
    @scheduled="(newValue: possibleJobs) => updateJob(newValue)" />

  <TaskCreateActionDialog
    v-model:visible="createJobVisible"
    :backgroundjob-type="createJobType"
    @save="handleSaveJob" />

  <TaskOffboardUserDialog
    v-model:visible="offboardUserDialogVisible"
    @save="
      (requests: Request[]) => {
        for (const request of requests) {
          handleSaveJob(request)
        }
      }
    " />

  <!-- Drawer content -->
  <SmDrawer
    v-model:visibility="localVisibility"
    :title="capitalize(i18n.t('task'))">
    <!-- Header -->
    <template #close>
      <div class="header">
        <SmButtonClose
          data-test-id="user-detail-drawer-close-button"
          @click="localVisibility = !localVisibility" />
      </div>
    </template>

    <!-- Drawer Content -->
    <template #default>
      <!--  Info Card -->
      <div>
        <div class="flex items-center justify-between">
          <h4>{{ task?.title }}</h4>
          <v-icon
            name="md-modeedit-round"
            scale="1"
            class="hover:cursor-pointer hover:text-gray-900 hover:dark:text-gray-400"
            @click="emit('edit', task?._id)" />
        </div>
        <p v-if="task?.description" class="whitespace-pre text-wrap">
          {{ task?.description }}
        </p>
      </div>

      <i18n-t
        keypath="createdByAt"
        tag="p"
        class="mb-4 mt-4 text-sm text-contrast-muted">
        <template #date>
          <span>{{ createdDate }}</span>
        </template>
        <template #user>
          <span>
            {{ memberName }}
          </span>
        </template>
      </i18n-t>

      <!-- Jobs Overview -->
      <div class="mt-8">
        <div class="mb-3 flex items-center justify-between">
          <h3>{{ capitalize(i18n.t('backgroundjob', { count: 2 })) }}</h3>
          <TasksProgress
            :background-job-ids="jobs.map((a) => a._id)"
            :show-fallback="false"
            class="rounded-lg border-2 p-2 pr-3" />
        </div>
        <div v-if="jobs.length > 0" class="mb-3 flex flex-col gap-3">
          <BackgroundjobPreview
            v-for="job in jobs"
            :key="job._id"
            :type="job.type"
            :data="job.data"
            :status="job.status">
            <template #right>
              <SmDropdown
                v-if="
                  job.status == BackgroundJobStatus.ENQUEUED ||
                  job.status == BackgroundJobStatus.PAUSED
                ">
                <SmDropdownItem
                  v-if="job.status == BackgroundJobStatus.ENQUEUED"
                  @click="pauseJob(job._id)">
                  {{ i18n.t('pauseJob') }}
                </SmDropdownItem>
                <SmDropdownItem @click="abortJob(job._id)">
                  {{ i18n.t('abortJob') }}
                </SmDropdownItem>
                <SmDropdownItem
                  v-if="job.status == BackgroundJobStatus.PAUSED"
                  @click="scheduleJob(job._id)">
                  {{ i18n.t('scheduleJob') }}
                </SmDropdownItem>
              </SmDropdown>
            </template>
            <template #footer>
              <div class="h-3"></div>
              <div class="grid grid-cols-2 text-contrast-muted">
                <p>{{ i18n.t('status') }}:</p>
                <SmStatus :status="getStatus(job.status)" />
                <template
                  v-if="
                    job.status != BackgroundJobStatus.PAUSED &&
                    job.status != BackgroundJobStatus.ABORTED
                  ">
                  <p>
                    {{
                      job.status === BackgroundJobStatus.COMPLETED ||
                      job.status === BackgroundJobStatus.ERROR
                        ? i18n.t('lastExecution')
                        : i18n.t('nextExecution')
                    }}:
                  </p>

                  <p>
                    {{ getLocalizedExecutionDate(job) }}
                  </p>
                </template>
              </div>
            </template>
          </BackgroundjobPreview>
        </div>
        <div
          v-else
          class="mb-3 flex h-36 items-center justify-center rounded border-2 border-dashed border-gray-300">
          <p class="text-contrast-muted">{{ i18n.t('noJobsAssigned') }}</p>
        </div>
      </div>

      <SmSelect
        v-model:selection="jobSelection"
        :options="jobSelectOptions"
        :label="i18n.t('chooseJobType')"
        :item-size="66"
        size="large"
        class="w-full"
        @change="handleJobSelectionChange">
        <template #trigger>
          <div class="flex h-10 items-center gap-4">
            <v-icon name="hi-plus" />
            <p>{{ i18n.t('createJob') }}</p>
          </div>
        </template>

        <template #item="{ item }">
          <div class="flex flex-col leading-6">
            <span>{{ item.label }}</span>
            <span class="text-contrast-muted">{{ item.description }}</span>
          </div>
        </template>
      </SmSelect>
    </template>

    <template #footer>
      <div class="flex pb-3">
        <SmButton
          v-if="hasEnqueuedJobs"
          size="small"
          outline
          @click="tasksJobPauseAllDialogVisible = true">
          {{ i18n.t('pauseAllJobs') }}
        </SmButton>
        <SmButton
          size="small"
          outline
          @click="emit('archive', task?._id, task?.background_job_ids)">
          {{ i18n.t('archiveTask') }}
        </SmButton>
      </div>
    </template>
  </SmDrawer>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import {
    BackgroundJobAccountGroupAccountsAdded,
    BackgroundJobAccountGroupAccountsRemoved,
    BackgroundJobSoftwareUserAdd,
    BackgroundJobSoftwareUserEnable,
    BackgroundJobSoftwareUserLicenseAdd,
    BackgroundJobSoftwareUserLicenseRemove,
    BackgroundJobSoftwareUserRemove,
    BackgroundJobStatus,
    BackgroundJobType,
    Task,
  } from '@/client'
  import { taskStore } from '@/stores/task.store'
  import { backgroundjobStore } from '@/stores/backgroundjob.store'
  import {
    getStatus,
    BackgroundjobFilterInstance,
    getLocalizedExecutionDate,
    ExtendedBackgroundJobType,
    availableBackgroundJobTypes,
  } from '@/stores/backgroundjob.utils'
  import { Request } from './TaskEditDialog.vue'
  import { useSessionStore } from '@/stores/sessionStore'
  import { capitalize } from '@/common/util/formatter'
  import { Option } from './sm/SmSelect.vue'
  import dayjs from 'dayjs'

  import { displayDate } from '@/common/util/timeUtils'
  import { Subscription } from 'rxjs'
  import { TaskFilterInstance } from '@/stores/task.utils'
  import { editToast, sendToast } from './sm/SmNotification'

  type possibleJobs =
    | BackgroundJobAccountGroupAccountsAdded
    | BackgroundJobAccountGroupAccountsRemoved
    | BackgroundJobSoftwareUserAdd
    | BackgroundJobSoftwareUserEnable
    | BackgroundJobSoftwareUserLicenseAdd
    | BackgroundJobSoftwareUserLicenseRemove
    | BackgroundJobSoftwareUserRemove

  const i18n = useI18n()
  const sessionStore = useSessionStore()

  const tasksJobToAbort = ref<string>('')
  const tasksJobAbortDialogVisible = ref(false)
  const tasksJobToSchedule = ref<string>('')
  const tasksJobScheduleDialogVisible = ref(false)
  const tasksJobPauseAllDialogVisible = ref(false)

  const pauseAllLoading = ref(false)

  const memberName = ref('')
  const createdDate = ref('')

  const task = ref<Task | undefined>(undefined)
  const jobs = ref<Array<possibleJobs>>([])

  const localVisibility = computed({
    get: () => props.drawerVisibility,
    set: (value) => emit('update:drawerVisibility', value),
  })

  const emit = defineEmits(['update:drawerVisibility', 'edit', 'archive'])

  const props = defineProps({
    taskId: {
      type: String,
      required: true,
    },
    drawerVisibility: {
      type: Boolean,
      required: true,
    },
  })

  onMounted(async () => {
    await subscribeTask(props.taskId)
  })

  onUnmounted(() => {
    currentSubscription.value?.unsubscribe()
  })

  watch(task, async () => {
    memberName.value = await getMemberName()
    createdDate.value = displayDate(dayjs(task.value?.created).toDate(), 'long')
  })

  async function loadJobs() {
    if (!task.value) return

    const filters = ref<BackgroundjobFilterInstance[]>([])
    const idFilter = new backgroundjobStore.filter.IdFilter(
      task.value?.background_job_ids
    )
    filters.value.push(idFilter)
    const backgroundJobs = await backgroundjobStore.getBackgroundjobs(
      filters.value
    )
    if (backgroundJobs) jobs.value = backgroundJobs
  }

  async function getMemberName() {
    const members = await sessionStore.getCompanyMembers()
    const member = members.find((m) => m._id === task.value?.created_by)
    return member?.profile?.display_name ?? ''
  }

  const hasEnqueuedJobs = computed(() =>
    jobs.value.some((job) => job.status === BackgroundJobStatus.ENQUEUED)
  )

  /**
   * Subscribe to task
   */
  const currentSubscription = ref<Subscription>()

  async function subscribeTask(id: string) {
    currentSubscription.value?.unsubscribe()

    const filters = ref<TaskFilterInstance[]>([])
    const idFilter = new taskStore.filter.IdFilter([id])
    filters.value.push(idFilter)

    await taskStore
      .getTasksSubscription(filters.value, {
        limit: 1,
      })
      .then((result) => {
        currentSubscription.value = result?.subscribe((data) => {
          if (data) task.value = data[0]

          if (
            task.value?.background_job_ids.length &&
            task.value?.background_job_ids.length > 0
          ) {
            loadJobs()
          }
        })
      })
  }

  /**
   * Functions
   */

  function pauseAllJobs() {
    pauseAllLoading.value = true
    jobs.value.forEach(async (job) => {
      await pauseJob(job._id)
    })

    pauseAllLoading.value = false
    tasksJobPauseAllDialogVisible.value = false
  }

  async function pauseJob(jobId: string) {
    backgroundjobStore.pauseBackgroundjob(jobId).then((res) => {
      updateJob(res.data)
    })
  }

  function abortJob(jobId: string) {
    tasksJobToAbort.value = jobId
    tasksJobAbortDialogVisible.value = true
  }

  function scheduleJob(jobId: string) {
    tasksJobToSchedule.value = jobId
    tasksJobScheduleDialogVisible.value = true
  }

  function updateJob(value: possibleJobs) {
    const updatedJobIndex = jobs.value.findIndex((job) => job._id === value._id)
    if (updatedJobIndex !== -1) {
      jobs.value[updatedJobIndex] = value
    }
  }

  /**
   * Create job
   */
  const createJobType = ref<ExtendedBackgroundJobType>(
    BackgroundJobType.SOFTWARE_USER_ADD
  )
  const createJobVisible = ref(false)
  const offboardUserDialogVisible = ref(false)
  const jobSelection = ref<Option<ExtendedBackgroundJobType>[]>([])
  const jobSelectOptions = computed(() => {
    const options: Option<ExtendedBackgroundJobType>[] = [
      ...unref(availableBackgroundJobTypes.value),
    ]

    // Add offboard user option
    if (!options.some((option) => option.value === 'offboard_user')) {
      options.push({
        value: 'offboard_user',
        label: i18n.t('jobtype.offboardUser.label'),
        description: i18n.t('jobtype.offboardUser.description'),
        section: i18n.t('views.templates.title'),
      })
    }

    // seperate disable and delete account option
    const index = options.findIndex(
      (option) => option.value === 'software_user_add'
    )
    if (index !== -1) {
      options.splice(
        index + 1,
        0,
        {
          value: 'delete_account',
          label: i18n.t('jobtype.delete_account.label'),
          description: i18n.t('jobtype.delete_account.description'),
          section: i18n.t('jobs'),
        },
        {
          value: 'disable_account',
          label: i18n.t('jobtype.disable_account.label'),
          description: i18n.t('jobtype.disable_account.description'),
          section: i18n.t('jobs'),
        }
      )
    }

    return options.filter((option) => option.value !== 'software_user_remove')
  })

  function addJob(option: Option<ExtendedBackgroundJobType>) {
    const isTemplateOffboarding =
      option.section === i18n.t('views.templates.title') &&
      option.value === 'offboard_user'

    if (option.section === i18n.t('jobs')) {
      createJobType.value = option.value
      createJobVisible.value = true
    } else if (isTemplateOffboarding) {
      offboardUserDialogVisible.value = true
    }

    // reset selection
    jobSelection.value = []
  }

  async function handleSaveJob(request: Request) {
    const { type, enqueue_in, data } = request

    const toastId = ref<symbol | null>(null)

    toastId.value = sendToast(
      i18n.t('notification.loading'),
      undefined,
      'loading'
    )

    const response = await backgroundjobStore.createBackgroundjob(
      type,
      data,
      enqueue_in
    )

    if (!response.data) return

    jobs.value.push(response.data)

    await taskStore.updateTask(props.taskId, {
      background_job_ids: [
        ...(task.value?.background_job_ids ?? []),
        response.data._id,
      ],
    })

    if (response.error) {
      editToast(toastId.value, {
        title: i18n.t('notification.loadingError'),
        message: '',
        type: 'error',
      })
    } else {
      editToast(toastId.value, {
        title: i18n.t('notifications.tasksView.background_job.createSuccess'),
        message: '',
        type: 'success',
      })
    }

    return response
  }

  function handleJobSelectionChange(
    value: Option<ExtendedBackgroundJobType>[] | undefined
  ) {
    if (value) addJob(value[0])
  }
</script>
