import { Account, AccountUpdateIn } from '@/client'
import { db } from './database'
import { createUpdateSet, deepUnwrap } from './util'
import { CacheStateRepository, CacheStateTable } from './cacheState.store'
import dayjs from 'dayjs'
import {
  deserializePerson,
  PersonTable,
  serializePerson,
} from './person.models'
import {
  _deletePerson,
  _getPerson,
  _getPersons,
  _patchPerson,
  _postPerson,
} from './person.requests'
import { applyPersonFilters, PersonFilters } from './person.filter'

const lastCacheChange = ref<Date>()
const loadingFullReload = ref<boolean>(false)

type Pagination = {
  limit?: number
  offset?: number
}

type Sort = {
  column?: keyof PersonTable
  direction?: 'asc' | 'desc'
}

export const PersonStore = {
  loadingFullReload,

  async bulkInsertPersons(accounts: Account[], batchSize: number = 1000) {
    const serializedPersons = accounts.map(serializePerson)

    await db.transaction().execute(async (trx) => {
      for (let i = 0; i < serializedPersons.length; i += batchSize) {
        const batch = serializedPersons.slice(i, i + batchSize)
        await trx
          .insertInto('person')
          .values(batch)
          .onConflict((oc) =>
            oc.column('_id').doUpdateSet(createUpdateSet(serializedPersons[0]))
          )
          .execute()
      }
    })
    if (accounts.length > 0) {
      lastCacheChange.value = new Date()
    }
    return accounts
  },

  async getAllPersons(): Promise<Account[]> {
    const records = await db.selectFrom('person').selectAll().execute()
    return records.map(deserializePerson)
  },

  async getPersonsCount(
    filters: PersonFilters = { isDeleted: false }
  ): Promise<number> {
    const unwrappedFilters = deepUnwrap(filters)

    let query = db.selectFrom('person').select(db.fn.count('_id').as('count'))
    query = applyPersonFilters(query, unwrappedFilters)

    const result = await query.executeTakeFirst()
    return result ? Number(result.count) : 0
  },

  async deleteAllPersons(): Promise<void> {
    await db.deleteFrom('person').execute()
    lastCacheChange.value = new Date()
  },

  getDatabase() {
    return db.selectFrom('person')
  },

  async refreshPersonCacheFromApi(
    filter: PersonFilters = {},
    includeDeleted: boolean = false
  ) {
    const results = await _getPersons({
      updatedGte: filter.updatedAfter,
      updatedLt: filter.updatedBefore,
      deletedAtGte: filter.deletedAfter,
      includeDeleted: includeDeleted,
    })

    if (!results.error && results.data) {
      await this.bulkInsertPersons(results.data)
    }
    return results
  },

  async updatePersonCacheFromApi(fullReload: boolean = false) {
    let isFullReload = true

    const lastRefresh = await CacheStateRepository.getCacheStateById('persons')

    const filter: PersonFilters = {}
    if (lastRefresh && lastRefresh.lastUpdate && !fullReload) {
      filter.updatedAfter = lastRefresh.lastUpdate
      isFullReload = false
    }

    loadingFullReload.value = isFullReload

    // Update updated licenses
    await this.refreshPersonCacheFromApi(filter)

    // Update deleted licenses
    if (lastRefresh && lastRefresh.lastUpdate && !fullReload) {
      await this.refreshPersonCacheFromApi(
        {
          deletedAfter: lastRefresh?.lastUpdate,
        },
        true // includeDeleted
      )
    }

    type UpdateCacheStateTable = Partial<Omit<CacheStateTable, 'id'>> & {
      id: string
    }
    const update: UpdateCacheStateTable = {
      id: 'persons',
      lastUpdate: dayjs().toISOString(),
    }

    if (isFullReload) {
      update.lastCompleteLoad = dayjs().toISOString()
    }

    await CacheStateRepository.upsertCacheState(update)
    loadingFullReload.value = false
  },

  async getPersons(
    filters: PersonFilters = { isDeleted: false },
    pagination: Pagination = {},
    sort: Sort = {}
  ): Promise<Account[]> {
    let query = db.selectFrom('person').selectAll()

    query = applyPersonFilters(query, filters)

    if (pagination.limit) {
      query = query.limit(pagination.limit)
    }
    if (pagination.offset) {
      query = query.offset(pagination.offset)
    }
    if (sort.column) {
      query = query.orderBy(sort.column, sort.direction ?? 'asc')
    }

    const results = await query.execute()
    return results.map(deserializePerson)
  },

  async createPerson(newPerson: Account) {
    return _postPerson(newPerson).then(async (response) => {
      if (!response.error && response.data) {
        const serializedPerson = serializePerson(response.data)
        await db.insertInto('person').values(serializedPerson).execute()
      }
      return response
    })
  },

  async getPersonById(id: string): Promise<Account | null> {
    const record = await db
      .selectFrom('person')
      .selectAll()
      .where('person._id', '=', id)
      .executeTakeFirst()

    let result = record ? deserializePerson(record) : record

    if (!record) {
      await _getPerson(id).then((response) => {
        if (response && response.data != null) {
          result = response.data
          this.bulkInsertPersons([response.data])
        }
      })
    }

    return result || null
  },

  async getPersonByEmail(email: string): Promise<Account | null> {
    const record = await db
      .selectFrom('person')
      .selectAll()
      .where('person.email', '=', email)
      .executeTakeFirst()

    const result = record ? deserializePerson(record) : record

    if (!record) {
      return null
    }

    return result || null
  },

  async updatePerson(id: string, updates: AccountUpdateIn) {
    return await _patchPerson(updates).then(async (response) => {
      if (response.error) return response

      const serializedUpdates = serializePerson({
        ...response.data,
      } as Account)

      await db
        .updateTable('person')
        .set(serializedUpdates)
        .where('person._id', '=', id)
        .execute()

      lastCacheChange.value = new Date()
      return response
    })
  },

  async deletePerson(id: string) {
    return _deletePerson(id).then(async (response) => {
      await db.deleteFrom('person').where('person._id', '=', id).execute()
      lastCacheChange.value = new Date()
      return response
    })
  },

  usePersonsCount(initialFilters: PersonFilters | Ref<PersonFilters> = {}) {
    const count = ref<number>(0)

    const fetchCount = async () => {
      const filters = deepUnwrap(initialFilters) as PersonFilters
      count.value = await this.getPersonsCount(filters)
    }

    const stopWatch = watch(
      [() => unref(initialFilters), lastCacheChange],
      async () => {
        await fetchCount()
      },
      { deep: true }
    )

    fetchCount()

    return {
      count,
      unsubscribe: stopWatch,
      lastCacheUpdate: lastCacheChange,
    }
  },

  usePersons(
    initialFilters:
      | PersonFilters
      | Ref<PersonFilters>
      | ComputedRef<PersonFilters> = {},
    initialPagination:
      | Pagination
      | Ref<Pagination>
      | ComputedRef<Pagination> = {},
    initialSort: Sort | Ref<Sort> | ComputedRef<Sort> = {}
  ) {
    const data = ref<Account[]>([])

    const fetchData = async () => {
      const filters = deepUnwrap(initialFilters) as PersonFilters
      const pagination = deepUnwrap(initialPagination) as Pagination
      const sort = deepUnwrap(initialSort) as Sort
      data.value = await this.getPersons(filters, pagination, sort)
    }

    const stopWatch = watch(
      [
        () => unref(initialFilters),
        () => unref(initialPagination),
        () => unref(initialSort),
        lastCacheChange,
      ],
      async () => {
        await fetchData()
      },
      { deep: true }
    )

    const updateParameters = (
      newFilters: Partial<PersonFilters> = {},
      newPagination: Partial<Pagination> = {},
      newSort: Partial<Sort> = {}
    ) => {
      const cloneAndMerge = <T extends object>(
        source: T | Ref<T>,
        updates: Partial<T>
      ): T => {
        if (isRef(source)) {
          return { ...source.value, ...updates }
        } else {
          return { ...source, ...updates }
        }
      }

      initialFilters = cloneAndMerge(initialFilters, newFilters)
      initialPagination = cloneAndMerge(initialPagination, newPagination)
      initialSort = cloneAndMerge(initialSort, newSort)

      fetchData()
    }

    fetchData()

    return {
      data,
      unsubscribe: stopWatch,
      updateParameters,
      lastCacheUpdate: lastCacheChange,
    }
  },
}
