/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NovuSubscriberInformation } from '../models/NovuSubscriberInformation';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NotificationService {
    /**
     * Get Novu Subscriber Information
     * @returns NovuSubscriberInformation Successful Response
     * @throws ApiError
     */
    public static getNovuSubscriberInformationNovuSubscriberInformationGet({
        companyId,
        xTransactionId,
    }: {
        companyId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<NovuSubscriberInformation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/novu-subscriber-information',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'company_id': companyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
