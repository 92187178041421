<template>
  <!-- Add Custom Tool Dialog -->
  <SoftwareDrawer
    v-model:show-drawer="showEditDialog"
    :custom-software-template="selectedSoftware"
    :is-already-existing-software="false"
    :is-name-equals-to-search="createManuallySoftware"
    :adding-software-from-template="selectedSoftware ? true : false"
    @software-deleted="emit('softwareDeleted')" />

  <SmDrawer
    v-model:visibility="showSoftwareDrawer"
    :title="i18n.t('drawers.addApplicationDrawer.title')">
    <div class="mb-2 flex h-full w-full flex-col overflow-hidden">
      <SmInput
        v-model="softwareFilter"
        class="mb-2"
        :label="i18n.t('drawers.addApplicationDrawer.searchPlaceholder')"
        size="small"
        outline />

      <div
        class="software-card mb-3"
        @click="
          () => {
            if (filteredSoftware.length !== 0) {
              showEditDialog = true
              selectedSoftware = undefined
            } else createManuallyManagedSoftware()
          }
        ">
        <div class="application-wrapper" data-test-id="add-custom-software">
          <div
            id="software-manually"
            class="tool-desc-text"
            style="margin-left: 0">
            <span class="application-name">{{
              filteredSoftware.length === 0
                ? i18n.t(
                    'drawers.addApplicationDrawer.searchedSoftwareNotFound.name',
                    {
                      name: softwareFilter,
                    }
                  )
                : i18n.t('drawers.addApplicationDrawer.manuallySoftware.name')
            }}</span>
            <div class="application-type">
              {{
                filteredSoftware.length === 0
                  ? i18n.t(
                      'drawers.addApplicationDrawer.searchedSoftwareNotFound.type'
                    )
                  : i18n.t('drawers.addApplicationDrawer.manuallySoftware.type')
              }}
            </div>
          </div>
          <v-icon name="hi-chevron-right" scale="1.4" animation="pulse" hover />
        </div>
      </div>

      <!-- Software List -->

      <VList
        v-slot="{ item }"
        :data="filteredSoftware"
        :style="{ height: 'calc(100% - 80px)' }">
        <div :key="item.name + item.type" class="software-card">
          <ApplicationAddDrawerSoftwareItem
            :software="item"
            @create-custom-software="
              (softwareInformation, settings) =>
                handleSoftwareAddButtonClick(softwareInformation, settings)
            "
            @close-drawer="showSoftwareDrawer = false" />
        </div>
      </VList>
    </div>
  </SmDrawer>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'

  import {
    SoftwareAuthorizationService,
    SoftwareInformation,
    SoftwareIntegrationsService,
    SoftwarePostIn,
    SoftwareStatus,
    SoftwareTypes,
  } from '@/client'
  import { useGlobalStore } from '@/stores/globalStore'
  import { ConnectSettings } from './ApplicationAddDrawerSoftwareItem.vue'
  import { VList } from 'virtua/vue'

  const store = useGlobalStore()
  const router = useRouter()
  const route = useRoute()
  const i18n = useI18n()

  // Props and Emits
  const props = defineProps({
    addSoftwareDrawer: {
      type: Boolean,
      default: false,
    },
  })
  const emit = defineEmits([
    'update:addSoftwareDrawer',
    'createCustomSoftware',
    'softwareDeleted',
  ])

  const showEditDialog = ref<boolean>(
    route.query['open-create-custom-application-drawer'] === 'true'
  )
  const selectedSoftware = ref<SoftwarePostIn>()

  const showSoftwareDrawer = computed({
    get: (): boolean => props.addSoftwareDrawer,
    set: (value: boolean): void => emit('update:addSoftwareDrawer', value),
  })

  // Load available Integrations
  const availableIntegrations = ref<SoftwareInformation[]>([])
  const loading = ref<boolean>(false)

  onMounted(async () => {
    await loadIntegrations()
  })

  async function loadIntegrations() {
    loading.value = true
    const response =
      await SoftwareIntegrationsService.getIntegrationsApiV1SoftwareIntegrationsGet(
        {}
      )

    availableIntegrations.value = response.toSorted((a, b) =>
      a.display_name.toLocaleLowerCase() > b.display_name.toLocaleLowerCase()
        ? 1
        : -1
    )

    loading.value = false
  }

  // OAuth Link
  function getOAuthLink(softwareName: string, settings: ConnectSettings) {
    SoftwareAuthorizationService.getApiV1OauthOauthLinkGet({
      softwareName: softwareName,
      readOnly: settings.readonly,
    }).then((result) => {
      showSoftwareDrawer.value = false
      window.open(result, '_blank')
    })
  }

  // This ref is used to keep the name input not disabled
  const createManuallySoftware = ref<boolean>(false)

  // This function will be triggered if the user searches a software which sonst exist
  // And it opens the EditSoftwareDrawer with the name prefilled with the searched name
  function createManuallyManagedSoftware() {
    createManuallySoftware.value = true
    selectedSoftware.value = {
      software_name: softwareFilter.value,
      type: SoftwareTypes.MANUALLY,
      data: {},
    }
    showEditDialog.value = true
  }

  function handleSoftwareAddButtonClick(
    software: SoftwareInformation,
    settings: ConnectSettings
  ) {
    if (
      software.custom_authorization_flow_required === true ||
      software.type === SoftwareTypes.BOT ||
      software.type === SoftwareTypes.TOKEN
    ) {
      const query: Record<string, string> = {
        softwareName: software.name,
        softwareType: software.type,
        readonly: settings.readonly ? 'true' : 'false',
        accessToken: store.getJWT() as string,
      }

      if (software.custom_authorization_flow_required) {
        query['customAuthorizationFlow'] = 'true'
      }

      const routeData = router.resolve({
        path: 'authorize',
        query: query,
      })
      showSoftwareDrawer.value = false
      window.open(routeData.href, '_blank')
    } else if (software.type == SoftwareTypes.OAUTH)
      getOAuthLink(software.name, settings)
    else {
      showEditDialog.value = true
      selectedSoftware.value = {
        software_name: software.name,
        type: software.type,
      }
    }
  }

  // Filter Logic
  const softwareFilter = ref('')
  const filteredSoftware = computed<SoftwareInformation[]>(() => {
    return availableIntegrations.value.filter((val: SoftwareInformation) => {
      // Filter work in progress
      if (val.status === SoftwareStatus.WORK_IN_PROGRESS) return false

      return (
        val.name.toLowerCase().includes(softwareFilter.value.toLowerCase()) ||
        val.display_name
          .toLowerCase()
          .includes(softwareFilter.value.toLowerCase())
      )
    })
  })

  watch(
    () => route.query,
    () => {
      if (route.query['open-create-custom-application-drawer']) {
        selectedSoftware.value = undefined
        showEditDialog.value =
          route.query['open-create-custom-application-drawer'] === 'true'
        router.push('/applications')
      }

      if (route.query['open-add-application-drawer']) {
        showSoftwareDrawer.value =
          route.query['open-add-application-drawer'] === 'true'
        router.push('/applications')
      }

      if (route.query['add-application-drawer-search-query']) {
        softwareFilter.value = route.query[
          'add-application-drawer-search-query'
        ] as string
        router.push('/applications')
      }
    }
  )
</script>

<style lang="scss">
  /* Add Software Drawer */

  /* Single Application */
  .application-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .software-card {
    width: 100%;
    cursor: pointer;
    padding: 1.5rem;
    border: 1px solid theme('colors.gray[300]');
    border-radius: var(--border-radius);
    margin-bottom: 0.75rem;
  }

  .dark .software-card {
    border: 1px solid var(--sm-cta);
  }

  .software-card:hover {
    background-color: var(--el-table-row-hover-bg-color);
  }

  .software-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
  }

  .application-name {
    font-size: 1.1rem;
    line-height: 1;
  }

  .tool-desc {
    display: flex;
  }

  .tool-desc-text {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .application-type {
    color: theme('colors.gray[500]');
  }
</style>
