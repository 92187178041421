<template>
  <SmDropdown
    ref="dropdown"
    trigger="click"
    dropdown-class="max-w-none p-0"
    type="panel"
    :close-on-click-inside="false">
    <template #trigger>
      <div class="relative">
        <v-icon
          name="hi-bell"
          scale="1.2"
          class="cursor-pointer !text-contrast" />
        <span
          v-if="notificationStore.unreadCount.value > 0"
          class="absolute right-px top-px block aspect-square h-3 w-3 rounded-full border border-background bg-primary align-top"></span>
      </div>
    </template>

    <div
      class="inbox-header flex justify-between p-5 pb-2"
      style="min-width: 400px">
      <h3 class="">{{ i18n.t('notification.title') }}</h3>
      <div
        v-if="notificationStore.unreadCount.value > 0"
        class="flex h-8 items-center justify-center rounded-full bg-primary px-4 py-1 text-sm">
        <span>
          {{
            i18n.t('XUnreadNotifications', {
              count: notificationStore.unreadCount.value,
            })
          }}
        </span>
      </div>
    </div>

    <!-- ! Mark all button is placed absolutly over the tabs -->
    <SmTooltip
      v-if="notificationStore.unreadCount.value > 0"
      :content="i18n.t('notification.readAll')"
      class="absolute right-px z-10 p-2 pr-6">
      <v-icon
        name="bi-check2-all"
        scale="1.4"
        class="cursor-pointer !text-contrast"
        @click="notificationStore.readAll()" />
    </SmTooltip>

    <!-- Tabs -->
    <el-tabs v-model="activeTabName" class="inbox-tabs">
      <el-tab-pane name="inbox" :label="i18n.t('notification.inbox')">
        <!-- Inbox Notifications -->
        <div
          ref="inboxScroller"
          style="max-height: 220px"
          class="overflow-auto px-2">
          <div class="spacer h-4"></div>
          <NotificationMessage v-if="loadingInbox" :show-skeleton="true" />
          <NotificationMessage
            v-for="notification in notificationStore.inbox.value"
            :key="notification.id"
            :notification="notification" />

          <p
            v-if="notificationStore.inbox.value.length == 0 && !loadingInbox"
            class="fallback text-center">
            {{ i18n.t('notification.noNotifications') }}
          </p>

          <div class="spacer h-4"></div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="archive" :label="i18n.t('notification.archive')" lazy>
        <!-- Archive Notifications -->
        <div
          ref="archiveScroller"
          style="max-height: 220px"
          class="overflow-auto px-2">
          <div class="spacer h-4"></div>
          <NotificationMessage v-if="loadingArchive" :show-skeleton="true" />
          <NotificationMessage
            v-for="notification in notificationStore.archive.value"
            v-else
            :key="notification.id"
            :notification="notification" />
          <p
            v-if="
              notificationStore.archive.value.length == 0 && !loadingArchive
            "
            class="fallback text-center">
            {{ i18n.t('notification.noArchivedNotifications') }}
          </p>
          <div class="spacer h-4"></div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </SmDropdown>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { notificationStore } from '@/stores/notification.store'
  import { ComponentExposed } from 'vue-component-type-helpers'
  import SmDropdown from './sm/SmDropdown.vue'
  import { useInfiniteScroll } from '@vueuse/core'

  const i18n = useI18n()

  const activeTabName = ref<string>('inbox')
  const loadingInbox = ref<boolean>(true)
  const loadingArchive = ref<boolean>(true)
  const dropdown = ref<ComponentExposed<typeof SmDropdown>>()

  // initially load unarchived notifications
  watch(
    () => dropdown.value?.visible ?? false,
    async (visibility) => {
      if (visibility) {
        await notificationStore.loadNotifications(false)
        loadingInbox.value = false
      }
    },
    { immediate: true }
  )

  // lazy load archived notifications
  watch(activeTabName, async () => {
    if (activeTabName.value === 'archive')
      await notificationStore.loadNotifications(true)
    loadingArchive.value = false
  })

  const inboxScroller = ref<HTMLElement | null>(null)
  const archiveScroller = ref<HTMLElement | null>(null)

  useInfiniteScroll(
    inboxScroller,
    () => {
      if (!loadingInbox.value && notificationStore.hasMoreInbox.value) {
        notificationStore.loadMoreNotifications(false)
      }
    },
    { distance: 4 }
  )

  useInfiniteScroll(
    archiveScroller,
    async () => {
      if (!loadingArchive.value && notificationStore.hasMoreArchive.value)
        await notificationStore.loadMoreNotifications(true)
    },
    { distance: 4 }
  )

  onMounted(async () => {
    await notificationStore.subscribeNotifications()
  })
</script>

<style lang="scss">
  .inbox-tabs {
    .el-tabs__header {
      margin-bottom: 0;
    }
    .el-tabs__nav {
      // if inbox tab is the first tab, pesudo selectors wont work :/
      .el-tabs__item {
        padding: 0 12px !important;
      }
      #tab-inbox {
        padding: 0 12px 0 1.25rem !important;
      }
    }
  }
</style>
