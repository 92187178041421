import { db } from './database'

// Interface for the CacheState table
export interface CacheStateTable {
  id: string // Primary key
  lastCompleteLoad: string | null // ISO string of the last complete load
  lastUpdate: string | null // ISO string of the last update
  version: string | null // Version of the cache
  companyId: string | null
}

// Function to create the "cache" table if it doesn't exist
export async function createCacheStateTable() {
  await db.schema
    .createTable('cache')
    .ifNotExists()
    .addColumn('id', 'text', (col) => col.primaryKey().notNull())
    .addColumn('lastCompleteLoad', 'text', (col) => col.defaultTo(null)) // ISO string
    .addColumn('lastUpdate', 'text', (col) => col.defaultTo(null)) // ISO string
    .addColumn('version', 'text', (col) => col.defaultTo(null))
    .addColumn('companyId', 'text', (col) => col.defaultTo(null))
    .execute()
}

export type UpdateCacheStateTable = Partial<Omit<CacheStateTable, 'id'>> & {
  id: string
}

// CRUD Operations exported as a module
export const CacheStateRepository = {
  // Create a new cache entry
  async createCacheState(cache: CacheStateTable): Promise<CacheStateTable> {
    const insertValue = await db
      .insertInto('cache')
      .values(cache)
      .returningAll()
      .execute()

    return insertValue[0]
  },

  // Retrieve a cache by its ID
  async getCacheStateById(id: string): Promise<CacheStateTable | undefined> {
    return db
      .selectFrom('cache')
      .selectAll()
      .where('id', '=', id)
      .executeTakeFirst()
  },

  // Update a cache entry by ID
  async updateCacheState(
    id: string,
    updates: Partial<Omit<CacheStateTable, 'id'>>
  ): Promise<CacheStateTable | undefined> {
    await db.updateTable('cache').set(updates).where('id', '=', id).execute()

    return this.getCacheStateById(id)
  },

  // Upsert a cache entry
  async upsertCacheState(
    cache: UpdateCacheStateTable
  ): Promise<CacheStateTable> {
    await db
      .insertInto('cache')
      .values(cache)
      .onConflict((oc) =>
        oc.column('id').doUpdateSet({
          lastCompleteLoad: cache.lastCompleteLoad,
          lastUpdate: cache.lastUpdate,
          version: cache.version,
          companyId: cache.companyId,
        })
      )
      .execute()

    return this.getCacheStateById(cache.id) as Promise<CacheStateTable>
  },

  // Delete a cache entry by ID
  async deleteCacheState(id: string): Promise<void> {
    await db.deleteFrom('cache').where('id', '=', id).execute()
  },

  // Retrieve all caches
  async listCacheStates(): Promise<CacheStateTable[]> {
    return db.selectFrom('cache').selectAll().execute()
  },
}
