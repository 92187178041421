<template>
  <ContentOptimization
    v-if="optimizationDialogOptimization"
    v-model="optimizationDialog"
    :optimization="optimizationDialogOptimization"
    :all-software-licenses
    @hidden="emit('reload')"
    @executed="emit('reload')" />

  <div class="list-wrapper">
    <VList v-slot="{ item, index }" :data="optimizationsWithDummy">
      <ListElement
        :key="item.data._id"
        :loading="loading"
        :optimization="item"
        :current-index="index"
        @optimization-clicked="handleOptimizationClicked" />
    </VList>
  </div>

  <div
    v-if="optimizations.length === 0 && emptyState && !loading"
    class="empty-state">
    <p class="empty-state-text">
      <span style="font-size: 1.3em; font-weight: 600">
        {{ i18n.t('views.dashboard.optimizations.optimized') }}
      </span>
      <br />
      <span style="font-size: 1.5rem"> 🎉 </span>
    </p>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { Optimization, SoftwareLicense } from '@/client'
  import ContentOptimization from '@/components/ContentOptimization.vue'

  import ListElement from './OptimizationsListItem.vue'
  import { VList } from 'virtua/vue'

  const i18n = useI18n()

  const emit = defineEmits(['optimizationClicked', 'reload'])
  const optimizationDialog = ref<boolean>(false)
  const optimizationDialogOptimization = ref<Optimization>()

  function handleOptimizationClicked(optimization: Optimization) {
    optimizationDialog.value = true
    optimizationDialogOptimization.value = optimization
    emit('optimizationClicked', optimization)
  }

  const props = defineProps({
    allSoftwareLicenses: {
      type: Array as PropType<SoftwareLicense[]>,
      required: true,
    },
    optimizations: {
      type: Array as PropType<Optimization[]>,
      required: true,
    },
    emptyState: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  })

  // Dummy Optimization for loading state
  const dummyOptimizations = [
    { data: { _id: 1 }, _id: 1 },
    { data: { _id: 2 }, _id: 2 },
    { data: { _id: 3 }, _id: 3 },
    { data: { _id: 4 }, _id: 4 },
    { data: { _id: 5 }, _id: 5 },
    { data: { _id: 6 }, _id: 6 },
    { data: { _id: 7 }, _id: 7 },
    { data: { _id: 8 }, _id: 8 },
    { data: { _id: 9 }, _id: 9 },
  ]

  const optimizationsWithDummy = computed(() => {
    if (props.loading) return dummyOptimizations
    return props.optimizations
  })
</script>

<style scoped lang="scss">
  .list-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .empty-state {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .empty-state-text {
      text-align: center;
    }
  }
</style>
