<template>
  <el-card
    shadow="always"
    :body-style="{
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      justifyContent: 'space-between',
      padding: '20px 40px',
      alignItems: 'center',
    }">
    <DataSourceDrawer
      v-model:visibility="dataSourceDrawerVisibility"
      :data-source="dataSource"
      :is-name-field-disabled="false"
      @refetch-data-source="emit('refetchDataSource')" />

    <div class="stats-card-application-info-container">
      <el-image
        v-if="dataSource.integration_id"
        class="company-img"
        :src="`https://logos.saasmetrix.io/${dataSource?.integration_id}.png`"
        fit="contain">
        <template #error>
          <v-icon name="md-hideimage-round" scale="4" />
        </template>
      </el-image>
      <v-icon v-else name="md-hideimage-round" scale="4" />
      <div class="text-wrapper">
        <div class="text-row">
          <TextMultiline
            :text="dataSource?.name || ''"
            style="font-size: 17px; font-weight: 600"
            tooltip-max-width="160px" />
        </div>
        <span style="font-size: 10px; text-align: left; margin-bottom: 5px">
          {{
            i18n.t('views.externalDataSources.lastUpdated', {
              time: dataSource?.last_updated
                ? displayDate(new Date(dataSource?.last_updated), {
                    dateStyle: 'long',
                    timeStyle: 'short',
                  })
                : '-',
            })
          }}
        </span>
        <div style="display: flex">
          <el-button size="small" @click="handleSoftwareEditButton">
            <div style="display: flex; gap: 5px; align-items: center">
              <v-icon name="md-modeedit-round" scale="0.9" />
              <span>{{ i18n.t('editDataSource') }}</span>
            </div>
          </el-button>
        </div>
      </div>
    </div>
    <StatsElement
      :display-number="optimizationCount.toString()"
      display-i18n-key="detectedOptimizations"
      icon="md-rocketlaunch-round"
      color="purple" />
    <StatsElement
      :display-number="employeeCount.toString()"
      display-i18n-key="employees"
      icon="md-group"
      color="red" />
  </el-card>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { EmployeesService, OptimizationsService, DataSource } from '@/client'
  import { displayDate } from '@/common/util/timeUtils'

  const i18n = useI18n()

  const props = defineProps<{ dataSource: DataSource }>()
  const emit = defineEmits<{ refetchDataSource: [] }>()

  const dataSourceDrawerVisibility = ref<boolean>(false)

  const handleSoftwareEditButton = () => {
    dataSourceDrawerVisibility.value = true
  }

  const employeeCount = ref<number>(0)
  const optimizationCount = ref<number>(0)

  watch(
    () => props.dataSource,
    () => {
      if (props.dataSource._id) {
        getEmployeeCount()
        getOptimizationCount()
      }
    },
    { immediate: true }
  )

  function getOptimizationCount() {
    OptimizationsService.countOptimizationsApiV1ManagementOptimizationsCountGet(
      {
        softwareId: props.dataSource._id,
      }
    ).then((res) => {
      optimizationCount.value = res
    }, console.error)
  }

  function getEmployeeCount() {
    EmployeesService.countDataSourceEmployeesApiV1DataSourceDataSourcesDataSourceIdEmployeesCountGet(
      {
        dataSourceId: props.dataSource._id,
      }
    ).then((res) => {
      employeeCount.value = res
    })
  }
  defineExpose({
    employeeCount,
    optimizationCount,
  })
</script>

<style lang="scss" scoped>
  .stats-card-application-info-container {
    display: grid;
    place-content: center;
    grid-template-columns: 50px 1fr;
    gap: 20px;
    width: 32%;
    height: 100%;

    .company-img {
      height: 100%;
      width: 100%;
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;

      .text-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }
    }
  }
</style>
