<template>
  <!-- Edit license -->
  <SmDialog
    v-model:visibility="visibilityEditDialog"
    size="medium"
    :title="currentSoftware?.display_name || currentSoftware?.software_name">
    <ContentLicenseEdit
      v-if="currentSoftware && user"
      :software-licenses="softwareLicensesBySubAccount"
      :price-informations="priceInformations"
      :user="[user]"
      :software="currentSoftware"
      :license="license"
      @edited-license="emit('license-changed')"
      @close="visibilityEditDialog = false" />
  </SmDialog>

  <!-- Deleting a single license -->
  <DeleteConfirmDialog
    v-model:visible="confirmDialogVisible"
    :title="i18n.t('deleteLicence')"
    :sub-title="
      i18n.t('views.applicationDetails.deleteLicenseOf', {
        email: license.email,
      })
    "
    @cancel="confirmDialogVisible = false"
    @delete="deleteLicense" />

  <!-- Drawer -->
  <SmDrawer v-model:visibility="visibility" :title="i18n.t('account')">
    <template #close>
      <div
        v-if="currentSoftware?.information.implements_delete_user_method"
        class="dropdown-item delete-user"
        @click="confirmDialogVisible = !confirmDialogVisible">
        <v-icon name="md-delete-round" class="trash-icon" scale="1.1" />
      </div>
      <SmButtonClose @close="visibility = false" />
    </template>
    <!--License Information-->
    <div class="license-information mb-8" style="margin-top: 10px">
      <el-image
        v-if="currentSoftware?.software_name"
        :src="`https://logos.saasmetrix.io/${currentSoftware?.software_name}.png`"
        style="width: 80px; height: 80px; margin-right: 20px">
        <template #error>
          <v-icon name="md-hideimage-round" width="80px" height="80px" />
        </template>
      </el-image>

      <div class="license-information__text">
        <h3 style="margin-top: 0">
          {{ currentSoftware?.display_name || currentSoftware?.software_name }}
        </h3>
        <span
          v-if="license.email"
          class="license-information__text__mail text-contrast-muted"
          >{{ license.email }}</span
        >
        <LicenceStatusSmall v-if="license.status" :status="license.status" />
      </div>
    </div>

    <!-- License Model Display and Select-->
    <div v-loading="softwareLoading" class="license-model mb-8">
      <div
        style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: baseline;
        ">
        <h2>{{ i18n.t('licenses') }}</h2>
        <SmTooltip>
          <div style="cursor: pointer" @click="visibilityEditDialog = true">
            <v-icon name="md-create-round" />
          </div>
          <template #content>
            <span>
              {{ i18n.t('editLicense') }}
            </span>
          </template>
        </SmTooltip>
      </div>

      <!-- Empty state -->
      <div
        v-if="
          !license ||
          !license.software_licenses ||
          Object.keys(license.software_licenses).length == 0
        ">
        <span>{{
          i18n.t('noLicenses', {
            software:
              currentSoftware?.display_name || currentSoftware?.software_name,
          })
        }}</span>
      </div>
      <!-- Not empty state (content) -->
      <div
        v-for="(child, _id) in license.software_licenses"
        v-else
        :key="_id"
        style="width: 100%; margin-top: 15px">
        <div v-if="child" class="license__item">
          <div class="license__item__name">
            <!-- Image -->
            <el-image
              v-if="child && child.icon"
              :src="`https://logos.saasmetrix.io/${currentSoftware?.software_name}/${child.icon}.png`"
              style="width: 30px; height: 30px; margin-right: 15px">
              <template #error>
                <div
                  style="
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                  <v-icon name="md-imagenotsupported" scale="1.1"></v-icon>
                </div>
              </template>
            </el-image>

            <span v-if="child.name">
              {{ child.name }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Optimierungen -->
    <div
      v-loading="optimizationsLoading"
      class="optimizations license-model mb-8">
      <h2>{{ i18n.t('sidebar.optimizations') }}</h2>
      <div class="optimizations-text">
        <span v-if="optimizations.length > 0 && currentSoftware">
          <span
            v-for="optimization in optimizations"
            :key="optimization._id"
            class="optimizations-content__text">
            <OptimizationDisplayText
              show-user-email
              :software="currentSoftware"
              :lines-to-show="8"
              :optimization="optimization" />
          </span>
        </span>
        <span v-else>{{
          i18n.t('drawers.licenceDetailDrawer.noOptimizations')
        }}</span>
      </div>
    </div>

    <!-- Statistik -->
    <div
      v-if="
        license && license.statistics && license.statistics.active_user_detail
      "
      class="license-model">
      <div class="statistics">
        <h2>{{ i18n.t('drawers.licenceDetailDrawer.statistics') }}</h2>
        <div @click="refreshStatistics">
          <v-icon name="md-rotateright-round" scale="1.1" />
        </div>
      </div>
      <div
        v-if="
          license && license.statistics && license.statistics.active_user_detail
        "
        class="statistics-content">
        <h3>{{ i18n.t('lastUsage') }}</h3>
        <span></span>

        <span>{{ i18n.t('teams') }}:</span>
        <span>{{
          license.statistics.active_user_detail.teamsLastActivityDate || '-'
        }}</span>
        <span>{{ i18n.t('exchange') }}:</span>
        <span>{{
          license.statistics.active_user_detail.exchangeLastActivityDate || '-'
        }}</span>
        <span>{{ i18n.t('oneDrive') }}:</span>
        <span>{{
          license.statistics.active_user_detail.oneDriveLastActivityDate || '-'
        }}</span>
        <span>{{ i18n.t('sharePoint') }}:</span>
        <span>{{
          license.statistics.active_user_detail.sharePointLastActivityDate ||
          '-'
        }}</span>
        <span>{{ i18n.t('skypeForBusiness') }}:</span>
        <span>{{
          license.statistics.active_user_detail
            .skypeForBusinessLastActivityDate || '-'
        }}</span>
        <span>{{ i18n.t('yammer') }}:</span>
        <span>{{
          license.statistics.active_user_detail.yammerLastActivityDate || '-'
        }}</span>
      </div>
      <span v-if="!license.statistics.active_user_detail">{{
        i18n.t('drawers.licenceDetailDrawer.noStatisticsAvailable')
      }}</span>
    </div>

    <!-- User Daten -->
    <div v-loading="usersLoading" class="user license-model">
      <h2>{{ i18n.t('person') }}</h2>
      <div class="user__content license-model__content">
        <span>{{ i18n.t('general.name') }}</span>
        <span>{{ user?.name }}</span>

        <span>{{
          i18n.t('drawers.userDetailDrawer.informationCard.items.email')
        }}</span>
        <span>{{ user?.email }}</span>

        <span>
          {{ i18n.t('drawers.userDetailDrawer.informationCard.items.phone') }}
        </span>
        <span>{{ user?.phone }}</span>
      </div>
    </div>
  </SmDrawer>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import {
    Account,
    LicenceOut,
    Optimization,
    OptimizationStatus,
    OptimizationsService,
    PriceInformation,
    SoftwareLicense,
    SoftwareOut,
    SoftwareService,
  } from '@/client'

  import ContentLicenseEdit from './ContentLicenseEdit.vue'
  import { editToast, sendToast } from './sm/SmNotification'
  import SmTooltip from './sm/SmTooltip.vue'
  import { ApplicationStore } from '@/stores/application.store'
  import { AccountStore } from '@/stores/account.store'
  import { PersonStore } from '@/stores/person.store'

  const i18n = useI18n()

  // Edit License Dialog
  const visibilityEditDialog = ref(false)

  const emit = defineEmits(['license-deleted', 'license-changed'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const props = defineProps({
    priceInformations: {
      type: Object as PropType<PriceInformation[]>,
      required: true,
    },
    softwareLicenses: {
      type: Object as PropType<SoftwareLicense[]>,
      required: true,
    },
    software: {
      type: Object as PropType<SoftwareOut>,
      default: null,
    },
    subAccountId: {
      type: String,
      default: null,
    },
  })

  // Visibility
  const visibility = defineModel('visibility', {
    type: Boolean,
    required: true,
  })

  const softwareLicensesBySubAccount = computed(() => {
    return props.softwareLicenses.filter(
      (sl) => sl.sub_account_id === license.value.sub_account_id
    )
  })

  // License
  const license = defineModel('license', {
    type: Object as PropType<LicenceOut>,
    required: true,
  })

  const confirmDialogVisible = ref(false)
  const usersLoading = ref(false)
  const optimizationsLoading = ref(false)
  const softwareLoading = ref(false)

  // Software
  const currentSoftware = ref<SoftwareOut>()
  function fetchSoftware() {
    if (!currentSoftware.value) {
      softwareLoading.value = true
      ApplicationStore.getApplicationById(license.value.software_id)
        .then((response) => {
          if (response === null) throw new Error('Application not found')
          currentSoftware.value = response
        })
        .finally(() => {
          softwareLoading.value = false
        })
    }
  }

  // Optimizations
  const optimizations = ref<Optimization[]>([])
  function fetchOptimizations() {
    optimizationsLoading.value = true
    OptimizationsService.getOptimizationsApiV1ManagementOptimizationsGet({
      softwareId: license.value.software_id,
      licenceId: license.value._id,
      status: OptimizationStatus.OPEN,
      softwareRequiresAction: false,
    })
      .then((response) => {
        optimizations.value = response
      })
      .finally(() => {
        optimizationsLoading.value = false
      })
  }

  // license
  function deleteLicense() {
    AccountStore.deleteAccount(license.value._id!).then(() => {
      sendToast(
        i18n.t(
          'notifications.applicationsDetailView.licence.deleteSuccess.title'
        ),
        '',
        'success'
      )
      emit('license-deleted')
      visibility.value = false
    })
  }

  // user
  const user = ref<Account | null>(null)
  function fetchUser() {
    usersLoading.value = true
    PersonStore.getPersonByEmail(license.value.email)
      .then((response) => {
        if (response) user.value = response
      })
      .finally(() => {
        usersLoading.value = false
      })
  }

  if (license.value) {
    fetchSoftware()
    fetchOptimizations()
    fetchUser()
  }
  watch(
    () => license.value,
    () => {
      fetchSoftware()
      fetchOptimizations()
      fetchUser()
    }
  )

  function refreshStatistics() {
    const notificationId = sendToast(
      'Loading new Statistics...',
      undefined,
      'loading'
    )
    SoftwareService.fetchLicenceStatisticsApiV1SoftwareSoftwareSoftwareIdFetchLicencesStatisticsPost(
      {
        softwareId: license.value.software_id,
        subAccountId: license.value.sub_account_id,
      }
    ).finally(() => {
      editToast(notificationId, {
        title: 'New Statistics loaded',
        type: 'success',
      })
    })
  }
</script>

<style lang="scss" scoped>
  h1,
  h2 {
    line-height: 1;
  }

  h2 {
    margin-bottom: 5px;
  }

  // License information
  .license-information {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;

    &__text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      &__mail {
        margin-bottom: 8px;
      }
    }
  }

  // License model
  .license-model {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &__content {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 2px;
      width: 100%;
      align-items: center;
      justify-items: flex-start;
    }
  }

  .license__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: -10px;
    transition: background-color 0.2s ease;
    border-bottom: 1px solid #e5e5e5;
    border-radius: 10px;

    &:last-child {
      border-bottom: none;
    }

    &__name {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .optimizations-text {
    display: flex;
    justify-content: flex-start;
  }

  .license-model-tooltip-content {
    display: grid;
    grid-template-columns: auto auto;
    gap: 0 10px;
  }

  .statistics {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    align-items: baseline;
  }

  .statistics-content {
    display: grid;
    grid-template-columns: auto auto;
    gap: 0 10px;
    width: 100%;
    text-align: left;
  }
</style>
