/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingPeriod } from '../models/BillingPeriod';
import type { BillingUnit } from '../models/BillingUnit';
import type { CurrencyCode } from '../models/CurrencyCode';
import type { PaginationSortOrder } from '../models/PaginationSortOrder';
import type { PriceInformation } from '../models/PriceInformation';
import type { PriceInformationPostIn } from '../models/PriceInformationPostIn';
import type { PriceInformationUpdate } from '../models/PriceInformationUpdate';
import type { SoftwareLicense } from '../models/SoftwareLicense';
import type { SoftwareLicenseCost } from '../models/SoftwareLicenseCost';
import type { SoftwareLicensePostIn } from '../models/SoftwareLicensePostIn';
import type { SoftwareLicenseUpdateIn } from '../models/SoftwareLicenseUpdateIn';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SoftwareLicensesService {
    /**
     * Get Software Licenses
     * @returns SoftwareLicense Successful Response
     * @throws ApiError
     */
    public static getSoftwareLicensesApiV1SoftwareSoftwareLicensesGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        externalIdExists,
        externalIdEq,
        externalIdGt,
        externalIdGte,
        externalIdIn,
        externalIdLt,
        externalIdLte,
        externalIdNe,
        externalIdNin,
        externalId,
        softwareIdExists,
        softwareIdEq,
        softwareIdGt,
        softwareIdGte,
        softwareIdIn,
        softwareIdLt,
        softwareIdLte,
        softwareIdNe,
        softwareIdNin,
        softwareId,
        nameExists,
        nameEq,
        nameGt,
        nameGte,
        nameIn,
        nameLt,
        nameLte,
        nameNe,
        nameNin,
        name,
        subtitleExists,
        subtitleEq,
        subtitleGt,
        subtitleGte,
        subtitleIn,
        subtitleLt,
        subtitleLte,
        subtitleNe,
        subtitleNin,
        subtitle,
        externalNameExists,
        externalNameEq,
        externalNameGt,
        externalNameGte,
        externalNameIn,
        externalNameLt,
        externalNameLte,
        externalNameNe,
        externalNameNin,
        externalName,
        lastLoginTimeExists,
        lastLoginTimeEq,
        lastLoginTimeGt,
        lastLoginTimeGte,
        lastLoginTimeIn,
        lastLoginTimeLt,
        lastLoginTimeLte,
        lastLoginTimeNe,
        lastLoginTimeNin,
        lastLoginTime,
        subAccountIdExists,
        subAccountIdEq,
        subAccountIdGt,
        subAccountIdGte,
        subAccountIdIn,
        subAccountIdLt,
        subAccountIdLte,
        subAccountIdNe,
        subAccountIdNin,
        subAccountId,
        limit,
        offset,
        sortBy,
        sortOrder,
        sort,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        externalIdExists?: (boolean | null),
        externalIdEq?: (string | null),
        externalIdGt?: (string | null),
        externalIdGte?: (string | null),
        externalIdIn?: (Array<string> | null),
        externalIdLt?: (string | null),
        externalIdLte?: (string | null),
        externalIdNe?: (string | null),
        externalIdNin?: (Array<string> | null),
        externalId?: (string | null),
        softwareIdExists?: (boolean | null),
        softwareIdEq?: (string | null),
        softwareIdGt?: (string | null),
        softwareIdGte?: (string | null),
        softwareIdIn?: (Array<string> | null),
        softwareIdLt?: (string | null),
        softwareIdLte?: (string | null),
        softwareIdNe?: (string | null),
        softwareIdNin?: (Array<string> | null),
        softwareId?: (string | null),
        nameExists?: (boolean | null),
        nameEq?: (string | null),
        nameGt?: (string | null),
        nameGte?: (string | null),
        nameIn?: (Array<string> | null),
        nameLt?: (string | null),
        nameLte?: (string | null),
        nameNe?: (string | null),
        nameNin?: (Array<string> | null),
        name?: (string | null),
        subtitleExists?: (boolean | null),
        subtitleEq?: (string | null),
        subtitleGt?: (string | null),
        subtitleGte?: (string | null),
        subtitleIn?: (Array<string> | null),
        subtitleLt?: (string | null),
        subtitleLte?: (string | null),
        subtitleNe?: (string | null),
        subtitleNin?: (Array<string> | null),
        subtitle?: (string | null),
        externalNameExists?: (boolean | null),
        externalNameEq?: (string | null),
        externalNameGt?: (string | null),
        externalNameGte?: (string | null),
        externalNameIn?: (Array<string> | null),
        externalNameLt?: (string | null),
        externalNameLte?: (string | null),
        externalNameNe?: (string | null),
        externalNameNin?: (Array<string> | null),
        externalName?: (string | null),
        lastLoginTimeExists?: (boolean | null),
        lastLoginTimeEq?: (string | null),
        lastLoginTimeGt?: (string | null),
        lastLoginTimeGte?: (string | null),
        lastLoginTimeIn?: (Array<string> | null),
        lastLoginTimeLt?: (string | null),
        lastLoginTimeLte?: (string | null),
        lastLoginTimeNe?: (string | null),
        lastLoginTimeNin?: (Array<string> | null),
        lastLoginTime?: (string | null),
        subAccountIdExists?: (boolean | null),
        subAccountIdEq?: (string | null),
        subAccountIdGt?: (string | null),
        subAccountIdGte?: (string | null),
        subAccountIdIn?: null,
        subAccountIdLt?: (string | null),
        subAccountIdLte?: (string | null),
        subAccountIdNe?: (string | null),
        subAccountIdNin?: null,
        subAccountId?: (string | null),
        limit?: (number | null),
        offset?: (number | null),
        /**
         * Field name to be sorted by. Ignored if 'sort' is specified
         */
        sortBy?: (string | null),
        /**
         * Sort order to be sorted by. Ignored if 'sort' is specified
         */
        sortOrder?: (PaginationSortOrder | null),
        /**
         * Sort field in the form `<field_name>:<sort_by>`. Example: `sort=email:asc`. If at least one is set, sort_by and sort_order are ignored
         */
        sort?: (Array<string> | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<SoftwareLicense>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software-licenses',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'external_id.$exists': externalIdExists,
                'external_id.$eq': externalIdEq,
                'external_id.$gt': externalIdGt,
                'external_id.$gte': externalIdGte,
                'external_id.$in': externalIdIn,
                'external_id.$lt': externalIdLt,
                'external_id.$lte': externalIdLte,
                'external_id.$ne': externalIdNe,
                'external_id.$nin': externalIdNin,
                'external_id': externalId,
                'software_id.$exists': softwareIdExists,
                'software_id.$eq': softwareIdEq,
                'software_id.$gt': softwareIdGt,
                'software_id.$gte': softwareIdGte,
                'software_id.$in': softwareIdIn,
                'software_id.$lt': softwareIdLt,
                'software_id.$lte': softwareIdLte,
                'software_id.$ne': softwareIdNe,
                'software_id.$nin': softwareIdNin,
                'software_id': softwareId,
                'name.$exists': nameExists,
                'name.$eq': nameEq,
                'name.$gt': nameGt,
                'name.$gte': nameGte,
                'name.$in': nameIn,
                'name.$lt': nameLt,
                'name.$lte': nameLte,
                'name.$ne': nameNe,
                'name.$nin': nameNin,
                'name': name,
                'subtitle.$exists': subtitleExists,
                'subtitle.$eq': subtitleEq,
                'subtitle.$gt': subtitleGt,
                'subtitle.$gte': subtitleGte,
                'subtitle.$in': subtitleIn,
                'subtitle.$lt': subtitleLt,
                'subtitle.$lte': subtitleLte,
                'subtitle.$ne': subtitleNe,
                'subtitle.$nin': subtitleNin,
                'subtitle': subtitle,
                'external_name.$exists': externalNameExists,
                'external_name.$eq': externalNameEq,
                'external_name.$gt': externalNameGt,
                'external_name.$gte': externalNameGte,
                'external_name.$in': externalNameIn,
                'external_name.$lt': externalNameLt,
                'external_name.$lte': externalNameLte,
                'external_name.$ne': externalNameNe,
                'external_name.$nin': externalNameNin,
                'external_name': externalName,
                'last_login_time.$exists': lastLoginTimeExists,
                'last_login_time.$eq': lastLoginTimeEq,
                'last_login_time.$gt': lastLoginTimeGt,
                'last_login_time.$gte': lastLoginTimeGte,
                'last_login_time.$in': lastLoginTimeIn,
                'last_login_time.$lt': lastLoginTimeLt,
                'last_login_time.$lte': lastLoginTimeLte,
                'last_login_time.$ne': lastLoginTimeNe,
                'last_login_time.$nin': lastLoginTimeNin,
                'last_login_time': lastLoginTime,
                'sub_account_id.$exists': subAccountIdExists,
                'sub_account_id.$eq': subAccountIdEq,
                'sub_account_id.$gt': subAccountIdGt,
                'sub_account_id.$gte': subAccountIdGte,
                'sub_account_id.$in': subAccountIdIn,
                'sub_account_id.$lt': subAccountIdLt,
                'sub_account_id.$lte': subAccountIdLte,
                'sub_account_id.$ne': subAccountIdNe,
                'sub_account_id.$nin': subAccountIdNin,
                'sub_account_id': subAccountId,
                'limit': limit,
                'offset': offset,
                'sort_by': sortBy,
                'sort_order': sortOrder,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Software Licenses Costs
     * @returns SoftwareLicenseCost Successful Response
     * @throws ApiError
     */
    public static getSoftwareLicensesCostsApiV1SoftwareSoftwareLicensesCostsGet({
        targetCurrencyCode = 'EUR',
        userCostIgnoreUnassignedQuota = false,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        externalIdExists,
        externalIdEq,
        externalIdGt,
        externalIdGte,
        externalIdIn,
        externalIdLt,
        externalIdLte,
        externalIdNe,
        externalIdNin,
        externalId,
        softwareIdExists,
        softwareIdEq,
        softwareIdGt,
        softwareIdGte,
        softwareIdIn,
        softwareIdLt,
        softwareIdLte,
        softwareIdNe,
        softwareIdNin,
        softwareId,
        nameExists,
        nameEq,
        nameGt,
        nameGte,
        nameIn,
        nameLt,
        nameLte,
        nameNe,
        nameNin,
        name,
        subtitleExists,
        subtitleEq,
        subtitleGt,
        subtitleGte,
        subtitleIn,
        subtitleLt,
        subtitleLte,
        subtitleNe,
        subtitleNin,
        subtitle,
        externalNameExists,
        externalNameEq,
        externalNameGt,
        externalNameGte,
        externalNameIn,
        externalNameLt,
        externalNameLte,
        externalNameNe,
        externalNameNin,
        externalName,
        lastLoginTimeExists,
        lastLoginTimeEq,
        lastLoginTimeGt,
        lastLoginTimeGte,
        lastLoginTimeIn,
        lastLoginTimeLt,
        lastLoginTimeLte,
        lastLoginTimeNe,
        lastLoginTimeNin,
        lastLoginTime,
        subAccountIdExists,
        subAccountIdEq,
        subAccountIdGt,
        subAccountIdGte,
        subAccountIdIn,
        subAccountIdLt,
        subAccountIdLte,
        subAccountIdNe,
        subAccountIdNin,
        subAccountId,
        limit,
        offset,
        sortBy,
        sortOrder,
        sort,
        xTransactionId,
    }: {
        targetCurrencyCode?: CurrencyCode,
        userCostIgnoreUnassignedQuota?: boolean,
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        externalIdExists?: (boolean | null),
        externalIdEq?: (string | null),
        externalIdGt?: (string | null),
        externalIdGte?: (string | null),
        externalIdIn?: (Array<string> | null),
        externalIdLt?: (string | null),
        externalIdLte?: (string | null),
        externalIdNe?: (string | null),
        externalIdNin?: (Array<string> | null),
        externalId?: (string | null),
        softwareIdExists?: (boolean | null),
        softwareIdEq?: (string | null),
        softwareIdGt?: (string | null),
        softwareIdGte?: (string | null),
        softwareIdIn?: (Array<string> | null),
        softwareIdLt?: (string | null),
        softwareIdLte?: (string | null),
        softwareIdNe?: (string | null),
        softwareIdNin?: (Array<string> | null),
        softwareId?: (string | null),
        nameExists?: (boolean | null),
        nameEq?: (string | null),
        nameGt?: (string | null),
        nameGte?: (string | null),
        nameIn?: (Array<string> | null),
        nameLt?: (string | null),
        nameLte?: (string | null),
        nameNe?: (string | null),
        nameNin?: (Array<string> | null),
        name?: (string | null),
        subtitleExists?: (boolean | null),
        subtitleEq?: (string | null),
        subtitleGt?: (string | null),
        subtitleGte?: (string | null),
        subtitleIn?: (Array<string> | null),
        subtitleLt?: (string | null),
        subtitleLte?: (string | null),
        subtitleNe?: (string | null),
        subtitleNin?: (Array<string> | null),
        subtitle?: (string | null),
        externalNameExists?: (boolean | null),
        externalNameEq?: (string | null),
        externalNameGt?: (string | null),
        externalNameGte?: (string | null),
        externalNameIn?: (Array<string> | null),
        externalNameLt?: (string | null),
        externalNameLte?: (string | null),
        externalNameNe?: (string | null),
        externalNameNin?: (Array<string> | null),
        externalName?: (string | null),
        lastLoginTimeExists?: (boolean | null),
        lastLoginTimeEq?: (string | null),
        lastLoginTimeGt?: (string | null),
        lastLoginTimeGte?: (string | null),
        lastLoginTimeIn?: (Array<string> | null),
        lastLoginTimeLt?: (string | null),
        lastLoginTimeLte?: (string | null),
        lastLoginTimeNe?: (string | null),
        lastLoginTimeNin?: (Array<string> | null),
        lastLoginTime?: (string | null),
        subAccountIdExists?: (boolean | null),
        subAccountIdEq?: (string | null),
        subAccountIdGt?: (string | null),
        subAccountIdGte?: (string | null),
        subAccountIdIn?: null,
        subAccountIdLt?: (string | null),
        subAccountIdLte?: (string | null),
        subAccountIdNe?: (string | null),
        subAccountIdNin?: null,
        subAccountId?: (string | null),
        limit?: (number | null),
        offset?: (number | null),
        /**
         * Field name to be sorted by. Ignored if 'sort' is specified
         */
        sortBy?: (string | null),
        /**
         * Sort order to be sorted by. Ignored if 'sort' is specified
         */
        sortOrder?: (PaginationSortOrder | null),
        /**
         * Sort field in the form `<field_name>:<sort_by>`. Example: `sort=email:asc`. If at least one is set, sort_by and sort_order are ignored
         */
        sort?: (Array<string> | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Record<string, SoftwareLicenseCost>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software-licenses/costs',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'target_currency_code': targetCurrencyCode,
                'user_cost_ignore_unassigned_quota': userCostIgnoreUnassignedQuota,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'external_id.$exists': externalIdExists,
                'external_id.$eq': externalIdEq,
                'external_id.$gt': externalIdGt,
                'external_id.$gte': externalIdGte,
                'external_id.$in': externalIdIn,
                'external_id.$lt': externalIdLt,
                'external_id.$lte': externalIdLte,
                'external_id.$ne': externalIdNe,
                'external_id.$nin': externalIdNin,
                'external_id': externalId,
                'software_id.$exists': softwareIdExists,
                'software_id.$eq': softwareIdEq,
                'software_id.$gt': softwareIdGt,
                'software_id.$gte': softwareIdGte,
                'software_id.$in': softwareIdIn,
                'software_id.$lt': softwareIdLt,
                'software_id.$lte': softwareIdLte,
                'software_id.$ne': softwareIdNe,
                'software_id.$nin': softwareIdNin,
                'software_id': softwareId,
                'name.$exists': nameExists,
                'name.$eq': nameEq,
                'name.$gt': nameGt,
                'name.$gte': nameGte,
                'name.$in': nameIn,
                'name.$lt': nameLt,
                'name.$lte': nameLte,
                'name.$ne': nameNe,
                'name.$nin': nameNin,
                'name': name,
                'subtitle.$exists': subtitleExists,
                'subtitle.$eq': subtitleEq,
                'subtitle.$gt': subtitleGt,
                'subtitle.$gte': subtitleGte,
                'subtitle.$in': subtitleIn,
                'subtitle.$lt': subtitleLt,
                'subtitle.$lte': subtitleLte,
                'subtitle.$ne': subtitleNe,
                'subtitle.$nin': subtitleNin,
                'subtitle': subtitle,
                'external_name.$exists': externalNameExists,
                'external_name.$eq': externalNameEq,
                'external_name.$gt': externalNameGt,
                'external_name.$gte': externalNameGte,
                'external_name.$in': externalNameIn,
                'external_name.$lt': externalNameLt,
                'external_name.$lte': externalNameLte,
                'external_name.$ne': externalNameNe,
                'external_name.$nin': externalNameNin,
                'external_name': externalName,
                'last_login_time.$exists': lastLoginTimeExists,
                'last_login_time.$eq': lastLoginTimeEq,
                'last_login_time.$gt': lastLoginTimeGt,
                'last_login_time.$gte': lastLoginTimeGte,
                'last_login_time.$in': lastLoginTimeIn,
                'last_login_time.$lt': lastLoginTimeLt,
                'last_login_time.$lte': lastLoginTimeLte,
                'last_login_time.$ne': lastLoginTimeNe,
                'last_login_time.$nin': lastLoginTimeNin,
                'last_login_time': lastLoginTime,
                'sub_account_id.$exists': subAccountIdExists,
                'sub_account_id.$eq': subAccountIdEq,
                'sub_account_id.$gt': subAccountIdGt,
                'sub_account_id.$gte': subAccountIdGte,
                'sub_account_id.$in': subAccountIdIn,
                'sub_account_id.$lt': subAccountIdLt,
                'sub_account_id.$lte': subAccountIdLte,
                'sub_account_id.$ne': subAccountIdNe,
                'sub_account_id.$nin': subAccountIdNin,
                'sub_account_id': subAccountId,
                'limit': limit,
                'offset': offset,
                'sort_by': sortBy,
                'sort_order': sortOrder,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Software Licenses
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countSoftwareLicensesApiV1SoftwareSoftwareLicensesCountGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        externalIdExists,
        externalIdEq,
        externalIdGt,
        externalIdGte,
        externalIdIn,
        externalIdLt,
        externalIdLte,
        externalIdNe,
        externalIdNin,
        externalId,
        softwareIdExists,
        softwareIdEq,
        softwareIdGt,
        softwareIdGte,
        softwareIdIn,
        softwareIdLt,
        softwareIdLte,
        softwareIdNe,
        softwareIdNin,
        softwareId,
        nameExists,
        nameEq,
        nameGt,
        nameGte,
        nameIn,
        nameLt,
        nameLte,
        nameNe,
        nameNin,
        name,
        subtitleExists,
        subtitleEq,
        subtitleGt,
        subtitleGte,
        subtitleIn,
        subtitleLt,
        subtitleLte,
        subtitleNe,
        subtitleNin,
        subtitle,
        externalNameExists,
        externalNameEq,
        externalNameGt,
        externalNameGte,
        externalNameIn,
        externalNameLt,
        externalNameLte,
        externalNameNe,
        externalNameNin,
        externalName,
        lastLoginTimeExists,
        lastLoginTimeEq,
        lastLoginTimeGt,
        lastLoginTimeGte,
        lastLoginTimeIn,
        lastLoginTimeLt,
        lastLoginTimeLte,
        lastLoginTimeNe,
        lastLoginTimeNin,
        lastLoginTime,
        subAccountIdExists,
        subAccountIdEq,
        subAccountIdGt,
        subAccountIdGte,
        subAccountIdIn,
        subAccountIdLt,
        subAccountIdLte,
        subAccountIdNe,
        subAccountIdNin,
        subAccountId,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        externalIdExists?: (boolean | null),
        externalIdEq?: (string | null),
        externalIdGt?: (string | null),
        externalIdGte?: (string | null),
        externalIdIn?: (Array<string> | null),
        externalIdLt?: (string | null),
        externalIdLte?: (string | null),
        externalIdNe?: (string | null),
        externalIdNin?: (Array<string> | null),
        externalId?: (string | null),
        softwareIdExists?: (boolean | null),
        softwareIdEq?: (string | null),
        softwareIdGt?: (string | null),
        softwareIdGte?: (string | null),
        softwareIdIn?: (Array<string> | null),
        softwareIdLt?: (string | null),
        softwareIdLte?: (string | null),
        softwareIdNe?: (string | null),
        softwareIdNin?: (Array<string> | null),
        softwareId?: (string | null),
        nameExists?: (boolean | null),
        nameEq?: (string | null),
        nameGt?: (string | null),
        nameGte?: (string | null),
        nameIn?: (Array<string> | null),
        nameLt?: (string | null),
        nameLte?: (string | null),
        nameNe?: (string | null),
        nameNin?: (Array<string> | null),
        name?: (string | null),
        subtitleExists?: (boolean | null),
        subtitleEq?: (string | null),
        subtitleGt?: (string | null),
        subtitleGte?: (string | null),
        subtitleIn?: (Array<string> | null),
        subtitleLt?: (string | null),
        subtitleLte?: (string | null),
        subtitleNe?: (string | null),
        subtitleNin?: (Array<string> | null),
        subtitle?: (string | null),
        externalNameExists?: (boolean | null),
        externalNameEq?: (string | null),
        externalNameGt?: (string | null),
        externalNameGte?: (string | null),
        externalNameIn?: (Array<string> | null),
        externalNameLt?: (string | null),
        externalNameLte?: (string | null),
        externalNameNe?: (string | null),
        externalNameNin?: (Array<string> | null),
        externalName?: (string | null),
        lastLoginTimeExists?: (boolean | null),
        lastLoginTimeEq?: (string | null),
        lastLoginTimeGt?: (string | null),
        lastLoginTimeGte?: (string | null),
        lastLoginTimeIn?: (Array<string> | null),
        lastLoginTimeLt?: (string | null),
        lastLoginTimeLte?: (string | null),
        lastLoginTimeNe?: (string | null),
        lastLoginTimeNin?: (Array<string> | null),
        lastLoginTime?: (string | null),
        subAccountIdExists?: (boolean | null),
        subAccountIdEq?: (string | null),
        subAccountIdGt?: (string | null),
        subAccountIdGte?: (string | null),
        subAccountIdIn?: null,
        subAccountIdLt?: (string | null),
        subAccountIdLte?: (string | null),
        subAccountIdNe?: (string | null),
        subAccountIdNin?: null,
        subAccountId?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software-licenses/count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'external_id.$exists': externalIdExists,
                'external_id.$eq': externalIdEq,
                'external_id.$gt': externalIdGt,
                'external_id.$gte': externalIdGte,
                'external_id.$in': externalIdIn,
                'external_id.$lt': externalIdLt,
                'external_id.$lte': externalIdLte,
                'external_id.$ne': externalIdNe,
                'external_id.$nin': externalIdNin,
                'external_id': externalId,
                'software_id.$exists': softwareIdExists,
                'software_id.$eq': softwareIdEq,
                'software_id.$gt': softwareIdGt,
                'software_id.$gte': softwareIdGte,
                'software_id.$in': softwareIdIn,
                'software_id.$lt': softwareIdLt,
                'software_id.$lte': softwareIdLte,
                'software_id.$ne': softwareIdNe,
                'software_id.$nin': softwareIdNin,
                'software_id': softwareId,
                'name.$exists': nameExists,
                'name.$eq': nameEq,
                'name.$gt': nameGt,
                'name.$gte': nameGte,
                'name.$in': nameIn,
                'name.$lt': nameLt,
                'name.$lte': nameLte,
                'name.$ne': nameNe,
                'name.$nin': nameNin,
                'name': name,
                'subtitle.$exists': subtitleExists,
                'subtitle.$eq': subtitleEq,
                'subtitle.$gt': subtitleGt,
                'subtitle.$gte': subtitleGte,
                'subtitle.$in': subtitleIn,
                'subtitle.$lt': subtitleLt,
                'subtitle.$lte': subtitleLte,
                'subtitle.$ne': subtitleNe,
                'subtitle.$nin': subtitleNin,
                'subtitle': subtitle,
                'external_name.$exists': externalNameExists,
                'external_name.$eq': externalNameEq,
                'external_name.$gt': externalNameGt,
                'external_name.$gte': externalNameGte,
                'external_name.$in': externalNameIn,
                'external_name.$lt': externalNameLt,
                'external_name.$lte': externalNameLte,
                'external_name.$ne': externalNameNe,
                'external_name.$nin': externalNameNin,
                'external_name': externalName,
                'last_login_time.$exists': lastLoginTimeExists,
                'last_login_time.$eq': lastLoginTimeEq,
                'last_login_time.$gt': lastLoginTimeGt,
                'last_login_time.$gte': lastLoginTimeGte,
                'last_login_time.$in': lastLoginTimeIn,
                'last_login_time.$lt': lastLoginTimeLt,
                'last_login_time.$lte': lastLoginTimeLte,
                'last_login_time.$ne': lastLoginTimeNe,
                'last_login_time.$nin': lastLoginTimeNin,
                'last_login_time': lastLoginTime,
                'sub_account_id.$exists': subAccountIdExists,
                'sub_account_id.$eq': subAccountIdEq,
                'sub_account_id.$gt': subAccountIdGt,
                'sub_account_id.$gte': subAccountIdGte,
                'sub_account_id.$in': subAccountIdIn,
                'sub_account_id.$lt': subAccountIdLt,
                'sub_account_id.$lte': subAccountIdLte,
                'sub_account_id.$ne': subAccountIdNe,
                'sub_account_id.$nin': subAccountIdNin,
                'sub_account_id': subAccountId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Software License
     * @returns SoftwareLicense Successful Response
     * @throws ApiError
     */
    public static getSoftwareLicenseApiV1SoftwareSoftwareLicensesSoftwareLicenseIdGet({
        softwareLicenseId,
        xTransactionId,
    }: {
        softwareLicenseId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<SoftwareLicense> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software-licenses/{software_license_id}',
            path: {
                'software_license_id': softwareLicenseId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Software Software License
     * @returns SoftwareLicense Successful Response
     * @throws ApiError
     */
    public static patchSoftwareSoftwareLicenseApiV1SoftwareSoftwareLicensesSoftwareLicenseIdPatch({
        softwareLicenseId,
        requestBody,
        xTransactionId,
    }: {
        softwareLicenseId: string,
        requestBody: SoftwareLicenseUpdateIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<SoftwareLicense> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/software/software-licenses/{software_license_id}',
            path: {
                'software_license_id': softwareLicenseId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Software Software License
     * @returns void
     * @throws ApiError
     */
    public static deleteSoftwareSoftwareLicenseApiV1SoftwareSoftwareLicensesSoftwareLicenseIdDelete({
        softwareLicenseId,
        xTransactionId,
    }: {
        softwareLicenseId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/software/software-licenses/{software_license_id}',
            path: {
                'software_license_id': softwareLicenseId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Software License Cost
     * @returns SoftwareLicenseCost Successful Response
     * @throws ApiError
     */
    public static getSoftwareLicenseCostApiV1SoftwareSoftwareLicensesSoftwareLicenseIdCostGet({
        softwareLicenseId,
        targetCurrencyCode = 'EUR',
        userCostIgnoreUnassignedQuota = false,
        xTransactionId,
    }: {
        softwareLicenseId: string,
        targetCurrencyCode?: CurrencyCode,
        userCostIgnoreUnassignedQuota?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<SoftwareLicenseCost> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software-licenses/{software_license_id}/cost',
            path: {
                'software_license_id': softwareLicenseId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'target_currency_code': targetCurrencyCode,
                'user_cost_ignore_unassigned_quota': userCostIgnoreUnassignedQuota,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Software Licenses For Software
     * @returns SoftwareLicense Successful Response
     * @throws ApiError
     */
    public static getSoftwareLicensesForSoftwareApiV1SoftwareSoftwareSoftwareIdLicensesGet({
        softwareId,
        xTransactionId,
    }: {
        softwareId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<SoftwareLicense>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software/{software_id}/licenses',
            path: {
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Post Software Software License
     * @returns SoftwareLicense Successful Response
     * @throws ApiError
     */
    public static postSoftwareSoftwareLicenseApiV1SoftwareSoftwareSoftwareIdLicensesPost({
        softwareId,
        requestBody,
        xTransactionId,
    }: {
        softwareId: string,
        requestBody: SoftwareLicensePostIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<SoftwareLicense> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/software/software/{software_id}/licenses',
            path: {
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Software Licenses Costs For Software
     * @returns SoftwareLicenseCost Successful Response
     * @throws ApiError
     */
    public static getSoftwareLicensesCostsForSoftwareApiV1SoftwareSoftwareSoftwareIdLicensesCostsGet({
        softwareId,
        targetCurrencyCode = 'EUR',
        userCostIgnoreUnassignedQuota = false,
        xTransactionId,
    }: {
        softwareId: string,
        targetCurrencyCode?: CurrencyCode,
        userCostIgnoreUnassignedQuota?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<Record<string, SoftwareLicenseCost>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software/{software_id}/licenses/costs',
            path: {
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'target_currency_code': targetCurrencyCode,
                'user_cost_ignore_unassigned_quota': userCostIgnoreUnassignedQuota,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Software Licenses For Software
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countSoftwareLicensesForSoftwareApiV1SoftwareSoftwareSoftwareIdLicensesCountGet({
        softwareId,
        xTransactionId,
    }: {
        softwareId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software/{software_id}/licenses/count',
            path: {
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Software License For Software
     * @returns SoftwareLicense Successful Response
     * @throws ApiError
     */
    public static getSoftwareLicenseForSoftwareApiV1SoftwareSoftwareSoftwareIdLicensesSoftwareLicenseIdGet({
        softwareLicenseId,
        softwareId,
        xTransactionId,
    }: {
        softwareLicenseId: string,
        softwareId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<SoftwareLicense> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software/{software_id}/licenses/{software_license_id}',
            path: {
                'software_license_id': softwareLicenseId,
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Software License Price Informations
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countSoftwareLicensePriceInformationsApiV1SoftwareSoftwareLicensePriceInformationsCountGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        softwareLicenseIdExists,
        softwareLicenseIdEq,
        softwareLicenseIdGt,
        softwareLicenseIdGte,
        softwareLicenseIdIn,
        softwareLicenseIdLt,
        softwareLicenseIdLte,
        softwareLicenseIdNe,
        softwareLicenseIdNin,
        softwareLicenseId,
        softwareIdExists,
        softwareIdEq,
        softwareIdGt,
        softwareIdGte,
        softwareIdIn,
        softwareIdLt,
        softwareIdLte,
        softwareIdNe,
        softwareIdNin,
        softwareId,
        subAccountIdExists,
        subAccountIdEq,
        subAccountIdGt,
        subAccountIdGte,
        subAccountIdIn,
        subAccountIdLt,
        subAccountIdLte,
        subAccountIdNe,
        subAccountIdNin,
        subAccountId,
        billingPeriodExists,
        billingPeriodEq,
        billingPeriodGt,
        billingPeriodGte,
        billingPeriodIn,
        billingPeriodLt,
        billingPeriodLte,
        billingPeriodNe,
        billingPeriodNin,
        billingPeriod,
        billingUnitExists,
        billingUnitEq,
        billingUnitGt,
        billingUnitGte,
        billingUnitIn,
        billingUnitLt,
        billingUnitLte,
        billingUnitNe,
        billingUnitNin,
        billingUnit,
        billingDurationExists,
        billingDurationEq,
        billingDurationGt,
        billingDurationGte,
        billingDurationIn,
        billingDurationLt,
        billingDurationLte,
        billingDurationNe,
        billingDurationNin,
        billingDuration,
        priceExists,
        priceEq,
        priceGt,
        priceGte,
        priceIn,
        priceLt,
        priceLte,
        priceNe,
        priceNin,
        price,
        currencyExists,
        currencyEq,
        currencyGt,
        currencyGte,
        currencyIn,
        currencyLt,
        currencyLte,
        currencyNe,
        currencyNin,
        currency,
        descriptionExists,
        descriptionEq,
        descriptionGt,
        descriptionGte,
        descriptionIn,
        descriptionLt,
        descriptionLte,
        descriptionNe,
        descriptionNin,
        description,
        quotaExists,
        quotaEq,
        quotaGt,
        quotaGte,
        quotaIn,
        quotaLt,
        quotaLte,
        quotaNe,
        quotaNin,
        quota,
        fromDateExists,
        fromDateEq,
        fromDateGt,
        fromDateGte,
        fromDateIn,
        fromDateLt,
        fromDateLte,
        fromDateNe,
        fromDateNin,
        fromDate,
        endDateExists,
        endDateEq,
        endDateGt,
        endDateGte,
        endDateIn,
        endDateLt,
        endDateLte,
        endDateNe,
        endDateNin,
        endDate,
        reminderDateExists,
        reminderDateEq,
        reminderDateGt,
        reminderDateGte,
        reminderDateIn,
        reminderDateLt,
        reminderDateLte,
        reminderDateNe,
        reminderDateNin,
        reminderDate,
        sendReminderToExists,
        sendReminderToEq,
        sendReminderToGt,
        sendReminderToGte,
        sendReminderToIn,
        sendReminderToLt,
        sendReminderToLte,
        sendReminderToNe,
        sendReminderToNin,
        sendReminderTo,
        reminderSendAtExists,
        reminderSendAtEq,
        reminderSendAtGt,
        reminderSendAtGte,
        reminderSendAtIn,
        reminderSendAtLt,
        reminderSendAtLte,
        reminderSendAtNe,
        reminderSendAtNin,
        reminderSendAt,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        softwareLicenseIdExists?: (boolean | null),
        softwareLicenseIdEq?: (string | null),
        softwareLicenseIdGt?: (string | null),
        softwareLicenseIdGte?: (string | null),
        softwareLicenseIdIn?: (Array<string> | null),
        softwareLicenseIdLt?: (string | null),
        softwareLicenseIdLte?: (string | null),
        softwareLicenseIdNe?: (string | null),
        softwareLicenseIdNin?: (Array<string> | null),
        softwareLicenseId?: (string | null),
        softwareIdExists?: (boolean | null),
        softwareIdEq?: (string | null),
        softwareIdGt?: (string | null),
        softwareIdGte?: (string | null),
        softwareIdIn?: (Array<string> | null),
        softwareIdLt?: (string | null),
        softwareIdLte?: (string | null),
        softwareIdNe?: (string | null),
        softwareIdNin?: (Array<string> | null),
        softwareId?: (string | null),
        subAccountIdExists?: (boolean | null),
        subAccountIdEq?: (string | null),
        subAccountIdGt?: (string | null),
        subAccountIdGte?: (string | null),
        subAccountIdIn?: (Array<string> | null),
        subAccountIdLt?: (string | null),
        subAccountIdLte?: (string | null),
        subAccountIdNe?: (string | null),
        subAccountIdNin?: (Array<string> | null),
        subAccountId?: (string | null),
        billingPeriodExists?: (boolean | null),
        billingPeriodEq?: (BillingPeriod | null),
        billingPeriodGt?: (BillingPeriod | null),
        billingPeriodGte?: (BillingPeriod | null),
        billingPeriodIn?: (Array<BillingPeriod> | null),
        billingPeriodLt?: (BillingPeriod | null),
        billingPeriodLte?: (BillingPeriod | null),
        billingPeriodNe?: (BillingPeriod | null),
        billingPeriodNin?: (Array<BillingPeriod> | null),
        billingPeriod?: (BillingPeriod | null),
        billingUnitExists?: (boolean | null),
        billingUnitEq?: (BillingUnit | null),
        billingUnitGt?: (BillingUnit | null),
        billingUnitGte?: (BillingUnit | null),
        billingUnitIn?: (Array<BillingUnit> | null),
        billingUnitLt?: (BillingUnit | null),
        billingUnitLte?: (BillingUnit | null),
        billingUnitNe?: (BillingUnit | null),
        billingUnitNin?: (Array<BillingUnit> | null),
        billingUnit?: (BillingUnit | null),
        billingDurationExists?: (boolean | null),
        billingDurationEq?: (number | null),
        billingDurationGt?: (number | null),
        billingDurationGte?: (number | null),
        billingDurationIn?: (Array<number> | null),
        billingDurationLt?: (number | null),
        billingDurationLte?: (number | null),
        billingDurationNe?: (number | null),
        billingDurationNin?: (Array<number> | null),
        billingDuration?: (number | null),
        priceExists?: (boolean | null),
        priceEq?: (number | null),
        priceGt?: (number | null),
        priceGte?: (number | null),
        priceIn?: (Array<number> | null),
        priceLt?: (number | null),
        priceLte?: (number | null),
        priceNe?: (number | null),
        priceNin?: (Array<number> | null),
        price?: (number | null),
        currencyExists?: (boolean | null),
        currencyEq?: (CurrencyCode | null),
        currencyGt?: (CurrencyCode | null),
        currencyGte?: (CurrencyCode | null),
        currencyIn?: (Array<CurrencyCode> | null),
        currencyLt?: (CurrencyCode | null),
        currencyLte?: (CurrencyCode | null),
        currencyNe?: (CurrencyCode | null),
        currencyNin?: (Array<CurrencyCode> | null),
        currency?: (CurrencyCode | null),
        descriptionExists?: (boolean | null),
        descriptionEq?: (string | null),
        descriptionGt?: (string | null),
        descriptionGte?: (string | null),
        descriptionIn?: (Array<string> | null),
        descriptionLt?: (string | null),
        descriptionLte?: (string | null),
        descriptionNe?: (string | null),
        descriptionNin?: (Array<string> | null),
        description?: (string | null),
        quotaExists?: (boolean | null),
        quotaEq?: (number | null),
        quotaGt?: (number | null),
        quotaGte?: (number | null),
        quotaIn?: (Array<number> | null),
        quotaLt?: (number | null),
        quotaLte?: (number | null),
        quotaNe?: (number | null),
        quotaNin?: (Array<number> | null),
        quota?: (number | null),
        fromDateExists?: (boolean | null),
        fromDateEq?: (string | null),
        fromDateGt?: (string | null),
        fromDateGte?: (string | null),
        fromDateIn?: (Array<string> | null),
        fromDateLt?: (string | null),
        fromDateLte?: (string | null),
        fromDateNe?: (string | null),
        fromDateNin?: (Array<string> | null),
        fromDate?: (string | null),
        endDateExists?: (boolean | null),
        endDateEq?: (string | null),
        endDateGt?: (string | null),
        endDateGte?: (string | null),
        endDateIn?: (Array<string> | null),
        endDateLt?: (string | null),
        endDateLte?: (string | null),
        endDateNe?: (string | null),
        endDateNin?: (Array<string> | null),
        endDate?: (string | null),
        reminderDateExists?: (boolean | null),
        reminderDateEq?: (string | null),
        reminderDateGt?: (string | null),
        reminderDateGte?: (string | null),
        reminderDateIn?: (Array<string> | null),
        reminderDateLt?: (string | null),
        reminderDateLte?: (string | null),
        reminderDateNe?: (string | null),
        reminderDateNin?: (Array<string> | null),
        reminderDate?: (string | null),
        sendReminderToExists?: (boolean | null),
        sendReminderToEq?: (string | null),
        sendReminderToGt?: (string | null),
        sendReminderToGte?: (string | null),
        sendReminderToIn?: (Array<string> | null),
        sendReminderToLt?: (string | null),
        sendReminderToLte?: (string | null),
        sendReminderToNe?: (string | null),
        sendReminderToNin?: (Array<string> | null),
        sendReminderTo?: (string | null),
        reminderSendAtExists?: (boolean | null),
        reminderSendAtEq?: (string | null),
        reminderSendAtGt?: (string | null),
        reminderSendAtGte?: (string | null),
        reminderSendAtIn?: (Array<string> | null),
        reminderSendAtLt?: (string | null),
        reminderSendAtLte?: (string | null),
        reminderSendAtNe?: (string | null),
        reminderSendAtNin?: (Array<string> | null),
        reminderSendAt?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software-license-price-informations/count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'software_license_id.$exists': softwareLicenseIdExists,
                'software_license_id.$eq': softwareLicenseIdEq,
                'software_license_id.$gt': softwareLicenseIdGt,
                'software_license_id.$gte': softwareLicenseIdGte,
                'software_license_id.$in': softwareLicenseIdIn,
                'software_license_id.$lt': softwareLicenseIdLt,
                'software_license_id.$lte': softwareLicenseIdLte,
                'software_license_id.$ne': softwareLicenseIdNe,
                'software_license_id.$nin': softwareLicenseIdNin,
                'software_license_id': softwareLicenseId,
                'software_id.$exists': softwareIdExists,
                'software_id.$eq': softwareIdEq,
                'software_id.$gt': softwareIdGt,
                'software_id.$gte': softwareIdGte,
                'software_id.$in': softwareIdIn,
                'software_id.$lt': softwareIdLt,
                'software_id.$lte': softwareIdLte,
                'software_id.$ne': softwareIdNe,
                'software_id.$nin': softwareIdNin,
                'software_id': softwareId,
                'sub_account_id.$exists': subAccountIdExists,
                'sub_account_id.$eq': subAccountIdEq,
                'sub_account_id.$gt': subAccountIdGt,
                'sub_account_id.$gte': subAccountIdGte,
                'sub_account_id.$in': subAccountIdIn,
                'sub_account_id.$lt': subAccountIdLt,
                'sub_account_id.$lte': subAccountIdLte,
                'sub_account_id.$ne': subAccountIdNe,
                'sub_account_id.$nin': subAccountIdNin,
                'sub_account_id': subAccountId,
                'billing_period.$exists': billingPeriodExists,
                'billing_period.$eq': billingPeriodEq,
                'billing_period.$gt': billingPeriodGt,
                'billing_period.$gte': billingPeriodGte,
                'billing_period.$in': billingPeriodIn,
                'billing_period.$lt': billingPeriodLt,
                'billing_period.$lte': billingPeriodLte,
                'billing_period.$ne': billingPeriodNe,
                'billing_period.$nin': billingPeriodNin,
                'billing_period': billingPeriod,
                'billing_unit.$exists': billingUnitExists,
                'billing_unit.$eq': billingUnitEq,
                'billing_unit.$gt': billingUnitGt,
                'billing_unit.$gte': billingUnitGte,
                'billing_unit.$in': billingUnitIn,
                'billing_unit.$lt': billingUnitLt,
                'billing_unit.$lte': billingUnitLte,
                'billing_unit.$ne': billingUnitNe,
                'billing_unit.$nin': billingUnitNin,
                'billing_unit': billingUnit,
                'billing_duration.$exists': billingDurationExists,
                'billing_duration.$eq': billingDurationEq,
                'billing_duration.$gt': billingDurationGt,
                'billing_duration.$gte': billingDurationGte,
                'billing_duration.$in': billingDurationIn,
                'billing_duration.$lt': billingDurationLt,
                'billing_duration.$lte': billingDurationLte,
                'billing_duration.$ne': billingDurationNe,
                'billing_duration.$nin': billingDurationNin,
                'billing_duration': billingDuration,
                'price.$exists': priceExists,
                'price.$eq': priceEq,
                'price.$gt': priceGt,
                'price.$gte': priceGte,
                'price.$in': priceIn,
                'price.$lt': priceLt,
                'price.$lte': priceLte,
                'price.$ne': priceNe,
                'price.$nin': priceNin,
                'price': price,
                'currency.$exists': currencyExists,
                'currency.$eq': currencyEq,
                'currency.$gt': currencyGt,
                'currency.$gte': currencyGte,
                'currency.$in': currencyIn,
                'currency.$lt': currencyLt,
                'currency.$lte': currencyLte,
                'currency.$ne': currencyNe,
                'currency.$nin': currencyNin,
                'currency': currency,
                'description.$exists': descriptionExists,
                'description.$eq': descriptionEq,
                'description.$gt': descriptionGt,
                'description.$gte': descriptionGte,
                'description.$in': descriptionIn,
                'description.$lt': descriptionLt,
                'description.$lte': descriptionLte,
                'description.$ne': descriptionNe,
                'description.$nin': descriptionNin,
                'description': description,
                'quota.$exists': quotaExists,
                'quota.$eq': quotaEq,
                'quota.$gt': quotaGt,
                'quota.$gte': quotaGte,
                'quota.$in': quotaIn,
                'quota.$lt': quotaLt,
                'quota.$lte': quotaLte,
                'quota.$ne': quotaNe,
                'quota.$nin': quotaNin,
                'quota': quota,
                'from_date.$exists': fromDateExists,
                'from_date.$eq': fromDateEq,
                'from_date.$gt': fromDateGt,
                'from_date.$gte': fromDateGte,
                'from_date.$in': fromDateIn,
                'from_date.$lt': fromDateLt,
                'from_date.$lte': fromDateLte,
                'from_date.$ne': fromDateNe,
                'from_date.$nin': fromDateNin,
                'from_date': fromDate,
                'end_date.$exists': endDateExists,
                'end_date.$eq': endDateEq,
                'end_date.$gt': endDateGt,
                'end_date.$gte': endDateGte,
                'end_date.$in': endDateIn,
                'end_date.$lt': endDateLt,
                'end_date.$lte': endDateLte,
                'end_date.$ne': endDateNe,
                'end_date.$nin': endDateNin,
                'end_date': endDate,
                'reminder_date.$exists': reminderDateExists,
                'reminder_date.$eq': reminderDateEq,
                'reminder_date.$gt': reminderDateGt,
                'reminder_date.$gte': reminderDateGte,
                'reminder_date.$in': reminderDateIn,
                'reminder_date.$lt': reminderDateLt,
                'reminder_date.$lte': reminderDateLte,
                'reminder_date.$ne': reminderDateNe,
                'reminder_date.$nin': reminderDateNin,
                'reminder_date': reminderDate,
                'send_reminder_to.$exists': sendReminderToExists,
                'send_reminder_to.$eq': sendReminderToEq,
                'send_reminder_to.$gt': sendReminderToGt,
                'send_reminder_to.$gte': sendReminderToGte,
                'send_reminder_to.$in': sendReminderToIn,
                'send_reminder_to.$lt': sendReminderToLt,
                'send_reminder_to.$lte': sendReminderToLte,
                'send_reminder_to.$ne': sendReminderToNe,
                'send_reminder_to.$nin': sendReminderToNin,
                'send_reminder_to': sendReminderTo,
                'reminder_send_at.$exists': reminderSendAtExists,
                'reminder_send_at.$eq': reminderSendAtEq,
                'reminder_send_at.$gt': reminderSendAtGt,
                'reminder_send_at.$gte': reminderSendAtGte,
                'reminder_send_at.$in': reminderSendAtIn,
                'reminder_send_at.$lt': reminderSendAtLt,
                'reminder_send_at.$lte': reminderSendAtLte,
                'reminder_send_at.$ne': reminderSendAtNe,
                'reminder_send_at.$nin': reminderSendAtNin,
                'reminder_send_at': reminderSendAt,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Software License Price Informations
     * @returns PriceInformation Successful Response
     * @throws ApiError
     */
    public static getSoftwareLicensePriceInformationsApiV1SoftwareSoftwareLicensePriceInformationsGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        softwareLicenseIdExists,
        softwareLicenseIdEq,
        softwareLicenseIdGt,
        softwareLicenseIdGte,
        softwareLicenseIdIn,
        softwareLicenseIdLt,
        softwareLicenseIdLte,
        softwareLicenseIdNe,
        softwareLicenseIdNin,
        softwareLicenseId,
        softwareIdExists,
        softwareIdEq,
        softwareIdGt,
        softwareIdGte,
        softwareIdIn,
        softwareIdLt,
        softwareIdLte,
        softwareIdNe,
        softwareIdNin,
        softwareId,
        subAccountIdExists,
        subAccountIdEq,
        subAccountIdGt,
        subAccountIdGte,
        subAccountIdIn,
        subAccountIdLt,
        subAccountIdLte,
        subAccountIdNe,
        subAccountIdNin,
        subAccountId,
        billingPeriodExists,
        billingPeriodEq,
        billingPeriodGt,
        billingPeriodGte,
        billingPeriodIn,
        billingPeriodLt,
        billingPeriodLte,
        billingPeriodNe,
        billingPeriodNin,
        billingPeriod,
        billingUnitExists,
        billingUnitEq,
        billingUnitGt,
        billingUnitGte,
        billingUnitIn,
        billingUnitLt,
        billingUnitLte,
        billingUnitNe,
        billingUnitNin,
        billingUnit,
        billingDurationExists,
        billingDurationEq,
        billingDurationGt,
        billingDurationGte,
        billingDurationIn,
        billingDurationLt,
        billingDurationLte,
        billingDurationNe,
        billingDurationNin,
        billingDuration,
        priceExists,
        priceEq,
        priceGt,
        priceGte,
        priceIn,
        priceLt,
        priceLte,
        priceNe,
        priceNin,
        price,
        currencyExists,
        currencyEq,
        currencyGt,
        currencyGte,
        currencyIn,
        currencyLt,
        currencyLte,
        currencyNe,
        currencyNin,
        currency,
        descriptionExists,
        descriptionEq,
        descriptionGt,
        descriptionGte,
        descriptionIn,
        descriptionLt,
        descriptionLte,
        descriptionNe,
        descriptionNin,
        description,
        quotaExists,
        quotaEq,
        quotaGt,
        quotaGte,
        quotaIn,
        quotaLt,
        quotaLte,
        quotaNe,
        quotaNin,
        quota,
        fromDateExists,
        fromDateEq,
        fromDateGt,
        fromDateGte,
        fromDateIn,
        fromDateLt,
        fromDateLte,
        fromDateNe,
        fromDateNin,
        fromDate,
        endDateExists,
        endDateEq,
        endDateGt,
        endDateGte,
        endDateIn,
        endDateLt,
        endDateLte,
        endDateNe,
        endDateNin,
        endDate,
        reminderDateExists,
        reminderDateEq,
        reminderDateGt,
        reminderDateGte,
        reminderDateIn,
        reminderDateLt,
        reminderDateLte,
        reminderDateNe,
        reminderDateNin,
        reminderDate,
        sendReminderToExists,
        sendReminderToEq,
        sendReminderToGt,
        sendReminderToGte,
        sendReminderToIn,
        sendReminderToLt,
        sendReminderToLte,
        sendReminderToNe,
        sendReminderToNin,
        sendReminderTo,
        reminderSendAtExists,
        reminderSendAtEq,
        reminderSendAtGt,
        reminderSendAtGte,
        reminderSendAtIn,
        reminderSendAtLt,
        reminderSendAtLte,
        reminderSendAtNe,
        reminderSendAtNin,
        reminderSendAt,
        limit,
        offset,
        sortBy,
        sortOrder,
        sort,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        softwareLicenseIdExists?: (boolean | null),
        softwareLicenseIdEq?: (string | null),
        softwareLicenseIdGt?: (string | null),
        softwareLicenseIdGte?: (string | null),
        softwareLicenseIdIn?: (Array<string> | null),
        softwareLicenseIdLt?: (string | null),
        softwareLicenseIdLte?: (string | null),
        softwareLicenseIdNe?: (string | null),
        softwareLicenseIdNin?: (Array<string> | null),
        softwareLicenseId?: (string | null),
        softwareIdExists?: (boolean | null),
        softwareIdEq?: (string | null),
        softwareIdGt?: (string | null),
        softwareIdGte?: (string | null),
        softwareIdIn?: (Array<string> | null),
        softwareIdLt?: (string | null),
        softwareIdLte?: (string | null),
        softwareIdNe?: (string | null),
        softwareIdNin?: (Array<string> | null),
        softwareId?: (string | null),
        subAccountIdExists?: (boolean | null),
        subAccountIdEq?: (string | null),
        subAccountIdGt?: (string | null),
        subAccountIdGte?: (string | null),
        subAccountIdIn?: (Array<string> | null),
        subAccountIdLt?: (string | null),
        subAccountIdLte?: (string | null),
        subAccountIdNe?: (string | null),
        subAccountIdNin?: (Array<string> | null),
        subAccountId?: (string | null),
        billingPeriodExists?: (boolean | null),
        billingPeriodEq?: (BillingPeriod | null),
        billingPeriodGt?: (BillingPeriod | null),
        billingPeriodGte?: (BillingPeriod | null),
        billingPeriodIn?: (Array<BillingPeriod> | null),
        billingPeriodLt?: (BillingPeriod | null),
        billingPeriodLte?: (BillingPeriod | null),
        billingPeriodNe?: (BillingPeriod | null),
        billingPeriodNin?: (Array<BillingPeriod> | null),
        billingPeriod?: (BillingPeriod | null),
        billingUnitExists?: (boolean | null),
        billingUnitEq?: (BillingUnit | null),
        billingUnitGt?: (BillingUnit | null),
        billingUnitGte?: (BillingUnit | null),
        billingUnitIn?: (Array<BillingUnit> | null),
        billingUnitLt?: (BillingUnit | null),
        billingUnitLte?: (BillingUnit | null),
        billingUnitNe?: (BillingUnit | null),
        billingUnitNin?: (Array<BillingUnit> | null),
        billingUnit?: (BillingUnit | null),
        billingDurationExists?: (boolean | null),
        billingDurationEq?: (number | null),
        billingDurationGt?: (number | null),
        billingDurationGte?: (number | null),
        billingDurationIn?: (Array<number> | null),
        billingDurationLt?: (number | null),
        billingDurationLte?: (number | null),
        billingDurationNe?: (number | null),
        billingDurationNin?: (Array<number> | null),
        billingDuration?: (number | null),
        priceExists?: (boolean | null),
        priceEq?: (number | null),
        priceGt?: (number | null),
        priceGte?: (number | null),
        priceIn?: (Array<number> | null),
        priceLt?: (number | null),
        priceLte?: (number | null),
        priceNe?: (number | null),
        priceNin?: (Array<number> | null),
        price?: (number | null),
        currencyExists?: (boolean | null),
        currencyEq?: (CurrencyCode | null),
        currencyGt?: (CurrencyCode | null),
        currencyGte?: (CurrencyCode | null),
        currencyIn?: (Array<CurrencyCode> | null),
        currencyLt?: (CurrencyCode | null),
        currencyLte?: (CurrencyCode | null),
        currencyNe?: (CurrencyCode | null),
        currencyNin?: (Array<CurrencyCode> | null),
        currency?: (CurrencyCode | null),
        descriptionExists?: (boolean | null),
        descriptionEq?: (string | null),
        descriptionGt?: (string | null),
        descriptionGte?: (string | null),
        descriptionIn?: (Array<string> | null),
        descriptionLt?: (string | null),
        descriptionLte?: (string | null),
        descriptionNe?: (string | null),
        descriptionNin?: (Array<string> | null),
        description?: (string | null),
        quotaExists?: (boolean | null),
        quotaEq?: (number | null),
        quotaGt?: (number | null),
        quotaGte?: (number | null),
        quotaIn?: (Array<number> | null),
        quotaLt?: (number | null),
        quotaLte?: (number | null),
        quotaNe?: (number | null),
        quotaNin?: (Array<number> | null),
        quota?: (number | null),
        fromDateExists?: (boolean | null),
        fromDateEq?: (string | null),
        fromDateGt?: (string | null),
        fromDateGte?: (string | null),
        fromDateIn?: (Array<string> | null),
        fromDateLt?: (string | null),
        fromDateLte?: (string | null),
        fromDateNe?: (string | null),
        fromDateNin?: (Array<string> | null),
        fromDate?: (string | null),
        endDateExists?: (boolean | null),
        endDateEq?: (string | null),
        endDateGt?: (string | null),
        endDateGte?: (string | null),
        endDateIn?: (Array<string> | null),
        endDateLt?: (string | null),
        endDateLte?: (string | null),
        endDateNe?: (string | null),
        endDateNin?: (Array<string> | null),
        endDate?: (string | null),
        reminderDateExists?: (boolean | null),
        reminderDateEq?: (string | null),
        reminderDateGt?: (string | null),
        reminderDateGte?: (string | null),
        reminderDateIn?: (Array<string> | null),
        reminderDateLt?: (string | null),
        reminderDateLte?: (string | null),
        reminderDateNe?: (string | null),
        reminderDateNin?: (Array<string> | null),
        reminderDate?: (string | null),
        sendReminderToExists?: (boolean | null),
        sendReminderToEq?: (string | null),
        sendReminderToGt?: (string | null),
        sendReminderToGte?: (string | null),
        sendReminderToIn?: (Array<string> | null),
        sendReminderToLt?: (string | null),
        sendReminderToLte?: (string | null),
        sendReminderToNe?: (string | null),
        sendReminderToNin?: (Array<string> | null),
        sendReminderTo?: (string | null),
        reminderSendAtExists?: (boolean | null),
        reminderSendAtEq?: (string | null),
        reminderSendAtGt?: (string | null),
        reminderSendAtGte?: (string | null),
        reminderSendAtIn?: (Array<string> | null),
        reminderSendAtLt?: (string | null),
        reminderSendAtLte?: (string | null),
        reminderSendAtNe?: (string | null),
        reminderSendAtNin?: (Array<string> | null),
        reminderSendAt?: (string | null),
        limit?: (number | null),
        offset?: (number | null),
        /**
         * Field name to be sorted by. Ignored if 'sort' is specified
         */
        sortBy?: (string | null),
        /**
         * Sort order to be sorted by. Ignored if 'sort' is specified
         */
        sortOrder?: (PaginationSortOrder | null),
        /**
         * Sort field in the form `<field_name>:<sort_by>`. Example: `sort=email:asc`. If at least one is set, sort_by and sort_order are ignored
         */
        sort?: (Array<string> | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<PriceInformation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software-license-price-informations',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'software_license_id.$exists': softwareLicenseIdExists,
                'software_license_id.$eq': softwareLicenseIdEq,
                'software_license_id.$gt': softwareLicenseIdGt,
                'software_license_id.$gte': softwareLicenseIdGte,
                'software_license_id.$in': softwareLicenseIdIn,
                'software_license_id.$lt': softwareLicenseIdLt,
                'software_license_id.$lte': softwareLicenseIdLte,
                'software_license_id.$ne': softwareLicenseIdNe,
                'software_license_id.$nin': softwareLicenseIdNin,
                'software_license_id': softwareLicenseId,
                'software_id.$exists': softwareIdExists,
                'software_id.$eq': softwareIdEq,
                'software_id.$gt': softwareIdGt,
                'software_id.$gte': softwareIdGte,
                'software_id.$in': softwareIdIn,
                'software_id.$lt': softwareIdLt,
                'software_id.$lte': softwareIdLte,
                'software_id.$ne': softwareIdNe,
                'software_id.$nin': softwareIdNin,
                'software_id': softwareId,
                'sub_account_id.$exists': subAccountIdExists,
                'sub_account_id.$eq': subAccountIdEq,
                'sub_account_id.$gt': subAccountIdGt,
                'sub_account_id.$gte': subAccountIdGte,
                'sub_account_id.$in': subAccountIdIn,
                'sub_account_id.$lt': subAccountIdLt,
                'sub_account_id.$lte': subAccountIdLte,
                'sub_account_id.$ne': subAccountIdNe,
                'sub_account_id.$nin': subAccountIdNin,
                'sub_account_id': subAccountId,
                'billing_period.$exists': billingPeriodExists,
                'billing_period.$eq': billingPeriodEq,
                'billing_period.$gt': billingPeriodGt,
                'billing_period.$gte': billingPeriodGte,
                'billing_period.$in': billingPeriodIn,
                'billing_period.$lt': billingPeriodLt,
                'billing_period.$lte': billingPeriodLte,
                'billing_period.$ne': billingPeriodNe,
                'billing_period.$nin': billingPeriodNin,
                'billing_period': billingPeriod,
                'billing_unit.$exists': billingUnitExists,
                'billing_unit.$eq': billingUnitEq,
                'billing_unit.$gt': billingUnitGt,
                'billing_unit.$gte': billingUnitGte,
                'billing_unit.$in': billingUnitIn,
                'billing_unit.$lt': billingUnitLt,
                'billing_unit.$lte': billingUnitLte,
                'billing_unit.$ne': billingUnitNe,
                'billing_unit.$nin': billingUnitNin,
                'billing_unit': billingUnit,
                'billing_duration.$exists': billingDurationExists,
                'billing_duration.$eq': billingDurationEq,
                'billing_duration.$gt': billingDurationGt,
                'billing_duration.$gte': billingDurationGte,
                'billing_duration.$in': billingDurationIn,
                'billing_duration.$lt': billingDurationLt,
                'billing_duration.$lte': billingDurationLte,
                'billing_duration.$ne': billingDurationNe,
                'billing_duration.$nin': billingDurationNin,
                'billing_duration': billingDuration,
                'price.$exists': priceExists,
                'price.$eq': priceEq,
                'price.$gt': priceGt,
                'price.$gte': priceGte,
                'price.$in': priceIn,
                'price.$lt': priceLt,
                'price.$lte': priceLte,
                'price.$ne': priceNe,
                'price.$nin': priceNin,
                'price': price,
                'currency.$exists': currencyExists,
                'currency.$eq': currencyEq,
                'currency.$gt': currencyGt,
                'currency.$gte': currencyGte,
                'currency.$in': currencyIn,
                'currency.$lt': currencyLt,
                'currency.$lte': currencyLte,
                'currency.$ne': currencyNe,
                'currency.$nin': currencyNin,
                'currency': currency,
                'description.$exists': descriptionExists,
                'description.$eq': descriptionEq,
                'description.$gt': descriptionGt,
                'description.$gte': descriptionGte,
                'description.$in': descriptionIn,
                'description.$lt': descriptionLt,
                'description.$lte': descriptionLte,
                'description.$ne': descriptionNe,
                'description.$nin': descriptionNin,
                'description': description,
                'quota.$exists': quotaExists,
                'quota.$eq': quotaEq,
                'quota.$gt': quotaGt,
                'quota.$gte': quotaGte,
                'quota.$in': quotaIn,
                'quota.$lt': quotaLt,
                'quota.$lte': quotaLte,
                'quota.$ne': quotaNe,
                'quota.$nin': quotaNin,
                'quota': quota,
                'from_date.$exists': fromDateExists,
                'from_date.$eq': fromDateEq,
                'from_date.$gt': fromDateGt,
                'from_date.$gte': fromDateGte,
                'from_date.$in': fromDateIn,
                'from_date.$lt': fromDateLt,
                'from_date.$lte': fromDateLte,
                'from_date.$ne': fromDateNe,
                'from_date.$nin': fromDateNin,
                'from_date': fromDate,
                'end_date.$exists': endDateExists,
                'end_date.$eq': endDateEq,
                'end_date.$gt': endDateGt,
                'end_date.$gte': endDateGte,
                'end_date.$in': endDateIn,
                'end_date.$lt': endDateLt,
                'end_date.$lte': endDateLte,
                'end_date.$ne': endDateNe,
                'end_date.$nin': endDateNin,
                'end_date': endDate,
                'reminder_date.$exists': reminderDateExists,
                'reminder_date.$eq': reminderDateEq,
                'reminder_date.$gt': reminderDateGt,
                'reminder_date.$gte': reminderDateGte,
                'reminder_date.$in': reminderDateIn,
                'reminder_date.$lt': reminderDateLt,
                'reminder_date.$lte': reminderDateLte,
                'reminder_date.$ne': reminderDateNe,
                'reminder_date.$nin': reminderDateNin,
                'reminder_date': reminderDate,
                'send_reminder_to.$exists': sendReminderToExists,
                'send_reminder_to.$eq': sendReminderToEq,
                'send_reminder_to.$gt': sendReminderToGt,
                'send_reminder_to.$gte': sendReminderToGte,
                'send_reminder_to.$in': sendReminderToIn,
                'send_reminder_to.$lt': sendReminderToLt,
                'send_reminder_to.$lte': sendReminderToLte,
                'send_reminder_to.$ne': sendReminderToNe,
                'send_reminder_to.$nin': sendReminderToNin,
                'send_reminder_to': sendReminderTo,
                'reminder_send_at.$exists': reminderSendAtExists,
                'reminder_send_at.$eq': reminderSendAtEq,
                'reminder_send_at.$gt': reminderSendAtGt,
                'reminder_send_at.$gte': reminderSendAtGte,
                'reminder_send_at.$in': reminderSendAtIn,
                'reminder_send_at.$lt': reminderSendAtLt,
                'reminder_send_at.$lte': reminderSendAtLte,
                'reminder_send_at.$ne': reminderSendAtNe,
                'reminder_send_at.$nin': reminderSendAtNin,
                'reminder_send_at': reminderSendAt,
                'limit': limit,
                'offset': offset,
                'sort_by': sortBy,
                'sort_order': sortOrder,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Software License Price Informations For Software License
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countSoftwareLicensePriceInformationsForSoftwareLicenseApiV1SoftwareSoftwareLicensesSoftwareLicenseIdPriceInformationsCountGet({
        softwareLicenseId,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        softwareIdExists,
        softwareIdEq,
        softwareIdGt,
        softwareIdGte,
        softwareIdIn,
        softwareIdLt,
        softwareIdLte,
        softwareIdNe,
        softwareIdNin,
        softwareId,
        subAccountIdExists,
        subAccountIdEq,
        subAccountIdGt,
        subAccountIdGte,
        subAccountIdIn,
        subAccountIdLt,
        subAccountIdLte,
        subAccountIdNe,
        subAccountIdNin,
        subAccountId,
        billingPeriodExists,
        billingPeriodEq,
        billingPeriodGt,
        billingPeriodGte,
        billingPeriodIn,
        billingPeriodLt,
        billingPeriodLte,
        billingPeriodNe,
        billingPeriodNin,
        billingPeriod,
        billingUnitExists,
        billingUnitEq,
        billingUnitGt,
        billingUnitGte,
        billingUnitIn,
        billingUnitLt,
        billingUnitLte,
        billingUnitNe,
        billingUnitNin,
        billingUnit,
        billingDurationExists,
        billingDurationEq,
        billingDurationGt,
        billingDurationGte,
        billingDurationIn,
        billingDurationLt,
        billingDurationLte,
        billingDurationNe,
        billingDurationNin,
        billingDuration,
        priceExists,
        priceEq,
        priceGt,
        priceGte,
        priceIn,
        priceLt,
        priceLte,
        priceNe,
        priceNin,
        price,
        currencyExists,
        currencyEq,
        currencyGt,
        currencyGte,
        currencyIn,
        currencyLt,
        currencyLte,
        currencyNe,
        currencyNin,
        currency,
        descriptionExists,
        descriptionEq,
        descriptionGt,
        descriptionGte,
        descriptionIn,
        descriptionLt,
        descriptionLte,
        descriptionNe,
        descriptionNin,
        description,
        quotaExists,
        quotaEq,
        quotaGt,
        quotaGte,
        quotaIn,
        quotaLt,
        quotaLte,
        quotaNe,
        quotaNin,
        quota,
        fromDateExists,
        fromDateEq,
        fromDateGt,
        fromDateGte,
        fromDateIn,
        fromDateLt,
        fromDateLte,
        fromDateNe,
        fromDateNin,
        fromDate,
        endDateExists,
        endDateEq,
        endDateGt,
        endDateGte,
        endDateIn,
        endDateLt,
        endDateLte,
        endDateNe,
        endDateNin,
        endDate,
        reminderDateExists,
        reminderDateEq,
        reminderDateGt,
        reminderDateGte,
        reminderDateIn,
        reminderDateLt,
        reminderDateLte,
        reminderDateNe,
        reminderDateNin,
        reminderDate,
        sendReminderToExists,
        sendReminderToEq,
        sendReminderToGt,
        sendReminderToGte,
        sendReminderToIn,
        sendReminderToLt,
        sendReminderToLte,
        sendReminderToNe,
        sendReminderToNin,
        sendReminderTo,
        reminderSendAtExists,
        reminderSendAtEq,
        reminderSendAtGt,
        reminderSendAtGte,
        reminderSendAtIn,
        reminderSendAtLt,
        reminderSendAtLte,
        reminderSendAtNe,
        reminderSendAtNin,
        reminderSendAt,
        xTransactionId,
    }: {
        softwareLicenseId: string,
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        softwareIdExists?: (boolean | null),
        softwareIdEq?: (string | null),
        softwareIdGt?: (string | null),
        softwareIdGte?: (string | null),
        softwareIdIn?: (Array<string> | null),
        softwareIdLt?: (string | null),
        softwareIdLte?: (string | null),
        softwareIdNe?: (string | null),
        softwareIdNin?: (Array<string> | null),
        softwareId?: (string | null),
        subAccountIdExists?: (boolean | null),
        subAccountIdEq?: (string | null),
        subAccountIdGt?: (string | null),
        subAccountIdGte?: (string | null),
        subAccountIdIn?: (Array<string> | null),
        subAccountIdLt?: (string | null),
        subAccountIdLte?: (string | null),
        subAccountIdNe?: (string | null),
        subAccountIdNin?: (Array<string> | null),
        subAccountId?: (string | null),
        billingPeriodExists?: (boolean | null),
        billingPeriodEq?: (BillingPeriod | null),
        billingPeriodGt?: (BillingPeriod | null),
        billingPeriodGte?: (BillingPeriod | null),
        billingPeriodIn?: (Array<BillingPeriod> | null),
        billingPeriodLt?: (BillingPeriod | null),
        billingPeriodLte?: (BillingPeriod | null),
        billingPeriodNe?: (BillingPeriod | null),
        billingPeriodNin?: (Array<BillingPeriod> | null),
        billingPeriod?: (BillingPeriod | null),
        billingUnitExists?: (boolean | null),
        billingUnitEq?: (BillingUnit | null),
        billingUnitGt?: (BillingUnit | null),
        billingUnitGte?: (BillingUnit | null),
        billingUnitIn?: (Array<BillingUnit> | null),
        billingUnitLt?: (BillingUnit | null),
        billingUnitLte?: (BillingUnit | null),
        billingUnitNe?: (BillingUnit | null),
        billingUnitNin?: (Array<BillingUnit> | null),
        billingUnit?: (BillingUnit | null),
        billingDurationExists?: (boolean | null),
        billingDurationEq?: (number | null),
        billingDurationGt?: (number | null),
        billingDurationGte?: (number | null),
        billingDurationIn?: (Array<number> | null),
        billingDurationLt?: (number | null),
        billingDurationLte?: (number | null),
        billingDurationNe?: (number | null),
        billingDurationNin?: (Array<number> | null),
        billingDuration?: (number | null),
        priceExists?: (boolean | null),
        priceEq?: (number | null),
        priceGt?: (number | null),
        priceGte?: (number | null),
        priceIn?: (Array<number> | null),
        priceLt?: (number | null),
        priceLte?: (number | null),
        priceNe?: (number | null),
        priceNin?: (Array<number> | null),
        price?: (number | null),
        currencyExists?: (boolean | null),
        currencyEq?: (CurrencyCode | null),
        currencyGt?: (CurrencyCode | null),
        currencyGte?: (CurrencyCode | null),
        currencyIn?: (Array<CurrencyCode> | null),
        currencyLt?: (CurrencyCode | null),
        currencyLte?: (CurrencyCode | null),
        currencyNe?: (CurrencyCode | null),
        currencyNin?: (Array<CurrencyCode> | null),
        currency?: (CurrencyCode | null),
        descriptionExists?: (boolean | null),
        descriptionEq?: (string | null),
        descriptionGt?: (string | null),
        descriptionGte?: (string | null),
        descriptionIn?: (Array<string> | null),
        descriptionLt?: (string | null),
        descriptionLte?: (string | null),
        descriptionNe?: (string | null),
        descriptionNin?: (Array<string> | null),
        description?: (string | null),
        quotaExists?: (boolean | null),
        quotaEq?: (number | null),
        quotaGt?: (number | null),
        quotaGte?: (number | null),
        quotaIn?: (Array<number> | null),
        quotaLt?: (number | null),
        quotaLte?: (number | null),
        quotaNe?: (number | null),
        quotaNin?: (Array<number> | null),
        quota?: (number | null),
        fromDateExists?: (boolean | null),
        fromDateEq?: (string | null),
        fromDateGt?: (string | null),
        fromDateGte?: (string | null),
        fromDateIn?: (Array<string> | null),
        fromDateLt?: (string | null),
        fromDateLte?: (string | null),
        fromDateNe?: (string | null),
        fromDateNin?: (Array<string> | null),
        fromDate?: (string | null),
        endDateExists?: (boolean | null),
        endDateEq?: (string | null),
        endDateGt?: (string | null),
        endDateGte?: (string | null),
        endDateIn?: (Array<string> | null),
        endDateLt?: (string | null),
        endDateLte?: (string | null),
        endDateNe?: (string | null),
        endDateNin?: (Array<string> | null),
        endDate?: (string | null),
        reminderDateExists?: (boolean | null),
        reminderDateEq?: (string | null),
        reminderDateGt?: (string | null),
        reminderDateGte?: (string | null),
        reminderDateIn?: (Array<string> | null),
        reminderDateLt?: (string | null),
        reminderDateLte?: (string | null),
        reminderDateNe?: (string | null),
        reminderDateNin?: (Array<string> | null),
        reminderDate?: (string | null),
        sendReminderToExists?: (boolean | null),
        sendReminderToEq?: (string | null),
        sendReminderToGt?: (string | null),
        sendReminderToGte?: (string | null),
        sendReminderToIn?: (Array<string> | null),
        sendReminderToLt?: (string | null),
        sendReminderToLte?: (string | null),
        sendReminderToNe?: (string | null),
        sendReminderToNin?: (Array<string> | null),
        sendReminderTo?: (string | null),
        reminderSendAtExists?: (boolean | null),
        reminderSendAtEq?: (string | null),
        reminderSendAtGt?: (string | null),
        reminderSendAtGte?: (string | null),
        reminderSendAtIn?: (Array<string> | null),
        reminderSendAtLt?: (string | null),
        reminderSendAtLte?: (string | null),
        reminderSendAtNe?: (string | null),
        reminderSendAtNin?: (Array<string> | null),
        reminderSendAt?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software-licenses/{software_license_id}/price-informations/count',
            path: {
                'software_license_id': softwareLicenseId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'software_id.$exists': softwareIdExists,
                'software_id.$eq': softwareIdEq,
                'software_id.$gt': softwareIdGt,
                'software_id.$gte': softwareIdGte,
                'software_id.$in': softwareIdIn,
                'software_id.$lt': softwareIdLt,
                'software_id.$lte': softwareIdLte,
                'software_id.$ne': softwareIdNe,
                'software_id.$nin': softwareIdNin,
                'software_id': softwareId,
                'sub_account_id.$exists': subAccountIdExists,
                'sub_account_id.$eq': subAccountIdEq,
                'sub_account_id.$gt': subAccountIdGt,
                'sub_account_id.$gte': subAccountIdGte,
                'sub_account_id.$in': subAccountIdIn,
                'sub_account_id.$lt': subAccountIdLt,
                'sub_account_id.$lte': subAccountIdLte,
                'sub_account_id.$ne': subAccountIdNe,
                'sub_account_id.$nin': subAccountIdNin,
                'sub_account_id': subAccountId,
                'billing_period.$exists': billingPeriodExists,
                'billing_period.$eq': billingPeriodEq,
                'billing_period.$gt': billingPeriodGt,
                'billing_period.$gte': billingPeriodGte,
                'billing_period.$in': billingPeriodIn,
                'billing_period.$lt': billingPeriodLt,
                'billing_period.$lte': billingPeriodLte,
                'billing_period.$ne': billingPeriodNe,
                'billing_period.$nin': billingPeriodNin,
                'billing_period': billingPeriod,
                'billing_unit.$exists': billingUnitExists,
                'billing_unit.$eq': billingUnitEq,
                'billing_unit.$gt': billingUnitGt,
                'billing_unit.$gte': billingUnitGte,
                'billing_unit.$in': billingUnitIn,
                'billing_unit.$lt': billingUnitLt,
                'billing_unit.$lte': billingUnitLte,
                'billing_unit.$ne': billingUnitNe,
                'billing_unit.$nin': billingUnitNin,
                'billing_unit': billingUnit,
                'billing_duration.$exists': billingDurationExists,
                'billing_duration.$eq': billingDurationEq,
                'billing_duration.$gt': billingDurationGt,
                'billing_duration.$gte': billingDurationGte,
                'billing_duration.$in': billingDurationIn,
                'billing_duration.$lt': billingDurationLt,
                'billing_duration.$lte': billingDurationLte,
                'billing_duration.$ne': billingDurationNe,
                'billing_duration.$nin': billingDurationNin,
                'billing_duration': billingDuration,
                'price.$exists': priceExists,
                'price.$eq': priceEq,
                'price.$gt': priceGt,
                'price.$gte': priceGte,
                'price.$in': priceIn,
                'price.$lt': priceLt,
                'price.$lte': priceLte,
                'price.$ne': priceNe,
                'price.$nin': priceNin,
                'price': price,
                'currency.$exists': currencyExists,
                'currency.$eq': currencyEq,
                'currency.$gt': currencyGt,
                'currency.$gte': currencyGte,
                'currency.$in': currencyIn,
                'currency.$lt': currencyLt,
                'currency.$lte': currencyLte,
                'currency.$ne': currencyNe,
                'currency.$nin': currencyNin,
                'currency': currency,
                'description.$exists': descriptionExists,
                'description.$eq': descriptionEq,
                'description.$gt': descriptionGt,
                'description.$gte': descriptionGte,
                'description.$in': descriptionIn,
                'description.$lt': descriptionLt,
                'description.$lte': descriptionLte,
                'description.$ne': descriptionNe,
                'description.$nin': descriptionNin,
                'description': description,
                'quota.$exists': quotaExists,
                'quota.$eq': quotaEq,
                'quota.$gt': quotaGt,
                'quota.$gte': quotaGte,
                'quota.$in': quotaIn,
                'quota.$lt': quotaLt,
                'quota.$lte': quotaLte,
                'quota.$ne': quotaNe,
                'quota.$nin': quotaNin,
                'quota': quota,
                'from_date.$exists': fromDateExists,
                'from_date.$eq': fromDateEq,
                'from_date.$gt': fromDateGt,
                'from_date.$gte': fromDateGte,
                'from_date.$in': fromDateIn,
                'from_date.$lt': fromDateLt,
                'from_date.$lte': fromDateLte,
                'from_date.$ne': fromDateNe,
                'from_date.$nin': fromDateNin,
                'from_date': fromDate,
                'end_date.$exists': endDateExists,
                'end_date.$eq': endDateEq,
                'end_date.$gt': endDateGt,
                'end_date.$gte': endDateGte,
                'end_date.$in': endDateIn,
                'end_date.$lt': endDateLt,
                'end_date.$lte': endDateLte,
                'end_date.$ne': endDateNe,
                'end_date.$nin': endDateNin,
                'end_date': endDate,
                'reminder_date.$exists': reminderDateExists,
                'reminder_date.$eq': reminderDateEq,
                'reminder_date.$gt': reminderDateGt,
                'reminder_date.$gte': reminderDateGte,
                'reminder_date.$in': reminderDateIn,
                'reminder_date.$lt': reminderDateLt,
                'reminder_date.$lte': reminderDateLte,
                'reminder_date.$ne': reminderDateNe,
                'reminder_date.$nin': reminderDateNin,
                'reminder_date': reminderDate,
                'send_reminder_to.$exists': sendReminderToExists,
                'send_reminder_to.$eq': sendReminderToEq,
                'send_reminder_to.$gt': sendReminderToGt,
                'send_reminder_to.$gte': sendReminderToGte,
                'send_reminder_to.$in': sendReminderToIn,
                'send_reminder_to.$lt': sendReminderToLt,
                'send_reminder_to.$lte': sendReminderToLte,
                'send_reminder_to.$ne': sendReminderToNe,
                'send_reminder_to.$nin': sendReminderToNin,
                'send_reminder_to': sendReminderTo,
                'reminder_send_at.$exists': reminderSendAtExists,
                'reminder_send_at.$eq': reminderSendAtEq,
                'reminder_send_at.$gt': reminderSendAtGt,
                'reminder_send_at.$gte': reminderSendAtGte,
                'reminder_send_at.$in': reminderSendAtIn,
                'reminder_send_at.$lt': reminderSendAtLt,
                'reminder_send_at.$lte': reminderSendAtLte,
                'reminder_send_at.$ne': reminderSendAtNe,
                'reminder_send_at.$nin': reminderSendAtNin,
                'reminder_send_at': reminderSendAt,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Software License Price Informations For Software License
     * @returns PriceInformation Successful Response
     * @throws ApiError
     */
    public static getSoftwareLicensePriceInformationsForSoftwareLicenseApiV1SoftwareSoftwareLicensesSoftwareLicenseIdPriceInformationsGet({
        softwareLicenseId,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        softwareIdExists,
        softwareIdEq,
        softwareIdGt,
        softwareIdGte,
        softwareIdIn,
        softwareIdLt,
        softwareIdLte,
        softwareIdNe,
        softwareIdNin,
        softwareId,
        subAccountIdExists,
        subAccountIdEq,
        subAccountIdGt,
        subAccountIdGte,
        subAccountIdIn,
        subAccountIdLt,
        subAccountIdLte,
        subAccountIdNe,
        subAccountIdNin,
        subAccountId,
        billingPeriodExists,
        billingPeriodEq,
        billingPeriodGt,
        billingPeriodGte,
        billingPeriodIn,
        billingPeriodLt,
        billingPeriodLte,
        billingPeriodNe,
        billingPeriodNin,
        billingPeriod,
        billingUnitExists,
        billingUnitEq,
        billingUnitGt,
        billingUnitGte,
        billingUnitIn,
        billingUnitLt,
        billingUnitLte,
        billingUnitNe,
        billingUnitNin,
        billingUnit,
        billingDurationExists,
        billingDurationEq,
        billingDurationGt,
        billingDurationGte,
        billingDurationIn,
        billingDurationLt,
        billingDurationLte,
        billingDurationNe,
        billingDurationNin,
        billingDuration,
        priceExists,
        priceEq,
        priceGt,
        priceGte,
        priceIn,
        priceLt,
        priceLte,
        priceNe,
        priceNin,
        price,
        currencyExists,
        currencyEq,
        currencyGt,
        currencyGte,
        currencyIn,
        currencyLt,
        currencyLte,
        currencyNe,
        currencyNin,
        currency,
        descriptionExists,
        descriptionEq,
        descriptionGt,
        descriptionGte,
        descriptionIn,
        descriptionLt,
        descriptionLte,
        descriptionNe,
        descriptionNin,
        description,
        quotaExists,
        quotaEq,
        quotaGt,
        quotaGte,
        quotaIn,
        quotaLt,
        quotaLte,
        quotaNe,
        quotaNin,
        quota,
        fromDateExists,
        fromDateEq,
        fromDateGt,
        fromDateGte,
        fromDateIn,
        fromDateLt,
        fromDateLte,
        fromDateNe,
        fromDateNin,
        fromDate,
        endDateExists,
        endDateEq,
        endDateGt,
        endDateGte,
        endDateIn,
        endDateLt,
        endDateLte,
        endDateNe,
        endDateNin,
        endDate,
        reminderDateExists,
        reminderDateEq,
        reminderDateGt,
        reminderDateGte,
        reminderDateIn,
        reminderDateLt,
        reminderDateLte,
        reminderDateNe,
        reminderDateNin,
        reminderDate,
        sendReminderToExists,
        sendReminderToEq,
        sendReminderToGt,
        sendReminderToGte,
        sendReminderToIn,
        sendReminderToLt,
        sendReminderToLte,
        sendReminderToNe,
        sendReminderToNin,
        sendReminderTo,
        reminderSendAtExists,
        reminderSendAtEq,
        reminderSendAtGt,
        reminderSendAtGte,
        reminderSendAtIn,
        reminderSendAtLt,
        reminderSendAtLte,
        reminderSendAtNe,
        reminderSendAtNin,
        reminderSendAt,
        limit,
        offset,
        sortBy,
        sortOrder,
        sort,
        xTransactionId,
    }: {
        softwareLicenseId: string,
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        softwareIdExists?: (boolean | null),
        softwareIdEq?: (string | null),
        softwareIdGt?: (string | null),
        softwareIdGte?: (string | null),
        softwareIdIn?: (Array<string> | null),
        softwareIdLt?: (string | null),
        softwareIdLte?: (string | null),
        softwareIdNe?: (string | null),
        softwareIdNin?: (Array<string> | null),
        softwareId?: (string | null),
        subAccountIdExists?: (boolean | null),
        subAccountIdEq?: (string | null),
        subAccountIdGt?: (string | null),
        subAccountIdGte?: (string | null),
        subAccountIdIn?: (Array<string> | null),
        subAccountIdLt?: (string | null),
        subAccountIdLte?: (string | null),
        subAccountIdNe?: (string | null),
        subAccountIdNin?: (Array<string> | null),
        subAccountId?: (string | null),
        billingPeriodExists?: (boolean | null),
        billingPeriodEq?: (BillingPeriod | null),
        billingPeriodGt?: (BillingPeriod | null),
        billingPeriodGte?: (BillingPeriod | null),
        billingPeriodIn?: (Array<BillingPeriod> | null),
        billingPeriodLt?: (BillingPeriod | null),
        billingPeriodLte?: (BillingPeriod | null),
        billingPeriodNe?: (BillingPeriod | null),
        billingPeriodNin?: (Array<BillingPeriod> | null),
        billingPeriod?: (BillingPeriod | null),
        billingUnitExists?: (boolean | null),
        billingUnitEq?: (BillingUnit | null),
        billingUnitGt?: (BillingUnit | null),
        billingUnitGte?: (BillingUnit | null),
        billingUnitIn?: (Array<BillingUnit> | null),
        billingUnitLt?: (BillingUnit | null),
        billingUnitLte?: (BillingUnit | null),
        billingUnitNe?: (BillingUnit | null),
        billingUnitNin?: (Array<BillingUnit> | null),
        billingUnit?: (BillingUnit | null),
        billingDurationExists?: (boolean | null),
        billingDurationEq?: (number | null),
        billingDurationGt?: (number | null),
        billingDurationGte?: (number | null),
        billingDurationIn?: (Array<number> | null),
        billingDurationLt?: (number | null),
        billingDurationLte?: (number | null),
        billingDurationNe?: (number | null),
        billingDurationNin?: (Array<number> | null),
        billingDuration?: (number | null),
        priceExists?: (boolean | null),
        priceEq?: (number | null),
        priceGt?: (number | null),
        priceGte?: (number | null),
        priceIn?: (Array<number> | null),
        priceLt?: (number | null),
        priceLte?: (number | null),
        priceNe?: (number | null),
        priceNin?: (Array<number> | null),
        price?: (number | null),
        currencyExists?: (boolean | null),
        currencyEq?: (CurrencyCode | null),
        currencyGt?: (CurrencyCode | null),
        currencyGte?: (CurrencyCode | null),
        currencyIn?: (Array<CurrencyCode> | null),
        currencyLt?: (CurrencyCode | null),
        currencyLte?: (CurrencyCode | null),
        currencyNe?: (CurrencyCode | null),
        currencyNin?: (Array<CurrencyCode> | null),
        currency?: (CurrencyCode | null),
        descriptionExists?: (boolean | null),
        descriptionEq?: (string | null),
        descriptionGt?: (string | null),
        descriptionGte?: (string | null),
        descriptionIn?: (Array<string> | null),
        descriptionLt?: (string | null),
        descriptionLte?: (string | null),
        descriptionNe?: (string | null),
        descriptionNin?: (Array<string> | null),
        description?: (string | null),
        quotaExists?: (boolean | null),
        quotaEq?: (number | null),
        quotaGt?: (number | null),
        quotaGte?: (number | null),
        quotaIn?: (Array<number> | null),
        quotaLt?: (number | null),
        quotaLte?: (number | null),
        quotaNe?: (number | null),
        quotaNin?: (Array<number> | null),
        quota?: (number | null),
        fromDateExists?: (boolean | null),
        fromDateEq?: (string | null),
        fromDateGt?: (string | null),
        fromDateGte?: (string | null),
        fromDateIn?: (Array<string> | null),
        fromDateLt?: (string | null),
        fromDateLte?: (string | null),
        fromDateNe?: (string | null),
        fromDateNin?: (Array<string> | null),
        fromDate?: (string | null),
        endDateExists?: (boolean | null),
        endDateEq?: (string | null),
        endDateGt?: (string | null),
        endDateGte?: (string | null),
        endDateIn?: (Array<string> | null),
        endDateLt?: (string | null),
        endDateLte?: (string | null),
        endDateNe?: (string | null),
        endDateNin?: (Array<string> | null),
        endDate?: (string | null),
        reminderDateExists?: (boolean | null),
        reminderDateEq?: (string | null),
        reminderDateGt?: (string | null),
        reminderDateGte?: (string | null),
        reminderDateIn?: (Array<string> | null),
        reminderDateLt?: (string | null),
        reminderDateLte?: (string | null),
        reminderDateNe?: (string | null),
        reminderDateNin?: (Array<string> | null),
        reminderDate?: (string | null),
        sendReminderToExists?: (boolean | null),
        sendReminderToEq?: (string | null),
        sendReminderToGt?: (string | null),
        sendReminderToGte?: (string | null),
        sendReminderToIn?: (Array<string> | null),
        sendReminderToLt?: (string | null),
        sendReminderToLte?: (string | null),
        sendReminderToNe?: (string | null),
        sendReminderToNin?: (Array<string> | null),
        sendReminderTo?: (string | null),
        reminderSendAtExists?: (boolean | null),
        reminderSendAtEq?: (string | null),
        reminderSendAtGt?: (string | null),
        reminderSendAtGte?: (string | null),
        reminderSendAtIn?: (Array<string> | null),
        reminderSendAtLt?: (string | null),
        reminderSendAtLte?: (string | null),
        reminderSendAtNe?: (string | null),
        reminderSendAtNin?: (Array<string> | null),
        reminderSendAt?: (string | null),
        limit?: (number | null),
        offset?: (number | null),
        /**
         * Field name to be sorted by. Ignored if 'sort' is specified
         */
        sortBy?: (string | null),
        /**
         * Sort order to be sorted by. Ignored if 'sort' is specified
         */
        sortOrder?: (PaginationSortOrder | null),
        /**
         * Sort field in the form `<field_name>:<sort_by>`. Example: `sort=email:asc`. If at least one is set, sort_by and sort_order are ignored
         */
        sort?: (Array<string> | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<PriceInformation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software-licenses/{software_license_id}/price-informations',
            path: {
                'software_license_id': softwareLicenseId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'software_id.$exists': softwareIdExists,
                'software_id.$eq': softwareIdEq,
                'software_id.$gt': softwareIdGt,
                'software_id.$gte': softwareIdGte,
                'software_id.$in': softwareIdIn,
                'software_id.$lt': softwareIdLt,
                'software_id.$lte': softwareIdLte,
                'software_id.$ne': softwareIdNe,
                'software_id.$nin': softwareIdNin,
                'software_id': softwareId,
                'sub_account_id.$exists': subAccountIdExists,
                'sub_account_id.$eq': subAccountIdEq,
                'sub_account_id.$gt': subAccountIdGt,
                'sub_account_id.$gte': subAccountIdGte,
                'sub_account_id.$in': subAccountIdIn,
                'sub_account_id.$lt': subAccountIdLt,
                'sub_account_id.$lte': subAccountIdLte,
                'sub_account_id.$ne': subAccountIdNe,
                'sub_account_id.$nin': subAccountIdNin,
                'sub_account_id': subAccountId,
                'billing_period.$exists': billingPeriodExists,
                'billing_period.$eq': billingPeriodEq,
                'billing_period.$gt': billingPeriodGt,
                'billing_period.$gte': billingPeriodGte,
                'billing_period.$in': billingPeriodIn,
                'billing_period.$lt': billingPeriodLt,
                'billing_period.$lte': billingPeriodLte,
                'billing_period.$ne': billingPeriodNe,
                'billing_period.$nin': billingPeriodNin,
                'billing_period': billingPeriod,
                'billing_unit.$exists': billingUnitExists,
                'billing_unit.$eq': billingUnitEq,
                'billing_unit.$gt': billingUnitGt,
                'billing_unit.$gte': billingUnitGte,
                'billing_unit.$in': billingUnitIn,
                'billing_unit.$lt': billingUnitLt,
                'billing_unit.$lte': billingUnitLte,
                'billing_unit.$ne': billingUnitNe,
                'billing_unit.$nin': billingUnitNin,
                'billing_unit': billingUnit,
                'billing_duration.$exists': billingDurationExists,
                'billing_duration.$eq': billingDurationEq,
                'billing_duration.$gt': billingDurationGt,
                'billing_duration.$gte': billingDurationGte,
                'billing_duration.$in': billingDurationIn,
                'billing_duration.$lt': billingDurationLt,
                'billing_duration.$lte': billingDurationLte,
                'billing_duration.$ne': billingDurationNe,
                'billing_duration.$nin': billingDurationNin,
                'billing_duration': billingDuration,
                'price.$exists': priceExists,
                'price.$eq': priceEq,
                'price.$gt': priceGt,
                'price.$gte': priceGte,
                'price.$in': priceIn,
                'price.$lt': priceLt,
                'price.$lte': priceLte,
                'price.$ne': priceNe,
                'price.$nin': priceNin,
                'price': price,
                'currency.$exists': currencyExists,
                'currency.$eq': currencyEq,
                'currency.$gt': currencyGt,
                'currency.$gte': currencyGte,
                'currency.$in': currencyIn,
                'currency.$lt': currencyLt,
                'currency.$lte': currencyLte,
                'currency.$ne': currencyNe,
                'currency.$nin': currencyNin,
                'currency': currency,
                'description.$exists': descriptionExists,
                'description.$eq': descriptionEq,
                'description.$gt': descriptionGt,
                'description.$gte': descriptionGte,
                'description.$in': descriptionIn,
                'description.$lt': descriptionLt,
                'description.$lte': descriptionLte,
                'description.$ne': descriptionNe,
                'description.$nin': descriptionNin,
                'description': description,
                'quota.$exists': quotaExists,
                'quota.$eq': quotaEq,
                'quota.$gt': quotaGt,
                'quota.$gte': quotaGte,
                'quota.$in': quotaIn,
                'quota.$lt': quotaLt,
                'quota.$lte': quotaLte,
                'quota.$ne': quotaNe,
                'quota.$nin': quotaNin,
                'quota': quota,
                'from_date.$exists': fromDateExists,
                'from_date.$eq': fromDateEq,
                'from_date.$gt': fromDateGt,
                'from_date.$gte': fromDateGte,
                'from_date.$in': fromDateIn,
                'from_date.$lt': fromDateLt,
                'from_date.$lte': fromDateLte,
                'from_date.$ne': fromDateNe,
                'from_date.$nin': fromDateNin,
                'from_date': fromDate,
                'end_date.$exists': endDateExists,
                'end_date.$eq': endDateEq,
                'end_date.$gt': endDateGt,
                'end_date.$gte': endDateGte,
                'end_date.$in': endDateIn,
                'end_date.$lt': endDateLt,
                'end_date.$lte': endDateLte,
                'end_date.$ne': endDateNe,
                'end_date.$nin': endDateNin,
                'end_date': endDate,
                'reminder_date.$exists': reminderDateExists,
                'reminder_date.$eq': reminderDateEq,
                'reminder_date.$gt': reminderDateGt,
                'reminder_date.$gte': reminderDateGte,
                'reminder_date.$in': reminderDateIn,
                'reminder_date.$lt': reminderDateLt,
                'reminder_date.$lte': reminderDateLte,
                'reminder_date.$ne': reminderDateNe,
                'reminder_date.$nin': reminderDateNin,
                'reminder_date': reminderDate,
                'send_reminder_to.$exists': sendReminderToExists,
                'send_reminder_to.$eq': sendReminderToEq,
                'send_reminder_to.$gt': sendReminderToGt,
                'send_reminder_to.$gte': sendReminderToGte,
                'send_reminder_to.$in': sendReminderToIn,
                'send_reminder_to.$lt': sendReminderToLt,
                'send_reminder_to.$lte': sendReminderToLte,
                'send_reminder_to.$ne': sendReminderToNe,
                'send_reminder_to.$nin': sendReminderToNin,
                'send_reminder_to': sendReminderTo,
                'reminder_send_at.$exists': reminderSendAtExists,
                'reminder_send_at.$eq': reminderSendAtEq,
                'reminder_send_at.$gt': reminderSendAtGt,
                'reminder_send_at.$gte': reminderSendAtGte,
                'reminder_send_at.$in': reminderSendAtIn,
                'reminder_send_at.$lt': reminderSendAtLt,
                'reminder_send_at.$lte': reminderSendAtLte,
                'reminder_send_at.$ne': reminderSendAtNe,
                'reminder_send_at.$nin': reminderSendAtNin,
                'reminder_send_at': reminderSendAt,
                'limit': limit,
                'offset': offset,
                'sort_by': sortBy,
                'sort_order': sortOrder,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Software License Price Information
     * @returns PriceInformation Successful Response
     * @throws ApiError
     */
    public static createSoftwareLicensePriceInformationApiV1SoftwareSoftwareLicensesSoftwareLicenseIdPriceInformationsPost({
        softwareLicenseId,
        requestBody,
        xTransactionId,
    }: {
        softwareLicenseId: string,
        requestBody: PriceInformationPostIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<PriceInformation> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/software/software-licenses/{software_license_id}/price-informations',
            path: {
                'software_license_id': softwareLicenseId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Software License Price Informations For Software
     * @returns PriceInformation Successful Response
     * @throws ApiError
     */
    public static getSoftwareLicensePriceInformationsForSoftwareApiV1SoftwareSoftwareSoftwareIdPriceInformationsGet({
        softwareId,
        includeDeleted = false,
        limit,
        offset,
        sortBy,
        sortOrder,
        sort,
        xTransactionId,
    }: {
        softwareId: string,
        includeDeleted?: boolean,
        limit?: (number | null),
        offset?: (number | null),
        /**
         * Field name to be sorted by. Ignored if 'sort' is specified
         */
        sortBy?: (string | null),
        /**
         * Sort order to be sorted by. Ignored if 'sort' is specified
         */
        sortOrder?: (PaginationSortOrder | null),
        /**
         * Sort field in the form `<field_name>:<sort_by>`. Example: `sort=email:asc`. If at least one is set, sort_by and sort_order are ignored
         */
        sort?: (Array<string> | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<PriceInformation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software/{software_id}/price-informations',
            path: {
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
                'limit': limit,
                'offset': offset,
                'sort_by': sortBy,
                'sort_order': sortOrder,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Software License Price Informations For Software
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countSoftwareLicensePriceInformationsForSoftwareApiV1SoftwareSoftwareSoftwareIdPriceInformationsCountGet({
        softwareId,
        includeDeleted = false,
        xTransactionId,
    }: {
        softwareId: string,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software/{software_id}/price-informations/count',
            path: {
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Software License Price Information
     * @returns PriceInformation Successful Response
     * @throws ApiError
     */
    public static getSoftwareLicensePriceInformationApiV1SoftwareSoftwareLicensePriceInformationsPriceInformationIdGet({
        priceInformationId,
        includeDeleted = false,
        xTransactionId,
    }: {
        priceInformationId: string,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<PriceInformation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software-license-price-informations/{price_information_id}',
            path: {
                'price_information_id': priceInformationId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Software License Price Information
     * @returns PriceInformation Successful Response
     * @throws ApiError
     */
    public static updateSoftwareLicensePriceInformationApiV1SoftwareSoftwareLicensePriceInformationsPriceInformationIdPatch({
        priceInformationId,
        requestBody,
        includeDeleted = false,
        xTransactionId,
    }: {
        priceInformationId: string,
        requestBody: PriceInformationUpdate,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<PriceInformation> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/software/software-license-price-informations/{price_information_id}',
            path: {
                'price_information_id': priceInformationId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Software License Price Information
     * @returns void
     * @throws ApiError
     */
    public static deleteSoftwareLicensePriceInformationApiV1SoftwareSoftwareLicensePriceInformationsPriceInformationIdDelete({
        priceInformationId,
        includeDeleted = false,
        xTransactionId,
    }: {
        priceInformationId: string,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/software/software-license-price-informations/{price_information_id}',
            path: {
                'price_information_id': priceInformationId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
