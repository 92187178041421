<template>
  <div>
    <AliasDialog
      v-model:visibility="aliasDialogVisibility"
      :alias="aliasDialogAlias"
      :user="user"
      @refetch-user="refetchUser()" />

    <AliasDeleteDialog
      v-if="aliasDeleteDialogVisibility && aliasDeleteDialogAlias"
      v-model:visibility="aliasDeleteDialogVisibility"
      :alias="aliasDeleteDialogAlias"
      :response="aliasDeleteDialogResponse"
      @abort="() => deleteDialogAbort()"
      @confirm="() => handleAliaseDelete(aliasDeleteDialogAlias)" />

    <div class="el-descriptions el-descriptions__title header-wrapper">
      <span>{{ i18n.t('drawers.userDetailDrawer.aliasesCard.title') }}</span>
      <div>
        <SmButton
          v-require-permission="CompanyAccessRole.ADMIN"
          outline
          size="small"
          style="margin-top: 2px"
          @click="
            () => (
              (aliasDialogAlias = undefined), (aliasDialogVisibility = true)
            )
          ">
          <v-icon name="md-add-round" class="mr-2 fill-contrast" />
          {{ i18n.t('drawers.userDetailDrawer.aliasesCard.createAlias') }}
        </SmButton>
      </div>
    </div>

    <SmTable
      :data="aliasesData"
      :columns="columns"
      :expandable="true"
      key-field="name"
      class="!h-auto overflow-hidden">
      <template #name="{ row }"> {{ row.name }} </template>

      <template #description="{ row }"> {{ row.description }} </template>

      <template #actions="{ row }">
        <div class="flex items-center gap-2">
          <v-icon
            name="md-modeedit-round"
            style="cursor: pointer"
            @click="
              () => ((aliasDialogAlias = row), (aliasDialogVisibility = true))
            " />

          <v-icon
            name="md-delete-round"
            class="delete-button"
            scale="1"
            @click="handleAliaseDelete(row)" />
        </div>
      </template>

      <template #expand="{ row }">
        <div
          v-if="aliasesLicences[row.name] && aliasesLicences[row.name].length"
          class="bg-gray-50 dark:bg-gray-700">
          <SmTable
            lazy
            :data="aliasesLicences[row.name]"
            :columns="expandColumns"
            key-field="_id"
            class="!h-auto overflow-hidden py-4 pl-14">
            <template #applicaction="{ row }">
              <div class="flex items-center gap-3">
                <ApplicationIcon
                  :software-name="getSoftwareName(row.software_id)"
                  size="20px" />
                <TextMultiline :text="getSoftwareName(row.software_id)" />
              </div>
            </template>
            <template #sub_account="{ row }">
              <TextMultiline
                v-if="getSubAccountNameById(row.sub_account_id)"
                :text="getSubAccountNameById(row.sub_account_id)"
                :show-tool-tip="true" />
            </template>
            <template #licence_model="{ row }">
              <TextMultiline
                v-for="license in row.software_licenses"
                :key="license.name"
                :text="license.name" />

              <span v-if="!row.software_licenses">
                {{ i18n.t('general.noModel') }}
              </span>
            </template>
          </SmTable>
        </div>
      </template>
    </SmTable>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import {
    Account,
    CompanyAccessRole,
    LicenceOut,
    SoftwareOut,
    SubAccount_Output,
    UserLicencesService,
  } from '@/client'
  import { deleteAlias } from '@/common/user'
  import { sendToast } from '@/components/sm/SmNotification'
  import { useGlobalStore } from '@/stores/globalStore'
  import { AliasType } from '@/types/user'
  import { Column } from './sm/SmTable.types'
  import { PersonStore } from '@/stores/person.store'

  interface aliasData {
    description: string
    software_id: string | null
    sub_account_id: string | null
    name: string
  }

  const emit = defineEmits(['update:user'])
  const props = defineProps({
    user: {
      type: Object as PropType<Account>,
      required: true,
    },
    softwares: {
      type: Array as PropType<SoftwareOut[]>,
      required: true,
    },
  })

  const user = computed({
    get: () => props.user,
    set: (value) => emit('update:user', value),
  })

  const softwares = computed(() => props.softwares)

  const store = useGlobalStore()
  const i18n = useI18n()

  const aliasDialogVisibility = ref<boolean>(false)
  const aliasDialogAlias = ref<AliasType>()
  const aliasesData = computed<aliasData[]>(() => {
    if (user.value && user.value.aliases) {
      return Object.entries(user.value.aliases).map(([key, value]) => ({
        name: key,
        description: value.description,
        software_id: value.software_id || null,
        sub_account_id: value.sub_account_id || null,
      }))
    }
    return []
  })

  const columns: Column<aliasData>[] = [
    {
      key: 'name',
      label: i18n.t('drawers.userDetailDrawer.licencesCard.table.name'),
      width: 1,
    },
    {
      key: 'description',
      label: i18n.t('general.description'),
      sortable: true,
      width: 1,
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
      width: 'auto',
    },
  ]

  const expandColumns: Column<LicenceOut>[] = [
    {
      key: 'applicaction',
      label: i18n.t('Application'),
      sortable: false,
      width: 1,
    },
    {
      key: 'sub_account',
      label: i18n.t('subaccount'),
      sortable: false,
      width: 1,
    },
    {
      key: 'licence_model',
      label: i18n.t('drawers.userDetailDrawer.licencesCard.table.licenceModel'),
      sortable: false,
      width: 1,
    },
  ]

  const aliasesLicences = ref<Record<string, LicenceOut[]>>({})

  const refetchUser = () => {
    PersonStore.getPersonByEmail(user.value.email).then((fetchedUser) => {
      if (fetchedUser) {
        user.value = fetchedUser
      }
    })
  }

  const aliasDeleteDialogVisibility = ref<boolean>(false)
  const aliasDeleteDialogAlias = ref<AliasType>()
  const aliasDeleteDialogResponse = ref()
  const handleAliaseDelete = (row?: AliasType) => {
    if (!row) {
      return sendToast(
        i18n.t('error.request.unknown.title'),
        i18n.t('error.request.unknown.message'),
        'error'
      )
    }

    deleteAlias(
      store.getIp,
      store.getJWT(),
      user.value.email,
      row,
      aliasDeleteDialogAlias.value == null,
      true
    ).then((response) => {
      if (aliasDeleteDialogAlias.value == null) {
        aliasDeleteDialogResponse.value = response
        aliasDeleteDialogAlias.value = row
        aliasDeleteDialogVisibility.value = true
      } else {
        refetchUser()
        aliasDeleteDialogVisibility.value = false
      }
    })
  }

  const deleteDialogAbort = () => {
    aliasDeleteDialogResponse.value = undefined
    aliasDeleteDialogAlias.value = undefined
    aliasDeleteDialogVisibility.value = false
  }

  const getSubAccountNameById = (subAccountId: string): string | undefined => {
    const subAccount = softwares.value
      .flatMap((software) => {
        if (software.sub_accounts) {
          if (Array.isArray(software.sub_accounts)) {
            return software.sub_accounts
          } else {
            return Object.values(software.sub_accounts)
          }
        } else {
          return []
        }
      })
      .find((subAccount: SubAccount_Output) => subAccount.id === subAccountId)

    return subAccount?.name || undefined
  }

  function getSoftwareName(id: string) {
    const software = softwares.value.find((software) => software._id === id)

    return software?.software_name || ''
  }

  onMounted(() => {
    if (user.value && user.value.aliases) {
      Object.keys(user.value.aliases || {}).forEach((alias) => {
        UserLicencesService.getLicencesApiV1SoftwareLicencesGet({
          email: alias,
        }).then((licences) => {
          // Check if the alias is still in the list
          aliasesLicences.value[alias] = licences
        })
      })
    }
  })
</script>

<style scoped>
  .company-mini-img {
    width: 20px;
    height: 20px;
  }
  .table_row_cell_content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .text-muted {
    color: var(--el-text-color-secondary);
    font-style: italic;
    gap: 0.5rem;
    display: flex;
    align-items: center;
  }
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  .delete-button:hover {
    color: var(--el-color-danger);
  }
</style>
