<template>
  <!-- Tags -->
  <TagManageDialog
    v-model:visibility="dialogManageVisible"
    @tag-changed="loadTags()" />

  <TagCreateDialog
    v-model:visibility="dialogCreateVisible"
    @saved="(newTag) => handleNewTagCreated(newTag)"
    @closed="newTagSoftware = null" />

  <!-- Disconnect -->
  <SoftwareDisconnectDialog
    v-if="softwareToDelete"
    v-model:visibility="deleteSoftwareDialogShown"
    :software="softwareToDelete" />

  <SmTable
    :data="filteredSoftware"
    :columns="columns"
    :row-link="getApplicationLink"
    :default-sorting="{ by: 'display_name', asc: false }"
    key-field="_id"
    :loading="loading"
    @cell-click="handleCellClick">
    <!-- Status Indicator -->
    <template #statusIndicator="{ row }">
      <LicenceStatusSmall :status="getStatus(row)" :show-text="false" />
    </template>

    <!-- Icon and Name -->
    <template #display_name="{ row }">
      <div class="flex items-center gap-3">
        <ApplicationIcon
          :software-name="getSoftwareName(row)"
          class="shrink-0"
          size="25px"
          :software-type="getSoftwareType(row)" />
        <TextMultiline>{{ row.display_name }}</TextMultiline>
        <SmTooltip v-if="row.type !== 'sso' && row.read_only">
          <template #content> {{ i18n.t('readonlyDesc') }} </template>
          <span class="no-data">({{ i18n.t('readonlyShort') }})</span>
        </SmTooltip>

        <span v-if="row.type === 'sso'" class="no-data"
          >({{
            i18n.t('viaX', {
              x: getDataFromSoftwareId(row.software_id)?.display_name,
            })
          }})
        </span>
      </div>
    </template>

    <!-- Tags -->
    <template #header-tags>
      <div class="flex items-center gap-2">
        {{ i18n.t('tags') }}
        <v-icon
          name="md-settings-round"
          scale="0.8"
          hover
          style="cursor: pointer"
          @click="dialogManageVisible = true" />
      </div>
    </template>

    <!-- Tags -->
    <template #tags="{ row }">
      <div class="flex w-full cursor-default flex-wrap items-center gap-2 py-2">
        <TagComponent
          v-for="tag in getTagsByIds(row.tags)"
          :key="tag._id"
          :tag="tag"
          :selected="selectedTags.includes(tag)"
          :style="{ maxWidth: 'calc(100% - 2rem)' }"
          class="cursor-pointer"
          @click.capture="handleTagClick(tag)" />
        <TagSelect
          show-create-tag
          :available-tags="availableTags"
          :selected="row.tags"
          @create-tag="openCreateTagDialog(row)"
          @delete-tag="removeTagFromApplication($event, row.tags, row)"
          @add-tag="addTagToApplication($event, row.tags, row)">
          <v-icon
            scale="0.8"
            name="hi-plus"
            class="tag-add-icon opacity-0 transition" />
        </TagSelect>
      </div>
    </template>

    <!-- User Count -->
    <template #user_count="{ row }">
      <span>
        {{ getUserCount(row) }}
      </span>
    </template>

    <!-- Costs -->
    <template #cost="{ row }">
      <span>
        {{ getSoftwareCostsString(row) }}
      </span>
    </template>

    <!-- Actions -->
    <template #action="{ row }">
      <div class="table-options">
        <SmDropdown trigger="click">
          <SmDropdownItem
            icon="md-gridview-round"
            @click="openApplicationPage(row)">
            {{ i18n.t('showApplication') }}
          </SmDropdownItem>
          <SmDropdownItem
            icon="md-modeedit-round"
            @click="openApplicationPage(row, true)">
            {{ i18n.t('editApplication') }}
          </SmDropdownItem>
          <SmDropdownItem
            v-if="
              isSoftwareOut(row) &&
              row?.information &&
              !row.information.sso_provider
            "
            v-require-permission="CompanyAccessRole.ADMIN"
            hover-color="var(--sm-magenta)"
            icon="md-linkoff-round"
            @click="handleDeleteSoftwareRowClick(row)">
            {{
              i18n.t('disconnectApplication', {
                softwareName: row.display_name,
              })
            }}
          </SmDropdownItem>

          <!-- Hide Subaccoutn/SSO Application -->
          <SmDropdownItem
            v-else-if="isSSOSubAccount(row)"
            v-require-permission="CompanyAccessRole.ADMIN"
            hover-color="var(--sm-magenta)"
            icon="md-hideimage-round"
            @click="handleHideSoftwareRowClick(row)">
            {{ i18n.t('hideApplication', { name: row.display_name }) }}
          </SmDropdownItem>
        </SmDropdown>
      </div>
    </template>
  </SmTable>
</template>

<script setup lang="ts">
  import {
    CompanyAccessRole,
    PaginationSortOrder,
    SoftwareCostOut,
    SoftwareOut,
    SoftwareService,
    SoftwareSubaccountsService,
    SoftwareTypes,
    SoftwareUpdateIn,
    SoftwareUserCount,
    SubAccountUpdateIn,
    SubAccount_Output,
    Tag,
    TagsService,
  } from '@/client'
  import { getCorrectedPrice } from '@/common/licenceModel'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import { CustomStatus } from './LicenceStatusSmall.vue'
  import { Column } from './sm/SmTable.types'

  interface Props {
    softwares: SoftwareOut[]
    softwareCosts: Record<string, SoftwareCostOut> | undefined
    userCounts: Record<string, SoftwareUserCount> | undefined
  }

  const props = defineProps<Props>()
  const emit = defineEmits<{
    'software-changed': []
    'tag-selection-changed': [selection: Tag[]]
    'software-hiden': [SSOSubAccount]
  }>()

  const i18n = useI18n()
  const router = useRouter()

  const dialogManageVisible = ref(false)
  const dialogCreateVisible = ref(false)
  const loading = defineModel<boolean>('loading')

  // ##############################################
  // Table Related
  // ##############################################
  export type SSOSubAccount = SubAccount_Output & {
    _id: string
    type: 'sso'
    software_id: string
  }

  const displayData = computed(() => {
    const _softwares: (SoftwareOut | SSOSubAccount)[] = []
    for (const software of props.softwares) {
      // Check if SSO
      if (software.information.sso_provider) {
        for (const [key, subAccount] of Object.entries(software.sub_accounts)) {
          if (!software.config.sub_accounts_filter.includes(key)) {
            _softwares.push({
              _id: key,
              type: 'sso',
              software_id: software._id,
              ...subAccount,
            })
          }
        }
      } else {
        _softwares.push({
          ...software,
        })
      }
    }
    return _softwares
  })

  function isSSOSubAccount(
    software: SoftwareOut | SSOSubAccount
  ): software is SSOSubAccount {
    return !isSoftwareOut(software)
  }

  function isSoftwareOut(
    software: SoftwareOut | SSOSubAccount
  ): software is SoftwareOut {
    return 'software_name' in software
  }

  function handleCellClick(
    _: number,
    columnKey: string,
    row: SoftwareOut | SSOSubAccount
  ) {
    if (columnKey === 'tags' || columnKey === 'action') return

    openApplicationPage(row)
  }

  function getSoftwareName(software: SoftwareOut | SSOSubAccount) {
    if (isSoftwareOut(software)) {
      return software.software_name
    }
    return software.name
  }

  function getSoftwareType(
    software: SoftwareOut | SSOSubAccount
  ): SoftwareTypes | 'SSO' | null {
    if (isSSOSubAccount(software)) {
      return 'SSO'
    }
    return software.type
  }

  // Click Event
  function getApplicationParams(software: SoftwareOut | SSOSubAccount) {
    if (software.type === 'sso') {
      return {
        id: software.software_id,
        subAccountId: encodeURIComponent(software._id),
      }
    } else {
      return {
        id: software._id,
      }
    }
  }

  function getApplicationLink(software: SoftwareOut | SSOSubAccount) {
    const isSso = software.type === 'sso'
    const { href } = router.resolve({
      name: 'Application',
      params: getApplicationParams(software),
      query: {
        sso: isSso ? 'true' : 'false',
      },
    })
    return href
  }

  function openApplicationPage(
    software: SoftwareOut | SSOSubAccount,
    editMode: boolean = false
  ) {
    const isSso = software.type === 'sso'
    router.push({
      name: 'Application',
      params: getApplicationParams(software),
      query: {
        sso: isSso ? 'true' : 'false',
        edit: editMode ? 'true' : 'false',
      },
    })
  }

  // Delete Software
  const deleteSoftwareDialogShown = ref(false)
  const softwareToDelete = ref<SoftwareOut>()
  function handleDeleteSoftwareRowClick(software: SoftwareOut) {
    softwareToDelete.value = software
    deleteSoftwareDialogShown.value = true
  }

  function handleHideSoftwareRowClick(subaccount: SSOSubAccount) {
    const _current_software = props.softwares.find(
      (s) => s._id === subaccount.software_id
    )

    const _current_subaccounts =
      _current_software?.config.sub_accounts_filter || []

    SoftwareService.putSoftwareApiV1SoftwareSoftwareSoftwareIdPut({
      softwareId: subaccount.software_id,
      requestBody: {
        config: {
          sub_accounts_filter: [..._current_subaccounts, subaccount._id],
        },
      },
    }).then(() => {
      emit('software-hiden', subaccount)
    })
  }

  // ##############################################
  // Sorting Related
  // ##############################################

  function userSortFn(
    a: SoftwareOut | SSOSubAccount,
    b: SoftwareOut | SSOSubAccount
  ) {
    const aCount = getUserCount(a)
    const bCount = getUserCount(b)

    // Handle cases where no count is found (-)
    if (aCount === '-' && bCount === '-') return 0
    if (aCount === '-') return 1
    if (bCount === '-') return -1

    // Sort by count
    return bCount - aCount
  }

  // ##############################################
  // Tags Related
  // ##############################################
  const availableTags = ref<Tag[]>([])
  const selectedTags = ref<Tag[]>([])

  function updateSoftware(softwareId: string, newSoftware: SoftwareUpdateIn) {
    SoftwareService.putSoftwareApiV1SoftwareSoftwareSoftwareIdPut({
      softwareId: softwareId,
      requestBody: newSoftware,
    }).then(() => {
      emit('software-changed')
    })
  }

  function updateSubAccount(
    softwareId: string,
    subAccountId: string,
    newSoftware: SubAccountUpdateIn
  ) {
    SoftwareSubaccountsService.updateSubAccountApiV1SoftwareSoftwareSoftwareIdSubAccountsSubAccountIdPatch(
      {
        softwareId: softwareId,
        subAccountId: subAccountId,
        requestBody: newSoftware,
      }
    ).then(() => {
      emit('software-changed')
    })
  }

  function getTagsByIds(ids: string[]): Tag[] {
    return availableTags.value.filter((tag) => ids.includes(tag._id))
  }

  function removeTagFromApplication(
    tagId: string,
    tagsBefore: string[],
    software: SoftwareOut | SSOSubAccount
  ) {
    const updateTags = tagsBefore.filter((existing) => existing != tagId)

    if (software.type === 'sso') {
      // SSO
      const softwareUpdate: SubAccountUpdateIn = {
        tags: updateTags,
      }
      updateSubAccount(software.software_id, software._id, softwareUpdate)
    } else {
      //Software
      const softwareUpdate: SoftwareUpdateIn = { tags: updateTags }
      updateSoftware(software._id, softwareUpdate)
    }
  }

  function addTagToApplication(
    tagId: string,
    tagsBefore: string[],
    software: SoftwareOut | SSOSubAccount
  ) {
    const updateTags = new Array(...tagsBefore)
    updateTags.push(tagId)
    if (software.type === 'sso') {
      // SSO
      const softwareUpdate: SubAccountUpdateIn = {
        tags: updateTags,
      }
      updateSubAccount(software.software_id, software._id, softwareUpdate)
    } else {
      //Software
      const softwareUpdate: SoftwareUpdateIn = { tags: updateTags }
      updateSoftware(software._id, softwareUpdate)
    }
  }

  function handleTagClick(tag: Tag) {
    if (selectedTags.value.includes(tag)) {
      selectedTags.value.splice(selectedTags.value.indexOf(tag), 1)
    } else {
      selectedTags.value.push(tag)
    }
    emit('tag-selection-changed', selectedTags.value)
  }

  const newTagSoftware = ref<SoftwareOut | SSOSubAccount | null>(null)

  function openCreateTagDialog(software: SoftwareOut | SSOSubAccount) {
    dialogCreateVisible.value = true
    newTagSoftware.value = software
  }

  function handleNewTagCreated(tag: Tag) {
    loadTags()
    addTagToApplication(
      tag._id,
      newTagSoftware.value?.tags || [],
      newTagSoftware.value!
    )
    newTagSoftware.value = null
  }

  function loadTags() {
    TagsService.getTagsApiV1ManagementTagsGet({
      sortBy: 'sorting',
      sortOrder: PaginationSortOrder.DESC,
    }).then((tags) => {
      availableTags.value = tags
    })
  }

  function getUserCount(software: SoftwareOut | SSOSubAccount) {
    if (props.userCounts && software._id in props.userCounts) {
      return props.userCounts[software._id]['total'] || 0
    }

    // SSOSubAccount
    if (
      software.type === 'sso' &&
      props.userCounts &&
      software.software_id in props.userCounts &&
      'sub_accounts' in props.userCounts[software.software_id]
    ) {
      const subaccounts = props.userCounts[software.software_id][
        'sub_accounts'
      ] as Record<string, number> // Should be a Record<string, number>
      return subaccounts[software._id] || 0
    }
    return '-'
  }

  // ##############################################
  // Costs Related
  // ##############################################

  function getSoftwareCosts(software: SoftwareOut | SSOSubAccount) {
    if (props.softwareCosts && software._id in props.softwareCosts) {
      return props.softwareCosts[software._id]['total']
    }
    if (
      'software_id' in software &&
      props.softwareCosts &&
      software.software_id in props.softwareCosts &&
      'sub_account_costs' in props.softwareCosts[software.software_id]
    ) {
      const subaccounts = props.softwareCosts[software.software_id][
        'sub_account_costs'
      ] as Record<string, number> // Should be a Record<string, number>
      return subaccounts[software._id] || 0
    }

    return 0
  }

  function getSoftwareCostsString(software: SoftwareOut | SSOSubAccount) {
    const costs = getSoftwareCosts(software)

    if (costs) {
      return getCorrectedPrice(costs)
    }

    return '-'
  }

  function getStatus(software: SoftwareOut | SSOSubAccount): CustomStatus {
    if (software.type === 'sso') {
      return {
        color: 'var(--sm-blue)',
        text: i18n.t('ssoManaged', {
          name: getDataFromSoftwareId(software.software_id)?.display_name,
        }),
      }
    }

    if (software.authentication_expired) {
      return {
        color: 'var(--sm-magenta)',
        text: i18n.t('reauthenticationRequired'),
      }
    }

    if (software.awaits_authorization) {
      return {
        color: 'var(--sm-yellow)',
        text: i18n.t('authPending.title'),
      }
    }

    switch (software.type) {
      case SoftwareTypes.MANUALLY:
        return {
          color: ' var(--sm-contrast-muted)',
          text: i18n.t('general.applicationTypeTag.manually'),
        }
      case SoftwareTypes.TOKEN:
      case SoftwareTypes.BOT:
      case SoftwareTypes.OAUTH:
        return {
          color: 'var(--sm-primary)',
          text: i18n.t(`general.applicationTypeTag.${software.type}`),
        }
      default:
        return {
          color: 'var(--sm-orange)',
          text: i18n.t('general.applicationTypeTag.unknown'),
        }
    }
  }

  onMounted(async () => {
    loadTags()
  })

  // ##############################################
  // Filter related
  // ##############################################

  // SoftwareSourceFilte
  export interface SoftwareSourceFilterState {
    manually: boolean
    sso: boolean
    integration: boolean
  }

  const softwareSourceFilter = defineModel<SoftwareSourceFilterState>(
    'softwareSourceFilter',
    {
      default: {
        manually: true,
        sso: true,
        integration: true,
      },
    }
  )

  export interface MoreApplicationsFilter {
    hideWithoutCosts: boolean
    hideWithCosts: boolean
    hideWithoutUser: boolean
  }

  const moreApplicationsFilter = defineModel<MoreApplicationsFilter>(
    'moreApplicationsFilter',
    {
      default: {
        hideWithoutCosts: false,
        hideWithCosts: false,
        hideWithoutUser: false,
      },
    }
  )

  // Search
  const searchQuery = defineModel<string>('searchQuery', {
    required: false,
    default: '',
  })

  const tagFilter = defineModel<Tag[]>('tagFilter', {
    required: false,
    default: [],
  })
  const filteredSoftware = computed(() => {
    return displayData.value?.filter((_software) => {
      // Source
      if (
        !softwareSourceFilter.value.manually &&
        _software.type === 'manually'
      ) {
        return false
      }
      if (!softwareSourceFilter.value.sso && _software.type === 'sso') {
        return false
      }
      if (
        !softwareSourceFilter.value.integration &&
        (_software.type === 'bot' ||
          _software.type === 'oauth' ||
          _software.type === 'token')
      ) {
        return false
      }

      // Tags
      if (tagFilter.value.length > 0) {
        if (!_software.tags) return false
        if (!tagFilter.value.some((tag) => _software.tags?.includes(tag._id))) {
          return false
        }
      }

      // More application filter
      if (
        moreApplicationsFilter.value.hideWithoutCosts &&
        getSoftwareCostsString(_software) === '-'
      ) {
        return false
      }
      if (
        moreApplicationsFilter.value.hideWithCosts &&
        getSoftwareCostsString(_software) !== '-'
      ) {
        return false
      }
      if (
        moreApplicationsFilter.value.hideWithoutUser &&
        getUserCount(_software) === 0
      ) {
        return false
      }

      // Search
      if (searchQuery.value === '') return true
      if (!_software.display_name) return false

      const searchQueryValid =
        _software.display_name
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase()) ||
        ((_software as SoftwareOut).information?.display_name
          ?.toLowerCase()
          .includes(searchQuery.value.toLowerCase()) ??
          false)

      return searchQueryValid
    })
  })

  const filteredTotalCost = computed(() => {
    if (!props.softwareCosts) return 0

    const filteredIds = filteredSoftware.value.map((item) => item._id)
    return Object.entries(props.softwareCosts)
      .filter(([id]) => filteredIds.includes(id))
      .reduce((sum, [, item]) => sum + item.total, 0)
  })

  const formattedTotalCost = computed(() =>
    getCorrectedPrice(filteredTotalCost.value)
  )

  const filteredTotalUser = computed(() => {
    if (!props.userCounts) return 0

    const filteredIds = filteredSoftware.value.map((item) => item._id)
    return Object.entries(props.userCounts)
      .filter(([id]) => filteredIds.includes(id))
      .reduce((sum, [, item]) => sum + (item.total || 0), 0)
  })

  const filteredTotalApps = computed(() => {
    if (filteredSoftware.value.length !== displayData.value.length) {
      return `${i18n.t('XofY', {
        x: filteredSoftware.value.length,
        y: displayData.value.length,
      })} ${i18n.t('application', { count: filteredSoftware.value.length })}`
    } else {
      return `${filteredSoftware.value.length} ${i18n.t('application', { count: filteredSoftware.value.length })}`
    }
  })

  // ##############################################
  // Utils
  // ##############################################

  function getDataFromSoftwareId(softwareId: string) {
    return props.softwares.find((s: SoftwareOut) => s._id === softwareId)
  }

  const filteredSoftwareCount = computed(() => {
    return filteredSoftware.value.length
  })

  const unfilteredSoftwareCount = computed(() => {
    return displayData.value?.length
  })

  const columns = computed<Column<SoftwareOut | SSOSubAccount>[]>(() => [
    {
      key: 'statusIndicator',
      label: '',
      width: '10px',
      sortable: false,
    },
    {
      key: 'display_name',
      label: i18n.t('name'),
      sortable: true,
      width: 1,
      sortFn: (a, b) => {
        if (a.display_name && b.display_name) {
          return a.display_name.localeCompare(b.display_name)
        }
        return 0
      },
      footer: filteredTotalApps,
      loader: true,
    },
    {
      key: 'tags',
      label: i18n.t('tags'),
      sortable: false,
      width: 1,
      loader: true,
    },
    {
      key: 'user_count',
      label: i18n.t('account', { count: 2 }),
      sortable: true,
      width: 0.7,
      sortFn: userSortFn,
      footer: filteredTotalUser,
      loader: true,
    },
    {
      key: 'cost',
      label: i18n.t('views.applications.table.costsLabel'),
      sortable: true,
      width: 0.7,
      sortFn: (a, b) => {
        return getSoftwareCosts(a) - getSoftwareCosts(b)
      },
      footer: formattedTotalCost,
      loader: true,
    },
    {
      key: 'action',
      label: '',
      width: '50px',
      sortable: false,
    },
  ])

  defineExpose({
    filteredSoftware,
    filteredSoftwareCount,
    unfilteredSoftwareCount,
  })
</script>

<style lang="scss">
  .sm-table-row {
    &:hover {
      .tag-add-icon {
        opacity: 1;
      }
    }
  }
</style>
