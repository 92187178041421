import { until, useColorMode, useDark } from '@vueuse/core'
import { defineStore } from 'pinia'

import {
  PreferenceAppearanceThemes,
  Preferences,
  PreferencesUpdate,
  UserProfileService,
} from '@/client'

export const usePreferenceStore = defineStore('preference', () => {
  const preferences = ref<Preferences>()
  const lastUpdated = ref<Date | null>(null)

  // ************************************************************
  // Color mode
  // ************************************************************
  watch(
    () => preferences.value?.appearance,
    async () => {
      const colorMode = useColorMode()
      switch (preferences.value?.appearance) {
        case PreferenceAppearanceThemes.DARK_THEME:
          colorMode.value = 'dark'
          break
        case PreferenceAppearanceThemes.LIGHT_THEME:
          colorMode.value = 'light'
          break
        default:
          colorMode.value = 'auto'
      }
    }
  )

  async function toggleColorMode() {
    const isDark = useDark()
    if (!preferences.value) await getAndUpdatePreferences()

    switch (preferences.value?.appearance) {
      case PreferenceAppearanceThemes.DARK_THEME:
        setPreference('appearance', PreferenceAppearanceThemes.LIGHT_THEME)
        break
      case PreferenceAppearanceThemes.LIGHT_THEME:
        setPreference('appearance', PreferenceAppearanceThemes.DARK_THEME)
        break
      default:
        // Get system color mode and toggle
        setPreference(
          'appearance',
          isDark.value
            ? PreferenceAppearanceThemes.LIGHT_THEME
            : PreferenceAppearanceThemes.DARK_THEME
        )
    }
  }

  async function setAppearance(appearance: PreferenceAppearanceThemes) {
    return await setPreference('appearance', appearance)
  }

  // ************************************************************
  // Get and update preferences
  // ************************************************************
  const currentlyLoadingPreferences = ref<boolean>(false)
  async function getPreferences() {
    // Prevent redundant requests
    if (currentlyLoadingPreferences.value === true) {
      await until(currentlyLoadingPreferences).toMatch((r) => r === false)
    }
    if (lastUpdated.value == null) {
      currentlyLoadingPreferences.value = true
      await getAndUpdatePreferences()
      currentlyLoadingPreferences.value = false
    }
    return preferences.value as Preferences
  }

  async function getAndUpdatePreferences() {
    const userProfile = await UserProfileService.getUserProfileUserGet({})
    if (!userProfile.preferences) {
      return
    }
    preferences.value = userProfile.preferences
    lastUpdated.value = new Date()
  }

  // ************************************************************
  // Update preference
  // ************************************************************
  async function setPreference<R extends keyof PreferencesUpdate>(
    preference: R,
    value: PreferencesUpdate[R]
  ): Promise<Preferences> {
    UserProfileService.updateUserProfileUserPatch({
      requestBody: {
        preferences: {
          [preference]: value,
        },
      },
    })

    // Update local preferences
    if (!preferences.value) await getAndUpdatePreferences()
    if (value) preferences.value![preference] = value as Preferences[R]

    // Save to local storage
    localStorage.setItem('preferences', JSON.stringify(preferences.value))

    return preferences.value as Preferences
  }

  return {
    preferences,
    getPreferences,
    getAndUpdatePreferences,
    setPreference,
    toggleColorMode,
    setAppearance,
  }
})
