<template>
  <div>
    <SmLoading :loading="form.processing" />
    <el-form label-position="top" require-asterisk-position="right">
      <!-- Description -->
      <el-form-item
        :error="getErrorMessage('description')"
        :prop="`form.fields.description`"
        :label="i18n.t('name')">
        <el-input
          v-model="form.fields.description"
          @blur="form.validate(['description'])" />
      </el-form-item>

      <div class="grid-2-col">
        <!-- Billing Unit -->
        <el-form-item
          :prop="`form.fields.billing_unit`"
          :error="getErrorMessage('billing_unit')"
          :label="i18n.t('billingUnit')">
          <el-select
            v-model="selectedBillingUnit"
            :placeholder="i18n.t('licenseModelForm.billingUnit.placeHolder')"
            :disabled="props.isFallback"
            @blur="form.validate(['billing_unit'])">
            <el-option
              v-for="u in availableBillingUnits"
              :key="u.value"
              :value="u.value"
              :label="u.label" />
          </el-select>
        </el-form-item>

        <!-- Billing Period -->
        <el-form-item
          :prop="`form.fields.billing_period`"
          :error="getErrorMessage('billing_period')"
          :label="i18n.t('billingPeriod')">
          <el-select
            v-model="selectedBillingPeriod"
            :placeholder="i18n.t('licenseModelForm.billingPeriod.placeHolder')"
            @blur="form.validate(['billing_period'])">
            <el-option
              v-for="p in availableBillingPeriods"
              :key="p.value"
              :value="p.value"
              :label="p.label" />
          </el-select>
        </el-form-item>
      </div>

      <div v-if="form.fields.billing_unit === 'quota'">
        <el-form-item
          v-if="
            (currentlyActive && !props.hasSyncedPrice && props.externalQuota) ||
            (currentlyActive && props.hasSyncedPrice && isSynced)
          "
          :prop="`form.fields.set_quota`"
          :error="getErrorMessage('quota')">
          <template #label>
            <div style="display: flex; gap: 6px">
              <span>{{
                i18n.t('licenseModelForm.automatic_assign.label')
              }}</span>
              <SmTooltip
                :content="i18n.t('licenseModelForm.automatic_assign.tooltip')">
                <v-icon name="md-help-round" scale=".8" />
              </SmTooltip>
            </div>
          </template>
          <SmCheckbox v-model="isSynced">
            <p style="line-height: 1.8rem">
              {{
                i18n.t('licenseModelForm.automatic_assign.checkbox', {
                  quota: leftOverQuota + (form.fields.quota ?? 0),
                })
              }}
            </p>
          </SmCheckbox>
        </el-form-item>

        <!-- Quota -->
        <el-form-item
          v-if="!props.isFallback"
          class="quota-input"
          :prop="`form.fields.set_quota`"
          :error="getErrorMessage('quota')"
          :label="i18n.t('quota')">
          <el-input-number
            v-model="form.fields.quota"
            :min="1"
            @blur="form.validate(['set_quota'])">
          </el-input-number>
        </el-form-item>
      </div>

      <div class="grid-2-col">
        <!-- Price -->
        <el-form-item
          :prop="`form.fields.price`"
          :label="
            form.fields.billing_unit === BillingUnit.QUOTA ||
            form.fields.billing_unit === BillingUnit.PER_USER
              ? i18n.t('pricePerQuota')
              : i18n.t('price')
          "
          :error="getErrorMessage('price')">
          <el-input
            v-model="form.fields.price"
            class="price-input"
            type="number"
            :formatter="
              (value: string) => value.replace(/([,.]\d{2})(\d)/g, '$1')
            "
            :placeholder="i18n.t('licenseModelForm.price.placeHolder')"
            @blur="form.validate(['price'])">
            <template #append>
              <el-select
                v-model="form.fields.currency"
                filterable
                style="width: 100px">
                <template #prefix>
                  <Icon
                    scale="0.8"
                    :icon="
                      form.fields.currency
                        ? currencyCodeToIconName(form.fields.currency)
                        : ''
                    " />
                </template>
                <el-option
                  v-for="c in currencies"
                  :key="c._id"
                  :value="c._id"
                  class="flex flex-row items-center justify-between gap-4">
                  <div>
                    <Icon
                      scale="0.8"
                      :icon="currencyCodeToIconName(c._id) || ''" />
                  </div>
                  <div class="currency-text">{{ c._id }}</div>
                </el-option>
              </el-select>
            </template>
          </el-input>
        </el-form-item>

        <!-- Contarct Duration -->
        <el-form-item
          :label="
            i18n.t(
              'dialogs.licenceModelEditDialog.licenseModelForm.contractDuration'
            )
          ">
          <el-date-picker
            v-model="contractDate"
            type="daterange"
            range-separator="-"
            :start-placeholder="i18n.t('views.documents.dateRange.from')"
            :end-placeholder="i18n.t('views.documents.dateRange.to')"
            format="DD.MM.YYYY"
            @blur="form.validate(['contract_duration'])"
            @change="updateContractDate" />
        </el-form-item>
      </div>

      <!-- Reminder -->
      <el-form-item>
        <SmCheckbox v-model="reminder" @change="updateReminderValues($event)">
          {{ i18n.t('reminder') }}
        </SmCheckbox>
      </el-form-item>

      <div
        class="grid-2-col"
        :class="{
          hidden: !reminder,
          shown: reminder,
          'reminder-date': true,
        }">
        <el-form-item
          :label="i18n.t('reminderDate')"
          :error="getErrorMessage('reminder_date')">
          <el-date-picker
            v-model="form.fields.reminder_date"
            format="DD.MM.YYYY"
            type="date"
            @blur="form.validate(['reminder_date'])" />
        </el-form-item>

        <el-form-item
          :error="getErrorMessage('send_reminder_to')"
          :prop="`priceInformation.send_reminder_to`"
          :label="
            i18n.t(
              'dialogs.licenceModelEditDialog.licenseModelForm.mailReceiver'
            )
          ">
          <el-select
            v-model="form.fields.send_reminder_to"
            @blur="form.validate(['send_reminder_to'])">
            <el-option
              v-for="m in members"
              :key="m._id"
              :value="m._id"
              :label="m.profile?.email" />
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script setup lang="ts">
  /* eslint @intlify/vue-i18n/no-raw-text: 0 */
  import { Icon } from '@iconify/vue'
  import * as Sentry from '@sentry/browser'
  import { useI18n } from 'vue-i18n'
  import { useSessionStore } from '@/stores/sessionStore'

  import {
    BillingPeriod,
    BillingUnit,
    Currency,
    CurrencyService,
    PriceInformation,
  } from '@/client'
  import {
    currencyCodeToIconName,
    sortCurrencies,
  } from '@/common/util/currencyUtil'
  import useForm from '@/common/form'
  import {
    notEmpty,
    isRequired,
    notEqual,
    ValidationResult,
  } from './sm/SmInput/SmInputValidator'
  import dayjs from 'dayjs'

  const i18n = useI18n()
  const sessionStore = useSessionStore()
  const members = computed(() => {
    return sessionStore.companyMembers
  })
  const model = defineModel<PriceInformation>('priceInformation', {
    required: true,
  })

  // Props
  export type Props = {
    hasSyncedPrice?: boolean
    totalActiveQuota?: number
    externalQuota?: number | null | undefined
    isFallback: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    hasSyncedPrice: false,
    totalActiveQuota: 0,
    externalQuota: undefined,
    isFallback: false,
  })

  const form = useForm(
    {
      ...toRaw(model.value),
    },
    // Form Hooks
    {
      onReset: () => {
        resetContractDate()
        reminder.value = model.value.reminder_date ? true : false
      },
    },

    // Form Validators
    {
      billing_period: [notEqual(BillingPeriod.UNKNOWN), isRequired],
      billing_unit: [notEqual(BillingUnit.UNKNOWN), isRequired],
      description: [notEmpty],
      price: [isRequired],
      reminder_date: [(x) => (reminder.value ? isRequired(x) : null)],
      send_reminder_to: [
        (input: string) => {
          if (reminder.value) {
            return isRequired(input)
          }
          return null
        },
      ],
    },
    {
      description: i18n.t('name'),
      billing_unit: i18n.t('billingUnit'),
      billing_period: i18n.t('billingPeriod'),
      quota: i18n.t('setQuota'),
      price: i18n.t('price'),
      from_date: i18n.t(
        'dialogs.licenceModelEditDialog.licenseModelForm.contractDuration'
      ),
      end_date: i18n.t(
        'dialogs.licenceModelEditDialog.licenseModelForm.contractDuration'
      ),
      reminder_date: i18n.t('reminderDate'),
      send_reminder_to: i18n.t(
        'dialogs.licenceModelEditDialog.licenseModelForm.mailReceiver'
      ),
    }
  )

  const currencies = ref<Currency[]>([])

  CurrencyService.getCurrenciesCurrenciesGet({}).then((c) => {
    currencies.value = sortCurrencies(c)
  })

  const isSynced = ref(form.fields.quota === 0 ? true : false)
  const leftOverQuota = computed(() => {
    return props.externalQuota && props.totalActiveQuota
      ? props.externalQuota - props.totalActiveQuota
      : 0
  })

  // Contract Duration
  const contractDate = ref([model.value.from_date, model.value.end_date])
  const updateContractDate = () => {
    form.fields.from_date = contractDate.value ? contractDate.value[0] : null
    form.fields.end_date = contractDate.value ? contractDate.value[1] : null
  }
  function resetContractDate() {
    contractDate.value = [model.value.from_date, model.value.end_date]
  }
  const currentlyActive = computed(() => {
    if (form.fields.from_date && form.fields.end_date) {
      return dayjs().isBetween(form.fields.from_date, form.fields.end_date)
    }
    return true
  })

  // Reminder
  const reminder = ref(model.value.reminder_date ? true : false)
  function updateReminderValues(state: boolean) {
    if (state === false) {
      form.fields.reminder_date = null
      form.fields.send_reminder_to = null
    }
  }

  const selectedBillingUnit = computed<BillingUnit | string>({
    get: () => {
      if (form.fields.billing_unit === BillingUnit.UNKNOWN) {
        return '' // show placeholder
      } else {
        return form.fields.billing_unit
      }
    },
    set: (value: string) => {
      if (
        typeof value === 'string' &&
        Object.values(BillingUnit).includes(value as BillingUnit)
      ) {
        form.fields.billing_unit = value as BillingUnit
      }
    },
  })

  const selectedBillingPeriod = computed<BillingPeriod | string>({
    get: () => {
      if (form.fields.billing_period === BillingPeriod.UNKNOWN) {
        return '' // show placeholder
      } else {
        return form.fields.billing_period
      }
    },
    set: (value: string) => {
      if (
        typeof value === 'string' &&
        Object.values(BillingPeriod).includes(value as BillingPeriod)
      ) {
        form.fields.billing_period = value as BillingPeriod
      }
    },
  })

  const availableBillingUnits = returnSelectOptions(
    'billingUnitValues',
    BillingUnit
  ).filter((option) => option.value !== BillingUnit.UNKNOWN)

  const availableBillingPeriods = returnSelectOptions(
    'billingPeriodValues',
    BillingPeriod
  ).filter((option) => option.value !== BillingPeriod.UNKNOWN)

  // Reset quota if billing unit is not QUOTA
  watch(
    () => form.fields.billing_unit,
    (newBillingUnit) => {
      if (newBillingUnit === BillingUnit.QUOTA) {
        form.fields.quota = form.fields.quota === null ? 0 : form.fields.quota
      } else {
        form.fields.quota = null
      }
    }
  )

  watch(
    () => form.fields.quota,
    (quota_value) => {
      // cap min value
      if (!isSynced.value) {
        if (
          (quota_value === null || quota_value === 0) &&
          props.externalQuota
        ) {
          form.fields.quota = 1
        }
      }
    }
  )

  watch(
    () => props.isFallback,
    (value) => {
      if (value) {
        form.fields.billing_unit = BillingUnit.QUOTA
        form.fields.quota = 0
      }
    }
  )

  function returnSelectOptions(
    i18nPrefix: string,
    optionEnum: { [s: number]: string }
  ) {
    return Object.values(optionEnum).map((enumValue: string) => {
      if (i18n.te(`${i18nPrefix}.${enumValue}`))
        return { label: i18n.t(`${i18nPrefix}.${enumValue}`), value: enumValue }

      Sentry.captureMessage(
        `Missing i18n translation in src/components/LicenseForm: ${i18nPrefix}.${enumValue} is missing`
      )
      return { label: enumValue, value: enumValue }
    })
  }

  function getErrorMessage(fieldName: keyof PriceInformation) {
    if (!((fieldName as string) in form.errors)) return ''
    const errors = form.errors[fieldName] as ValidationResult[]
    if (!errors || errors.length === 0 || !errors[0] || !errors[0].message)
      return ''
    const message = errors[0].message as string
    return message
  }

  onMounted(() => {
    if (!members.value.length) {
      sessionStore.getCompanyMembers()
    }
  })

  defineExpose({
    form,
  })
</script>

<style scoped lang="scss">
  .text-muted {
    color: var(--el-text-color-secondary);
    gap: 0.5rem;
    display: flex;
    align-items: center;
  }

  .number-input {
    flex-grow: 2;
  }

  .currency-input {
    flex-grow: 1;
  }

  .price-inputs {
    display: flex;
    gap: 1rem;

    width: 250px;
  }

  // Reminder
  .reminder-date,
  .quota-input {
    display: grid;
    transition: all 500ms ease-in-out;

    .el-form-item,
    &.el-form-item {
      transition: all 500ms ease-in-out;
      max-height: 100px;
    }

    &.hidden .el-form-item,
    &.hidden.el-form-item {
      margin: 0;
      max-height: 0;
    }

    > div,
    &.el-form-item {
      overflow: hidden;
    }
  }

  .grid-2-col {
    display: grid;
    gap: 20px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  :deep(.price-input) {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }

  :deep(.reminder-date) {
    .el-date-editor {
      width: 100%;
    }
  }

  :deep(.el-input-number.show-max-value) {
    .el-input__inner {
      flex-grow: 0;
      width: auto;
      text-align: left;
    }
    .el-input__wrapper::after {
      content: '/\00a0\00a0\00a0' var(--dynamic-max-value);
      display: block;
      white-space: nowrap;
      color: var(--el-input-text-color);
      font-size: var(--el-font-size-base);
    }
  }
</style>
