<template>
  <!-- Add Application Drawer  -->
  <ApplicationAddDrawer
    v-model:add-software-drawer="addSoftwareDrawer"
    @create-custom-software="(software: any) => openCustomDialog(software)"
    @software-deleted="loadData" />

  <!-- Page Content -->
  <div class="home">
    <!-- Header -->
    <ViewHeader :title="headline" />

    <div style="display: grid; grid-template-rows: auto 1fr">
      <div style="display: flex; justify-content: flex-end" class="no-print">
        <div class="table-controls-container">
          <!-- Filters -->

          <div style="display: flex; align-items: center; gap: 10px">
            <!-- Sources -->
            <SmDropdown :close-on-click-inside="false" max-height="30vh">
              <template #trigger>
                <SmButton outline size="small">
                  <div
                    v-if="activeSourceFilterCount === 3"
                    style="display: flex; align-items: center">
                    <span>{{ i18n.t('allSources') }}</span>
                    <v-icon name="md-expandmore-round" :scale="1.2" />
                  </div>

                  <div v-else style="display: flex; align-items: center">
                    <span>{{
                      i18n.t('sources', { count: activeSourceFilterCount })
                    }}</span>
                    <v-icon name="md-expandmore-round" :scale="1.2" />
                  </div>
                </SmButton>
              </template>
              <div style="padding: 1rem">
                <SoftwareSourceFilter
                  v-model:active-source-filter="activeSourceFilter"
                  :softwares="currentSoftware" />
              </div>
            </SmDropdown>

            <!-- Tag Filter -->
            <SmDropdown :close-on-click-inside="false" max-height="30vh">
              <template #trigger>
                <SmButton outline size="small">
                  <div style="display: flex; align-items: center">
                    <span v-if="tagFilter.length === 0">{{
                      i18n.t('allTags')
                    }}</span>
                    <span v-else>{{
                      i18n.t('selectedTags', { count: tagFilter.length })
                    }}</span>

                    <v-icon name="md-expandmore-round" :scale="1.2" />
                  </div>
                </SmButton>
              </template>
              <div style="padding: 1rem">
                <TagFilterContent
                  v-model:tag-filter="tagFilter"
                  :tags="availableTags"
                  :software-counts="softwareCount"
                  @software-changed="loadData" />
              </div>
            </SmDropdown>

            <!-- More -->
            <SmDropdown :close-on-click-inside="false" max-height="30vh">
              <template #trigger>
                <SmButton outline size="small">
                  <div style="display: flex; align-items: center">
                    <span>{{ i18n.t('moreFilter') }}</span>
                    <v-icon name="md-expandmore-round" :scale="1.2" />
                  </div>
                </SmButton>
              </template>
              <div style="padding: 1rem">
                <SoftwareMoreFilter
                  v-model:more-applications-filter="moreApplicationsFilter" />
              </div>
            </SmDropdown>
          </div>

          <!-- Search -->
          <div style="width: 270px">
            <SmInput
              v-model="searchQuery"
              outline
              size="medium"
              :label="i18n.t('views.applications.table.searchPlaceholder')">
            </SmInput>
          </div>

          <!-- Add Software -->
          <SmButton
            v-require-permission="CompanyAccessRole.ADMIN"
            @click="addSoftwareDrawer = true">
            {{ i18n.t('views.applications.addSoftwareButtonText') }}
          </SmButton>
        </div>
      </div>
      <el-card
        shadow="always"
        style="overflow-y: auto; position: relative"
        :body-style="{
          height: '100%',
          width: '100%',
        }">
        <ApplicationsTable
          v-if="loading || currentSoftware.length"
          ref="applicationTableRef"
          :tag-filter="tagFilter"
          :search-query="searchQuery"
          :user-counts="userCounts"
          :softwares="currentSoftware"
          :software-costs="softwareCosts"
          :software-source-filter="activeSourceFilter"
          :more-applications-filter="moreApplicationsFilter"
          :loading="loading"
          @software-changed="loadData"
          @software-hiden="(e) => hideSSOSubaccount(e)"
          @tag-selection-changed="(newTags: Tag[]) => (tagFilter = newTags)" />

        <EmptyState
          v-else-if="!loading && currentSoftware.length === 0"
          icon="md-gridview-round"
          :title="i18n.t('applicationEmptyStateTitle')"
          :text="i18n.t('applicationEmptyStateText')"
          :create-label="i18n.t('views.applications.addSoftwareButtonText')"
          :show-docs-btn="false"
          class="empty-state"
          @create="addSoftwareDrawer = true" />
      </el-card>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useDocumentVisibility } from '@vueuse/core'
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'
  import { ComponentExposed } from 'vue-component-type-helpers'

  import {
    CompanyAccessRole,
    PaginationSortOrder,
    SoftwareCostOut,
    SoftwareCostService,
    SoftwareService,
    SoftwareUserCount,
    Tag,
    TagsService,
  } from '@/client'
  import ApplicationsTable, {
    MoreApplicationsFilter,
    SoftwareSourceFilterState,
    SSOSubAccount,
  } from '@/components/ApplicationsTable.vue'
  import ViewHeader from '@/components/ViewHeader.vue'
  import { Software } from '@/types/software'
  import { ApplicationStore } from '@/stores/application.store'
  import { usePreferenceStore } from '@/stores/preferenceStore'

  const i18n = useI18n()
  const route = useRoute()

  const preferenceStore = usePreferenceStore()

  const applicationTableRef = ref<ComponentExposed<typeof ApplicationsTable>>()

  // Load Software of the current user
  const currentSoftware = ApplicationStore.useApplications().data
  const userCounts = ref<Record<string, SoftwareUserCount>>({})
  const softwareCosts = ref<Record<string, SoftwareCostOut>>()

  // AddSoftwareDrawer related stuff
  const addSoftwareDrawer = ref(false)
  const createCustomSoftware = ref(false)
  const customSoftwareTemplate = ref<Software>()
  const loading = ref(true)

  watch(
    () => currentSoftware.value,
    () => {
      loading.value = false
    }
  )

  // Table related stuff
  const searchQuery = ref<string>('')

  // Fetch users count
  const getUserCount = () => {
    return SoftwareService.getUserCountApiV1SoftwareUserCountGet({})
  }

  const openCustomDialog = (software: Software) => {
    customSoftwareTemplate.value = software
    createCustomSoftware.value = true
  }

  // Fetch software cost
  async function fetchCosts() {
    await preferenceStore.getPreferences()

    watch(
      () => preferenceStore.preferences?.currency_code,
      () => {
        SoftwareCostService.getCostsApiV1SoftwareCostGet({
          includeSubAccounts: true,
          targetCurrencyCode: preferenceStore.preferences?.currency_code,
        }).then((res) => {
          softwareCosts.value = res.software_costs
        })
      },
      { immediate: true }
    )
  }

  watchEffect(() => {
    if (route.query['open-add-application-drawer']) {
      addSoftwareDrawer.value =
        route.query['open-add-application-drawer'] === 'true'
    }
  })

  async function loadData() {
    ApplicationStore.updateApplicationCacheFromApi()

    getUserCount().then((result) => (userCounts.value = result))

    fetchCosts()
  }

  // Refetch data if the tab loses the focus
  const visibility = useDocumentVisibility()
  watch(visibility, (current, previous) => {
    if (current === 'visible' && previous === 'hidden') {
      if (!addSoftwareDrawer.value) loadData()
    }
  })

  onMounted(async () => {
    loadData()
  })

  // Hide SSO subaccount
  const hideSSOSubaccount = (software: SSOSubAccount) => {
    // Delete the subaccount from the software
    const softwareIndex = currentSoftware.value.findIndex(
      (s) => s._id === software.software_id
    )
    if (softwareIndex === -1) return

    // If multiple "Hide" operations are triggert.
    // The filter needs to be updated since it is reused to create the new filter.
    currentSoftware.value[softwareIndex].config.sub_accounts_filter.push(
      software._id
    )

    delete currentSoftware.value[softwareIndex].sub_accounts[software._id]
  }

  const softwareCount = computed(() => {
    let count = 0

    // SSO subaccounts count
    for (const software of currentSoftware.value) {
      if (!software.information.sso_provider) continue
      count += Object.keys(software.sub_accounts).length
    }

    // Software count (excluding SSO subaccounts)
    for (const software of currentSoftware.value) {
      if (!software.information.sso_provider) count++
    }

    return count
  })

  // ####################################
  // Tags
  // ####################################
  const availableTags = ref<Tag[]>([])
  const tagFilter = ref<Tag[]>([])

  async function loadTags() {
    TagsService.getTagsApiV1ManagementTagsGet({
      sortBy: 'sorting',
      sortOrder: PaginationSortOrder.DESC,
    }).then((tags) => {
      availableTags.value = tags
    })
  }

  onMounted(() => {
    loadTags()
  })

  // ####################################
  // Filter
  // ####################################

  const activeSourceFilter = ref<SoftwareSourceFilterState>({
    manually: true,
    sso: true,
    integration: true,
  })

  const activeSourceFilterCount = computed(() => {
    return Object.values(activeSourceFilter.value).filter((value) => value)
      .length
  })

  const moreApplicationsFilter = ref<MoreApplicationsFilter>({
    hideWithoutCosts: false,
    hideWithCosts: false,
    hideWithoutUser: false,
  })

  // ####################################
  //  Util
  // ####################################

  const headline = computed(() => {
    const title = i18n.t('views.applications.title')
    const { filteredSoftwareCount, unfilteredSoftwareCount } =
      applicationTableRef.value || {}

    if (unfilteredSoftwareCount) {
      return filteredSoftwareCount === unfilteredSoftwareCount
        ? `${title} (${unfilteredSoftwareCount})`
        : `${title} (${filteredSoftwareCount}/${unfilteredSoftwareCount})`
    }

    return title
  })
</script>
