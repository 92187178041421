import {
  BackgroundJobAccountGroupAccountsAdded,
  BackgroundJobAccountGroupAccountsRemoved,
  BackgroundJobSoftwareUserAdd,
  BackgroundJobSoftwareUserAddData,
  BackgroundJobSoftwareUserEnable,
  BackgroundJobSoftwareUserEnableData,
  BackgroundJobSoftwareUserLicenseAdd,
  BackgroundJobSoftwareUserLicenseAddData,
  BackgroundJobSoftwareUserLicenseRemove,
  BackgroundJobSoftwareUserLicenseRemoveData,
  BackgroundJobSoftwareUserRemove,
  BackgroundJobSoftwareUserRemoveData,
  BackgroundJobsService,
  BackgroundJobStatus,
  BackgroundJobType,
} from '@/client'
import schema from '@/stores/backgroundjob.schema'

import { useFetch } from '@/common/useFetch'
import { filters } from './backgroundjob.filter'
import { db } from './_db'
import { MangoQuerySortPart } from 'rxdb'
import filterUtils from '@/common/util/filterUtils'
import { until } from '@vueuse/shared'

import { useI18n } from 'vue-i18n'
import { Status } from '@/components/sm/SmStatus.vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

/**
 * Variables
 */
export type BackgroundjobFilterType = (typeof filters)[keyof typeof filters]
export type BackgroundjobFilterInstance = InstanceType<BackgroundjobFilterType>
export type ExtendedBackgroundJobType =
  | BackgroundJobType
  | 'offboard_user'
  | 'delete_account'
  | 'disable_account'

export const _currentLoadingAllBackgroundjobs: Ref<boolean> = ref(false)
export const _allBackgroundjobsLoaded: Ref<Date> | Ref<undefined> =
  ref(undefined)
export const collectionInitiated = ref(false)

/**
 * Initialize
 */

export async function _initBackgroundjobCollection() {
  // check if db is initialized
  if (!db.value) {
    return
  }

  // Check if collection is already existing in DB
  const collections = db.value?.collections
  if (collections.backgroundjobs) {
    collectionInitiated.value = true
    return
  }

  await db.value
    .addCollections({
      backgroundjobs: {
        schema: schema,
      },
    })
    .catch((error) => {
      console.error(error)
    })

  collectionInitiated.value = true
}

export async function _checkBackgroundjobCollection() {
  if (!collectionInitiated.value) {
    await _initBackgroundjobCollection()
  }
}

/**
 * Helper Functions
 */

// selection
const typesToKeep = [
  // selection of currently available types
  BackgroundJobType.SOFTWARE_USER_ADD,
  BackgroundJobType.SOFTWARE_USER_ENABLE,
  BackgroundJobType.SOFTWARE_USER_LICENSE_ADD,
  BackgroundJobType.SOFTWARE_USER_LICENSE_REMOVE,
  BackgroundJobType.SOFTWARE_USER_REMOVE,
]

export const availableBackgroundJobTypes = computed(() => {
  const i18n = useI18n()

  return Object.values(BackgroundJobType)
    .filter((type) => typesToKeep.includes(type))
    .map((type) => ({
      value: type,
      label: i18n.t(`jobtype.${type}.label`),
      description: i18n.t(`jobtype.${type}.description`),
      section: i18n.t('jobs'),
    }))
})

// status
export function getStatus(status: BackgroundJobStatus): Status {
  const i18n = useI18n()

  switch (status) {
    case BackgroundJobStatus.ENQUEUED:
      return {
        text: i18n.t('jobStatus.enqueued'),
        color: 'bg-blue',
      }
    case BackgroundJobStatus.ERROR:
      return { text: i18n.t('jobStatus.error'), color: 'bg-red' }
    case BackgroundJobStatus.PAUSED:
      return { text: i18n.t('jobStatus.paused'), color: 'bg-contrast-muted' }
    case BackgroundJobStatus.USER_ACTION_REQUIRED:
      return {
        text: i18n.t('jobStatus.userActionRequired'),
        color: 'bg-purple',
      }
    case BackgroundJobStatus.ABORTED:
      return { text: i18n.t('jobStatus.aborted'), color: 'bg-orange' }
    case BackgroundJobStatus.COMPLETED:
      return { text: i18n.t('jobStatus.completed'), color: 'bg-primary' }
    case BackgroundJobStatus.DEPRECATED:
      return { text: i18n.t('jobStatus.deprecated'), color: 'bg-yellow' }
    case BackgroundJobStatus.AWAITS_SOFTWARE_AUTHORIZATION:
      return {
        text: i18n.t('jobStatus.awaitsSoftwareAuthorization'),
        color: 'bg-purple',
      }
  }
}

// execution date
export function getLocalizedExecutionDate(
  action:
    | BackgroundJobAccountGroupAccountsAdded
    | BackgroundJobAccountGroupAccountsRemoved
    | BackgroundJobSoftwareUserAdd
    | BackgroundJobSoftwareUserEnable
    | BackgroundJobSoftwareUserLicenseAdd
    | BackgroundJobSoftwareUserLicenseRemove
    | BackgroundJobSoftwareUserRemove
) {
  const i18n = useI18n()
  const date =
    action.status === BackgroundJobStatus.COMPLETED ||
    action.status === BackgroundJobStatus.ERROR
      ? action.last_executed_at
      : action.next_execution_at
  const day = _translateDate(date as string)
  const time = _dateToString(date as string)

  return i18n.t('dayAtTime', {
    day,
    time,
  })
}

function _translateDate(date: string): string {
  const i18n = useI18n()
  const tz = dayjs.tz.guess()
  const dateObject = dayjs(date).tz(tz)

  if (
    dateObject.format('DD.MM.YYYY') === dayjs(new Date()).format('DD.MM.YYYY')
  )
    return i18n.t('views.dashboard.lastActivity.today')
  if (
    dateObject.format('DD.MM.YYYY') ===
    dayjs(new Date()).subtract(1, 'days').format('DD.MM.YYYY')
  ) {
    return i18n.t('views.dashboard.lastActivity.yesterday')
  }
  return dateObject.format('DD.MM.YYYY')
}

function _dateToString(date: string): string {
  const tz = dayjs.tz.guess()
  const dateObject = dayjs(date, { utc: true }).tz(tz)
  return dateObject.format('HH:mm')
}

/**
 * RXDB Helper
 */

export async function _getQuery(
  filters?: BackgroundjobFilterInstance[],
  {
    limit,
    skip,
    sort,
  }: {
    limit?: number
    skip?: number
    sort?: MangoQuerySortPart<
      | BackgroundJobAccountGroupAccountsAdded
      | BackgroundJobAccountGroupAccountsRemoved
      | BackgroundJobSoftwareUserAdd
      | BackgroundJobSoftwareUserEnable
      | BackgroundJobSoftwareUserLicenseAdd
      | BackgroundJobSoftwareUserLicenseRemove
      | BackgroundJobSoftwareUserRemove
    >[]
  } = {}
) {
  // For now, we just take the first filter
  const mergedFilter = await filterUtils.mergeFilters(filters || [])

  if (_currentLoadingAllBackgroundjobs.value === true) {
    await until(_currentLoadingAllBackgroundjobs).toBe(false)
  }

  if (!_allBackgroundjobsLoaded.value) {
    await _reloadBackgroundjobs()
  }

  return db.value?.backgroundjobs.find({
    selector: mergedFilter,
    limit,
    skip,
    sort,
  })
}

export async function _addBackgroundjobs(
  backgroundjobs: Array<
    | BackgroundJobAccountGroupAccountsAdded
    | BackgroundJobAccountGroupAccountsRemoved
    | BackgroundJobSoftwareUserAdd
    | BackgroundJobSoftwareUserEnable
    | BackgroundJobSoftwareUserLicenseAdd
    | BackgroundJobSoftwareUserLicenseRemove
    | BackgroundJobSoftwareUserRemove
  >
) {
  if (!collectionInitiated.value) {
    await _initBackgroundjobCollection()
  }

  await db.value?.backgroundjobs.bulkUpsert(backgroundjobs)
}

export async function _reloadBackgroundjobs(
  filter?: BackgroundjobFilterInstance[]
) {
  if (filter === undefined) {
    _currentLoadingAllBackgroundjobs.value = true
  }

  //  Get software from backend
  const { data, error, loading } = await _fetchBackgroundjobs(filter)
  //  Add software to DB
  if (data) {
    await _addBackgroundjobs(toRaw(data))

    _currentLoadingAllBackgroundjobs.value = false
    _allBackgroundjobsLoaded.value = new Date()
  }
  return { data, error, loading }
}
/**
 * API
 */

export async function _fetchBackgroundjobs(
  filter?: BackgroundjobFilterInstance[]
) {
  const _filter = {
    filters: filter || [],
    includeDeleted: true,
  }

  const { data, error, fetchData, loading } = useFetch(
    () =>
      BackgroundJobsService.getBackgroundJobsApiV1BackgroundJobsGet(_filter),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _fetchBackgroundjob(id: string) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      BackgroundJobsService.getBackgroundJobApiV1BackgroundJobsBackgroundJobIdGet(
        { backgroundJobId: id }
      ),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _createBackgroundjob(
  type: BackgroundJobType,
  request_data:
    | BackgroundJobSoftwareUserAddData
    | BackgroundJobSoftwareUserEnableData
    | BackgroundJobSoftwareUserLicenseAddData
    | BackgroundJobSoftwareUserLicenseRemoveData
    | BackgroundJobSoftwareUserRemoveData,
  enqueue_in: number
) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      BackgroundJobsService.createBackgroundJobApiV1BackgroundJobsPost({
        requestBody: {
          type: type,
          data: request_data,
          enqueue_in: enqueue_in,
        },
      }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _enqueueBackgroundjob(id: string, dateInSeconds: number) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      BackgroundJobsService.enqueueBackgroundJobByIdApiV1BackgroundJobsBackgroundJobIdEnqueuePost(
        {
          backgroundJobId: id,
          requestBody: {
            enqueue_in: dateInSeconds,
          },
        }
      ),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _abortBackgroundjob(id: string) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      BackgroundJobsService.abortBackgroundJobApiV1BackgroundJobsBackgroundJobIdAbortPost(
        {
          backgroundJobId: id,
        }
      ),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _pauseBackgroundjob(id: string) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      BackgroundJobsService.pauseBackgroundJobApiV1BackgroundJobsBackgroundJobIdPausePost(
        {
          backgroundJobId: id,
        }
      ),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}
