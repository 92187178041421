<template>
  <div class="license-table" style="height: 100%; width: 100%">
    <el-card
      style="height: 100%; width: 100%"
      :body-style="{
        height: '100%',
        width: '100%',
      }"
      shadow="always">
      <!-- Subaccount selection  -->
      <SubAccountSelector
        v-if="
          props.software?.sub_accounts != undefined &&
          Object.keys(props.software?.sub_accounts).length > 0
        "
        v-model:active-sub-account-id="activeSubAccountId"
        :software="props.software" />

      <!-- Licenses table header -->
      <LicencesTableHeader
        v-model:filter="filter"
        v-model:search-query="searchQuery"
        :license-count="licenseCount || 0"
        :software-licenses="softwareLicenses"
        :selection="selection as LicenceOut[]"
        :software="props.software"
        @export-licenses="emit('exportLicenses')"
        @import-licenses="emit('importLicenses')"
        @open-filter="openFilter"
        @open-add-user="() => emit('openAddUser')" />

      <div style="height: calc(100% - 50px)">
        <LicenseTable
          v-if="props.software != undefined"
          ref="licenseTable"
          :filter="filter"
          :select="true"
          display-status-indicator
          :display-licence-count="displayLicenseCount"
          :display-phone-number="displayPhoneNumber"
          :software-id="props.software._id"
          display-name
          :only-cached="isSSO"
          :sub-account-id="activeSubAccountId"
          @licenses-changed="emit('licensesChanged')" />
      </div>
    </el-card>
  </div>
</template>

<script lang="ts" setup>
  import {
    LicenceOut,
    SoftwareLicense,
    SoftwareOut,
    SubAccount_Output,
  } from '@/client'
  import LicenseTable from '@/components/LicenseTable.vue'

  import LicencesTableHeader from './components/LicencesTableHeader.vue'
  import SubAccountSelector from './components/LicencesTableSubAccountSelector.vue'
  import { useRoute } from 'vue-router'
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { AccountFilters } from '@/stores/account.filter'

  const route = useRoute()

  const emit = defineEmits<{
    (e: 'openAddUser'): void
    (e: 'importLicenses'): void
    (e: 'exportLicenses'): void
    (e: 'update:subAccount', subAccount: SubAccount_Output): void
    (e: 'licensesChanged'): void
  }>()

  const props = defineProps({
    softwareLicenses: {
      type: Array as PropType<SoftwareLicense[]>,
      required: true,
    },
    software: {
      type: Object as PropType<SoftwareOut>,
      required: true,
    },
  })

  const isSSO = computed(() => route.query.sso === 'true')

  const searchQuery = ref<string>('')

  const subAccount = defineModel<SubAccount_Output>('subAccount')

  const activeSubAccountId = ref<string>(getInitialSubAccountId())

  watch(activeSubAccountId, (newVal) => {
    subAccount.value = props.software?.sub_accounts[newVal]
    licenseTable.value?.updateLicenses({ allowCached: false })
  })

  const licenseTable = ref<ComponentExposed<typeof LicenseTable>>()

  function refetchSoftwareLicenses() {
    licenseTable.value?.refetchSoftwareLicenses()
  }
  function handleLicencesRefetch({ cached = false } = {}) {
    licenseTable.value?.updateLicenses({ allowCached: cached })
  }

  function getInitialSubAccountId() {
    if (subAccount.value) return subAccount.value.id
    return Object.keys(props.software?.sub_accounts)[0]
  }

  const licenseCount = computed(() => {
    return licenseTable.value?.filteredCount
  })

  const selection = computed(() => {
    return licenseTable.value?.selection
  })

  const licenses = computed(() => {
    return licenseTable.value?.licences
  })

  function openFilter() {
    if (!licenseTable.value) return
    licenseTable.value.openFilterDialog()
  }

  // Only display the license count for Adobe and Microsoft365
  const displayLicenseCount = computed(() => {
    const softwareName = props.software?.software_name
    if (softwareName == 'Adobe' || softwareName == 'Microsoft365') {
      return true
    }
    return false
  })

  const displayPhoneNumber = computed(() => {
    const softwareName = props.software?.software_name
    if (softwareName == 'ThreeCX') {
      return true
    }
    return false
  })

  // Filter

  const filter = ref<AccountFilters>({})

  watch(searchQuery, () => {
    filter.value.email = searchQuery.value.trim()
  })

  watch(
    activeSubAccountId,
    () => {
      if (activeSubAccountId.value) {
        filter.value.subAccountId = activeSubAccountId.value
      } else {
        filter.value.subAccountId = undefined
      }
    },
    { immediate: true }
  )

  watch(
    () => props.software,
    () => {
      if (props.software?._id) {
        filter.value.softwareId = props.software._id
      }
    },
    { immediate: true }
  )

  defineExpose({
    handleLicencesRefetch,
    licenseCount,
    lincenses: licenses,
    accountFilter: filter,
    refetchSoftwareLicenses: refetchSoftwareLicenses,
  })
</script>

<style lang="scss" scoped>
  :deep(.license-table) {
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>
