<template>
  <div class="licence-filter">
    <div class="filter-grid">
      <!-- License Model -->
      <div class="filter-item width-2">
        <h4>{{ i18n.t('licenses') }}</h4>

        <SmSelect
          v-model:selection="selectedSoftwareLicensesOptions"
          :options="licenseModelOptions"
          :label="i18n.t('chooseLicenses')"
          :multiselect="true"
          searchable
          @change="
            (selection) => {
              if (selection) handleSoftwareLicenseSelection(selection)
            }
          " />
      </div>

      <!-- Guest -->
      <div class="filter-item">
        <h4>{{ i18n.t('guest') }}</h4>
        <SmSelect
          v-model:selection="guestFilter"
          :options="guestFilterOptions"
          @change="
            (_guestFilter) => {
              if (_guestFilter) guestFilter = _guestFilter
            }
          " />
      </div>

      <!-- Status -->
      <div class="filter-item">
        <h4>{{ i18n.t('status') }}</h4>
        <SmSelect
          v-model:selection="selectedStatusFilter"
          :options="statusOptions">
          <template #trigger="{ items }">
            <LicenceStatusSmall v-if="items[0]" :status="items[0].value" />
          </template>
          <template #item="{ item }">
            <LicenceStatusSmall :status="item.value" />
          </template>
        </SmSelect>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { LicenceStatus, SoftwareLicense, SoftwareOut } from '@/client'

  import { CustomStatus } from './LicenceStatusSmall.vue'
  import { Option } from './sm/SmSelect.vue'
  import { AccountFilters } from '@/stores/account.filter'

  const i18n = useI18n()

  interface Props {
    software?: SoftwareOut
    softwareLicenses: SoftwareLicense[]
  }

  const props = defineProps<Props>()

  const filter = defineModel<AccountFilters>('filter', {
    required: true,
  })

  //#region status
  const allStatusOption: Option<CustomStatus> = {
    label: i18n.t('all'),
    value: {
      text: i18n.t('all'),
      color: 'var(--sm-blue)',
    },
  }

  const availableStatus: Array<LicenceStatus> = [
    LicenceStatus.ACTIVE,
    LicenceStatus.DISABLED,
    LicenceStatus.INVITED,
  ]

  const selectedStatusFilter = ref<Option<LicenceStatus | CustomStatus>[]>([
    allStatusOption,
  ])

  const statusOptions: Option<LicenceStatus | CustomStatus>[] = computed(() => {
    const options = availableStatus.map((status) => {
      return {
        label: i18n.t(`licenseStatus.${status}`),
        value: status,
      }
    })

    return [allStatusOption, ...options]
  }).value

  // Update filter object
  watch(selectedStatusFilter, () => {
    if (
      availableStatus.includes(
        selectedStatusFilter.value[0].value as LicenceStatus
      )
    ) {
      filter.value.status = [
        selectedStatusFilter.value[0].value as LicenceStatus,
      ]
    } else {
      filter.value.status = undefined
    }
  })
  //#endregion

  //#region guest
  interface GuestFilterOption {
    label: string
    value: 'all' | 'guest' | 'non-guest'
  }

  const guestFilterOptions: GuestFilterOption[] = [
    {
      label: i18n.t('all'),
      value: 'all',
    },
    {
      label: i18n.t('guest'),
      value: 'guest',
    },
    {
      label: i18n.t('notGuest'),
      value: 'non-guest',
    },
  ] as const

  const guestFilter = ref<GuestFilterOption[]>([guestFilterOptions[0]])

  watch(guestFilter, () => {
    const filterValue = guestFilter.value[0].value
    if (filterValue === 'non-guest') {
      filter.value.isGuest = false
    } else if (filterValue === 'guest') {
      filter.value.isGuest = true
    } else if (filterValue === 'all') {
      filter.value.isGuest = undefined
    }
  })

  //#endregion

  //#region license model

  const selectedSoftwareLicensesOptions = ref<Option<string>[]>([])
  const selectedSoftwareLicenses = ref<string[]>([])

  const licenseModelOptions = computed(() => {
    return props.softwareLicenses
      .map((licensemodel) => {
        return {
          label: licensemodel.name,
          value: licensemodel._id,
        }
      })
      .toSorted((a, b) => {
        return a.label.localeCompare(b.label)
      })
  })

  function handleSoftwareLicenseSelection(selection: Option<string>[]) {
    // get only the value of each selection
    selectedSoftwareLicensesOptions.value = selection
    selectedSoftwareLicenses.value = selection.map((s) => s.value)
  }

  watch(selectedSoftwareLicenses, () => {
    if (selectedSoftwareLicenses.value.length === 0) {
      filter.value.softwareLicenses = undefined
    } else {
      filter.value.softwareLicenses = selectedSoftwareLicenses.value
    }
  })

  onMounted(() => {
    // set status filter for SmSelect
    selectedSoftwareLicensesOptions.value = licenseModelOptions.value.filter(
      (lm) => filter.value.softwareLicenses?.includes(lm.value)
    )

    // set status filter for SmSelect
    selectedStatusFilter.value = statusOptions.filter((s) =>
      filter.value?.status?.includes(s.value as LicenceStatus)
    )
    if (selectedStatusFilter.value.length === 0) {
      selectedStatusFilter.value = [allStatusOption]
    }
  })

  // #endregion
</script>

<style lang="scss" scoped>
  .filter-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }

  .filter-item {
    display: flex;
    flex-direction: column;

    h4 {
      font-weight: 400;
      margin-bottom: 0.5rem;
    }
  }

  .dropdown-select {
    display: flex;
    align-items: center;

    padding: 0.5rem 1rem;

    border-radius: 8px;
    font-size: 1rem;
  }

  .width-2 {
    grid-column: span 2;
  }

  .dropdown-item {
    display: grid;
    grid-template-columns: 1fr 10px;

    font-size: 0.9rem;
  }
  .licence-filter {
    cursor: pointer;
    pointer-events: all;

    min-width: 200px;
  }
</style>
