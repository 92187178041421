<template>
  <SmDialog v-model:visibility="visibility" :title="i18n.t('azureSync')">
    <div style="display: flex; gap: 1rem; margin-top: 0.5rem">
      <SmInput v-model="groupID" size="small" label="Group ID" outline />
      <SmButton @click="addWhitelistItem(groupID)">
        {{ i18n.t('add') }}
      </SmButton>
    </div>
    <div style="height: 16px" />

    <h4>{{ i18n.t('currentGroups') }}</h4>
    <div style="height: 8px" />

    <div
      v-if="datasource.config.group_config.groups_whitelist.length !== 0"
      class="wrapper">
      <div
        v-for="group in datasource.config.group_config.groups_whitelist"
        :key="group"
        style="display: flex; gap: 1rem">
        <span>{{ group }}</span>
        <div>
          <v-icon
            name="md-delete-round"
            class="trash-icon"
            scale="1.1"
            @click="deleteWhitelistItem(group)" />
        </div>
      </div>
    </div>
    <div v-else>{{ i18n.t('noGroups') }}</div>
  </SmDialog>
</template>

<script setup lang="ts">
  import { DataSource, DataSourcesService } from '@/client'
  import { useI18n } from 'vue-i18n'

  const visibility = ref<boolean>(false)
  const i18n = useI18n()

  const groupID = ref<string>('')

  const emit = defineEmits(['groups-changed'])

  const datasource = defineModel('datasource', {
    type: Object as PropType<DataSource>,
    required: true,
  })

  function addWhitelistItem(item: string) {
    datasource.value.config.group_config.groups_whitelist.push(item)
    save()
  }

  function deleteWhitelistItem(item: string) {
    datasource.value.config.group_config.groups_whitelist =
      datasource.value.config.group_config.groups_whitelist.filter(
        (i) => i !== item
      )
    save()
  }

  function save() {
    DataSourcesService.putDataSourcesApiV1DataSourceDataSourcesDataSourceIdPut({
      dataSourceId: datasource.value._id,
      requestBody: datasource.value,
    })
      .then(() => {
        emit('groups-changed')
      })
      .finally(() => {
        groupID.value = ''
      })
  }
</script>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  }
</style>
