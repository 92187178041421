<template>
  <TextMultiline v-if="optimization.optimization_type == 'unused'">
    {{ unusedText }}
  </TextMultiline>
  <TextMultiline v-else-if="optimization.optimization_type == 'duplicate'">
    {{ duplicateText }}
  </TextMultiline>
  <TextMultiline
    v-else-if="
      optimization.optimization_type == OptimizationType.REDUNDANT_LICENSE
    ">
    {{ redundantText }}
  </TextMultiline>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { Optimization, OptimizationType, SoftwareOut } from '@/client'
  import { computedAsync } from '@vueuse/core'
  import { AccountStore } from '@/stores/account.store'
  import { PersonStore } from '@/stores/person.store'

  const i18n = useI18n()

  interface Props {
    optimization: Optimization
    showUserEmail: boolean
    software: SoftwareOut
  }

  const props = defineProps<Props>()

  const license = computedAsync(async () => {
    if (!props.optimization.licence_id) return
    return await AccountStore.getAccountById(props.optimization.licence_id)
  })

  // Account
  const account = ref<string>()
  watch(
    () => license.value,
    async (newLicense) => {
      if (newLicense && newLicense.account_id) {
        PersonStore.getPersonByEmail(newLicense.account_id).then((res) => {
          if (res) {
            account.value = res.email
          }
        })
      }
    },
    { immediate: true }
  )

  // If redundant optimization, get distinct count of all child licenses
  const redundantLicenseCount = computed(() => {
    const redundantLicenses: string[] = []
    if (
      props.optimization.optimization_type == OptimizationType.REDUNDANT_LICENSE
    ) {
      props.optimization.data?.redundant_software_license_ids?.forEach(
        (redundantsoftwareLicensesData) => {
          redundantsoftwareLicensesData.redundant_software_license_ids.forEach(
            (redundantsoftwareLicense) => {
              if (!redundantLicenses.includes(redundantsoftwareLicense)) {
                redundantLicenses.push(redundantsoftwareLicense)
              }
            }
          )
        }
      )
    }
    return new Set(redundantLicenses).size
  })

  const softwareDisplayName = computed(() => {
    return props.software.display_name || props.software.software_name
  })

  const unusedText = computed(() => {
    return i18n.t('views.dashboard.optimizations.rowText.rest', {
      email: account.value,
      software_name: softwareDisplayName.value,
      days: props.optimization.data?.days_unused || 0,
      has: i18n.t('views.dashboard.optimizations.rowText.has'),
    })
  })
  const duplicateText = computed(() => {
    return i18n.t('views.dashboard.optimizations.duplicate.rowText.rest', {
      email: account.value,
      software_name: softwareDisplayName.value,
    })
  })
  const redundantText = computed(() => {
    return i18n.t('views.dashboard.optimizations.redundant.rowText.rest', {
      email: account.value,
      count: redundantLicenseCount.value,
    })
  })
</script>
