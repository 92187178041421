import { createRouter, createWebHistory } from 'vue-router'

import { useSessionStore } from '@/stores/sessionStore'
import ApplicationsDetailView from '@/views/ApplicationsDetail/ApplicationDetailsView.vue'
import ApplicationsView from '@/views/ApplicationsView.vue'
import CompanySelectVue from '@/views/BetaLogin/components/CompanySelect.vue'
import InviteDisplay from '@/views/BetaLogin/components/InviteDisplay.vue'
import LoginFormVue from '@/views/BetaLogin/components/LoginForm.vue'
import DashboardView from '@/views/DashboardView.vue'
import DocumentsView from '@/views/DocumentsView.vue'
import GroupsView from '@/views/GroupsView.vue'
import UsersView from '@/views/UsersView.vue'
import AutomationsView from '@/views/AutomationsView.vue'
import TestView from '@/views/testView.vue'
import LicensesView from '@/views/LicensesView.vue'
import DataSourcesView from '@/views/DataSourcesView.vue'
import DataSourceDetailsView from '@/views/DataSourceDetailsView.vue'

// Lazy Load Components../views/NotFound/NotFoundView.vue
const NotFound = () => import('@/views/NotFoundView.vue')
const ThirdPartyResultView = () => import('@/views/ThirdPartyResultView.vue')
const AuthorizeView = () => import('@/views/AuthorizeView.vue')

const DataSourceCallbackView = () =>
  import('@/views/DataSourceCallbackView.vue')

const LoginV3 = () => import('@/views/BetaLogin/LoginV3.vue')

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: {
      layout: 'Sidebar',
      requireLogin: true,
    },
  },
  {
    path: '/test',
    name: 'Test',
    component: TestView,
    meta: {
      layout: 'Sidebar',
      requireLogin: true,
    },
  },

  {
    path: '/users',
    name: 'Users',
    component: UsersView,
    meta: {
      layout: 'Sidebar',
      requireLogin: true,
    },
  },
  {
    path: '/documents',
    name: 'Documents',
    component: DocumentsView,
    meta: {
      layout: 'Sidebar',
      requireLogin: true,
    },
  },
  {
    path: '/applications',
    name: 'Applications',
    component: ApplicationsView,
    meta: {
      layout: 'Sidebar',
      requireLogin: true,
    },
  },
  {
    path: '/applications/:id/:subAccountId?',
    name: 'Application',
    component: ApplicationsDetailView,
    meta: {
      layout: 'Sidebar',
      requireLogin: true,
    },
  },
  {
    path: '/groups',
    name: 'Groups',
    component: GroupsView,
    meta: {
      layout: 'Sidebar',
      requireLogin: true,
    },
  },
  {
    path: '/data-sources',
    name: 'Data-Sources',
    component: DataSourcesView,
    meta: {
      layout: 'Sidebar',
      requireLogin: true,
    },
  },
  {
    path: '/data-sources/callback',
    name: 'Data-Source-Callback',
    component: DataSourceCallbackView,
    meta: {
      layout: 'NoSidebar',
      requireLogin: true,
    },
  },
  {
    path: '/data-sources/:id',
    name: 'Data-Source',
    component: DataSourceDetailsView,
    meta: {
      layout: 'Sidebar',
      requireLogin: true,
    },
  },
  {
    path: '/login',
    name: 'Login v3',
    component: LoginV3,
    meta: {
      requireLogin: false,
    },
    children: [
      {
        path: '',
        name: 'Login',
        component: LoginFormVue,
      },
      {
        path: 'company',
        name: 'Company',
        component: CompanySelectVue,
      },
      {
        path: 'invite',
        name: 'Invite',
        component: InviteDisplay,
      },
    ],
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: LicensesView,
    meta: {
      layout: 'Sidebar',
      requireLogin: true,
    },
  },
  {
    path: '/licenses',
    redirect: { name: 'Accounts' },
  },
  {
    path: '/automations',
    name: 'Automations',
    component: AutomationsView,
    query: { tab: 'tasks' },
    meta: {
      layout: 'Sidebar',
      requireLogin: true,
    },
  },
  {
    path: '/actions',
    redirect: { name: 'Automations', query: { tab: 'jobs' } },
  },
  {
    path: '/callback',
    name: 'Erfolgreich Verbunden',
    component: ThirdPartyResultView,
    meta: {
      layout: 'NoSidebar',
      requireLogin: false,
    },
  },
  {
    path: '/authorize',
    name: 'Authorize',
    component: AuthorizeView,
    meta: {
      layout: 'NoSidebar',
      requireLogin: false,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      layout: 'Sidebar',
      requireLogin: false,
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.afterEach((to) => {
  if (!to.meta.requireLogin) {
    return
  }

  const sessionStore = useSessionStore()
  sessionStore.requireLogin()
})

export default router
