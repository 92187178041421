<!--
  This is the component that displays the software requirements for the software creation.
  All Components are imported from the src/components/old/Requirements/user folder.

  The followning naming scheme is used (casing is not important):
  <Type><Softwarename>.vue
  <Type> is the type of the software.
  <Softwarename> is the name of the software.

  example: BotSlack.vue, TokenJira.vue, etc.
 -->

<template>
  <!--
    This is the component that displays the software requirements for the software creation.
    The component modifies the software data.
   -->
  <component
    :is="pageComponents[filename]"
    v-if="pageComponents[filename]"
    v-model:software-data="softwareData"
    v-model:data-source="dataSource"
    :is-software-already-existing="props.isSoftwareAlreadyExisting" />

  <component
    :is="pageComponents[type + 'general']"
    v-else-if="pageComponents[type + 'general']"
    v-model:software-data="softwareData"
    v-model:data-source="dataSource"
    :is-software-already-existing="props.isSoftwareAlreadyExisting" />
</template>

<script lang="ts" setup>
  import { SoftwarePostIn, DataSourceIn } from '@/client'

  /**
   * Props and emits
   */
  const props = defineProps<{
    isSoftwareAlreadyExisting?: boolean
  }>()

  const softwareData = defineModel<SoftwarePostIn>('softwareData')

  type DataSourceInWithType = DataSourceIn & {
    type: string
  }
  const dataSource = defineModel<DataSourceInWithType>('dataSource', {})

  const type = computed(() => {
    let type = ''

    // Check if type is in softwareData or dataSource
    if (softwareData.value && softwareData.value.type) {
      type = softwareData.value.type as string
    } else if (dataSource.value && dataSource.value.type) {
      type = dataSource.value.type as string
    }

    return `${type.toLowerCase()}`
  })

  /**
   * Computes the filename of the software requirements file
   */
  const filename = computed(() => {
    let type = ''
    let softwareName = ''

    if (softwareData.value) {
      type = softwareData.value.type as string
      softwareName = softwareData.value.software_name as string
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      type = dataSource.value!.type as string
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      softwareName = dataSource.value!.name as string
    }

    return `${type.toLowerCase()}${softwareName.toLowerCase()}`
  })

  /**
   * Defines the async component for the software requirements
   * All software requirements are defined in the components/old/SoftwareRequirements/user folder
   * these files are imported and saves in the pageComponents object
   */
  const pages = import.meta.glob('./software/*.vue')
  const pageComponents: Record<string, unknown> = {}
  Object.keys(pages).forEach((key) => {
    const name: string = (
      key.match('\\.\\/software\\/(.*?).vue') as RegExpMatchArray
    )[1].toLowerCase()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageComponents[name] = defineAsyncComponent(() => pages[key]() as any)
  })
</script>
