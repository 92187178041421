<template>
  <AzureSyncDialog
    v-if="dataSource"
    v-model:visibility="syncDialogVisibility"
    :datasource="dataSource"
    @groups-changed="fetchEmployees(true)" />
  <div class="home">
    <ViewHeader back-arrow-route="/data-sources">
      <template #title>
        <div style="display: flex">
          <h2>
            {{
              i18n.t('views.externalDataSourceDetails.title', {
                name: dataSource?.name,
              })
            }}
          </h2>
          <!-- Azure Sync Dialog -->
          <div
            class="datasource-sync-wrapper"
            @click="syncDialogVisibility = true">
            <SmTooltip>
              <v-icon
                name="md-groups-round"
                class="fill-contrast"
                scale="1.2" />

              <template #content>
                {{ i18n.t('syncGroups') }}
              </template>
            </SmTooltip>
          </div>
        </div>
      </template>
    </ViewHeader>
    <div class="employee-grid">
      <DataSourceDetailStatsCard
        v-if="dataSource"
        ref="statsCard"
        class="header-card"
        :data-source="dataSource"
        @refetch-data-source="fetchDataSource" />
      <el-card class="employees-card" :body-style="{ height: '100%' }">
        <div class="grid h-full grid-rows-[auto_1fr]">
          <div class="table-header">
            <h4 class="light-text">
              {{ i18n.t('employees') + ` (${statsCard?.employeeCount || 0})` }}
            </h4>
            <SmInput
              v-model:model-value="searchquery"
              size="small"
              outline
              style="width: 250px"
              :label="
                i18n.t(
                  'views.externalDataSourceDetails.employeesTable.searchPlaceholder'
                )
              " />
          </div>
          <EmployeeTable
            :employees
            :searchquery
            :loading="false"
            height="auto" />
        </div>
      </el-card>
      <BaseOptimizationsCard
        v-if="dataSource"
        class="optimizations-card"
        :software-id="dataSource?._id"
        :hide-chart="true"
        :card-text-style="{
          color: 'var(--el-text-color-secondary)',
          fontSize: '0.9rem',
          fontWeight: 700,
        }" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { ref } from 'vue'
  import {
    DataSource,
    DataSourcesService,
    Employee,
    EmployeesService,
  } from '@/client'
  import { useRoute, useRouter } from 'vue-router'
  import BaseOptimizationsCard from '@/components/BaseOptimizationsCard/BaseOptimizationsCard.vue'
  import DataSourceDetailStatsCard from '@/components/DataSourceDetailStatsCard.vue'
  import { ComponentExposed } from 'vue-component-type-helpers'

  const dataSource = ref<DataSource>()
  const employees = ref<Employee[]>([])
  const loading = ref(false)
  const syncDialogVisibility = ref(false)
  const statsCard = ref<ComponentExposed<typeof DataSourceDetailStatsCard>>()
  const searchquery = ref('')

  const i18n = useI18n()
  const route = useRoute()
  const router = useRouter()

  function fetchDataSource() {
    if (!route.params.id) return router.push('/data-sources')
    DataSourcesService.getDataSourceApiV1DataSourceDataSourcesDataSourceIdGet({
      dataSourceId: route.params.id as string,
    }).then((datasource) => {
      dataSource.value = datasource
    })
  }

  async function fetchEmployees(cached: boolean) {
    return EmployeesService.getDataSourceEmployeesApiV1DataSourceDataSourcesDataSourceIdEmployeesGet(
      {
        dataSourceId: route.params.id as string,
        cached: cached,
      }
    ).then((res) => {
      employees.value = res
    })
  }

  loading.value = true

  fetchDataSource()
  fetchEmployees(true)
  fetchEmployees(false).finally(() => {
    loading.value = false
  })
</script>

<style scoped lang="scss">
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
  }
  .light-text {
    color: var(--el-text-color-secondary);
  }

  .employee-grid {
    display: grid;
    height: 100%;
    width: 100%;
    gap: 20px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows:
      auto minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
      minmax(0, 1fr) minmax(0, 1fr);
    grid-template-areas:
      'headerCard headerCard headerCard headerCard headerCard headerCard'
      'employeesCard employeesCard employeesCard employeesCard optimizationsCard optimizationsCard'
      'employeesCard employeesCard employeesCard employeesCard optimizationsCard optimizationsCard'
      'employeesCard employeesCard employeesCard employeesCard optimizationsCard optimizationsCard'
      'employeesCard employeesCard employeesCard employeesCard optimizationsCard optimizationsCard'
      'employeesCard employeesCard employeesCard employeesCard optimizationsCard optimizationsCard'
      'employeesCard employeesCard employeesCard employeesCard optimizationsCard optimizationsCard';

    .header-card {
      grid-area: headerCard;
    }

    .employees-card {
      grid-area: employeesCard;
    }

    .optimizations-card {
      grid-area: optimizationsCard;
    }

    .lastActivity-card {
      grid-area: CardActivity;
    }
  }
  .datasource-sync-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    padding-left: 1rem;

    cursor: pointer;
  }
</style>
