/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataSource } from '../models/DataSource';
import type { DataSourceInstall } from '../models/DataSourceInstall';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DataSourceAuthorizationService {
    /**
     * Get Oauth Url
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getOauthUrlApiV1DataSourceOauthOauthUrlGet({
        integrationId,
        xTransactionId,
    }: {
        integrationId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data_source/oauth/oauth_url',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'integration_id': integrationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Oauth Callback
     * @returns DataSource Successful Response
     * @throws ApiError
     */
    public static oauthCallbackApiV1DataSourceOauthCallbackPost({
        state,
        xTransactionId,
    }: {
        state: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<DataSource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/data_source/oauth/callback',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'state': state,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Refresh Token
     * @returns any Successful Response
     * @throws ApiError
     */
    public static refreshTokenApiV1DataSourceOauthRefreshTokenPost({
        dataSourceId,
        xTransactionId,
    }: {
        dataSourceId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/data_source/oauth/refresh_token',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'data_source_id': dataSourceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Post Data Source
     * @returns DataSource Successful Response
     * @throws ApiError
     */
    public static postDataSourceApiV1DataSourceDataSourcesPost({
        requestBody,
        xTransactionId,
    }: {
        requestBody: DataSourceInstall,
        xTransactionId?: (string | null),
    }): CancelablePromise<DataSource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/data_source/data_sources',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
