<template>
  <!-- Content -->
  <div
    class="flex h-full w-full items-center justify-center gap-6"
    :class="props.orientation === 'vertical' ? 'flex-col' : 'flex-row'">
    <div
      v-if="props.icon"
      class="relative z-10"
      :class="props.orientation === 'vertical' ? 'p-4' : 'p-3'">
      <slot name="icon">
        <v-icon
          :name="props.icon"
          :scale="props.orientation === 'vertical' ? 1.5 : 1"
          class="fill-primary" />

        <div
          class="absolute left-0 top-0 h-full w-full rounded-full bg-primary opacity-10"></div>
      </slot>
    </div>

    <div
      v-if="props.title || props.text"
      :class="props.orientation === 'vertical' ? 'text-center' : ''">
      <slot name="content">
        <h3 v-if="props.title" class="mb-2">{{ props.title }}</h3>
        <p v-if="props.text" class="max-w-xl">{{ props.text }}</p>
      </slot>
    </div>

    <div
      v-if="(showDocsBtn || showCreateBtn) && props.orientation === 'vertical'"
      class="grid grid-cols-2">
      <SmButton
        v-if="showDocsBtn"
        type="primary"
        :class="{ 'col-span-2': !showCreateBtn }"
        outline
        @click="emit('showDocs')">
        {{ props.docsLabel ?? i18n.t('readDocs') }}
      </SmButton>
      <SmButton
        v-if="showCreateBtn"
        type="primary"
        :class="{ 'col-span-2': !showDocsBtn }"
        @click="emit('create')">
        {{ props.createLabel ?? i18n.t('create') }}
      </SmButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()
  const emit = defineEmits(['create', 'showDocs'])

  const props = defineProps({
    icon: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    createLabel: {
      type: String,
      required: false,
    },
    docsLabel: {
      type: String,
      required: false,
    },
    showDocsBtn: {
      type: Boolean,
      default: true,
    },
    showCreateBtn: {
      type: Boolean,
      default: true,
    },
    orientation: {
      type: String,
      default: 'vertical',
    },
  })
</script>
