<template>
  <div class="sidebar">
    <div
      class="animated-bg flex w-full justify-end bg-gray-100 dark:bg-gray-700"
      :style="{
        top: activeTabPosition.top + 'px',
        height: activeTabPosition.height + 'px',
      }">
      <div class="h-full w-1 bg-primary"></div>
    </div>

    <SettingsSidebarItem
      ref="accountItem"
      :icon="CircleUserRound"
      :text="i18n.t('account')"
      @click="handleTabClick('account')" />
    <SettingsSidebarItem
      ref="securityItem"
      :icon="Lock"
      :text="i18n.t('security')"
      @click="handleTabClick('security')" />
    <SettingsSidebarItem
      ref="usersItem"
      :icon="UsersRound"
      :text="i18n.t('users')"
      @click="handleTabClick('users')" />
    <SettingsSidebarItem
      ref="apiItem"
      :icon="Webhook"
      :text="i18n.t('api')"
      @click="handleTabClick('api')" />
    <SettingsSidebarItem
      ref="feedbackItem"
      :icon="Megaphone"
      :text="i18n.t('feedback')"
      @click="handleTabClick('feedback')" />
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'
  import {
    CircleUserRound,
    Lock,
    UsersRound,
    Webhook,
    Megaphone,
  } from 'lucide-vue-next'
  import SettingsSidebarItem from './SettingsSidebarItem.vue'

  const i18n = useI18n()

  // In your <script setup>
  const accountItem = ref<InstanceType<typeof SettingsSidebarItem>>()
  const securityItem = ref<InstanceType<typeof SettingsSidebarItem>>()
  const usersItem = ref<InstanceType<typeof SettingsSidebarItem>>()
  const apiItem = ref<InstanceType<typeof SettingsSidebarItem>>()
  const feedbackItem = ref<InstanceType<typeof SettingsSidebarItem>>()

  const items = {
    account: accountItem,
    security: securityItem,
    users: usersItem,
    api: apiItem,
    feedback: feedbackItem,
  }

  export type SettingsTabNames = keyof typeof items

  const activeItemName = defineModel<keyof typeof items>('activeItemName', {
    default: 'account',
  })

  interface ActiveTabPosition {
    top: number
    height: number
  }
  const activeTabPosition = ref<ActiveTabPosition>({
    top: 0,
    height: 45,
  })

  function setActiveTabPosition() {
    const activeItemElememt: HTMLElement =
      items[activeItemName.value].value?.$el

    if (!activeItemElememt) return
    const box = activeItemElememt.getBoundingClientRect()

    const activeItemPartentElement = activeItemElememt.parentNode!
    const boxParent = activeItemPartentElement.getBoundingClientRect()

    activeTabPosition.value.top = box.y - boxParent.y
    activeTabPosition.value.height = box.height
  }

  onMounted(() => {
    watch(
      activeItemName,
      () => {
        setActiveTabPosition()
      },
      { immediate: true, deep: true }
    )

    setTimeout(() => setActiveTabPosition(), 200)
  })

  function handleTabClick(name: keyof typeof items) {
    activeItemName.value = name
  }
</script>

<style scoped>
  .sidebar {
    position: relative;
    height: 100%;
    text-align: left;
  }

  .animated-bg {
    position: absolute;
    z-index: -1;
    transition:
      top 0.2s ease,
      height 0.2s ease;
  }
</style>
