<template>
  <DialogDemoLeave v-model:visibility="showLeaveDemo" />
  <div class="header-menu no-print">
    <TheAutomaticLogoutTimer
      v-if="
        preferenceStore.preferences?.automatic_logout_time &&
        preferenceStore.preferences?.automatic_logout_time > 0
      "
      class="logout-timer"
      :logout-time="preferenceStore.preferences.automatic_logout_time" />

    <!-- Demo data switch -->
    <SmButton
      v-if="sessionStore.currentCompanyInfo?.demo"
      class="leave-demo-button"
      :text="i18n.t('headerMenu.realDataButton')"
      type="primary"
      @click="showLeaveDemo = true" />

    <NotificationInbox v-if="sessionStore.status === 'LOGGED_IN'" />

    <HeaderDropdown />
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { usePreferenceStore } from '@/stores/preferenceStore'
  import { useSessionStore } from '@/stores/sessionStore'

  import DialogDemoLeave from './DialogDemoLeave.vue'
  import TheAutomaticLogoutTimer from './TheAutomaticLogoutTimer.vue'

  const i18n = useI18n()
  const preferenceStore = usePreferenceStore()
  const sessionStore = useSessionStore()

  // Leave demo data
  const showLeaveDemo = ref(false)
</script>

<style scoped>
  .icon-left {
    margin-right: 0.5rem;
  }

  .header-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
</style>
