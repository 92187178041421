<template>
  <SmDialog
    v-model:visibility="visibility"
    size="large"
    height="50vh"
    :body-style="{ overflow: 'hidden' }"
    :dialog-style="{ padding: '0px' }"
    :title="i18n.t('settings')">
    <template #header><div /></template>

    <!-- Content -->
    <div class="settings-grid">
      <!-- Header left -->
      <div class="border-b border-r dark:border-gray-700">
        <h3 class="p-5 pb-6 pt-6 font-semibold">{{ i18n.t('settings') }}</h3>
      </div>

      <!-- Header right -->
      <div class="flex justify-end border-b align-middle dark:border-gray-700">
        <SmButtonClose class="p-6" @click="visibility = false" />
      </div>

      <!-- Sidebar -->
      <div class="border-r pt-1 dark:border-gray-700">
        <SettingsSidebar v-model:active-item-name="activeTab" />
      </div>

      <!-- Content -->
      <div style="height: 100%" class="settings-content">
        <SettingsAccountPage v-if="activeTab === 'account'" />
        <SettingsPasswordPage v-if="activeTab === 'security'" />
        <SettingsCompanyPage v-if="activeTab === 'users'" />
        <SettingsHelpPage v-if="activeTab === 'feedback'" />
        <SettingsAPIPage v-if="activeTab === 'api'" />
      </div>
    </div>
  </SmDialog>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import SmButtonClose from './sm/SmButtonClose.vue'
  import { SettingsTabNames } from './SettingsSidebar.vue'

  const visibility = defineModel('visibility', {
    type: Boolean,
    default: false,
  })
  const i18n = useI18n()

  const activeTab = ref<SettingsTabNames>('account')
</script>

<style scoped>
  .settings-grid {
    height: 100%;
    display: grid;
    grid-template-columns: 220px 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .settings-content {
    padding-right: 20px;
    padding-left: 20px;
    max-height: calc(50vh - 15px); /* 50 vh is dialog height */
    overflow-y: auto;
  }

  :deep(.settings-container) {
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: clamp(300px, 100%, 700px);
  }

  .settings-sidebar {
    max-height: calc(50vh - 15px); /* 50 vh is dialog height */
    overflow-y: auto;
  }
</style>
