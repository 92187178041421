<template>
  <SmTable
    :row-style="{ cursor: 'initial' }"
    :style="{ height, overflow: 'auto' }"
    :data="filteredEmployees"
    :columns
    key-field="_id"
    :loading>
    <template #start_date="{ row }">
      <p v-if="row.start_date">
        {{ displayDate(new Date(row.start_date), { dateStyle: 'short' }) }}
      </p>
    </template>
    <template #end_date="{ row }">
      <p v-if="row.end_date">
        {{ displayDate(new Date(row.end_date), { dateStyle: 'short' }) }}
      </p>
    </template>
  </SmTable>
</template>

<script setup lang="ts">
  import { Employee } from '@/client'
  import { useI18n } from 'vue-i18n'
  import { Column } from './sm/SmTable.types'
  import { displayDate } from '@/common/util/timeUtils'

  const i18n = useI18n()
  const props = defineProps<{
    employees: Employee[]
    loading: boolean
    searchquery: string
    height: string
  }>()

  const filteredEmployees = computed(() => {
    if (props.searchquery == '') return props.employees
    return props.employees.filter((e) => {
      if (e.email.toLowerCase().includes(props.searchquery.toLowerCase()))
        return true
      if (e.name?.toLowerCase().includes(props.searchquery.toLowerCase()))
        return true
    })
  })

  const columns = computed<Column<Employee>[]>(() => [
    {
      key: 'email',
      label: i18n.t('email'),
      sortable: true,
      width: 1,
    },
    {
      key: 'name',
      label: i18n.t('name'),
      sortable: true,
      width: 1,
    },
    {
      key: 'supervisor_name',
      label: i18n.t('supervisor'),
      sortable: true,
      width: 1,
    },
    {
      key: 'start_date',
      label: i18n.t('hiredOn'),
      sortable: true,
      width: 1,
    },
    // {
    //   key: 'end_date',
    //   label: i18n.t('terminatedOn'),
    //   sortable: true,
    //   width: 1,
    // },
  ])
</script>
