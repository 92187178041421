/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountingImportOut } from '../models/AccountingImportOut';
import type { AccountingImportSource } from '../models/AccountingImportSource';
import type { AccountingImportStatus } from '../models/AccountingImportStatus';
import type { AccountingImportUpdateIn } from '../models/AccountingImportUpdateIn';
import type { Body_upload_accounting_import_file_api_v1_accounting_accounting_imports_post } from '../models/Body_upload_accounting_import_file_api_v1_accounting_accounting_imports_post';
import type { PaginationSortOrder } from '../models/PaginationSortOrder';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AccountingService {
    /**
     * Get Accounting Imports
     * @returns AccountingImportOut Successful Response
     * @throws ApiError
     */
    public static getAccountingImportsApiV1AccountingAccountingImportsGet({
        tagsExists,
        tagsEq,
        tagsGt,
        tagsGte,
        tagsIn,
        tagsLt,
        tagsLte,
        tagsNe,
        tagsNin,
        tagsAll,
        tagsSize,
        tags,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        filenameExists,
        filenameEq,
        filenameGt,
        filenameGte,
        filenameIn,
        filenameLt,
        filenameLte,
        filenameNe,
        filenameNin,
        filename,
        sourceExists,
        sourceEq,
        sourceGt,
        sourceGte,
        sourceIn,
        sourceLt,
        sourceLte,
        sourceNe,
        sourceNin,
        source,
        nameExists,
        nameEq,
        nameGt,
        nameGte,
        nameIn,
        nameLt,
        nameLte,
        nameNe,
        nameNin,
        name,
        dateExists,
        dateEq,
        dateGt,
        dateGte,
        dateIn,
        dateLt,
        dateLte,
        dateNe,
        dateNin,
        date,
        descriptionExists,
        descriptionEq,
        descriptionGt,
        descriptionGte,
        descriptionIn,
        descriptionLt,
        descriptionLte,
        descriptionNe,
        descriptionNin,
        description,
        notesExists,
        notesEq,
        notesGt,
        notesGte,
        notesIn,
        notesLt,
        notesLte,
        notesNe,
        notesNin,
        notes,
        statusExists,
        statusEq,
        statusGt,
        statusGte,
        statusIn,
        statusLt,
        statusLte,
        statusNe,
        statusNin,
        status,
        limit,
        offset,
        sortBy,
        sortOrder,
        sort,
        xTransactionId,
    }: {
        tagsExists?: (boolean | null),
        tagsEq?: (string | null),
        tagsGt?: (string | null),
        tagsGte?: (string | null),
        tagsIn?: (Array<string> | null),
        tagsLt?: (string | null),
        tagsLte?: (string | null),
        tagsNe?: (string | null),
        tagsNin?: (Array<string> | null),
        tagsAll?: (Array<string> | null),
        tagsSize?: (number | null),
        tags?: (string | null),
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        filenameExists?: (boolean | null),
        filenameEq?: (string | null),
        filenameGt?: (string | null),
        filenameGte?: (string | null),
        filenameIn?: (Array<string> | null),
        filenameLt?: (string | null),
        filenameLte?: (string | null),
        filenameNe?: (string | null),
        filenameNin?: (Array<string> | null),
        filename?: (string | null),
        sourceExists?: (boolean | null),
        sourceEq?: (AccountingImportSource | null),
        sourceGt?: (AccountingImportSource | null),
        sourceGte?: (AccountingImportSource | null),
        sourceIn?: (Array<AccountingImportSource> | null),
        sourceLt?: (AccountingImportSource | null),
        sourceLte?: (AccountingImportSource | null),
        sourceNe?: (AccountingImportSource | null),
        sourceNin?: (Array<AccountingImportSource> | null),
        source?: (AccountingImportSource | null),
        nameExists?: (boolean | null),
        nameEq?: (string | null),
        nameGt?: (string | null),
        nameGte?: (string | null),
        nameIn?: (Array<string> | null),
        nameLt?: (string | null),
        nameLte?: (string | null),
        nameNe?: (string | null),
        nameNin?: (Array<string> | null),
        name?: (string | null),
        dateExists?: (boolean | null),
        dateEq?: (string | null),
        dateGt?: (string | null),
        dateGte?: (string | null),
        dateIn?: (Array<string> | null),
        dateLt?: (string | null),
        dateLte?: (string | null),
        dateNe?: (string | null),
        dateNin?: (Array<string> | null),
        date?: (string | null),
        descriptionExists?: (boolean | null),
        descriptionEq?: (string | null),
        descriptionGt?: (string | null),
        descriptionGte?: (string | null),
        descriptionIn?: (Array<string> | null),
        descriptionLt?: (string | null),
        descriptionLte?: (string | null),
        descriptionNe?: (string | null),
        descriptionNin?: (Array<string> | null),
        description?: (string | null),
        notesExists?: (boolean | null),
        notesEq?: (string | null),
        notesGt?: (string | null),
        notesGte?: (string | null),
        notesIn?: (Array<string> | null),
        notesLt?: (string | null),
        notesLte?: (string | null),
        notesNe?: (string | null),
        notesNin?: (Array<string> | null),
        notes?: (string | null),
        statusExists?: (boolean | null),
        statusEq?: (AccountingImportStatus | null),
        statusGt?: (AccountingImportStatus | null),
        statusGte?: (AccountingImportStatus | null),
        statusIn?: (Array<AccountingImportStatus> | null),
        statusLt?: (AccountingImportStatus | null),
        statusLte?: (AccountingImportStatus | null),
        statusNe?: (AccountingImportStatus | null),
        statusNin?: (Array<AccountingImportStatus> | null),
        status?: (AccountingImportStatus | null),
        limit?: (number | null),
        offset?: (number | null),
        /**
         * Field name to be sorted by. Ignored if 'sort' is specified
         */
        sortBy?: (string | null),
        /**
         * Sort order to be sorted by. Ignored if 'sort' is specified
         */
        sortOrder?: (PaginationSortOrder | null),
        /**
         * Sort field in the form `<field_name>:<sort_by>`. Example: `sort=email:asc`. If at least one is set, sort_by and sort_order are ignored
         */
        sort?: (Array<string> | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<AccountingImportOut>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accounting/accounting-imports',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'tags.$exists': tagsExists,
                'tags.$eq': tagsEq,
                'tags.$gt': tagsGt,
                'tags.$gte': tagsGte,
                'tags.$in': tagsIn,
                'tags.$lt': tagsLt,
                'tags.$lte': tagsLte,
                'tags.$ne': tagsNe,
                'tags.$nin': tagsNin,
                'tags.$all': tagsAll,
                'tags.$size': tagsSize,
                'tags': tags,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'filename.$exists': filenameExists,
                'filename.$eq': filenameEq,
                'filename.$gt': filenameGt,
                'filename.$gte': filenameGte,
                'filename.$in': filenameIn,
                'filename.$lt': filenameLt,
                'filename.$lte': filenameLte,
                'filename.$ne': filenameNe,
                'filename.$nin': filenameNin,
                'filename': filename,
                'source.$exists': sourceExists,
                'source.$eq': sourceEq,
                'source.$gt': sourceGt,
                'source.$gte': sourceGte,
                'source.$in': sourceIn,
                'source.$lt': sourceLt,
                'source.$lte': sourceLte,
                'source.$ne': sourceNe,
                'source.$nin': sourceNin,
                'source': source,
                'name.$exists': nameExists,
                'name.$eq': nameEq,
                'name.$gt': nameGt,
                'name.$gte': nameGte,
                'name.$in': nameIn,
                'name.$lt': nameLt,
                'name.$lte': nameLte,
                'name.$ne': nameNe,
                'name.$nin': nameNin,
                'name': name,
                'date.$exists': dateExists,
                'date.$eq': dateEq,
                'date.$gt': dateGt,
                'date.$gte': dateGte,
                'date.$in': dateIn,
                'date.$lt': dateLt,
                'date.$lte': dateLte,
                'date.$ne': dateNe,
                'date.$nin': dateNin,
                'date': date,
                'description.$exists': descriptionExists,
                'description.$eq': descriptionEq,
                'description.$gt': descriptionGt,
                'description.$gte': descriptionGte,
                'description.$in': descriptionIn,
                'description.$lt': descriptionLt,
                'description.$lte': descriptionLte,
                'description.$ne': descriptionNe,
                'description.$nin': descriptionNin,
                'description': description,
                'notes.$exists': notesExists,
                'notes.$eq': notesEq,
                'notes.$gt': notesGt,
                'notes.$gte': notesGte,
                'notes.$in': notesIn,
                'notes.$lt': notesLt,
                'notes.$lte': notesLte,
                'notes.$ne': notesNe,
                'notes.$nin': notesNin,
                'notes': notes,
                'status.$exists': statusExists,
                'status.$eq': statusEq,
                'status.$gt': statusGt,
                'status.$gte': statusGte,
                'status.$in': statusIn,
                'status.$lt': statusLt,
                'status.$lte': statusLte,
                'status.$ne': statusNe,
                'status.$nin': statusNin,
                'status': status,
                'limit': limit,
                'offset': offset,
                'sort_by': sortBy,
                'sort_order': sortOrder,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Upload Accounting Import File
     * @returns AccountingImportOut Successful Response
     * @throws ApiError
     */
    public static uploadAccountingImportFileApiV1AccountingAccountingImportsPost({
        formData,
        xTransactionId,
    }: {
        formData: Body_upload_accounting_import_file_api_v1_accounting_accounting_imports_post,
        xTransactionId?: (string | null),
    }): CancelablePromise<AccountingImportOut> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/accounting/accounting-imports',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Accounting Imports
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countAccountingImportsApiV1AccountingAccountingImportsCountGet({
        tagsExists,
        tagsEq,
        tagsGt,
        tagsGte,
        tagsIn,
        tagsLt,
        tagsLte,
        tagsNe,
        tagsNin,
        tagsAll,
        tagsSize,
        tags,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        filenameExists,
        filenameEq,
        filenameGt,
        filenameGte,
        filenameIn,
        filenameLt,
        filenameLte,
        filenameNe,
        filenameNin,
        filename,
        sourceExists,
        sourceEq,
        sourceGt,
        sourceGte,
        sourceIn,
        sourceLt,
        sourceLte,
        sourceNe,
        sourceNin,
        source,
        nameExists,
        nameEq,
        nameGt,
        nameGte,
        nameIn,
        nameLt,
        nameLte,
        nameNe,
        nameNin,
        name,
        dateExists,
        dateEq,
        dateGt,
        dateGte,
        dateIn,
        dateLt,
        dateLte,
        dateNe,
        dateNin,
        date,
        descriptionExists,
        descriptionEq,
        descriptionGt,
        descriptionGte,
        descriptionIn,
        descriptionLt,
        descriptionLte,
        descriptionNe,
        descriptionNin,
        description,
        notesExists,
        notesEq,
        notesGt,
        notesGte,
        notesIn,
        notesLt,
        notesLte,
        notesNe,
        notesNin,
        notes,
        statusExists,
        statusEq,
        statusGt,
        statusGte,
        statusIn,
        statusLt,
        statusLte,
        statusNe,
        statusNin,
        status,
        xTransactionId,
    }: {
        tagsExists?: (boolean | null),
        tagsEq?: (string | null),
        tagsGt?: (string | null),
        tagsGte?: (string | null),
        tagsIn?: (Array<string> | null),
        tagsLt?: (string | null),
        tagsLte?: (string | null),
        tagsNe?: (string | null),
        tagsNin?: (Array<string> | null),
        tagsAll?: (Array<string> | null),
        tagsSize?: (number | null),
        tags?: (string | null),
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        filenameExists?: (boolean | null),
        filenameEq?: (string | null),
        filenameGt?: (string | null),
        filenameGte?: (string | null),
        filenameIn?: (Array<string> | null),
        filenameLt?: (string | null),
        filenameLte?: (string | null),
        filenameNe?: (string | null),
        filenameNin?: (Array<string> | null),
        filename?: (string | null),
        sourceExists?: (boolean | null),
        sourceEq?: (AccountingImportSource | null),
        sourceGt?: (AccountingImportSource | null),
        sourceGte?: (AccountingImportSource | null),
        sourceIn?: (Array<AccountingImportSource> | null),
        sourceLt?: (AccountingImportSource | null),
        sourceLte?: (AccountingImportSource | null),
        sourceNe?: (AccountingImportSource | null),
        sourceNin?: (Array<AccountingImportSource> | null),
        source?: (AccountingImportSource | null),
        nameExists?: (boolean | null),
        nameEq?: (string | null),
        nameGt?: (string | null),
        nameGte?: (string | null),
        nameIn?: (Array<string> | null),
        nameLt?: (string | null),
        nameLte?: (string | null),
        nameNe?: (string | null),
        nameNin?: (Array<string> | null),
        name?: (string | null),
        dateExists?: (boolean | null),
        dateEq?: (string | null),
        dateGt?: (string | null),
        dateGte?: (string | null),
        dateIn?: (Array<string> | null),
        dateLt?: (string | null),
        dateLte?: (string | null),
        dateNe?: (string | null),
        dateNin?: (Array<string> | null),
        date?: (string | null),
        descriptionExists?: (boolean | null),
        descriptionEq?: (string | null),
        descriptionGt?: (string | null),
        descriptionGte?: (string | null),
        descriptionIn?: (Array<string> | null),
        descriptionLt?: (string | null),
        descriptionLte?: (string | null),
        descriptionNe?: (string | null),
        descriptionNin?: (Array<string> | null),
        description?: (string | null),
        notesExists?: (boolean | null),
        notesEq?: (string | null),
        notesGt?: (string | null),
        notesGte?: (string | null),
        notesIn?: (Array<string> | null),
        notesLt?: (string | null),
        notesLte?: (string | null),
        notesNe?: (string | null),
        notesNin?: (Array<string> | null),
        notes?: (string | null),
        statusExists?: (boolean | null),
        statusEq?: (AccountingImportStatus | null),
        statusGt?: (AccountingImportStatus | null),
        statusGte?: (AccountingImportStatus | null),
        statusIn?: (Array<AccountingImportStatus> | null),
        statusLt?: (AccountingImportStatus | null),
        statusLte?: (AccountingImportStatus | null),
        statusNe?: (AccountingImportStatus | null),
        statusNin?: (Array<AccountingImportStatus> | null),
        status?: (AccountingImportStatus | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accounting/accounting-imports/count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'tags.$exists': tagsExists,
                'tags.$eq': tagsEq,
                'tags.$gt': tagsGt,
                'tags.$gte': tagsGte,
                'tags.$in': tagsIn,
                'tags.$lt': tagsLt,
                'tags.$lte': tagsLte,
                'tags.$ne': tagsNe,
                'tags.$nin': tagsNin,
                'tags.$all': tagsAll,
                'tags.$size': tagsSize,
                'tags': tags,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'filename.$exists': filenameExists,
                'filename.$eq': filenameEq,
                'filename.$gt': filenameGt,
                'filename.$gte': filenameGte,
                'filename.$in': filenameIn,
                'filename.$lt': filenameLt,
                'filename.$lte': filenameLte,
                'filename.$ne': filenameNe,
                'filename.$nin': filenameNin,
                'filename': filename,
                'source.$exists': sourceExists,
                'source.$eq': sourceEq,
                'source.$gt': sourceGt,
                'source.$gte': sourceGte,
                'source.$in': sourceIn,
                'source.$lt': sourceLt,
                'source.$lte': sourceLte,
                'source.$ne': sourceNe,
                'source.$nin': sourceNin,
                'source': source,
                'name.$exists': nameExists,
                'name.$eq': nameEq,
                'name.$gt': nameGt,
                'name.$gte': nameGte,
                'name.$in': nameIn,
                'name.$lt': nameLt,
                'name.$lte': nameLte,
                'name.$ne': nameNe,
                'name.$nin': nameNin,
                'name': name,
                'date.$exists': dateExists,
                'date.$eq': dateEq,
                'date.$gt': dateGt,
                'date.$gte': dateGte,
                'date.$in': dateIn,
                'date.$lt': dateLt,
                'date.$lte': dateLte,
                'date.$ne': dateNe,
                'date.$nin': dateNin,
                'date': date,
                'description.$exists': descriptionExists,
                'description.$eq': descriptionEq,
                'description.$gt': descriptionGt,
                'description.$gte': descriptionGte,
                'description.$in': descriptionIn,
                'description.$lt': descriptionLt,
                'description.$lte': descriptionLte,
                'description.$ne': descriptionNe,
                'description.$nin': descriptionNin,
                'description': description,
                'notes.$exists': notesExists,
                'notes.$eq': notesEq,
                'notes.$gt': notesGt,
                'notes.$gte': notesGte,
                'notes.$in': notesIn,
                'notes.$lt': notesLt,
                'notes.$lte': notesLte,
                'notes.$ne': notesNe,
                'notes.$nin': notesNin,
                'notes': notes,
                'status.$exists': statusExists,
                'status.$eq': statusEq,
                'status.$gt': statusGt,
                'status.$gte': statusGte,
                'status.$in': statusIn,
                'status.$lt': statusLt,
                'status.$lte': statusLte,
                'status.$ne': statusNe,
                'status.$nin': statusNin,
                'status': status,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Accounting Import
     * @returns AccountingImportOut Successful Response
     * @throws ApiError
     */
    public static getAccountingImportApiV1AccountingAccountingImportsAccountingImportIdGet({
        accountingImportId,
        xTransactionId,
    }: {
        accountingImportId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<AccountingImportOut> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accounting/accounting-imports/{accounting_import_id}',
            path: {
                'accounting_import_id': accountingImportId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Accounting Import
     * @returns AccountingImportOut Successful Response
     * @throws ApiError
     */
    public static patchAccountingImportApiV1AccountingAccountingImportsAccountingImportIdPatch({
        accountingImportId,
        requestBody,
        xTransactionId,
    }: {
        accountingImportId: string,
        requestBody: AccountingImportUpdateIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<AccountingImportOut> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/accounting/accounting-imports/{accounting_import_id}',
            path: {
                'accounting_import_id': accountingImportId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Accounting Import
     * @returns void
     * @throws ApiError
     */
    public static deleteAccountingImportApiV1AccountingAccountingImportsAccountingImportIdDelete({
        accountingImportId,
        xTransactionId,
    }: {
        accountingImportId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/accounting/accounting-imports/{accounting_import_id}',
            path: {
                'accounting_import_id': accountingImportId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Download Accounting Import File
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadAccountingImportFileApiV1AccountingAccountingImportsAccountingImportIdDownloadGet({
        accountingImportId,
        xTransactionId,
    }: {
        accountingImportId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accounting/accounting-imports/{accounting_import_id}/download',
            path: {
                'accounting_import_id': accountingImportId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
