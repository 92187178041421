<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import {
    CompanyAccessRole,
    CompanyIdentityAccessWithUserProfile,
    CompanyManagementService,
    Invite,
    InviteStatus,
    InviteStatusIn,
  } from '@/client'
  import { displayDate } from '@/common/util/timeUtils'

  const i18n = useI18n()

  const inviteDialogVisible = ref(false)

  const pendingInvites = ref<Invite[]>([])

  function getPendingInvites() {
    CompanyManagementService.getInvitesApiV1CompanyInvitesGet({})
      .then((res) => {
        const _pendingInvitesWithoutAccepted = res.filter(
          (invite) =>
            invite.status === InviteStatus.PENDING ||
            invite.status === InviteStatus.DECLINED
        )
        pendingInvites.value = _pendingInvitesWithoutAccepted
      })
      .catch((err) => {
        console.error(err)
      })
  }

  function deleteInvite(id: string) {
    CompanyManagementService.patchInviteApiV1CompanyInvitesInviteIdPatch({
      inviteId: id,
      requestBody: {
        status: InviteStatusIn.WITHDRAWN,
      },
    })
      .then(() => {
        getPendingInvites()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const pendingInvitedColumns = [
    {
      label: i18n.t('email'),
      key: 'invited_email',
      sortable: false,
      width: 1,
    },
    {
      label: i18n.t('role'),
      key: 'role',
      sortable: false,
      width: 1,
    },
    {
      label: i18n.t('status'),
      key: 'status',
      sortable: true,
      width: 1,
    },
    {
      label: i18n.t('invitedAt'),
      key: 'created',
      sortable: true,
      width: 1,
    },
    {
      label: '',
      key: 'actions',
      width: '40px',
      sortable: false,
    },
  ]

  // Members related

  const members = ref<CompanyIdentityAccessWithUserProfile[]>([])
  function getCompanyMembers() {
    CompanyManagementService.getCompanyIdentityAccessesWithUserProfileApiV1CompanyIdentityAccessesGet(
      {}
    )
      .then((res) => {
        members.value = res
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const deleteUserDialogVisible = ref(false)
  const userIdToDelete = ref<string | undefined>(undefined)
  function handleDeleteUserClick(id: string) {
    userIdToDelete.value = id
    deleteUserDialogVisible.value = true
  }

  function deleteUser(id: string) {
    CompanyManagementService.updateCompanyIdentityAccessRoleApiV1CompanyIdentityAccessesIdentityIdRolePatch(
      {
        identityId: id,
        requestBody: {
          role_update: CompanyAccessRole.SUSPENDED,
        },
      }
    )
      .then(() => {
        getCompanyMembers()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  onMounted(() => {
    getPendingInvites()
    getCompanyMembers()
  })

  const membersColumns = [
    {
      label: i18n.t('name'),
      key: 'name',
      sortable: false,
      width: 1,
    },
    {
      label: i18n.t('email'),
      key: 'email',
      sortable: false,
      width: 1,
    },
    {
      label: i18n.t('role'),
      key: 'role',
      sortable: false,
      width: 1,
    },
    {
      label: i18n.t('lastLogin'),
      key: 'last_login',
      sortable: false,
      width: 1,
    },
    {
      label: '',
      key: 'actions',
      width: '40px',
      sortable: false,
    },
  ]

  // Reload if invite dialog opens or closes
  watch(inviteDialogVisible, () => {
    getPendingInvites()
  })
</script>

<template>
  <SettingsInviteNewDialog v-model:show="inviteDialogVisible" />

  <DeleteConfirmDialog
    v-if="userIdToDelete"
    :title="i18n.t('deleteUser')"
    :visible="deleteUserDialogVisible"
    :sub-title="i18n.t('deleteUserSubTitle', { name: userIdToDelete })"
    @cancel="deleteUserDialogVisible = false"
    @delete="deleteUser(userIdToDelete)"></DeleteConfirmDialog>

  <div class="flex flex-col gap-5 p-4 pb-5 pt-5">
    <!-- Members -->
    <div style="width: 100%">
      <div class="invite-header">
        <h3>{{ i18n.t('members') }}</h3>
        <SmTooltip>
          <SmButton
            v-require-permission="CompanyAccessRole.ADMIN"
            @click="inviteDialogVisible = true">
            {{ i18n.t('invite') }}
          </SmButton>

          <template #content>
            {{ i18n.t('inviteNewUser') }}
          </template>
        </SmTooltip>
      </div>

      <p>{{ i18n.t('memberOverviewDescription') }}</p>
      <SmTable :data="members" :columns="membersColumns" key-field="_id">
        <template #role="{ row }">
          {{ i18n.t(row.company_access.role) }}
        </template>

        <template #name="{ row }">
          <span v-if="row.profile?.display_name">
            {{ row.profile.display_name }}
          </span>
        </template>

        <template #email="{ row }">
          <span v-if="row.profile?.email">
            {{ row.profile.email }}
          </span>
        </template>

        <template #last_login="{ row }">
          {{
            displayDate(new Date(row.company_access.last_login!), {
              dateStyle: 'short',
            })
          }}
        </template>

        <template #actions="{ row }">
          <v-icon
            v-if="row.company_access.role !== 'suspended'"
            name="md-delete-round"
            @click="handleDeleteUserClick(row._id)" />
          <span v-else> </span>
        </template>
      </SmTable>
    </div>

    <!-- Invites -->
    <div style="width: 100%">
      <!-- Invites -->
      <div class="invite-header">
        <h3>{{ i18n.t('invites') }}</h3>
      </div>

      <p>{{ i18n.t('inviteOverviewDescription') }}</p>

      <SmTable
        :data="pendingInvites"
        :columns="pendingInvitedColumns"
        :default-sorting="{ by: 'status', asc: true }"
        key-field="_id">
        <!-- Role -->
        <template #role="{ row }">
          {{ i18n.t(row.role) }}
        </template>

        <!-- Status -->
        <template #status="{ row }">
          {{ i18n.t(row.status) }}
        </template>

        <template #actions="{ row }">
          <div>
            <!-- If Pending show withdraw button -->
            <SmTooltip
              v-if="
                row.status === InviteStatus.PENDING ||
                row.status === InviteStatus.DECLINED
              ">
              <v-icon name="md-delete-round" @click="deleteInvite(row._id)" />

              <template #content>
                {{ i18n.t('withdrawInvite') }}
              </template>
            </SmTooltip>
          </div>
        </template>

        <template #created="{ row }">
          {{ displayDate(new Date(row.created), { dateStyle: 'short' }) }}
        </template>
      </SmTable>
    </div>
  </div>
</template>

<style scoped>
  .invite-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content-wrapper {
    display: flex;
    gap: 2rem;
    flex-direction: column;
  }

  p {
    margin-bottom: 1rem;
  }
</style>
