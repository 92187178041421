<template>
  <BackgroundjobAbortDialog
    v-model:visible="tasksJobAbortDialogVisible"
    v-model:job-id="tasksJobToAbort" />

  <BackgroundjobEditDialog
    v-model:visible="tasksJobEditDialogVisible"
    v-model:job-id="tasksJobToEdit" />

  <BackgroundjobScheduleDialog
    v-model:visible="tasksJobScheduleDialogVisible"
    v-model:job-id="tasksJobToSchedule" />

  <BackgroundjobTableHeader
    v-if="backgroundjobsExist"
    @create-job="openCreateJobDialog()"
    @update-filter="
      (newFilters: BackgroundjobFilterInstance[]) => updateFilter(newFilters)
    " />

  <div
    v-if="loadingBackgroundjobs"
    v-loading="loadingBackgroundjobs"
    class="h-full w-full"></div>

  <SmTable
    v-else-if="backgroundjobsExist"
    :data="backgroundJobs"
    :columns="columns"
    key-field="_id"
    class="!h-auto overflow-hidden"
    :default-sorting="{
      by: 'execution',
      asc: true,
    }"
    @scroll-end-reached="loadMoreBackgroundjobs">
    <template #message="{ row }">
      <BackgroundjobMessage
        :type="row.type as BackgroundJobType"
        :data="row.data"
        class="py-5"
        :clickable="true" />
    </template>

    <!-- Status (indicator) -->
    <template #status="{ row }">
      <SmTooltip :content="getStatus(row.status).text" :active="true">
        <div class="flex items-center gap-2">
          <span
            class="indicator-dot shrink-0"
            :class="getStatus(row.status).color"></span>
          <TextMultiline :text="getStatus(row.status).text" />
        </div>
      </SmTooltip>
    </template>

    <!-- Creator -->
    <template #creator="{ row }">
      <div class="flex items-center gap-4">
        <SmAvatar
          v-if="row.created_by_identity_id"
          :name="getMemberName(row.created_by_identity_id)"
          size="xsmall"
          class="bg-gray-200"
          form="circle" />
        <TextMultiline
          v-if="row.created_by_identity_id"
          :text="getMemberName(row.created_by_identity_id)" />
        <TextMultiline
          v-else
          class="text-muted"
          :text="i18n.t('createdAutomatically')" />
      </div>
    </template>

    <!-- Excecution Date -->
    <template #execution="{ row }">
      <TextMultiline
        v-if="
          row.status === BackgroundJobStatus.COMPLETED ||
          row.status === BackgroundJobStatus.ERROR
        "
        class="text-contrast-muted"
        :text="
          i18n.t('dayAtTime', {
            day: translateDate(row.last_executed_at as string),
            time: dateToString(row.last_executed_at as string),
          })
        " />

      <TextMultiline
        v-else-if="row.status == BackgroundJobStatus.ENQUEUED"
        :text="
          i18n.t('dayAtTime', {
            day: translateDate(row.next_execution_at as string),
            time: dateToString(row.next_execution_at as string),
          })
        " />

      <p v-else></p>
    </template>

    <!-- Action -->
    <template #actions="{ row }">
      <SmDropdown
        v-if="
          row.status == BackgroundJobStatus.ENQUEUED ||
          row.status == BackgroundJobStatus.PAUSED
        "
        trigger="click"
        class="ml-auto mr-4">
        <!-- Show User -->
        <SmDropdownItem
          v-if="row.status == BackgroundJobStatus.ENQUEUED"
          icon="md-pause"
          @click="pauseJob(row._id)">
          {{ i18n.t('pauseJob') }}
        </SmDropdownItem>

        <!-- Edit User -->
        <SmDropdownItem
          v-if="row.status == BackgroundJobStatus.ENQUEUED"
          icon="io-close"
          @click="openAbortJobDialog(row._id)">
          {{ i18n.t('abortJob') }}
        </SmDropdownItem>

        <!-- schedule job -->
        <SmDropdownItem
          v-if="row.status == BackgroundJobStatus.PAUSED"
          icon="md-accesstime"
          @click="openScheduleJobDialog(row._id)">
          {{ i18n.t('scheduleJob') }}
        </SmDropdownItem>
      </SmDropdown>
      <p v-else></p>
    </template>
  </SmTable>

  <EmptyState
    v-else
    icon="oi-workflow"
    :title="capitalize(i18n.t('backgroundjob', { count: 2 }))"
    :text="i18n.t('backgroundjobEmptyStateText')"
    :create-label="i18n.t('createJob')"
    :show-create-btn="false"
    :show-docs-btn="false" />
</template>

<script setup lang="ts">
  import {
    BackgroundJobAccountGroupAccountsAdded,
    BackgroundJobAccountGroupAccountsRemoved,
    BackgroundJobSoftwareUserAdd,
    BackgroundJobSoftwareUserEnable,
    BackgroundJobSoftwareUserLicenseAdd,
    BackgroundJobSoftwareUserLicenseRemove,
    BackgroundJobSoftwareUserRemove,
    BackgroundJobStatus,
    BackgroundJobType,
    CompanyIdentityAccessWithUserProfile,
  } from '@/client'
  import { Column } from '@/components/sm/SmTable.types'
  import dayjs from 'dayjs'
  import { useI18n } from 'vue-i18n'

  import utc from 'dayjs/plugin/utc'
  import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
  import localizedFormat from 'dayjs/plugin/localizedFormat'
  import { sendToast } from './sm/SmNotification'
  import {
    backgroundjobStore,
    getBackgroundjobs,
    getBackgroundjobsSubscription,
  } from '@/stores/backgroundjob.store'
  import { Subscription } from 'rxjs'
  import {
    BackgroundjobFilterInstance,
    getStatus,
  } from '@/stores/backgroundjob.utils'
  import { useSessionStore } from '@/stores/sessionStore'
  import { capitalize } from '@/common/util/formatter'
  import { displayDate } from '@/common/util/timeUtils'

  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(localizedFormat)

  const i18n = useI18n()
  const tz = dayjs.tz.guess()

  const sessionStore = useSessionStore()

  const tasksJobToEdit = ref<string>('')
  const tasksJobEditDialogVisible = ref(false)

  const tasksJobToSchedule = ref<string>('')
  const tasksJobScheduleDialogVisible = ref(false)

  const tasksJobToAbort = ref<string>('')
  const tasksJobAbortDialogVisible = ref(false)

  const backgroundjobCountLabel = computed(() => {
    return `${filteredCount.value} ${i18n.t('backgroundjob', { count: filteredCount.value })}`
  })

  const columns = computed<
    Column<
      | BackgroundJobAccountGroupAccountsAdded
      | BackgroundJobAccountGroupAccountsRemoved
      | BackgroundJobSoftwareUserAdd
      | BackgroundJobSoftwareUserEnable
      | BackgroundJobSoftwareUserLicenseAdd
      | BackgroundJobSoftwareUserLicenseRemove
      | BackgroundJobSoftwareUserRemove
    >[]
  >(() => [
    {
      key: 'message',
      label: i18n.t('description'),
      sortable: false,
      width: 4,
      footer: backgroundjobCountLabel,
    },
    {
      key: 'status',
      label: i18n.t('status'),
      sortable: true,
      width: 1,
    },
    {
      key: 'creator',
      label: i18n.t('creator'),
      width: 1,
    },
    {
      key: 'execution',
      label: i18n.t('date'),
      sortable: true,
      width: 1,
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
      width: '50px',
    },
  ])

  function translateDate(date: string): string {
    const dateObject = dayjs(date).tz(tz)
    const today = dayjs().tz(tz).startOf('day')
    const yesterday = today.subtract(1, 'day')

    if (dateObject.isSame(today, 'day')) {
      return i18n.t('views.dashboard.lastActivity.today')
    }

    if (dateObject.isSame(yesterday, 'day')) {
      return i18n.t('views.dashboard.lastActivity.yesterday')
    }

    return displayDate(new Date(date), { dateStyle: 'short' })
  }

  function dateToString(date: string): string {
    const dateObject = dayjs(date, { utc: true }).tz(tz)
    return dateObject.format('HH:mm')
  }

  /**
   *  Backgroundjob functions
   */

  function pauseJob(jobId: string) {
    backgroundjobStore
      .pauseBackgroundjob(jobId)
      .then(() => {
        sendToast(i18n.t('pauseJobSuccess'), undefined, 'success')
      })
      .catch(() => {
        sendToast(i18n.t('pauseJobFailed'), undefined, 'error')
      })
  }

  function openAbortJobDialog(jobId: string) {
    tasksJobToAbort.value = jobId ? jobId : ''
    tasksJobAbortDialogVisible.value = true
  }

  function openScheduleJobDialog(jobId: string) {
    tasksJobToSchedule.value = jobId ? jobId : ''
    tasksJobScheduleDialogVisible.value = true
  }

  function openCreateJobDialog(jobId?: string) {
    tasksJobEditDialogVisible.value = true
    tasksJobToEdit.value = jobId ?? ''
  }

  /**
   *  Row functions
   */

  function getMemberName(id: string): string {
    const member = sessionStore.companyMembers?.find(
      (member: CompanyIdentityAccessWithUserProfile) => member._id === id
    )

    if (!member || !member.profile) return i18n.t('general.unknown')

    return member.profile.display_name
  }

  /**
   * Filters
   */

  const filters = ref<BackgroundjobFilterInstance[]>([])
  function updateFilter(newFilters: BackgroundjobFilterInstance[]) {
    filters.value = newFilters
    reloadBackgroundjobs()
  }

  /**
   * Load Background Jobs
   */

  const backgroundJobs = ref<
    Array<
      | BackgroundJobAccountGroupAccountsAdded
      | BackgroundJobAccountGroupAccountsRemoved
      | BackgroundJobSoftwareUserAdd
      | BackgroundJobSoftwareUserEnable
      | BackgroundJobSoftwareUserLicenseAdd
      | BackgroundJobSoftwareUserLicenseRemove
      | BackgroundJobSoftwareUserRemove
    >
  >([])

  const INITIAL_ROW_LIMIT = 300
  const ROW_EXPAND_COUNT = 100
  const currentSubscription = ref<Subscription>()
  const loadingBackgroundjobs = ref<boolean>(true)
  const totalBackgroundjobsCount = ref<number>(0)
  const filteredCount = ref<number>(0)
  const backgroundjobsExist = ref<boolean>(false)

  async function reloadBackgroundjobs() {
    loadingBackgroundjobs.value = true
    currentSubscription.value?.unsubscribe()

    getBackgroundjobsSubscription(filters.value, {
      limit: INITIAL_ROW_LIMIT,
    }).then(async (result) => {
      currentSubscription.value = result?.subscribe((data) => {
        backgroundJobs.value = data
      })

      if (backgroundJobs.value.length === 0) {
        // check if there would be any data w/o any filter applied
        const anyResult = await backgroundjobStore.getBackgroundjobs([], {
          limit: 1,
        })

        backgroundjobsExist.value = !!anyResult?.length
      } else {
        backgroundjobsExist.value = true
      }

      loadingBackgroundjobs.value = false
    })
  }

  async function loadMoreBackgroundjobs() {
    getBackgroundjobs(filters.value, {
      limit: ROW_EXPAND_COUNT,
      skip: backgroundJobs.value.length,
    }).then((response) => {
      if (response) backgroundJobs.value = backgroundJobs.value.concat(response)
    })
  }

  function countBackgroundjobs(useFilters: boolean = true) {
    backgroundjobStore
      .getBackgroundjobCount(useFilters ? filters.value : [])
      .then((count) => {
        filteredCount.value = count.filtered ?? 0
        totalBackgroundjobsCount.value = count.total ?? 0
      })
  }

  watch(
    () => backgroundJobs.value,
    async () => {
      countBackgroundjobs()
    }
  )

  /**
   * Lifecycle hooks
   */

  onMounted(async () => {
    reloadBackgroundjobs()
    await sessionStore.getCompanyMembers()
  })

  onUnmounted(() => {
    currentSubscription.value?.unsubscribe()
  })
</script>

<style lang="scss" scoped>
  .indicator-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
</style>
