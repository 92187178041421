import { SelectQueryBuilder } from 'kysely'
import { Database } from './database'

export type PersonFilters = {
  isDeleted?: boolean
  email?: string
  createdBefore?: string
  createdAfter?: string
  updatedBefore?: string
  updatedAfter?: string
  deletedBefore?: string
  deletedAfter?: string
}

export function applyPersonFilters<T>(
  query: SelectQueryBuilder<Database, 'person', T>,
  filters: PersonFilters
): SelectQueryBuilder<Database, 'person', T> {
  if (filters.isDeleted !== true) {
    query = query.where('deleted', '=', filters.isDeleted ? 1 : 0)
  }
  if (filters.email) {
    query = query.where('email', 'like', `%${filters.email}%`)
  }
  if (filters.createdBefore) {
    query = query.where('created', '<', filters.createdBefore)
  }
  if (filters.createdAfter) {
    query = query.where('created', '>', filters.createdAfter)
  }
  if (filters.updatedBefore) {
    query = query.where('updated', '<', filters.updatedBefore)
  }
  if (filters.updatedAfter) {
    query = query.where('updated', '>', filters.updatedAfter)
  }
  if (filters.deletedBefore) {
    query = query.where('deleted_at', '<', filters.deletedBefore)
  }
  if (filters.deletedAfter) {
    query = query.where('deleted_at', '>', filters.deletedAfter)
  }

  return query
}
