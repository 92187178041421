<!-- SM Select -->

<template>
  <div ref="dropdown" class="sm-select">
    <SmDropdown
      ref="selectDropdown"
      :class="{ 'cursor-not-allowed': props.disabled }"
      type="panel">
      <template #trigger>
        <div
          class="trigger flex items-center border border-gray-300 dark:border-gray-600"
          :class="{
            'rounded border': props.triggerStyle === 'default',
            'rounded-full border': props.triggerStyle === 'round',
            'border-none !p-0': props.triggerStyle === 'plain',
            'px-4 py-2': props.triggerSize === 'default',
            'px-3 py-0': props.triggerSize === 'small',
          }">
          <slot name="trigger" :items="selectedOptions">
            <!-- Multiselect Tags -->
            <div v-if="props.multiselect || props.searchable" class="label">
              <div
                v-if="selectedOptions.length > 0"
                class="current-selection mr-4 flex gap-2">
                <SmTag
                  v-for="option in visibleOptions"
                  :key="option.label"
                  :label="option.label"
                  hide-tooltip
                  class="w-full max-w-fit leading-7">
                  <template #action>
                    <v-icon
                      name="io-close"
                      scale=".8"
                      class="cursor-pointer"
                      @click="deselectOption(option)" />
                  </template>
                </SmTag>

                <SmTooltip>
                  <SmTag
                    v-if="selectedOptions.length > props.itemsToShow"
                    :label="'+' + (selectedOptions.length - 1)"
                    hide-tooltip
                    class="whitespace-nowrap leading-7" />
                  <template #content>
                    <div v-for="t in selectedOptions.slice(1)" :key="t.label">
                      {{ t.label }}
                    </div>
                  </template>
                </SmTooltip>
              </div>
            </div>
            <!-- Single Select Tag -->
            <div v-else>
              <TextMultiline v-if="selectedOptions[0]">
                {{ selectedOptions[0].label }}
              </TextMultiline>
              <TextMultiline
                v-else
                class="placeholder text-gray-400"
                :text="props.label" />
            </div>
          </slot>

          <!-- Search Input -->
          <input
            v-if="props.searchable"
            v-model="searchString"
            class="min-w-[120px] grow"
            :placeholder="props.label" />

          <!-- dropdown indicator -->
          <v-icon
            name="md-expandmore-round"
            scale="1.5"
            class="dropdown-indicator ml-auto cursor-pointer" />
        </div>
      </template>

      <div v-if="props.loading">
        <SmSkeleton height="20px" width="80%" class="mx-5 mt-4 !block" />
        <SmSkeleton height="20px" width="60%" class="mx-5 mb-4 mt-8 !block" />
      </div>

      <div v-else-if="filteredOptions?.length" style="max-height: 300px">
        <div
          v-if="props.dropdownMinWidth"
          :style="{ 'min-width': props.dropdownMinWidth }"></div>

        <VList
          v-slot="{ item }"
          :data="filteredOptions"
          :style="{ maxHeight: '300px' }"
          class="!contain-none">
          <SmDropdownItem :key="item[keyField]" @click="selectOption(item)">
            <div
              class="dropdown-item flex w-full text-base"
              :class="{
                'font-bold': selectedOptions.includes(item),
              }">
              <slot name="item" :item="item">
                <TextMultiline :text="item.label" />
              </slot>
              <v-icon
                v-if="selectedOptions.includes(item)"
                fill="var(--sm-text-color)"
                scale="0.9"
                name="md-check-round"
                class="ml-auto" />
            </div>
          </SmDropdownItem>
        </VList>
      </div>
      <p
        v-else-if="filteredOptions?.length === 0"
        class="w-full p-3 text-center">
        {{ i18n.t('noSelectionAvailable') }}
      </p>
      <p v-else class="w-full p-3 text-center">
        <SmSkeleton height="28px" width="100%" />
      </p>
    </SmDropdown>
  </div>
</template>

<script lang="ts" generic="T">
  export type Option<T> = {
    value: T
    label: string
    key?: string
    description?: string
    section?: string
  }
</script>

<script setup lang="ts" generic="T">
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'
  import SmDropdown from './SmDropdown.vue'
  import { VList } from 'virtua/vue'

  type TriggerStyle = 'default' | 'round' | 'plain'

  const emit = defineEmits<{
    change: [value: Option<T>[] | undefined]
  }>()

  const selection = defineModel('selection', {
    type: Array as PropType<Option<T>[] | undefined>,
    default: [],
  })

  const props = defineProps({
    options: {
      type: Array as PropType<Option<T>[] | null>,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    itemsToShow: {
      type: Number,
      default: 2,
    },
    itemSize: {
      type: Number,
      default: 45,
    },
    triggerSize: {
      type: String as PropType<'default' | 'small'>,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showSections: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    triggerStyle: {
      type: String as PropType<TriggerStyle>,
      default: 'default',
    },
    dropdownMinWidth: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
  })

  const selectDropdown = ref<ComponentExposed<typeof SmDropdown>>()
  const i18n = useI18n()

  const selectedOptions: Ref<Option<T>[]> = ref([])
  const searchString = ref<string>('')

  const keyField = computed(() => {
    return props.options?.some((option) => option.key) ? 'key' : 'value'
  })

  const filteredOptions = computed(() => {
    if (!searchString.value) {
      return props.options
    }

    const searchTerm = searchString.value.toLowerCase()
    return props.options?.filter((option) => {
      if (!option.label) return
      return option.label.toLowerCase().includes(searchTerm)
    })
  })

  const visibleOptions = computed(() => {
    return selectedOptions.value.length > props.itemsToShow
      ? selectedOptions.value.slice(0, 1)
      : selectedOptions.value
  })

  function selectOption(option: Option<T>) {
    //single select
    if (!props.multiselect) {
      selectedOptions.value = []
      selectedOptions.value.push(option)
      selection.value = selectedOptions.value
      selectDropdown.value?.setVisibility(false)
      searchString.value = ''
      emit('change', selectedOptions.value)
      return
    }

    // multi select
    if (selectedOptions.value.includes(option)) {
      deselectOption(option)
      return
    } else {
      // select
      if (selectedOptions.value.length == 0) selectedOptions.value = []
      selectedOptions.value.push(option)
    }

    selection.value =
      selectedOptions.value.length == 0 ? undefined : selectedOptions.value
    emit('change', selectedOptions.value)
  }

  function deselectOption(option: Option<T>) {
    const index = selectedOptions.value?.indexOf(option)
    selectedOptions.value.splice(index, 1)

    selection.value =
      selectedOptions.value.length === 0 ? undefined : selectedOptions.value
    emit('change', selectedOptions.value)
  }

  watch(
    selection,
    () => {
      if (selection.value) {
        selectedOptions.value = selection.value
      }
    },
    { immediate: true }
  )

  watch(
    () => searchString.value,
    (value) => {
      if (value) {
        selectDropdown.value?.setVisibility(true)
      }
    }
  )
</script>

<style lang="scss">
  .sm-select {
    .trigger {
      .dropdown-indicator {
        transition: all 0.2s ease;
        transform: rotate(0deg);
      }
      &.active {
        .dropdown-indicator {
          transform: rotate(180deg);
        }
      }
    }
  }
  input {
    background: none;
  }

  .dropdown-item {
    border-radius: var(--border-radius-base);
  }
</style>

<style lang="scss" scoped>
  .is-error .trigger {
    border-color: var(--sm-red);
  }
</style>
