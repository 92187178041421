<template>
  <canvas id="myChart" style="height: 100%"></canvas>
</template>

<script lang="ts" setup>
  import { Chart, ChartData, ChartOptions } from 'chart.js'
  import { useI18n } from 'vue-i18n'

  import { Optimization, SubAccount_Output } from '@/client'
  import { ApplicationStore } from '@/stores/application.store'
  import { isEqual } from 'lodash-es'

  const props = defineProps({
    optimizations: {
      type: Array as PropType<Optimization[]>,
      required: true,
    },
  })

  const i18n = useI18n()

  export interface SSOSubAccount extends SubAccount_Output {
    _id: string
    type: 'sso'
    software_id: string
  }

  const chartData = computed(() => {
    const count: Record<string, number> = {}
    for (const element of props.optimizations) {
      if (!element.software_id) {
        continue
      }
      if (count[element.software_id]) {
        count[element.software_id] += 1
      } else {
        count[element.software_id] = 1
      }
    }
    return count
  })

  const chartCounts = computed(() => Object.values(chartData.value))
  const chartLabels = computed(() => Object.keys(chartData.value))

  const style = getComputedStyle(document.body)
  const notHighlighted = style.getPropertyValue('--el-border-color')
  const highlighted = '#5a28ff'

  const { data: currentSoftware } = ApplicationStore.useApplications()

  const data = computed<ChartData<'bar'>>(() => {
    // Ensure the reactivity system tracks these dependencies
    const softwareList = currentSoftware.value // Access .value for reactivity
    const counts = chartCounts.value // Access .value for reactivity
    const labels = chartLabels.value // Access .value for reactivity

    const backgroundColors = counts.map((number) => {
      if (Math.max(...counts) === number) {
        return highlighted
      } else if (Math.min(...counts) === number) {
        return highlighted
      } else {
        return notHighlighted
      }
    })

    return {
      labels: labels.map((id) => {
        const software = softwareList.find((s) => s._id === id)
        return software?.display_name || 'Unknown' // Handle potential null/undefined
      }),
      datasets: [
        {
          data: counts,
          backgroundColor: backgroundColors,
          borderRadius: 25,
          borderSkipped: false,
          width: 20,
          barThickness: 15,
        },
      ],
    }
  })

  const options = computed<ChartOptions<'bar'>>(() => ({
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const software = currentSoftware.value.find(
              (software) => context.label === software.display_name
            )
            return i18n.t('views.dashboard.optimizations.tooltip', {
              softwareName: software?.display_name || 'Unknown',
              count: context.raw, // `context.raw` contains the value for the bar
            })
          },
        },
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    animation: {
      duration: 1000,
      easing: 'easeOutQuart',
      delay: (context) => context.dataIndex * 100,
    },
  }))

  let chart: Chart | null = null

  onMounted(() => {
    chart = new Chart('myChart', {
      type: 'bar',
      data: data.value,
      options: options.value,
    })
  })

  watch(data, (newData) => {
    if (chart) {
      chart.data = newData
      chart.update()
    }
  })
</script>

<style lang="scss" scoped>
  #myChart {
    height: 100%;
    width: 100%;
  }
</style>
