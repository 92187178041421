<template>
  <span
    :style="{ height, width: computedWidth }"
    class="skeleton-box flex items-center justify-center">
    <slot />
  </span>
</template>

<script setup lang="ts">
  const props = defineProps({
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: '1em',
    },
    maxWidth: {
      // The default maxiumum width is 100%.
      default: 100,
      type: Number,
    },
    minWidth: {
      // Lines have a minimum width of 80%.
      default: 80,
      type: Number,
    },
  })

  const computedWidth = computed(() => {
    return (
      props.width ||
      `${Math.floor(
        Math.random() * (props.maxWidth - props.minWidth) + props.minWidth
      )}%`
    )
  })
</script>

<style lang="scss" scoped>
  .skeleton-box {
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    background-color: theme('colors.gray.200');
    border-radius: var(--border-radius-small);

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.3) 60%,
        rgba(255, 255, 255, 0.2) 80%,
        rgba(255, 255, 255, 0)
      );
      animation: shimmer 3s infinite;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }

  .dark .skeleton-box {
    background-color: theme('colors.gray.600');
  }
</style>
