/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Employee } from '../models/Employee';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EmployeesService {
    /**
     * Get Employees
     * @returns Employee Successful Response
     * @throws ApiError
     */
    public static getEmployeesApiV1DataSourceEmployeesGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        email,
        phoneExists,
        phoneEq,
        phoneGt,
        phoneGte,
        phoneIn,
        phoneLt,
        phoneLte,
        phoneNe,
        phoneNin,
        phone,
        endDateExists,
        endDateEq,
        endDateGt,
        endDateGte,
        endDateIn,
        endDateLt,
        endDateLte,
        endDateNe,
        endDateNin,
        endDate,
        lastWorkDayExists,
        lastWorkDayEq,
        lastWorkDayGt,
        lastWorkDayGte,
        lastWorkDayIn,
        lastWorkDayLt,
        lastWorkDayLte,
        lastWorkDayNe,
        lastWorkDayNin,
        lastWorkDay,
        deletedExternalExists,
        deletedExternalEq,
        deletedExternalGt,
        deletedExternalGte,
        deletedExternalIn,
        deletedExternalLt,
        deletedExternalLte,
        deletedExternalNe,
        deletedExternalNin,
        deletedExternal,
        remoteIdExists,
        remoteIdEq,
        remoteIdGt,
        remoteIdGte,
        remoteIdIn,
        remoteIdLt,
        remoteIdLte,
        remoteIdNe,
        remoteIdNin,
        remoteId,
        dataSourceIdExists,
        dataSourceIdEq,
        dataSourceIdGt,
        dataSourceIdGte,
        dataSourceIdIn,
        dataSourceIdLt,
        dataSourceIdLte,
        dataSourceIdNe,
        dataSourceIdNin,
        dataSourceId,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        email?: (string | null),
        phoneExists?: (boolean | null),
        phoneEq?: (string | null),
        phoneGt?: (string | null),
        phoneGte?: (string | null),
        phoneIn?: (Array<string> | null),
        phoneLt?: (string | null),
        phoneLte?: (string | null),
        phoneNe?: (string | null),
        phoneNin?: (Array<string> | null),
        phone?: (string | null),
        endDateExists?: (boolean | null),
        endDateEq?: (string | null),
        endDateGt?: (string | null),
        endDateGte?: (string | null),
        endDateIn?: (Array<string> | null),
        endDateLt?: (string | null),
        endDateLte?: (string | null),
        endDateNe?: (string | null),
        endDateNin?: (Array<string> | null),
        endDate?: (string | null),
        lastWorkDayExists?: (boolean | null),
        lastWorkDayEq?: (string | null),
        lastWorkDayGt?: (string | null),
        lastWorkDayGte?: (string | null),
        lastWorkDayIn?: (Array<string> | null),
        lastWorkDayLt?: (string | null),
        lastWorkDayLte?: (string | null),
        lastWorkDayNe?: (string | null),
        lastWorkDayNin?: (Array<string> | null),
        lastWorkDay?: (string | null),
        deletedExternalExists?: (boolean | null),
        deletedExternalEq?: (boolean | null),
        deletedExternalGt?: (boolean | null),
        deletedExternalGte?: (boolean | null),
        deletedExternalIn?: (Array<boolean> | null),
        deletedExternalLt?: (boolean | null),
        deletedExternalLte?: (boolean | null),
        deletedExternalNe?: (boolean | null),
        deletedExternalNin?: (Array<boolean> | null),
        deletedExternal?: (boolean | null),
        remoteIdExists?: (boolean | null),
        remoteIdEq?: (string | null),
        remoteIdGt?: (string | null),
        remoteIdGte?: (string | null),
        remoteIdIn?: (Array<string> | null),
        remoteIdLt?: (string | null),
        remoteIdLte?: (string | null),
        remoteIdNe?: (string | null),
        remoteIdNin?: (Array<string> | null),
        remoteId?: (string | null),
        dataSourceIdExists?: (boolean | null),
        dataSourceIdEq?: (string | null),
        dataSourceIdGt?: (string | null),
        dataSourceIdGte?: (string | null),
        dataSourceIdIn?: (Array<string> | null),
        dataSourceIdLt?: (string | null),
        dataSourceIdLte?: (string | null),
        dataSourceIdNe?: (string | null),
        dataSourceIdNin?: (Array<string> | null),
        dataSourceId?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<Employee>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data_source/employees',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'email': email,
                'phone.$exists': phoneExists,
                'phone.$eq': phoneEq,
                'phone.$gt': phoneGt,
                'phone.$gte': phoneGte,
                'phone.$in': phoneIn,
                'phone.$lt': phoneLt,
                'phone.$lte': phoneLte,
                'phone.$ne': phoneNe,
                'phone.$nin': phoneNin,
                'phone': phone,
                'end_date.$exists': endDateExists,
                'end_date.$eq': endDateEq,
                'end_date.$gt': endDateGt,
                'end_date.$gte': endDateGte,
                'end_date.$in': endDateIn,
                'end_date.$lt': endDateLt,
                'end_date.$lte': endDateLte,
                'end_date.$ne': endDateNe,
                'end_date.$nin': endDateNin,
                'end_date': endDate,
                'last_work_day.$exists': lastWorkDayExists,
                'last_work_day.$eq': lastWorkDayEq,
                'last_work_day.$gt': lastWorkDayGt,
                'last_work_day.$gte': lastWorkDayGte,
                'last_work_day.$in': lastWorkDayIn,
                'last_work_day.$lt': lastWorkDayLt,
                'last_work_day.$lte': lastWorkDayLte,
                'last_work_day.$ne': lastWorkDayNe,
                'last_work_day.$nin': lastWorkDayNin,
                'last_work_day': lastWorkDay,
                'deleted_external.$exists': deletedExternalExists,
                'deleted_external.$eq': deletedExternalEq,
                'deleted_external.$gt': deletedExternalGt,
                'deleted_external.$gte': deletedExternalGte,
                'deleted_external.$in': deletedExternalIn,
                'deleted_external.$lt': deletedExternalLt,
                'deleted_external.$lte': deletedExternalLte,
                'deleted_external.$ne': deletedExternalNe,
                'deleted_external.$nin': deletedExternalNin,
                'deleted_external': deletedExternal,
                'remote_id.$exists': remoteIdExists,
                'remote_id.$eq': remoteIdEq,
                'remote_id.$gt': remoteIdGt,
                'remote_id.$gte': remoteIdGte,
                'remote_id.$in': remoteIdIn,
                'remote_id.$lt': remoteIdLt,
                'remote_id.$lte': remoteIdLte,
                'remote_id.$ne': remoteIdNe,
                'remote_id.$nin': remoteIdNin,
                'remote_id': remoteId,
                'data_source_id.$exists': dataSourceIdExists,
                'data_source_id.$eq': dataSourceIdEq,
                'data_source_id.$gt': dataSourceIdGt,
                'data_source_id.$gte': dataSourceIdGte,
                'data_source_id.$in': dataSourceIdIn,
                'data_source_id.$lt': dataSourceIdLt,
                'data_source_id.$lte': dataSourceIdLte,
                'data_source_id.$ne': dataSourceIdNe,
                'data_source_id.$nin': dataSourceIdNin,
                'data_source_id': dataSourceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Employees
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countEmployeesApiV1DataSourceEmployeesCountGet({
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        email,
        phoneExists,
        phoneEq,
        phoneGt,
        phoneGte,
        phoneIn,
        phoneLt,
        phoneLte,
        phoneNe,
        phoneNin,
        phone,
        endDateExists,
        endDateEq,
        endDateGt,
        endDateGte,
        endDateIn,
        endDateLt,
        endDateLte,
        endDateNe,
        endDateNin,
        endDate,
        lastWorkDayExists,
        lastWorkDayEq,
        lastWorkDayGt,
        lastWorkDayGte,
        lastWorkDayIn,
        lastWorkDayLt,
        lastWorkDayLte,
        lastWorkDayNe,
        lastWorkDayNin,
        lastWorkDay,
        deletedExternalExists,
        deletedExternalEq,
        deletedExternalGt,
        deletedExternalGte,
        deletedExternalIn,
        deletedExternalLt,
        deletedExternalLte,
        deletedExternalNe,
        deletedExternalNin,
        deletedExternal,
        remoteIdExists,
        remoteIdEq,
        remoteIdGt,
        remoteIdGte,
        remoteIdIn,
        remoteIdLt,
        remoteIdLte,
        remoteIdNe,
        remoteIdNin,
        remoteId,
        dataSourceIdExists,
        dataSourceIdEq,
        dataSourceIdGt,
        dataSourceIdGte,
        dataSourceIdIn,
        dataSourceIdLt,
        dataSourceIdLte,
        dataSourceIdNe,
        dataSourceIdNin,
        dataSourceId,
        xTransactionId,
    }: {
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        email?: (string | null),
        phoneExists?: (boolean | null),
        phoneEq?: (string | null),
        phoneGt?: (string | null),
        phoneGte?: (string | null),
        phoneIn?: (Array<string> | null),
        phoneLt?: (string | null),
        phoneLte?: (string | null),
        phoneNe?: (string | null),
        phoneNin?: (Array<string> | null),
        phone?: (string | null),
        endDateExists?: (boolean | null),
        endDateEq?: (string | null),
        endDateGt?: (string | null),
        endDateGte?: (string | null),
        endDateIn?: (Array<string> | null),
        endDateLt?: (string | null),
        endDateLte?: (string | null),
        endDateNe?: (string | null),
        endDateNin?: (Array<string> | null),
        endDate?: (string | null),
        lastWorkDayExists?: (boolean | null),
        lastWorkDayEq?: (string | null),
        lastWorkDayGt?: (string | null),
        lastWorkDayGte?: (string | null),
        lastWorkDayIn?: (Array<string> | null),
        lastWorkDayLt?: (string | null),
        lastWorkDayLte?: (string | null),
        lastWorkDayNe?: (string | null),
        lastWorkDayNin?: (Array<string> | null),
        lastWorkDay?: (string | null),
        deletedExternalExists?: (boolean | null),
        deletedExternalEq?: (boolean | null),
        deletedExternalGt?: (boolean | null),
        deletedExternalGte?: (boolean | null),
        deletedExternalIn?: (Array<boolean> | null),
        deletedExternalLt?: (boolean | null),
        deletedExternalLte?: (boolean | null),
        deletedExternalNe?: (boolean | null),
        deletedExternalNin?: (Array<boolean> | null),
        deletedExternal?: (boolean | null),
        remoteIdExists?: (boolean | null),
        remoteIdEq?: (string | null),
        remoteIdGt?: (string | null),
        remoteIdGte?: (string | null),
        remoteIdIn?: (Array<string> | null),
        remoteIdLt?: (string | null),
        remoteIdLte?: (string | null),
        remoteIdNe?: (string | null),
        remoteIdNin?: (Array<string> | null),
        remoteId?: (string | null),
        dataSourceIdExists?: (boolean | null),
        dataSourceIdEq?: (string | null),
        dataSourceIdGt?: (string | null),
        dataSourceIdGte?: (string | null),
        dataSourceIdIn?: (Array<string> | null),
        dataSourceIdLt?: (string | null),
        dataSourceIdLte?: (string | null),
        dataSourceIdNe?: (string | null),
        dataSourceIdNin?: (Array<string> | null),
        dataSourceId?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data_source/employees/count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'email': email,
                'phone.$exists': phoneExists,
                'phone.$eq': phoneEq,
                'phone.$gt': phoneGt,
                'phone.$gte': phoneGte,
                'phone.$in': phoneIn,
                'phone.$lt': phoneLt,
                'phone.$lte': phoneLte,
                'phone.$ne': phoneNe,
                'phone.$nin': phoneNin,
                'phone': phone,
                'end_date.$exists': endDateExists,
                'end_date.$eq': endDateEq,
                'end_date.$gt': endDateGt,
                'end_date.$gte': endDateGte,
                'end_date.$in': endDateIn,
                'end_date.$lt': endDateLt,
                'end_date.$lte': endDateLte,
                'end_date.$ne': endDateNe,
                'end_date.$nin': endDateNin,
                'end_date': endDate,
                'last_work_day.$exists': lastWorkDayExists,
                'last_work_day.$eq': lastWorkDayEq,
                'last_work_day.$gt': lastWorkDayGt,
                'last_work_day.$gte': lastWorkDayGte,
                'last_work_day.$in': lastWorkDayIn,
                'last_work_day.$lt': lastWorkDayLt,
                'last_work_day.$lte': lastWorkDayLte,
                'last_work_day.$ne': lastWorkDayNe,
                'last_work_day.$nin': lastWorkDayNin,
                'last_work_day': lastWorkDay,
                'deleted_external.$exists': deletedExternalExists,
                'deleted_external.$eq': deletedExternalEq,
                'deleted_external.$gt': deletedExternalGt,
                'deleted_external.$gte': deletedExternalGte,
                'deleted_external.$in': deletedExternalIn,
                'deleted_external.$lt': deletedExternalLt,
                'deleted_external.$lte': deletedExternalLte,
                'deleted_external.$ne': deletedExternalNe,
                'deleted_external.$nin': deletedExternalNin,
                'deleted_external': deletedExternal,
                'remote_id.$exists': remoteIdExists,
                'remote_id.$eq': remoteIdEq,
                'remote_id.$gt': remoteIdGt,
                'remote_id.$gte': remoteIdGte,
                'remote_id.$in': remoteIdIn,
                'remote_id.$lt': remoteIdLt,
                'remote_id.$lte': remoteIdLte,
                'remote_id.$ne': remoteIdNe,
                'remote_id.$nin': remoteIdNin,
                'remote_id': remoteId,
                'data_source_id.$exists': dataSourceIdExists,
                'data_source_id.$eq': dataSourceIdEq,
                'data_source_id.$gt': dataSourceIdGt,
                'data_source_id.$gte': dataSourceIdGte,
                'data_source_id.$in': dataSourceIdIn,
                'data_source_id.$lt': dataSourceIdLt,
                'data_source_id.$lte': dataSourceIdLte,
                'data_source_id.$ne': dataSourceIdNe,
                'data_source_id.$nin': dataSourceIdNin,
                'data_source_id': dataSourceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Employee
     * @returns Employee Successful Response
     * @throws ApiError
     */
    public static getEmployeeApiV1DataSourceEmployeesEmployeeIdGet({
        employeeId,
        includeDeleted = false,
        xTransactionId,
    }: {
        employeeId: string,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<Employee> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data_source/employees/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_deleted': includeDeleted,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Data Source Employees
     * @returns Employee Successful Response
     * @throws ApiError
     */
    public static getDataSourceEmployeesApiV1DataSourceDataSourcesDataSourceIdEmployeesGet({
        dataSourceId,
        cached = false,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        email,
        phoneExists,
        phoneEq,
        phoneGt,
        phoneGte,
        phoneIn,
        phoneLt,
        phoneLte,
        phoneNe,
        phoneNin,
        phone,
        endDateExists,
        endDateEq,
        endDateGt,
        endDateGte,
        endDateIn,
        endDateLt,
        endDateLte,
        endDateNe,
        endDateNin,
        endDate,
        lastWorkDayExists,
        lastWorkDayEq,
        lastWorkDayGt,
        lastWorkDayGte,
        lastWorkDayIn,
        lastWorkDayLt,
        lastWorkDayLte,
        lastWorkDayNe,
        lastWorkDayNin,
        lastWorkDay,
        deletedExternalExists,
        deletedExternalEq,
        deletedExternalGt,
        deletedExternalGte,
        deletedExternalIn,
        deletedExternalLt,
        deletedExternalLte,
        deletedExternalNe,
        deletedExternalNin,
        deletedExternal,
        remoteIdExists,
        remoteIdEq,
        remoteIdGt,
        remoteIdGte,
        remoteIdIn,
        remoteIdLt,
        remoteIdLte,
        remoteIdNe,
        remoteIdNin,
        remoteId,
        xTransactionId,
    }: {
        dataSourceId: string,
        cached?: boolean,
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        email?: (string | null),
        phoneExists?: (boolean | null),
        phoneEq?: (string | null),
        phoneGt?: (string | null),
        phoneGte?: (string | null),
        phoneIn?: (Array<string> | null),
        phoneLt?: (string | null),
        phoneLte?: (string | null),
        phoneNe?: (string | null),
        phoneNin?: (Array<string> | null),
        phone?: (string | null),
        endDateExists?: (boolean | null),
        endDateEq?: (string | null),
        endDateGt?: (string | null),
        endDateGte?: (string | null),
        endDateIn?: (Array<string> | null),
        endDateLt?: (string | null),
        endDateLte?: (string | null),
        endDateNe?: (string | null),
        endDateNin?: (Array<string> | null),
        endDate?: (string | null),
        lastWorkDayExists?: (boolean | null),
        lastWorkDayEq?: (string | null),
        lastWorkDayGt?: (string | null),
        lastWorkDayGte?: (string | null),
        lastWorkDayIn?: (Array<string> | null),
        lastWorkDayLt?: (string | null),
        lastWorkDayLte?: (string | null),
        lastWorkDayNe?: (string | null),
        lastWorkDayNin?: (Array<string> | null),
        lastWorkDay?: (string | null),
        deletedExternalExists?: (boolean | null),
        deletedExternalEq?: (boolean | null),
        deletedExternalGt?: (boolean | null),
        deletedExternalGte?: (boolean | null),
        deletedExternalIn?: (Array<boolean> | null),
        deletedExternalLt?: (boolean | null),
        deletedExternalLte?: (boolean | null),
        deletedExternalNe?: (boolean | null),
        deletedExternalNin?: (Array<boolean> | null),
        deletedExternal?: (boolean | null),
        remoteIdExists?: (boolean | null),
        remoteIdEq?: (string | null),
        remoteIdGt?: (string | null),
        remoteIdGte?: (string | null),
        remoteIdIn?: (Array<string> | null),
        remoteIdLt?: (string | null),
        remoteIdLte?: (string | null),
        remoteIdNe?: (string | null),
        remoteIdNin?: (Array<string> | null),
        remoteId?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<Employee>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data_source/data_sources/{data_source_id}/employees',
            path: {
                'data_source_id': dataSourceId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'cached': cached,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'email': email,
                'phone.$exists': phoneExists,
                'phone.$eq': phoneEq,
                'phone.$gt': phoneGt,
                'phone.$gte': phoneGte,
                'phone.$in': phoneIn,
                'phone.$lt': phoneLt,
                'phone.$lte': phoneLte,
                'phone.$ne': phoneNe,
                'phone.$nin': phoneNin,
                'phone': phone,
                'end_date.$exists': endDateExists,
                'end_date.$eq': endDateEq,
                'end_date.$gt': endDateGt,
                'end_date.$gte': endDateGte,
                'end_date.$in': endDateIn,
                'end_date.$lt': endDateLt,
                'end_date.$lte': endDateLte,
                'end_date.$ne': endDateNe,
                'end_date.$nin': endDateNin,
                'end_date': endDate,
                'last_work_day.$exists': lastWorkDayExists,
                'last_work_day.$eq': lastWorkDayEq,
                'last_work_day.$gt': lastWorkDayGt,
                'last_work_day.$gte': lastWorkDayGte,
                'last_work_day.$in': lastWorkDayIn,
                'last_work_day.$lt': lastWorkDayLt,
                'last_work_day.$lte': lastWorkDayLte,
                'last_work_day.$ne': lastWorkDayNe,
                'last_work_day.$nin': lastWorkDayNin,
                'last_work_day': lastWorkDay,
                'deleted_external.$exists': deletedExternalExists,
                'deleted_external.$eq': deletedExternalEq,
                'deleted_external.$gt': deletedExternalGt,
                'deleted_external.$gte': deletedExternalGte,
                'deleted_external.$in': deletedExternalIn,
                'deleted_external.$lt': deletedExternalLt,
                'deleted_external.$lte': deletedExternalLte,
                'deleted_external.$ne': deletedExternalNe,
                'deleted_external.$nin': deletedExternalNin,
                'deleted_external': deletedExternal,
                'remote_id.$exists': remoteIdExists,
                'remote_id.$eq': remoteIdEq,
                'remote_id.$gt': remoteIdGt,
                'remote_id.$gte': remoteIdGte,
                'remote_id.$in': remoteIdIn,
                'remote_id.$lt': remoteIdLt,
                'remote_id.$lte': remoteIdLte,
                'remote_id.$ne': remoteIdNe,
                'remote_id.$nin': remoteIdNin,
                'remote_id': remoteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Data Source Employees
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countDataSourceEmployeesApiV1DataSourceDataSourcesDataSourceIdEmployeesCountGet({
        dataSourceId,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        email,
        phoneExists,
        phoneEq,
        phoneGt,
        phoneGte,
        phoneIn,
        phoneLt,
        phoneLte,
        phoneNe,
        phoneNin,
        phone,
        endDateExists,
        endDateEq,
        endDateGt,
        endDateGte,
        endDateIn,
        endDateLt,
        endDateLte,
        endDateNe,
        endDateNin,
        endDate,
        lastWorkDayExists,
        lastWorkDayEq,
        lastWorkDayGt,
        lastWorkDayGte,
        lastWorkDayIn,
        lastWorkDayLt,
        lastWorkDayLte,
        lastWorkDayNe,
        lastWorkDayNin,
        lastWorkDay,
        deletedExternalExists,
        deletedExternalEq,
        deletedExternalGt,
        deletedExternalGte,
        deletedExternalIn,
        deletedExternalLt,
        deletedExternalLte,
        deletedExternalNe,
        deletedExternalNin,
        deletedExternal,
        remoteIdExists,
        remoteIdEq,
        remoteIdGt,
        remoteIdGte,
        remoteIdIn,
        remoteIdLt,
        remoteIdLte,
        remoteIdNe,
        remoteIdNin,
        remoteId,
        xTransactionId,
    }: {
        dataSourceId: string,
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        email?: (string | null),
        phoneExists?: (boolean | null),
        phoneEq?: (string | null),
        phoneGt?: (string | null),
        phoneGte?: (string | null),
        phoneIn?: (Array<string> | null),
        phoneLt?: (string | null),
        phoneLte?: (string | null),
        phoneNe?: (string | null),
        phoneNin?: (Array<string> | null),
        phone?: (string | null),
        endDateExists?: (boolean | null),
        endDateEq?: (string | null),
        endDateGt?: (string | null),
        endDateGte?: (string | null),
        endDateIn?: (Array<string> | null),
        endDateLt?: (string | null),
        endDateLte?: (string | null),
        endDateNe?: (string | null),
        endDateNin?: (Array<string> | null),
        endDate?: (string | null),
        lastWorkDayExists?: (boolean | null),
        lastWorkDayEq?: (string | null),
        lastWorkDayGt?: (string | null),
        lastWorkDayGte?: (string | null),
        lastWorkDayIn?: (Array<string> | null),
        lastWorkDayLt?: (string | null),
        lastWorkDayLte?: (string | null),
        lastWorkDayNe?: (string | null),
        lastWorkDayNin?: (Array<string> | null),
        lastWorkDay?: (string | null),
        deletedExternalExists?: (boolean | null),
        deletedExternalEq?: (boolean | null),
        deletedExternalGt?: (boolean | null),
        deletedExternalGte?: (boolean | null),
        deletedExternalIn?: (Array<boolean> | null),
        deletedExternalLt?: (boolean | null),
        deletedExternalLte?: (boolean | null),
        deletedExternalNe?: (boolean | null),
        deletedExternalNin?: (Array<boolean> | null),
        deletedExternal?: (boolean | null),
        remoteIdExists?: (boolean | null),
        remoteIdEq?: (string | null),
        remoteIdGt?: (string | null),
        remoteIdGte?: (string | null),
        remoteIdIn?: (Array<string> | null),
        remoteIdLt?: (string | null),
        remoteIdLte?: (string | null),
        remoteIdNe?: (string | null),
        remoteIdNin?: (Array<string> | null),
        remoteId?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data_source/data_sources/{data_source_id}/employees/count',
            path: {
                'data_source_id': dataSourceId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'email': email,
                'phone.$exists': phoneExists,
                'phone.$eq': phoneEq,
                'phone.$gt': phoneGt,
                'phone.$gte': phoneGte,
                'phone.$in': phoneIn,
                'phone.$lt': phoneLt,
                'phone.$lte': phoneLte,
                'phone.$ne': phoneNe,
                'phone.$nin': phoneNin,
                'phone': phone,
                'end_date.$exists': endDateExists,
                'end_date.$eq': endDateEq,
                'end_date.$gt': endDateGt,
                'end_date.$gte': endDateGte,
                'end_date.$in': endDateIn,
                'end_date.$lt': endDateLt,
                'end_date.$lte': endDateLte,
                'end_date.$ne': endDateNe,
                'end_date.$nin': endDateNin,
                'end_date': endDate,
                'last_work_day.$exists': lastWorkDayExists,
                'last_work_day.$eq': lastWorkDayEq,
                'last_work_day.$gt': lastWorkDayGt,
                'last_work_day.$gte': lastWorkDayGte,
                'last_work_day.$in': lastWorkDayIn,
                'last_work_day.$lt': lastWorkDayLt,
                'last_work_day.$lte': lastWorkDayLte,
                'last_work_day.$ne': lastWorkDayNe,
                'last_work_day.$nin': lastWorkDayNin,
                'last_work_day': lastWorkDay,
                'deleted_external.$exists': deletedExternalExists,
                'deleted_external.$eq': deletedExternalEq,
                'deleted_external.$gt': deletedExternalGt,
                'deleted_external.$gte': deletedExternalGte,
                'deleted_external.$in': deletedExternalIn,
                'deleted_external.$lt': deletedExternalLt,
                'deleted_external.$lte': deletedExternalLte,
                'deleted_external.$ne': deletedExternalNe,
                'deleted_external.$nin': deletedExternalNin,
                'deleted_external': deletedExternal,
                'remote_id.$exists': remoteIdExists,
                'remote_id.$eq': remoteIdEq,
                'remote_id.$gt': remoteIdGt,
                'remote_id.$gte': remoteIdGte,
                'remote_id.$in': remoteIdIn,
                'remote_id.$lt': remoteIdLt,
                'remote_id.$lte': remoteIdLte,
                'remote_id.$ne': remoteIdNe,
                'remote_id.$nin': remoteIdNin,
                'remote_id': remoteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
