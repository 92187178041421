<template>
  <ExportDialog v-model:visibility="visibilityExportDialog" />

  <div class="home">
    <!-- Page Content -->
    <ViewHeader
      v-if="licenseTable?.totalLicensesCount === licenseTable?.filteredCount"
      :title="
        i18n.t('account', { count: 2 }) +
        ` (${licenseTable?.totalLicensesCount})`
      " />

    <ViewHeader
      v-else
      :title="
        i18n.t('account', { count: 2 }) +
        ` (${licenseTable?.filteredCount}/${licenseTable?.totalLicensesCount})`
      " />
    <div
      class="table-controls-container"
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
      ">
      <span style="font-size: 15px; font-weight: bold; text-align: center">
      </span>
      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;
        ">
        <!-- Filter -->
        <SmDropdown
          :close-on-click-inside="false"
          dropdown-class="w-[42rem] max-w-7xl">
          <template #trigger>
            <SmButton outline size="small">
              <div>
                <v-icon name="md-filteralt-round" />
                {{ i18n.t('filter') }}
              </div>
            </SmButton>
          </template>

          <div style="padding: 1rem">
            <LicenceFilterDialog
              v-model:filter="filter"
              :software-licenses="licenseTable?.softwareLicenses || []"
              :close-on-click-inside="false" />
          </div>
        </SmDropdown>

        <SmTooltip
          :content="i18n.t('views.licences.header.exportButtonTooltipExtra')">
          <SmButton outline @click="getAndDownloadLicensesCSV">
            <v-icon
              name="md-arrowdownward-round"
              class="export-icon"
              scale="1" />
            <span style="margin-left: 0.5rem"> {{ i18n.t('csv') }} </span>
          </SmButton>
        </SmTooltip>
        <SmInput
          v-model="searchQuery"
          outline
          style="width: 300px"
          size="medium"
          :label="i18n.t('searchEmail')" />
      </div>
    </div>
    <el-card
      shadow="always"
      class="grow overflow-auto"
      :body-style="{
        height: '100%',
        width: '100%',
      }">
      <LicenseTable
        ref="licenseTable"
        :filter="filter"
        display-software-name
        display-status
        display-price />
    </el-card>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import ExportDialog from '@/components/ExportDialog.vue'
  import LicenseTable from '@/components/LicenseTable.vue'
  import ViewHeader from '@/components/ViewHeader.vue'
  import SmTooltip from '@/components/sm/SmTooltip.vue'
  import { AccountFilters } from '@/stores/account.filter'

  const licenseTable = ref<InstanceType<typeof LicenseTable>>()

  const i18n = useI18n()

  const searchQuery = ref<string>('')

  const visibilityExportDialog = ref<boolean>(false)

  function getAndDownloadLicensesCSV() {
    visibilityExportDialog.value = true
  }

  // Filter
  const filter = ref<AccountFilters>({})

  watch(searchQuery, () => {
    filter.value.email = searchQuery.value
  })
</script>

<style lang="scss" scoped>
  .home {
    display: flex;
    flex-direction: column;
  }
</style>
