<template>
  <OptimizationDialogEmployee
    v-if="isEmployeeOptimization && dataSource && employee"
    v-model:visibility="dialogEmployeeVisibility"
    :optimization="props.optimization"
    :datasource="dataSource"
    :employee="employee" />

  <!-- Content -->
  <div class="element-wrapper">
    <!-- Icon  -->
    <div class="icon-wrapper" @click="handleOptimizationClicked">
      <SmSkeleton v-if="loading" height="100%" width="100%" />
      <ApplicationIcon
        :software-name="software?.software_name || dataSource?.name!"
        class="shrink-0"
        size="2.1rem"
        :software-type="software?.type" />
    </div>

    <!-- Optimization Information  -->
    <div class="text-wrapper" @click="handleOptimizationClicked">
      <!-- Header -->
      <div class="software-name">
        <SmSkeleton v-if="loading" :min-width="20" :max-width="30" />
        <span v-else-if="isEmployeeOptimization">{{ dataSource?.name }}</span>
        <span v-else>{{ softwareDisplayName }}</span>
      </div>

      <!-- Text -->
      <span class="sub-title">
        <SmSkeleton v-if="loading" :min-width="70" :max-width="90" />
        <OptimizationEmployeeText
          v-else-if="isEmployeeOptimization && dataSource && employee"
          :optimization="optimization"
          :datasource="dataSource"
          :employee="employee" />
        <OptimizationDisplayText
          v-else-if="!isEmployeeOptimization && software"
          show-user-email
          :optimization="optimization"
          :software="software" />
      </span>
    </div>

    <!-- Menu -->
    <SmDropdown trigger="click" style="padding-right: 10px">
      <SmDropdownItem
        icon="md-bolt-round"
        @click="() => emit('optimizationClicked', $props.optimization)">
        {{ i18n.t('moreInformation') }}
      </SmDropdownItem>

      <SmDropdownItem
        v-if="!isEmployeeOptimization"
        icon="md-gridview-round"
        @click="router.push('/applications/' + optimization.software_id)">
        {{ i18n.t('showApplication') }}
      </SmDropdownItem>
    </SmDropdown>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  import {
    DataSource,
    DataSourcesService,
    Employee,
    EmployeesService,
    Optimization,
  } from '@/client'
  import OptimizationDisplayText from '@/components/OptimizationDisplayText.vue'

  import SmSkeleton from './sm/SmSkeleton.vue'
  import { ApplicationStore } from '@/stores/application.store'

  const router = useRouter()
  const i18n = useI18n()

  const emit = defineEmits<{
    (e: 'optimizationClicked', optimization: Optimization): void
  }>()

  const props = defineProps({
    currentIndex: {
      type: Number,
      required: true,
    },
    optimization: {
      type: Object as PropType<Optimization>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  })

  const isEmployeeOptimization = computed(() => {
    if (props.optimization?.employee_id) return true
    return false
  })

  watch(
    () => props.optimization,
    (newOptimization) => {
      if (newOptimization.employee_id && newOptimization.data_source_id) {
        getEmployee()
        getDataSource()
      }
    },
    { immediate: true }
  )

  // ############################
  // #  Software Optimization  #
  // ############################

  const softwareId = computed(() => props.optimization.software_id)
  const software = ApplicationStore.useApplicationByID(softwareId).application

  const softwareDisplayName = computed(() => {
    return software.value?.display_name || software.value?.software_name
  })

  function handleOptimizationClicked() {
    // Don`t emit if loading
    if (props.loading) return
    if (isEmployeeOptimization.value) {
      dialogEmployeeVisibility.value = true
      return
    }

    emit('optimizationClicked', props.optimization)
  }

  // ############################
  // #  Employee Optimization  #
  // ############################

  const employee = ref<Employee>()
  const dataSource = ref<DataSource>()
  const dialogEmployeeVisibility = ref(false)

  function getEmployee() {
    if (props.optimization?.employee_id && props.optimization?.data_source_id) {
      EmployeesService.getDataSourceEmployeesApiV1DataSourceDataSourcesDataSourceIdEmployeesGet(
        {
          dataSourceId: props.optimization.data_source_id,
          idEq: props.optimization.employee_id,
        }
      ).then((res) => {
        employee.value = res[0]
      })
    }
  }

  function getDataSource() {
    if (props.optimization?.data_source_id) {
      DataSourcesService.getDataSourceApiV1DataSourceDataSourcesDataSourceIdGet(
        {
          dataSourceId: props.optimization.data_source_id,
        }
      ).then((res) => {
        dataSource.value = res
      })
    }
  }
</script>

<style lang="scss" scoped>
  .element-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    float: left;
    margin: 5px 0px;
    height: 52px;
    width: 100%;

    .icon-wrapper {
      display: flex;
      height: 30px;
      width: 30px;
      cursor: pointer;
    }

    .company-img {
      display: flex;
      place-items: center;
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      flex: 1;
      height: fit-content;
      cursor: pointer;

      .software-name {
        text-align: left;
        line-height: 1;
        font-size: var(--font-size-smaller);
        font-weight: bolder;
        margin-bottom: 4px;
      }

      .sub-title {
        text-align: left;
        color: var(--el-text-color-secondary);
        font-size: var(--font-size-smaller);
        line-height: 1.3;
      }
    }
  }
</style>
