<template>
  <ApplicationDetailSelectUserTabSaveDialog
    v-model:visibility="licencesSaveDialogVisibility"
    :delete-licences="props.selection"
    :save-licences="[]"
    :selected-sub-account-id="
      applicationDetailsStore.$state.selectedSubAccount?.id
    "
    @refetch-licences="refetchLicenses" />
  <div
    class="flex w-full items-center justify-end gap-2 pl-4 font-bold text-primary">
    <div class="mr-auto">
      <TextMultiline>
        {{
          i18n.t('XofY', { x: props.selection?.length, y: props.totalAccounts })
        }}
        {{ i18n.t('accounts', { count: props.totalAccounts }) }}
      </TextMultiline>
    </div>

    <!-- Add Licenses -->
    <SmDropdown
      v-if="permissions?.implements_update_method"
      :close-on-click-inside="false"
      dropdown-class="w-[42rem] max-w-7xl ml-auto">
      <template #trigger>
        <SmButton
          outline
          size="smaller"
          class="justify-center px-3 hover:text-white">
          <v-icon name="hi-solid-plus" scale="0.8" />
          <span class="button-text ml-2">{{ i18n.t('addLicenses') }}</span>
        </SmButton>
      </template>

      <div style="padding: 1rem">
        <p class="mb-2">{{ i18n.t('addLicenses') }}</p>

        <SmSelect
          :options="selectOptions"
          :label="i18n.t('selectLicenseModel')"
          :selection="[]"
          searchable
          :multiselect="true"
          class="mb-4 w-full"
          @change="
            (value) => {
              if (value) currentSelection = value
            }
          " />

        <SmButton
          type="secondary"
          size="small"
          class="mb-4 justify-center"
          :loading="loadingAddLicense"
          @click="handleSoftwareLicenseAdd(currentSelection)">
          {{ i18n.t('general.add') }}
        </SmButton>
      </div>
    </SmDropdown>

    <!-- Remove Licenses -->
    <SmDropdown
      v-if="permissions?.implements_update_method"
      :close-on-click-inside="false"
      dropdown-class="w-[42rem] max-w-7xl">
      <template #trigger>
        <SmButton outline class="px-3" size="smaller">
          <v-icon name="hi-solid-minus" scale="0.8" />
          <span class="button-text ml-2">{{ i18n.t('removeLicenses') }}</span>
        </SmButton>
      </template>

      <div style="padding: 1rem">
        <p class="mb-2">{{ i18n.t('removeLicenses') }}</p>
        <SmSelect
          :options="selectOptions"
          :label="i18n.t('selectLicenseModel')"
          :selection="[]"
          searchable
          :multiselect="true"
          class="mb-4 w-full"
          @change="
            (value) => {
              if (value) currentSelection = value
            }
          " />

        <SmButton
          type="secondary"
          size="small"
          class="mb-4 justify-center"
          :loading="loadingRemoveLicense"
          @click="handleSoftwareLicenseRemove(currentSelection)">
          {{ i18n.t('general.remove') }}
        </SmButton>
      </div>
    </SmDropdown>

    <!-- Delete account -->
    <SmButton
      v-if="permissions?.implements_delete_user_method"
      type="danger"
      size="smaller"
      class="justify-center px-3"
      @click="licencesSaveDialogVisibility = true">
      <v-icon name="md-delete-round" class="fill-white" scale="0.8" />
      <span class="button-text ml-2">{{
        i18n.t(
          'views.applicationDetails.licencesCard.bulkSelectButton.deleteButton.text',
          { count: props.selection.length }
        )
      }}</span>
    </SmButton>
  </div>
</template>

<script lang="ts" setup>
  import {
    LicenceOut,
    SoftwareInformation,
    SoftwareLicense,
    SoftwareLicenseMini,
    SoftwareLicenseMiniUpdate,
  } from '@/client'
  import ApplicationDetailSelectUserTabSaveDialog from '../views/ApplicationsDetail/components/ApplicationDetailDrawer/tabs/ApplicationDetailSelectUserTab/ApplicationDetailSelectUserTabSaveDialog.vue'
  import { useApplicationDetailsStore } from '@/views/ApplicationsDetail/ApplicationDetailsView.store'
  import { useI18n } from 'vue-i18n'
  import { editToast, sendToast } from './sm/SmNotification'
  import { Option } from '@/components/sm/SmSelect.vue'
  import { AccountStore } from '@/stores/account.store'

  const i18n = useI18n()
  const applicationDetailsStore = useApplicationDetailsStore()

  const emit = defineEmits<{
    requestLicencesRefetch: []
    resetSelection: []
    updateCount: []
  }>()

  const props = defineProps<{
    selection: LicenceOut[] // Selected accounts
    availableLicenses: SoftwareLicense[]
    totalAccounts: number
    permissions: SoftwareInformation | undefined
  }>()

  const licencesSaveDialogVisibility = ref<boolean>(false)
  const currentSelection = ref<Option<string>[]>([])
  const selectOptions = props.availableLicenses.map((license) => {
    return { value: license._id, label: license.name }
  })
  const loadingAddLicense = ref<boolean>(false)
  const loadingRemoveLicense = ref<boolean>(false)
  /**
   *  Functions
   */
  const refetchLicenses = () => {
    for (const key in props.selection) {
      const updateQuotaObject: Record<string, SoftwareLicenseMini | null> = {}

      const software_licenses = props.selection[key].software_licenses

      for (const software_license in software_licenses) {
        updateQuotaObject[software_license] = null
      }

      applicationDetailsStore.updateQuota(updateQuotaObject)
    }

    emit('requestLicencesRefetch')
    emit('resetSelection')
    emit('updateCount')

    applicationDetailsStore.$state.licencesTable.selectedLicences = []
    licencesSaveDialogVisibility.value = false
  }

  async function handleSoftwareLicenseRemove(
    selectionToRemove: Option<string>[]
  ) {
    const promises: Promise<unknown>[] = []

    if (selectionToRemove.length == 0) return

    loadingRemoveLicense.value = true

    const software_licenses = ref<Record<string, null>>({})
    const updateQuotaObject: Record<string, SoftwareLicenseMini | null> = {}

    for (const licence of props.selection) {
      // get licenseModelMini of selection
      for (const licenseModel in licence.software_licenses) {
        // Check if the license model's _id is in the selectionToRemove
        if (selectionToRemove.some((item) => item.value === licenseModel)) {
          software_licenses.value[licenseModel] = null
        }
      }
      const promise = AccountStore.updateAccount(licence._id, {
        software_licenses: software_licenses.value,
      })
      promises.push(promise)
    }

    await handlePromise(promises, updateQuotaObject)
    loadingRemoveLicense.value = false
  }

  async function handleSoftwareLicenseAdd(selectionToAdd: Option<string>[]) {
    const promises: Promise<unknown>[] = []

    if (selectionToAdd.length == 0) return

    loadingAddLicense.value = true

    const software_licenses = ref<Record<string, SoftwareLicenseMiniUpdate>>({})
    const updateQuotaObject: Record<string, SoftwareLicenseMini | null> = {}

    for (const licence of props.selection) {
      // get licenseModelMini of selection
      for (const licenseModel of selectionToAdd) {
        const licenseModelMini = props.availableLicenses.find(
          (softwareLicense) => softwareLicense._id === licenseModel.value
        )!
        software_licenses.value[licenseModel.value] = licenseModelMini
        updateQuotaObject[licenseModel.value] = licenseModelMini
      }

      const promise = AccountStore.updateAccount(licence._id!, {
        software_licenses: software_licenses.value,
      })

      promises.push(promise)
    }

    await handlePromise(promises, updateQuotaObject)
    loadingAddLicense.value = false
  }

  async function handlePromise(
    promises: Promise<unknown>[],
    newQuotaObject: Record<string, SoftwareLicenseMini | null> = {}
  ) {
    const toastId = ref<symbol | null>(null)

    toastId.value = sendToast(
      i18n.t('notification.loading'),
      i18n.t('pleaseDoNotReload'),
      'loading'
    )

    await Promise.all(promises)
      .then(() => {
        emit('requestLicencesRefetch')
        emit('resetSelection')

        applicationDetailsStore.updateQuota(newQuotaObject)

        if (toastId.value) {
          editToast(toastId.value, {
            title: i18n.t(
              'notifications.applicationsDetailView.licence.bulkLicenceModelChange.title'
            ),
            message: i18n.t(
              'notifications.applicationsDetailView.licence.bulkLicenceModelChange.message'
            ),
            type: 'success',
          })
        }
      })
      .catch(() => {
        if (toastId.value) {
          editToast(toastId.value, {
            title: i18n.t(
              'notifications.applicationsDetailView.licence.bulkLicenceModelChangeFailed.title'
            ),
            message: i18n.t(
              'notifications.applicationsDetailView.licence.bulkLicenceModelChangeFailed.message'
            ),
            type: 'error',
          })
        }
      })
  }
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 1800px) {
    .button-text {
      display: none;
    }
  }
</style>
