<template>
  <el-form
    v-model="jobForm.fields"
    :model="jobForm.fields"
    label-width="150px"
    label-position="top"
    require-asterisk-position="right">
    <!-- Account -->
    <el-form-item
      v-if="accountSelection || loadingAccountSelection"
      :label="i18n.t('user')"
      prop="account"
      :error="jobForm.getErrorMessage('account')">
      <SmSelect
        v-model:selection="jobForm.fields.account"
        :options="accountSelection"
        :label="i18n.t('selectUser')"
        :item-size="66"
        :loading="loadingAccountSelection"
        class="w-full"
        searchable>
        <template #item="{ item }">
          <div class="flex flex-col leading-6">
            <span>{{ item.value.name }}</span>
            <span class="text-contrast-muted">{{ item.value.email }}</span>
          </div>
        </template>
      </SmSelect>
    </el-form-item>

    <!-- Software -->
    <el-form-item
      v-if="softwareSelection || loadingSoftwareSelection"
      :label="i18n.t('software')"
      prop="software"
      :error="jobForm.getErrorMessage('software')">
      <SmSelect
        v-model:selection="jobForm.fields.software"
        :options="softwareSelection"
        :label="i18n.t('selectSoftware')"
        :loading="loadingSoftwareSelection"
        class="w-full"
        searchable
        @blur="jobForm.validate(['sub_account'])">
        <template #item="{ item }">
          <TextMultiline>
            {{ item.value.display_name }}
            <span v-if="item.value.information.subtitle">
              ({{ item.valiue.information.subtitle }})</span
            >
          </TextMultiline>
        </template>
      </SmSelect>
    </el-form-item>

    <!-- Subaccount -->
    <el-form-item
      v-if="
        (subaccountSelection && subaccountSelection?.length > 1) ||
        loadingSubaccountSelection
      "
      :label="i18n.t('subaccount')"
      prop="sub_account"
      :error="jobForm.getErrorMessage('sub_account')">
      <SmSelect
        v-model:selection="jobForm.fields.sub_account"
        :options="subaccountSelection"
        :label="i18n.t('selectSubaccount')"
        :loading="loadingSubaccountSelection"
        class="w-full"
        searchable
        @blur="jobForm.validate(['sub_account'])" />
    </el-form-item>

    <!-- Multiple Licensemodels -->
    <el-form-item
      v-if="
        (softwarelicenseSelection && softwarelicenseSelection?.length > 0) ||
        loadingSoftwarelicenseSelection
      "
      :label="i18n.t('licensemodel', { count: 2 }) + ' ' + i18n.t('optional')"
      prop="software_licenses"
      :error="jobForm.getErrorMessage('software_licenses')">
      <SmSelect
        v-model:selection="jobForm.fields.software_licenses"
        :options="softwarelicenseSelection"
        :multiselect="true"
        :label="i18n.t('selectLicenseModels')"
        :loading="loadingSoftwarelicenseSelection"
        class="w-full"
        searchable
        @blur="jobForm.validate(['software_licenses'])" />
    </el-form-item>

    <div class="flex gap-4">
      <!-- Date -->
      <el-form-item class="w-1/2">
        <template #label>
          <div class="flex gap-2">
            {{ i18n.t('jobExecutionInput.label') }}
            <SmTooltip>
              <template #content>
                {{ i18n.t('jobExecutionInput.tooltip') }}
              </template>
              <v-icon
                name="md-help-round"
                scale="0.9"
                class="fill-contrast-muted" />
            </SmTooltip>
          </div>
        </template>
        <SmDatepicker
          v-model:date="jobForm.fields.enqueue_date"
          class="w-full" />
      </el-form-item>

      <el-form-item
        :label="i18n.t('time')"
        class="w-1/2"
        :error="jobForm.getErrorMessage('enqueue_in')">
        <SmTimeInput
          v-model:time="jobForm.fields.enqueue_time"
          :disabled="!jobForm.fields.enqueue_date"
          :placeholder="
            !jobForm.fields.enqueue_date
              ? i18n.t('pleaseSelectDate')
              : undefined
          "
          class="w-full" />
      </el-form-item>
    </div>
  </el-form>
</template>

<script setup lang="ts">
  import {
    Account,
    BackgroundJobSoftwareUserAddData,
    BackgroundJobType,
    SoftwareOut,
  } from '@/client'
  import useForm from '@/common/form'
  import { useI18n } from 'vue-i18n'
  import { isDateAfterNow, isRequired } from './sm/SmInput/SmInputValidator'
  import { Option } from './sm/SmSelect.vue'
  import TextMultiline from './sm/TextMultiline.vue'
  import { getSoftwareLicenses } from '@/common/license'
  import { Request } from './TaskEditDialog.vue'
  import dayjs from 'dayjs'
  import { backgroundjobStore } from '@/stores/backgroundjob.store'
  import { ApplicationStore } from '@/stores/application.store'
  import { PersonStore } from '@/stores/person.store'

  const i18n = useI18n()
  const fallBackTime = '0600'
  const tomorrowDate = dayjs().add(1, 'day').toDate()

  /**
   * Form
   */
  const jobForm = useForm(
    {
      type: BackgroundJobType.SOFTWARE_USER_ADD,
      account: undefined,
      software: undefined,
      sub_account: undefined,
      software_licenses: undefined,
      enqueue_in: tomorrowDate as Date | null,
      enqueue_date: tomorrowDate,
      enqueue_time: fallBackTime,
    },
    // Form Hooks
    {
      onReset: () => {},
    },

    // Form Validators
    {
      account: [isRequired],
      software: [isRequired],
      enqueue_in: [isDateAfterNow(600, true)],
    },
    {}
  )

  const selectedDateTime = computed(() => {
    if (!jobForm.fields.enqueue_date) return null

    const date = dayjs(jobForm.fields.enqueue_date)
    const time = jobForm.fields.enqueue_time || fallBackTime

    const dateTime = date
      .hour(parseInt(time.slice(0, 2)))
      .minute(parseInt(time.slice(2, 4)))

    return dateTime.toDate()
  })

  watch(
    selectedDateTime,
    (val) => {
      jobForm.fields.enqueue_in = val
    },
    { immediate: true }
  )

  function createRequestBody(): Request<BackgroundJobSoftwareUserAddData> {
    const formFields = JSON.parse(JSON.stringify(toRaw(jobForm).fields))
    const requestBody: Request<BackgroundJobSoftwareUserAddData> = {
      type: BackgroundJobType.SOFTWARE_USER_ADD,
      data: {} as BackgroundJobSoftwareUserAddData,
      enqueue_in: 0,
    }

    // Extract ids from objects
    if (formFields.account) {
      requestBody.data.account_id = formFields.account[0].value._id
    }

    if (formFields.software && typeof formFields.software === 'object') {
      requestBody.data.software_id = formFields.software[0].value._id
    }

    // Handle subaccount logic
    if (!formFields.sub_account && 'sub_account' in jobForm.fields) {
      formFields.sub_account = subaccountSelection.value
    }

    if (formFields.sub_account && formFields.sub_account[0]) {
      requestBody.data.sub_account_id = formFields.sub_account[0].value
    } else {
      requestBody.data.sub_account_id = 'main' // Fallback
    }

    // Extract software license ids
    if (formFields.software_licenses && formFields.software_licenses.length) {
      requestBody.data.software_license_ids = formFields.software_licenses.map(
        (license: Option<string>) => license.value
      )
    }

    // Convert dateTime to seconds
    if (selectedDateTime.value) {
      requestBody.enqueue_in = dayjs(selectedDateTime.value).diff(dayjs(), 's')
    }

    return requestBody
  }

  function submit() {
    if (!jobForm?.dirty) if (!jobForm.validate()) return

    const requestBody = createRequestBody()
    const { type, enqueue_in, data } = requestBody

    return backgroundjobStore
      .createBackgroundjob(type, data, enqueue_in)
      .then((response) => {
        return response
      })
  }

  function resetForm() {
    jobForm?.reset()
  }

  function validateForm() {
    return jobForm?.validate()
  }
  /**
   * Selection Data
   */

  const accountSelection = ref<Option<Account>[] | undefined>([])
  const loadingAccountSelection = ref<boolean>(false)
  const softwareSelection = ref<Option<SoftwareOut>[] | undefined>([])
  const loadingSoftwareSelection = ref<boolean>(false)
  const subaccountSelection = ref<Option<string>[]>()
  const loadingSubaccountSelection = ref<boolean>(false)
  const softwarelicenseSelection = ref<Option<string>[]>()
  const loadingSoftwarelicenseSelection = ref<boolean>(false)

  async function loadAccountSelection() {
    loadingAccountSelection.value = true

    const persons = await PersonStore.getPersons()

    accountSelection.value = persons.map((account) => ({
      value: account,
      label: account.name as string,
      key: account._id,
    }))

    loadingAccountSelection.value = false
  }

  async function loadSoftwareSelection() {
    loadingSoftwareSelection.value = true

    const allSoftwares = await ApplicationStore.getApplications()
    softwareSelection.value = allSoftwares?.map((software) => ({
      value: software,
      label: software.display_name,
      key: software._id,
    }))

    loadingSoftwareSelection.value = false
  }

  async function loadSubaccountSelection(software: Option<SoftwareOut>) {
    const allSubaccounts = software?.value.sub_accounts
    if (!allSubaccounts) return

    loadingSubaccountSelection.value = true

    if (allSubaccounts) {
      subaccountSelection.value = Object.values(allSubaccounts).map(
        (subaccount) => ({
          value: subaccount.id,
          label: subaccount.name,
        })
      )
    }

    loadingSubaccountSelection.value = false
  }

  async function loadSoftwareLicenseSelection(software: Option<SoftwareOut>) {
    loadingSoftwarelicenseSelection.value = true
    const allSoftwareLicenses = await getSoftwareLicenses(software?.value._id)
    softwarelicenseSelection.value = Object.values(allSoftwareLicenses).map(
      (softwareLicense) => ({
        value: softwareLicense.license._id,
        label: softwareLicense.license.name,
      })
    )

    loadingSoftwarelicenseSelection.value = false
  }

  /**
   * Watch functions
   */

  watch(
    () => jobForm.fields.software,
    (software) => {
      if (software && software[0]) {
        // load selections depending on the selected software
        loadSubaccountSelection(software[0] as Option<SoftwareOut>)
        loadSoftwareLicenseSelection(software[0] as Option<SoftwareOut>)
      } else {
        // reset selection when software is not selected
        subaccountSelection.value = undefined
        softwarelicenseSelection.value = undefined

        if (jobForm.fields.sub_account) jobForm.fields.sub_account = undefined
        if (jobForm.fields.software_licenses)
          jobForm.fields.software_licenses = undefined
      }
    },
    { immediate: true, deep: true }
  )

  onMounted(() => {
    loadAccountSelection()
    loadSoftwareSelection()
  })

  defineExpose({ submit, resetForm, validateForm, createRequestBody })
</script>

<style scoped></style>
