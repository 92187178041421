import { NotificationService } from '@/client'
import { useFetch } from '@/common/useFetch'
import { Notification, Novu } from '@novu/js'
import { useSessionStore } from './sessionStore'

let novu: Novu

const inbox = ref<Notification[]>([])
const archive = ref<Notification[]>([])
const unreadCount = ref<number>(0)

const hasMoreInbox = ref(true)
const hasMoreArchive = ref(true)

const defaultLimit = 10

/**
 * Init
 **/

async function _initNovu() {
  if (novu) return novu

  const sessionStore = useSessionStore()

  const company_id = sessionStore.currentCompany?.company_id

  if (!company_id) return

  // get subscriber information
  const info = await _getSubscriberInformation(company_id)

  // init novu instace
  const newNovuInstance = new Novu({
    subscriberId: info.data?.subscriber_id ?? '',
    applicationIdentifier: info.data?.application_identifier ?? '',
    subscriberHash: info.data?.subscriber_hash ?? '',
    backendUrl: 'https://eu.api.novu.co',
    socketUrl: 'https://eu.ws.novu.co',
    useCache: false,
  })

  novu = newNovuInstance

  // initially load unreadCount
  novu.notifications
    .count({
      read: false,
      archived: false,
    })
    .then((response) => {
      unreadCount.value = response.data?.count || 0
    })

  return novu
}

async function _getSubscriberInformation(company_id: string) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      NotificationService.getNovuSubscriberInformationNovuSubscriberInformationGet(
        { companyId: company_id }
      ),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

/**
 * Fetch Data
 **/

// Utility function to reload notifications and unread count
async function loadNotifications(archived = false) {
  await _initNovu()

  const response = await novu.notifications.list({
    limit: defaultLimit,
    archived,
  })

  if (archived) {
    hasMoreArchive.value = response?.data?.hasMore || false
    return (archive.value = response?.data?.notifications || [])
  } else if (!archived) {
    hasMoreInbox.value = response?.data?.hasMore || false
    return (inbox.value = response?.data?.notifications || [])
  }
}

async function loadMoreNotifications(archived = false) {
  await _initNovu()

  if (archived && !hasMoreArchive.value) return
  if (!archived && !hasMoreInbox.value) return

  const offset = archived ? archive.value.length : inbox.value.length

  const response = await novu.notifications.list({
    limit: defaultLimit,
    archived,
    offset: offset,
  })

  if (response.data?.notifications.length) {
    if (archived) {
      hasMoreArchive.value = response?.data?.hasMore || false
      archive.value = archive.value.concat(response?.data?.notifications || [])
    } else {
      hasMoreInbox.value = response?.data?.hasMore || false
      inbox.value = inbox.value.concat(response?.data?.notifications || [])
    }
  }
}

// Subscribe to real-time notifications
async function subscribeNotifications() {
  await _initNovu()
  novu.on('notifications.notification_received', async (data) => {
    inbox.value.unshift(data.result)
    unreadCount.value++
  })
}

/**
 * Actions
 **/

// Mark all as read
async function readAll() {
  await _initNovu()

  novu.notifications.readAll()
  inbox.value = inbox.value.map((notification) => ({
    ...notification,
    isRead: true,
  }))
  unreadCount.value = 0
}

async function readNotification(notificationId: string) {
  await _initNovu()

  novu.notifications.read({ notificationId })

  inbox.value = inbox.value.map((notification) =>
    notification.id === notificationId
      ? { ...notification, isRead: true }
      : notification
  )
  unreadCount.value--
}

// Archive a single notification
async function archiveNotification(notificationId: string) {
  await _initNovu()

  readNotification(notificationId)
  novu.notifications.archive({ notificationId })

  const notificationIndex = inbox.value.findIndex(
    (n) => n.id === notificationId
  )

  if (notificationIndex !== -1) {
    const archivedNotification = {
      ...inbox.value[notificationIndex],
      isArchived: true,
    }

    inbox.value.splice(notificationIndex, 1)
    archive.value.unshift(archivedNotification)
  }

  if (inbox.value.length < 10 && hasMoreInbox.value) {
    await loadMoreNotifications(false)
  }
}

/**
 * Reset
 **/

function resetNovuInstance() {
  novu = null
}

export const notificationStore = {
  inbox,
  archive,
  unreadCount,
  hasMoreInbox,
  hasMoreArchive,
  loadNotifications,
  loadMoreNotifications,
  subscribeNotifications,
  readAll,
  readNotification,
  archiveNotification,
  resetNovuInstance,
}
