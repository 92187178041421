<!--
  MarkdownContent is a component that renders markdown content.

  The component is currently used to render the manual for a specific SaaS app.
  The manual is a markdown string is provided as a prop.
  The markdown string is converted to HTML using showdown.
  The HTML is rendered using v-html.
 -->

<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div v-if="htmlParsed" class="markdown-wrapper" v-html="htmlParsed" />
</template>

<script setup lang="ts">
  import showdown from 'showdown'

  const converter = new showdown.Converter()
  const htmlParsed = ref<string | null>(null)

  const headline = ref<string | null>(null)

  const props = defineProps({
    markdown: {
      type: String,
      required: true,
    },
    baseUrl: {
      type: String,
      default: 'https://cdn.saasmetrix.io/Anleitungen',
    },
  })

  defineExpose({
    headline,
  })

  watch(
    () => props.markdown,
    () => {
      if (!props.markdown) return
      // Convert the markdown to HTML using showdown
      htmlParsed.value = converter.makeHtml(props.markdown)
      // Replace relative URLs with absolute URLs
      htmlParsed.value = replaceRelativeUrls(htmlParsed.value)
      // Extract the headline from the HTML
      ;[headline.value, htmlParsed.value] = extractHeadline(
        htmlParsed.value,
        true
      )
    },
    { immediate: true }
  )

  // This function replaces the relative image URL with an absolute URL
  // e.g. .15FiveToken_assets/15FiveToken_1.png -> https://cdn.saasmetrix.io/Anleitungen/15FiveToken_assets/15FiveToken_1.png
  function replaceRelativeUrls(html: string) {
    // Regex to find relative image URLs
    const regex = /src="\.([^"]+)"/g
    const matches = html.match(regex)
    if (matches) {
      matches.forEach((match) => {
        // Remove src=" and "
        match = match.slice(5, -1)

        // Add the base URL to the relative URL
        const absoluteUrl = `${props.baseUrl}/${match}`
        // Replace the relative URL with the absolute URL in the HTML
        html = html.replace(match, absoluteUrl)
      })
    }
    return html
  }

  // This function extracts the headline from the html
  // If remove is true, the headline will be removed from the html
  function extractHeadline(html: string, remove = false) {
    // Regex to extract the headline from the html ignores id and class attributes
    const regex = /<h1[^>]*>([^<]+)<\/h1>/
    const match = html.match(regex)
    if (match) {
      if (remove) {
        html = html.replace(regex, '')
      }
      return [match[1], html]
    }
    return [null, html]
  }
</script>

<style lang="scss">
  .markdown-wrapper {
    max-width: 100%;
    margin: 0 auto;
  }

  .markdown-wrapper img {
    padding: 5px;
    border-radius: 1rem;
  }

  // This style is used to style the ordered list in the markdown content
  .markdown-wrapper ol {
    counter-reset: item;

    li {
      counter-increment: item;
      list-style: none;
      position: relative;
      margin-bottom: 1rem;

      &:before {
        content: counter(item);
        position: absolute;
        left: -2rem;
        top: -0.38rem;
        font-weight: bold;
        font-size: 1.5rem;
      }
    }
  }
</style>
