import { createLicenceOutTable, LicenceOutTable } from './account.models.js'
import { SQLocalKysely } from 'sqlocal/kysely'
import { Kysely } from 'kysely'
import { sql } from 'kysely'
import { CacheStateTable, createCacheStateTable } from './cacheState.store.js'
import {
  createSoftwareOutTable,
  SoftwareOutTable,
} from './application.models.js'
import { AccountStore } from './account.store.js'
import { ApplicationStore } from './application.store.js'
import { createPersonTable, PersonTable } from './person.models.js'
import { PersonStore } from './person.store.js'

export interface Database {
  licence_out: LicenceOutTable
  cache: CacheStateTable
  application: SoftwareOutTable
  person: PersonTable
}

const { dialect } = new SQLocalKysely('database.sqlite3')
export const db = new Kysely<Database>({ dialect })

await sql`PRAGMA synchronous = OFF;`.execute(db)
await sql`PRAGMA journal_mode = MEMORY;`.execute(db)
await sql`PRAGMA locking_mode = EXCLUSIVE;`.execute(db)
await sql`PRAGMA foreign_keys = OFF;`.execute(db)
await sql`PRAGMA temp_store = MEMORY;`.execute(db)
await sql`PRAGMA cache_size = 114176;`.execute(db)
await sql`PRAGMA SQLITE_MAX_SQL_LENGTH = 1073741824;`.execute(db)
await sql`PRAGMA journal_size_limit = 6144000;`.execute(db)

createLicenceOutTable().catch((err) => {
  console.error('Failed to create table:', err)
})

createCacheStateTable().catch((err) => {
  console.error('Failed to create Metadata Cache table', err)
})

createSoftwareOutTable().catch((err) => {
  console.error('Failed to create Application Cache', err)
})

createPersonTable().catch((err) => {
  console.error('Failed to create Person table', err)
})

// Function to reset the database and recreate tables
export async function resetDatabase() {
  try {
    // Drop existing tables
    await sql`DROP TABLE IF EXISTS licence_out`.execute(db)
    await sql`DROP TABLE IF EXISTS cache`.execute(db)
    await sql`DROP TABLE IF EXISTS application`.execute(db)
    await sql`DROP TABLE IF EXISTS person`.execute(db)

    // Recreate tables
    await createLicenceOutTable()
    await createCacheStateTable()
    await createSoftwareOutTable()
    await createPersonTable()
  } catch (err) {
    console.error('Failed to reset database:', err)
    throw err
  }
}

export async function updateAllCaches() {
  AccountStore.updateAccountCacheFromApi()
  ApplicationStore.updateApplicationCacheFromApi()
  PersonStore.updatePersonCacheFromApi()
}
