import { addRxPlugin, MangoQuerySortPart } from 'rxdb'
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode'
import { RxDBUpdatePlugin } from 'rxdb/plugins/update'
import { db } from './_db'

import {
  BackgroundJobAccountGroupAccountsAdded,
  BackgroundJobAccountGroupAccountsRemoved,
  BackgroundJobSoftwareUserAdd,
  BackgroundJobSoftwareUserAddData,
  BackgroundJobSoftwareUserEnable,
  BackgroundJobSoftwareUserEnableData,
  BackgroundJobSoftwareUserLicenseAdd,
  BackgroundJobSoftwareUserLicenseAddData,
  BackgroundJobSoftwareUserLicenseRemove,
  BackgroundJobSoftwareUserLicenseRemoveData,
  BackgroundJobSoftwareUserRemove,
  BackgroundJobSoftwareUserRemoveData,
  BackgroundJobType,
} from '@/client'
import { until } from '@vueuse/shared'
import { filters } from './backgroundjob.filter'

import {
  _abortBackgroundjob,
  _allBackgroundjobsLoaded,
  _checkBackgroundjobCollection,
  _createBackgroundjob,
  _currentLoadingAllBackgroundjobs,
  _enqueueBackgroundjob,
  _fetchBackgroundjob,
  _getQuery,
  _pauseBackgroundjob,
  _reloadBackgroundjobs,
  BackgroundjobFilterInstance,
} from './backgroundjob.utils'
import filterUtils from '@/common/util/filterUtils'

addRxPlugin(RxDBUpdatePlugin)

if (import.meta.env.MODE !== 'production') {
  addRxPlugin(RxDBDevModePlugin)
}

/**
 * CREATE
 */

async function createBackgroundjob(
  type: BackgroundJobType,
  data:
    | BackgroundJobSoftwareUserAddData
    | BackgroundJobSoftwareUserEnableData
    | BackgroundJobSoftwareUserLicenseAddData
    | BackgroundJobSoftwareUserLicenseRemoveData
    | BackgroundJobSoftwareUserRemoveData,
  enqueue_in: number
) {
  await _checkBackgroundjobCollection()

  return _createBackgroundjob(type, data, enqueue_in).then((response) => {
    if (response.data) {
      const dataToInsert = JSON.parse(JSON.stringify(response.data))
      db.value?.backgroundjobs.insert(dataToInsert)
    }
    return response
  })
}

/**
 * READ
 */

export async function getBackgroundjobs(
  filters?: BackgroundjobFilterInstance[],
  {
    limit,
    skip,
    sort,
  }: {
    limit?: number
    skip?: number
    sort?: MangoQuerySortPart<
      | BackgroundJobAccountGroupAccountsAdded
      | BackgroundJobAccountGroupAccountsRemoved
      | BackgroundJobSoftwareUserAdd
      | BackgroundJobSoftwareUserEnable
      | BackgroundJobSoftwareUserLicenseAdd
      | BackgroundJobSoftwareUserLicenseRemove
      | BackgroundJobSoftwareUserRemove
    >[]
  } = {}
) {
  await _checkBackgroundjobCollection()

  const query = await _getQuery(filters, { limit, skip, sort })
  const backgroundjobs = await query?.exec()

  return toRaw(backgroundjobs)
}

export async function getBackgroundjobsSubscription(
  filters?: BackgroundjobFilterInstance[],
  {
    limit,
    skip,
    sort,
  }: {
    limit?: number
    skip?: number
    sort?: MangoQuerySortPart<
      | BackgroundJobSoftwareUserAddData
      | BackgroundJobSoftwareUserEnableData
      | BackgroundJobSoftwareUserLicenseAddData
      | BackgroundJobSoftwareUserLicenseRemoveData
      | BackgroundJobSoftwareUserRemoveData
    >[]
  } = {}
) {
  await _checkBackgroundjobCollection()

  const query = await _getQuery(filters, { limit, skip, sort })
  const querySub = query?.$
  return querySub
}

export async function getBackgroundjobCount(
  filters?: BackgroundjobFilterInstance[]
) {
  await _checkBackgroundjobCollection()

  // For now, we just take the first filter
  const mergedFilter = filterUtils.mergeFilters(filters || [])

  if (_currentLoadingAllBackgroundjobs.value === true) {
    await until(_currentLoadingAllBackgroundjobs).toBe(false)
  }

  if (!_allBackgroundjobsLoaded.value) {
    await _reloadBackgroundjobs()
  }

  const filteredCount = db.value?.backgroundjobs
    .count({ selector: mergedFilter })
    .exec()
  const totalCount = db.value?.backgroundjobs.count().exec()

  return { total: await totalCount, filtered: await filteredCount }
}

export async function getBackgroundjob(id: string) {
  await _checkBackgroundjobCollection()

  const cache = db.value?.backgroundjobs
    .findOne({ selector: { _id: id } })
    .exec()

  if (!cache) {
    const fetchedBackgroundjob = await _fetchBackgroundjob(id)
    return fetchedBackgroundjob
  }

  return cache
}

/**
 * UPDATE
 */

async function enqueueBackgroundjob(id: string, dateInSeconds: number = 1) {
  await _checkBackgroundjobCollection()

  return _enqueueBackgroundjob(id, dateInSeconds).then(async (response) => {
    await db.value?.backgroundjobs.upsert({ _id: id, ...toRaw(response.data) })
    return response
  })
}

async function abortBackgroundjob(id: string) {
  await _checkBackgroundjobCollection()

  return _abortBackgroundjob(id).then(async (response) => {
    await db.value?.backgroundjobs
      .findOne({ selector: { _id: id } })
      .patch(toRaw(response.data))

    return response
  })
}

async function pauseBackgroundjob(id: string) {
  await _checkBackgroundjobCollection()

  return _pauseBackgroundjob(id).then((response) => {
    db.value?.backgroundjobs
      .findOne({ selector: { _id: id } })
      .patch(toRaw(response.data))
    return response
  })
}

/**
 * DELETE
 */

// currently not deleting background jobs

export const backgroundjobStore = {
  createBackgroundjob,
  getBackgroundjobs,
  getBackgroundjobsSubscription,
  getBackgroundjobCount,
  enqueueBackgroundjob,
  abortBackgroundjob,
  pauseBackgroundjob,

  filter: {
    ...filters,
  },
}
