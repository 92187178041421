<!--
  SoftwareAuthFlhandleAuthenticationCompletedowDialog
  This component generates forms based on backend responses.

  Currently it supports:
  - String inputs (text, password)
  - Enum inputs (select)

  Flow:
  1. Component is created and calls the startAuth method to get an id for the auth flow
  2. The "next_step" is requested from the backend to get the first step of the auth flow
 -->
<template>
  <div v-if="authStatus == 'next_step'" class="dialog-content">
    <!-- Generic form starts here -->
    <!-- Message/Description -->
    <div v-if="stepInformation?.i18n_code" class="step-information">
      {{ i18n.t(stepInformation?.i18n_code) }}
    </div>
    <div v-else-if="stepInformation?.message">
      {{ stepInformation.message }}
    </div>

    <!-- Form -->
    <el-form hide-required-asterisk @submit.prevent>
      <span v-for="field in currentFormFields" :key="field.title">
        <el-form-item
          :required="field.required"
          :label="i18n.te(field.title) ? i18n.t(field.title) : field.title">
          <!-- String -->
          <el-input
            v-if="field.type == 'string'"
            v-model="userInput[field.title]"
            :show-password="field.title == 'password'">
          </el-input>

          <!-- Enum -->
          <el-select-v2
            v-else-if="field.type == 'enum'"
            :key="field.availableValues[0].label"
            v-model="userInput[field.title]"
            :options="field.availableValues">
          </el-select-v2>
        </el-form-item>
      </span>
    </el-form>
    <!-- Dynamic Error messages for steps are displayed here -->
    <SmDialogMessage
      v-if="inputErrorMessage"
      type="error"
      :visible="inputErrorMessage != null"
      :message="inputErrorMessage" />
    <!-- GENERIC FORM ENDS HERE -->
    <div class="button-wrapper">
      <SmButton
        ref="submitButtonTest"
        :loading="requestLoading"
        class="submit-button"
        @click="goToNextStep()">
        {{ i18n.t('submit') }}
      </SmButton>
    </div>
  </div>
  <div v-else-if="authStatus == 'success'" class="dialog-content">
    <div class="icons-wrapper">
      <img
        :src="`https://logos.saasmetrix.io/${props.softwareName}.png`"
        class="integration-icon" />
      <div class="x-wrapper">
        <p class="x">x</p>
      </div>
      <img
        src="https://logos.saasmetrix.io/Logo_Icon_light.svg"
        class="integration-icon" />
    </div>
    {{
      i18n.t('dialogs.authFlowDialog.successText', {
        softwareName: softwareName,
      })
    }}
  </div>
  <div v-else-if="authStatus == 'error'" class="dialog-content">
    <SmDialogMessage
      :visible="authStatus == 'error'"
      type="error"
      :message="
        i18n.t('dialogs.authFlowDialog.failedText', {
          softwareName: softwareName,
        })
      " />
    <div class="button-wrapper">
      <SmButton :loading="requestLoading" @click="startAuth()">
        {{ i18n.t('dialogs.authFlowDialog.tryAgain') }}
      </SmButton>
    </div>
  </div>
  <!-- CONTENT ENDS HERE-->
</template>

<script setup lang="ts">
  // Imports
  import * as Sentry from '@sentry/vue'
  import { OptionType } from 'element-plus/es/components/select-v2/src/select.types'
  import JsonRefs from 'json-refs'
  import { useI18n } from 'vue-i18n'

  import {
    SoftwareAuthorizationOutError,
    SoftwareAuthorizationOutNextStep,
    SoftwareAuthorizationService,
    SoftwareTypes,
  } from '@/client'
  import { AuthType } from '@/stores/applicationReauthStore'

  const i18n = useI18n()

  // Types
  type inputFieldInformationTypes = 'string' | 'enum'

  type inputInformationBase = {
    title: string
    type: inputFieldInformationTypes
    required: boolean
  }

  type textInputField = inputInformationBase & { type: 'string' }
  type enumInputField = inputInformationBase & {
    type: 'enum'
    availableValues: OptionType[]
  }
  type InputInformation = textInputField | enumInputField

  // Emits
  const emit = defineEmits(['update:visibility', 'authentication-completed'])

  // Props
  const props = defineProps<{
    softwareName: string
    softwareId?: string
    softwareType: SoftwareTypes
    authType: AuthType
  }>()

  /*
  Defining Constants

  authorizationId: Every authorization process has an id, that is used to identify the process. The id is generated by the backend.
  authStatus: The status of the authorization process. Can be 'next_step', 'success' or 'error'.
  next_step: The authorization process is not finished yet, the user has to provide more information in a next step.
  success: The authorization process is finished successfully.
  error: The authorization process failed.
  stepInformation: The information about the current step. This is a SoftwareAuthorizationOutNextStep object.
  stepInformationData: Information about the form fields of the current step. A parsed version of stepInformation.data.
  */
  const requestLoading = ref(false)
  const authorizationId = ref<string>() // this is the id of the authorization process
  const authStatus = ref<'success' | 'error' | 'next_step'>('next_step')
  const stepInformation = ref<SoftwareAuthorizationOutNextStep>()
  const stepInformationData = ref()
  const submitButtonTest = ref<HTMLElement>()

  // Input-Step related Data
  const inputErrorMessage = ref<string | null>(null)
  const userInput = ref<Record<string, string>>({})

  const currentFormFields: Ref<InputInformation[]> = ref([])
  watch(
    () => stepInformationData.value,
    () => {
      if (!stepInformationData.value) return
      currentFormFields.value = []

      for (const [key, value] of Object.entries(
        stepInformationData.value.properties
      )) {
        try {
          currentFormFields.value.push(
            stepInformationDataToCurrentFormField(key, value)
          )
        } catch (e) {
          Sentry.captureMessage(
            'Error while parsing stepInformationData in AuthFlowContent'
          )
          authStatus.value = 'error'
        }
      }
    }
  )

  // On Setup
  startAuth()

  document.addEventListener('keypress', (event) => {
    if (event.key === 'Enter') {
      goToNextStep()
    }
  })

  // ********************************* Request Functions *********************************

  // Start the authorization process
  // This function is called on component creation
  // Get the authorization id and start the first step (call goToNextStep)
  function startAuth() {
    requestLoading.value = true
    SoftwareAuthorizationService.startAuthorizeSoftwareApiV1SoftwareAuthorizeStartPost(
      {
        requestBody: {
          software_name: props.softwareName,
          software_type: props.softwareType,
          software_id: props.softwareId,
        },
      }
    ).then(
      (res) => {
        authorizationId.value = res
        authStatus.value = 'next_step'
        requestLoading.value = false
        // After the id has been crawled, the first step is initiated
        goToNextStep()
      },
      // rejected part
      () => {
        requestLoading.value = false
        authStatus.value = 'error'
      }
    )
  }

  function goToNextStep() {
    if (!authorizationId.value) {
      authStatus.value = 'error'
      return
    }

    requestLoading.value = true
    SoftwareAuthorizationService.authorizeSoftwareStepApiV1SoftwareAuthorizeStepPost(
      {
        requestBody: {
          software_authorization_id: authorizationId.value,
          user_input: userInput.value,
        },
      }
    )
      .then(
        async (res) => {
          switch (res.type) {
            case 'success': {
              authStatus.value = 'success'
              // software get's authenticated with the id
              switch (props.authType) {
                case 'authenticate':
                  SoftwareAuthorizationService.postSoftwareApiV1SoftwareSoftwarePost(
                    {
                      requestBody: {
                        software_name: props.softwareName,
                        type: props.softwareType,
                        software_authorization_id: authorizationId.value,
                      },
                    }
                  ).then(
                    () => {},
                    () => {
                      authStatus.value = 'error'
                    }
                  )
                  break
                case 'reauthenticate':
                  if (!props.softwareId) {
                    Sentry.captureMessage(
                      'Error while reauthenticating software in AuthFlowContent, ID is MISSING as Prop to SoftwareAuthFlowContent'
                    )
                    return
                  }
                  SoftwareAuthorizationService.reauthenticateSoftwareApiV1SoftwareSoftwareSoftwareIdReAuthenticatePost(
                    {
                      softwareId: props.softwareId,
                      requestBody: {
                        software_authorization_id: authorizationId.value,
                      },
                    }
                  ).then(
                    () => {},
                    () => {
                      authStatus.value = 'error'
                    }
                  )
              }
              // dialog closes automatically
              setTimeout(() => {
                emit('authentication-completed')
              }, 1500)
              break
            }

            case 'error': {
              inputErrorMessage.value = (
                res as SoftwareAuthorizationOutError
              ).error_message
              break
            }
            case 'next_step': {
              const castedResult = res as SoftwareAuthorizationOutNextStep
              inputErrorMessage.value = null
              stepInformation.value = castedResult

              if (!castedResult.data) {
                authStatus.value = 'error'
                return
              }

              // parsing the json scheme data, so that $refs (json scheme pointers, that point to other definition locations) are resolved automatically
              JsonRefs.resolveRefs(castedResult.data).then((resolvedData) => {
                stepInformationData.value = resolvedData.resolved
              })
              break
            }
          }
        },
        // rejected callback
        () => {
          authStatus.value = 'error'
        }
      )
      .finally(() => {
        userInput.value = {}
        requestLoading.value = false
      })
  }

  // ********************************* Helper Functions *********************************
  function stepInformationDataToCurrentFormField(key: string, value: any) {
    // if the property value.enum exists, the form field is an enum form field
    // the {type: 'enum'} property is not set on enums by default in the backend data, so it's manually set here
    if (value.enum) value.type = 'enum'
    const isRequired = stepInformationData.value.required.includes(key)

    if (value.const) {
      const item = value.const
      return {
        title: key,
        type: 'enum',
        required: isRequired as boolean,
        availableValues: [
          { label: i18n.te(item) ? i18n.t(item) : item, value: value.const },
        ],
      } as enumInputField
    }

    switch (value.type) {
      case 'string':
        return {
          title: key,
          type: 'string',
          required: isRequired as boolean,
        } as textInputField
      case 'enum':
        // Check if description is available
        if (value.description) {
          // Parse Description to get labels
          // Example: description: "User profiles:\n* `null` - Personal Account\n* `11EC180B4A82421E985D772BB5DD70C8` - First Company\n* `11EC180B4A82421E985D772BB5DD70AB` - Second company"
          const description = value.description

          // Remove prefix
          const descriptionWithoutPrefix = description.replace(
            'User profiles:\n',
            ''
          )

          // Split by newline
          const descriptionSplitByNewline = descriptionWithoutPrefix.split('\n')

          // Remove empty lines
          const descriptionWithoutEmptyLines = descriptionSplitByNewline.filter(
            (line: string) => line != ''
          )

          // Remove bullet points
          const descriptionWithoutBulletPoints =
            descriptionWithoutEmptyLines.map((line: string) =>
              line.replace('* ', '')
            )

          // Split by `-`
          const descriptionSplitByDash = descriptionWithoutBulletPoints.map(
            (line: string) => line.split(' - ')
          )

          // Remove ``
          descriptionSplitByDash.forEach((line: string[]) => {
            line[0] = line[0].replaceAll('`', '').replaceAll("'", '')
          })

          // Create availableValues
          const availableValues = descriptionSplitByDash.map(
            (line: string[]) => {
              return {
                label: i18n.te(line[1]) ? i18n.t(line[1]) : line[1],
                value: line[0],
              }
            }
          )

          return {
            title: key,
            type: 'enum',
            required: isRequired as boolean,
            availableValues: availableValues,
          } as enumInputField
        }

        return {
          title: key,
          type: 'enum',
          required: isRequired as boolean,
          availableValues: value.enum.map((item: string) => ({
            label: i18n.te(item) ? i18n.t(item) : item,
            value: item.replace('`', '').replace("'", ''),
          })),
        } as enumInputField
    }
    throw new Error('Unknown type')
  }
</script>

<style scoped lang="scss">
  .step-information {
    text-align: left;
  }

  .dialog-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }

  .software-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
  }

  .dialog-content {
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .error-message {
    font-size: 14px;
    color: var(--sm-magenta);
  }

  .x {
    font-size: 2.5rem;
    margin: 0;
    vertical-align: middle;
  }

  .x-wrapper {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--el-text-color-disabled);
  }

  .integration-icon {
    height: 4rem;
    width: 4rem;
  }

  .icons-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
</style>
