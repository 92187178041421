<template>
  <div>
    <CompanyCreateDialog
      v-model:visible="companyCreateDialog"
      @company-created="handleCompanyLoad" />
    <div class="header-wrapper">
      <header>{{ i18n.t('companies') }}</header>
      <div style="cursor: pointer" @click="companyCreateDialog = true">
        <SmTooltip placement="right">
          <v-icon
            name="md-add-round"
            style="margin-right: -5px"
            scale="1.7"
            class="fill-contrast"
            animation="pulse"
            hover />

          <template #content>
            {{ i18n.t('createCompany') }}
          </template>
        </SmTooltip>
      </div>
    </div>
    <p>{{ i18n.t('chooseCompanyLoginText') }}</p>
    <div class="companies">
      <div
        v-for="company in companies"
        :key="company.company_id"
        @click="loginToCompany(company)">
        <CompanySelectItem :company="company" />
      </div>
    </div>

    <!-- Empty State -->
    <div v-if="companies.length === 0 && !loading">
      <SmButton
        style="margin-top: 1rem"
        size="large"
        @click="companyCreateDialog = true">
        {{ i18n.t('createCompany') }}
      </SmButton>
    </div>

    <!-- Loading state -->
    <div v-if="loading" style="width: 100%; height: 75px">
      <SmLoading :loading="loading" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  import {
    AvailableCompany,
    CompaniesService,
    CompanyAccessRole,
  } from '@/client'
  import { useSessionStore } from '@/stores/sessionStore'

  import CompanySelectItem from './CompanySelectItem.vue'

  const i18n = useI18n()
  const sessionStore = useSessionStore()
  const router = useRouter()

  const loading = ref(false)

  const companyCreateDialog = ref(false)
  const companies = ref<AvailableCompany[]>([])

  async function getCompanies() {
    loading.value = true
    // Get Companies
    const companies = await CompaniesService.getAvailableCompaniesCompaniesGet(
      {}
    )

    loading.value = false

    // Sort by last login
    companies.sort((a, b) => {
      if (a.last_login === b.last_login) return 0
      if (a.last_login === null) return 1
      if (b.last_login === null) return -1
      return a.last_login > b.last_login ? -1 : 1
    })

    return companies
  }

  onMounted(() => {
    handleCompanyLoad()
  })

  async function handleCompanyLoad() {
    companies.value = await getCompanies()

    // If there is only one company and query parameter autoLogin is set, login to that company
    if (
      companies.value.length === 1 &&
      router.currentRoute.value.query.autoLogin
    ) {
      await loginToCompany(companies.value[0])
    }
  }

  async function loginToCompany(company: AvailableCompany) {
    if (company.role == CompanyAccessRole.SUSPENDED) return

    await sessionStore.loginToCompany(company)
    router.push({ name: 'Dashboard' })
  }
</script>

<style lang="scss" scoped>
  .companies {
    margin-top: 1rem;

    display: flex;
    flex-direction: column;

    max-height: 60vh;
    overflow-y: auto;
  }

  .company {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    padding: 10px;

    border-radius: var(--border-radius-base);
    transition: background-color 0.2s ease;

    &:hover {
      background: theme('colors.gray[100]');
    }
  }

  .dark .company:hover {
    background: theme('colors.gray[600]');
  }
  // Header
  header {
    font-size: 2rem;
    font-weight: 600;
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 0.5rem;
  }
</style>
