<template>
  <!-- View Group -->
  <SmDrawer v-model:visibility="viewDialogVisible">
    <template #title>
      <h2 v-if="editGroup">{{ editGroup.name }}</h2>
    </template>
    <GroupDisplayDescription v-if="editGroup" :group="editGroup" class="mb-4" />

    <GroupDetailsContent
      :group="editGroup!"
      :all-applications="allApplications"
      :all-accounts="allAccounts"
      :all-tags="allTags"
      :all-software-licenses
      :all-price-informations
      @group-changed="loadGroups" />
  </SmDrawer>

  <!-- New Group -->
  <GroupAddDialog
    v-model:visibility="editGroupDialogVisible"
    v-model:account-group="accountGroup"
    :all-price-informations
    :all-software-licenses
    :mode="mode"
    @groups-changed="loadGroups" />

  <!-- Add User -->
  <GroupAddUserDialog
    ref="groupAddUserDialog"
    v-model:visibility="addUserDialogVisible"
    v-model:account-group="editGroup"
    :all-accounts="allAccounts"
    @groups-changed="loadGroups" />

  <!-- View Content -->
  <div class="home">
    <ViewHeader :title="i18n.t('groups')" />

    <!-- Search -->
    <div v-if="groups.length > 0">
      <SmInput
        v-model="searchTerm"
        outline
        label="Search"
        style="max-width: 400px; margin-left: auto; margin-bottom: 10px"
        size="medium" />
    </div>

    <div v-if="groups.length > 0" v-auto-animate class="group-grid">
      <GroupCard
        v-for="group in filteredGroups"
        :key="group._id"
        :group="group"
        @view="handleView"
        @add-user="handleAddUser(group)"
        @edit="handleEdit(group)" />

      <GroupAddCard
        :all-price-informations
        :all-applications="allApplications"
        :all-software-licenses
        @groups-changed="loadGroups" />
    </div>

    <template v-else-if="loadingGroups">
      <div class="group-grid mt-11">
        <SmSkeleton
          v-for="index in 4"
          :key="index"
          height="140px"
          width="100%"
          class="!rounded-lg" />
      </div>
    </template>

    <EmptyState
      v-if="!loadingGroups && groups.length === 0"
      icon="md-groups-round"
      :title="capitalize(i18n.t('groupsHeadline'))"
      :text="i18n.t('groupsDescription')"
      :create-label="i18n.t('addGroup')"
      :show-docs-btn="false"
      class="empty-state"
      @create="handleAdd()" />
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import {
    AccountGroup,
    Account,
    PriceInformation,
    SoftwareLicense,
    Tag,
    TagsService,
    UserGroupsService,
    SoftwareLicensesService,
    AccountGroupIn,
  } from '@/client'
  import { capitalize } from '@/common/util/formatter'
  import { ApplicationStore } from '@/stores/application.store'
  import { PersonStore } from '@/stores/person.store'

  const i18n = useI18n()

  const { data: allApplications } = ApplicationStore.useApplications()
  const allSoftwareLicenses = ref<SoftwareLicense[]>([])
  const allPriceInformations = ref<PriceInformation[]>([])
  const allAccounts = ref<Account[]>([])
  const allTags = ref<Tag[]>([])
  const groups = ref<AccountGroup[]>([])

  // View
  const viewDialogVisible = ref(false)

  // Edit
  const editGroupDialogVisible = ref(false)

  const accountGroup = computed(() =>
    mode.value === 'add' ? addGroup.value : editGroup.value
  )
  const editGroup = ref<AccountGroup>()
  const addGroup = ref<AccountGroupIn>()
  const mode = ref<'add' | 'edit'>('add')
  const loadingGroups = ref(false)

  // Add user
  const addUserDialogVisible = ref(false)

  function handleAddUser(group: AccountGroup) {
    editGroup.value = group
    addUserDialogVisible.value = true
  }

  function handleEdit(group: AccountGroup) {
    editGroup.value = group
    editGroupDialogVisible.value = true
    mode.value = 'edit'
  }
  function handleAdd() {
    const group = ref<AccountGroupIn>({
      name: '',
      accounts: [],
      permission: {
        software_permissions: {},
      },
      color: {
        r: 0,
        g: 0,
        b: 255,
      },
    })
    addGroup.value = group.value
    editGroupDialogVisible.value = true
    mode.value = 'add'
  }

  function handleView(group: AccountGroup) {
    editGroup.value = group
    viewDialogVisible.value = true
  }

  const searchTerm = ref('')
  const filteredGroups = computed(() => {
    if (!searchTerm.value) return groups.value
    return groups.value.filter((group) =>
      group.name.toLowerCase().includes(searchTerm.value.toLowerCase())
    )
  })

  function loadGroups() {
    loadingGroups.value = true
    UserGroupsService.getAccountGroupsApiV1ManagementGroupsGet({}).then(
      (res) => {
        groups.value = res.sort((a, b) => a.name.localeCompare(b.name))
        editGroup.value = res.find((g) => g._id == editGroup.value?._id)
        loadingGroups.value = false
      }
    )
  }

  onMounted(() => {
    TagsService.getTagsApiV1ManagementTagsGet({}).then((tags) => {
      allTags.value = tags
    })

    const { data } = ApplicationStore.useApplications()
    allApplications.value = data.value

    SoftwareLicensesService.getSoftwareLicensesApiV1SoftwareSoftwareLicensesGet(
      {}
    ).then((licenses) => {
      allSoftwareLicenses.value = licenses
    })

    SoftwareLicensesService.getSoftwareLicensePriceInformationsApiV1SoftwareSoftwareLicensePriceInformationsGet(
      {}
    ).then((priceInformations) => {
      allPriceInformations.value = priceInformations
    })
    loadGroups()

    PersonStore.getPersons().then((user) => {
      if (user) allAccounts.value = user
    })
  })
</script>

<style scoped lang="scss">
  .home {
    grid-template-rows: auto auto 1fr;
  }

  .empty-state {
    grid-row-start: 2;
    grid-row-end: 4;
  }
  .group-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1.3rem;
    grid-auto-rows: max-content;
  }
</style>
