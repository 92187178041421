<template>
  <!-- Fallback -->
  <div v-if="showSkeleton" class="flex max-w-sm flex-col px-2">
    <SmSkeleton width="70%" height="16px" class="mb-2" />
    <SmSkeleton width="30%" height="12px" class="mb-2" />
  </div>

  <SmDropdownItem
    v-else-if="props.notification"
    size="medium"
    class="notification-item cursor-default justify-between gap-4"
    hover-animation="indent">
    <!-- Message -->
    <div class="max-w-sm">
      <!-- Subject -->
      <TextMultiline :lines-to-show="2">
        <i18n-t
          v-if="props.notification.subject"
          :keypath="props.notification.subject"
          tag="p">
          <template v-if="props.notification.data?.member_id" #member>
            <b>{{
              getMemberName(props.notification.data?.member_id as string)
            }}</b>
          </template>

          <template v-if="props.notification.data?.company_id" #company>
            <b>{{ props.notification.data?.company_id }}</b>
          </template>
        </i18n-t>
      </TextMultiline>

      <div class="flex items-center gap-2">
        <!-- Indicator -->
        <SmTooltip
          v-if="!props.notification.isRead"
          :content="i18n.t('notification.newNotification')">
          <span
            class="block aspect-square h-2 w-2 rounded-full bg-primary align-top"></span>
        </SmTooltip>

        <!-- Details -->
        <p class="text-sm text-contrast-muted">
          {{ timestamp }}
        </p>
      </div>
    </div>

    <!-- Actions -->
    <div
      v-if="!props.notification?.isArchived"
      class="actions flex items-center gap-2">
      <SmTooltip :content="i18n.t('notification.archiveNotification')">
        <v-icon
          name="bi-archive"
          scale="1"
          class="cursor-pointer !text-contrast"
          @click="
            notificationStore.archiveNotification(props.notification.id)
          " />
      </SmTooltip>

      <SmTooltip
        v-if="!props.notification.isRead"
        :content="i18n.t('notification.readNotification')">
        <v-icon
          name="bi-check2"
          scale="1.4"
          class="cursor-pointer !text-contrast dark:text-contrast"
          @click="notificationStore.readNotification(props.notification.id)" />
      </SmTooltip>
    </div>
  </SmDropdownItem>
</template>

<script setup lang="ts">
  import { CompanyIdentityAccessWithUserProfile } from '@/client'
  import { useSessionStore } from '@/stores/sessionStore'
  import { Notification } from '@novu/js'
  import dayjs from 'dayjs'
  import TextMultiline from './sm/TextMultiline.vue'
  import SmTooltip from './sm/SmTooltip.vue'
  import { notificationStore } from '@/stores/notification.store'
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()

  type PublicInterface<T> = {
    [P in keyof T]: T[P]
  }

  const sessionStore = useSessionStore()

  const props = defineProps({
    notification: {
      type: Object as () => PublicInterface<Notification>,
      default: null,
      required: false,
    },
    showSkeleton: {
      type: Boolean,
      default: false,
    },
  })

  const companyMembers = ref<CompanyIdentityAccessWithUserProfile[]>([])
  const timestamp = computed(() =>
    dayjs(props.notification?.createdAt).format('DD. MMM.,  HH:mm')
  )

  function getMemberName(memberId: string) {
    if (!memberId) {
      return ''
    }
    if (memberId === 'system') {
      return 'System'
    }

    return companyMembers.value.find((member) => member._id === memberId)
      ?.profile?.display_name
  }

  onMounted(async () => {
    if (props.notification?.data?.member_id)
      companyMembers.value = await sessionStore.getCompanyMembers()
  })
</script>

<style lang="scss">
  .inbox-tabs {
    .el-tabs__nav {
      // if inbox tab is the first tab, pesudo selectors wont work :/
      .el-tabs__item {
        padding: 0 12px !important;
      }
      #tab-inbox {
        padding: 0 12px 0 1.25rem !important;
      }
    }
  }

  .notification-item {
    .actions {
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      .actions {
        opacity: 100;
      }
    }
  }
</style>
