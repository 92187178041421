<template>
  <!-- Optimization dialog -->
  <SmDialog v-model:visibility="modelValue" :title="title" size="medium">
    <div v-if="props.optimization" class="optimization">
      <div v-if="license" class="optimization-content">
        <span>{{ i18n.t('license.license') }}</span>
        <span>{{ license?.email }}</span>
        <span>{{ i18n.t('software') }}</span>
        <span>{{ software?.display_name || software?.software_name }}</span>
      </div>
      <!-- Content dependent on Optimization type -->
      <!-- Unused -->
      <ContentOptimizationUnused
        v-if="
          props.optimization.optimization_type === OptimizationType.UNUSED &&
          license
        "
        :optimization="optimization"
        :license="license" />

      <!-- Redundant -->
      <ContentOptimizationRedundant
        v-else-if="
          software &&
          user &&
          license &&
          props.optimization.optimization_type ===
            OptimizationType.REDUNDANT_LICENSE
        "
        :software-licenses
        :optimization="optimization"
        :license="license"
        :software="software"
        :user="user"
        @close="handleClose" />

      <!-- Duplicate -->
      <ContentOptimizationDuplicate
        v-else-if="
          props.optimization.optimization_type == OptimizationType.DUPLICATE &&
          software?.display_name
        "
        :optimization="optimization"
        :software-name="software?.display_name" />

      <!-- Employee -->
      <OptimizationDialogEmployeeContent
        v-else-if="props.optimization.employee_id"
        :optimization="props.optimization" />
    </div>
    <template #footer>
      <div
        v-if="
          props.optimization.optimization_type !==
            OptimizationType.REDUNDANT_LICENSE &&
          props.optimization.optimization_type !== OptimizationType.DUPLICATE
        "
        v-require-permission="CompanyAccessRole.ADMIN"
        class="buttons">
        <SmButton
          v-if="!executed"
          :loading="loading"
          :text="i18n.t('hide')"
          outline
          @click="handleHideClicked" />
        <SmButton
          v-if="!executed"
          :loading="loading"
          :text="i18n.t('execute')"
          @click="handleExecuteClicked" />
        <SmButton
          v-if="executed"
          :text="i18n.t('close')"
          outline
          @click="handleClose()" />
      </div>
    </template>
  </SmDialog>
</template>

<script lang="ts" setup>
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'

  import {
    Account,
    CompanyAccessRole,
    Optimization,
    OptimizationType,
    OptimizationsService,
    SoftwareLicense,
  } from '@/client'

  import { computedAsync } from '@vueuse/core'
  import ContentOptimizationDuplicate from './ContentOptimizationDuplicate.vue'
  import ContentOptimizationRedundant from './ContentOptimizationRedundant.vue'
  import ContentOptimizationUnused from './ContentOptimizationUnused.vue'
  import LicenseModelEditTable from './LicenseModelEditTable.vue'
  import { AccountStore } from '@/stores/account.store'
  import { ApplicationStore } from '@/stores/application.store'
  import { PersonStore } from '@/stores/person.store'

  const i18n = useI18n()

  const executed = ref(false)
  const loading = ref(false)
  const user = ref<Account | null>(null)

  const emit = defineEmits([
    'update:modelValue',
    'hidden',
    'executed',
    'userAdded',
  ])

  const props = defineProps({
    allSoftwareLicenses: {
      type: Array as PropType<SoftwareLicense[]>,
      required: true,
    },
    optimization: {
      type: Object as PropType<Optimization>,
      required: true,
    },
  })

  const modelValue = defineModel({
    type: Boolean,
    required: true,
  })

  const softwareId = computed(() => props.optimization.software_id)

  const { application: software } =
    ApplicationStore.useApplicationByID(softwareId)

  const softwareLicenseEditTable =
    ref<ComponentExposed<typeof LicenseModelEditTable>>()

  const license = computedAsync(async () => {
    if (props.optimization.licence_id === null) return null
    return await AccountStore.getAccountById(props.optimization.licence_id)
  })

  const softwareLicenses = computed(() => {
    return props.allSoftwareLicenses.filter(
      (license) => license.software_id === props.optimization.software_id
    )
  })

  const title = computed(() => {
    let title = i18n.t('optimization')
    if (!props.optimization) return 'Optimierung'
    switch (props.optimization.optimization_type) {
      case OptimizationType.UNUSED:
        title = i18n.t('unusedLicence')
        break
      case OptimizationType.DUPLICATE:
        title = i18n.t('duplicateLicence')
        break
    }
    return title
  })

  function handleHideClicked() {
    loading.value = true
    OptimizationsService.hideOptimizationApiV1ManagementOptimizationsOptimizationIdHidePost(
      { optimizationId: props.optimization._id }
    ).then(() => {
      loading.value = false
      executed.value = true
      emit('hidden')
    })
  }

  function handleExecuteClicked() {
    loading.value = true
    OptimizationsService.executeOptimizationApiV1ManagementOptimizationsOptimizationIdExecutePost(
      { optimizationId: props.optimization._id }
    ).then(() => {
      loading.value = false
      executed.value = true
      emit('executed')
    })
  }

  watch(
    () => modelValue,
    (newValue) => {
      if (!newValue) {
        executed.value = false
        loading.value = false
      }
    }
  )

  watch(
    () => props.optimization,
    (newValue) => {
      if (!newValue) return

      // Get user
      if (newValue.account_id)
        PersonStore.getPersonById(newValue.account_id).then((_user) => {
          if (_user) {
            user.value = _user
          }
        })
    },
    { immediate: true }
  )

  watch(
    () => props.optimization?.optimization_type,
    (newValue) => {
      if (!newValue) return
      if (newValue === OptimizationType.REDUNDANT_LICENSE) {
        // Deselect all redundant licenses
        if (props.optimization?.data?.redundant_software_license_ids) {
          props.optimization.data.redundant_software_license_ids.forEach(
            (data) => {
              data.redundant_software_license_ids.forEach((duplicate) => {
                if (!softwareLicenseEditTable.value) return
                if (!duplicate) return
                softwareLicenseEditTable.value?.deselectLicenseModel(duplicate)
              })
            }
          )
        }
      }
    },
    { immediate: true }
  )

  function handleClose() {
    emit('executed')
    loading.value = false
    executed.value = false
    modelValue.value = false
  }
</script>

<style scoped>
  .optimization {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .buttons {
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: center;
  }

  .optimization-content {
    display: grid;
    grid-template-columns: 150px auto;
    gap: 0.4rem;
  }

  h3 {
    grid-column: 1 / 2;
    margin-bottom: -0.4rem;
    margin-top: 1rem;
  }
</style>
