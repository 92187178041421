import { CurrencyCode } from '@/client'

// Typdefinition für die Währungsinformationen inklusive der Symbolposition
export type CurrencyInfo = {
  i18nKey: string
  currencySymbol: string
  decimalSeparator: string
  thousandSeparator: string
  symbolPosition: 'prefix' | 'suffix'
  formatter: (value: number) => string
}

// Hilfsfunktion, die einen Formatter erstellt, der den Zahlenwert formatiert
// und das currencySymbol gemäß der symbolPosition einfügt.
function createFormatter(
  currencySymbol: string,
  decimalSeparator: string,
  thousandSeparator: string,
  symbolPosition: 'prefix' | 'suffix'
): (value: number) => string {
  return (value: number) => {
    const [integerPart, decimalPart] = value.toFixed(2).split('.')
    const formattedInteger = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      thousandSeparator
    )
    const formattedNumber = `${formattedInteger}${decimalSeparator}${decimalPart}`
    return symbolPosition === 'prefix'
      ? `${currencySymbol} ${formattedNumber}`
      : `${formattedNumber} ${currencySymbol}`
  }
}

const defaultDecimal = '.'
const defaultThousand = ','
const altDecimal = ','
const altThousand = '.'

export function guessCurrency(): string {
  // Fallback-Währung
  const defaultCurrency = 'EUR'

  // Versuche, die Locale aus dem Browser auszulesen (z. B. "de-DE", "en-US")
  const locale =
    navigator.language || (navigator.languages && navigator.languages[0])
  if (!locale) {
    return defaultCurrency
  }

  // Extrahiere den Regionscode (z. B. "DE" aus "de-DE")
  const regionMatch = locale.match(/-([A-Z]{2})/i)
  if (!regionMatch) {
    return defaultCurrency
  }
  const regionCode = regionMatch[1].toUpperCase()

  return regionCurrencyMap[regionCode] || defaultCurrency
}

// Mapping-Tabelle: Ländercode -> Währung
const regionCurrencyMap: Record<string, keyof typeof CurrencyCode> = {
  AD: 'EUR',
  AE: 'AED',
  AF: 'AFN',
  AG: 'XCD',
  AI: 'XCD',
  AL: 'ALL',
  AM: 'AMD',
  AO: 'AOA',
  AR: 'ARS',
  AS: 'USD',
  AT: 'EUR',
  AU: 'AUD',
  AW: 'AWG',
  AX: 'EUR',
  AZ: 'AZN',
  BA: 'BAM',
  BB: 'BBD',
  BD: 'BDT',
  BE: 'EUR',
  BF: 'XOF',
  BG: 'BGN',
  BH: 'BHD',
  BI: 'BIF',
  BJ: 'XOF',
  BL: 'EUR',
  BM: 'BMD',
  BN: 'BND',
  BO: 'BOB',
  BQ: 'USD',
  BR: 'BRL',
  BS: 'BSD',
  BT: 'BTN',
  BV: 'NOK', // Für unbewohnte Gebiete – Fallback hier exemplarisch
  BW: 'BWP',
  BY: 'BYN',
  BZ: 'BZD',
  CA: 'CAD',
  CC: 'AUD',
  CD: 'CDF',
  CF: 'XAF',
  CG: 'XAF',
  CH: 'CHF',
  CI: 'XOF',
  CK: 'NZD',
  CL: 'CLP',
  CM: 'XAF',
  CN: 'CNY',
  CO: 'COP',
  CR: 'CRC',
  CU: 'CUP',
  CV: 'CVE',
  CW: 'ANG',
  CX: 'AUD',
  CY: 'EUR',
  CZ: 'CZK',
  DE: 'EUR',
  DJ: 'DJF',
  DK: 'DKK',
  DM: 'XCD',
  DO: 'DOP',
  DZ: 'DZD',
  EC: 'USD',
  EE: 'EUR',
  EG: 'EGP',
  EH: 'MAD',
  ER: 'ERN',
  ES: 'EUR',
  ET: 'ETB',
  FI: 'EUR',
  FJ: 'FJD',
  FK: 'FKP',
  FM: 'USD',
  FO: 'DKK',
  FR: 'EUR',
  GA: 'XAF',
  GB: 'GBP',
  GD: 'XCD',
  GE: 'GEL',
  GF: 'EUR',
  GG: 'GBP',
  GH: 'GHS',
  GI: 'GIP',
  GL: 'DKK',
  GM: 'GMD',
  GN: 'GNF',
  GP: 'EUR',
  GQ: 'XAF',
  GR: 'EUR',
  GS: 'GBP',
  GT: 'GTQ',
  GU: 'USD',
  GW: 'XOF',
  GY: 'GYD',
  HK: 'HKD',
  HM: 'AUD',
  HN: 'HNL',
  HR: 'HRK',
  HT: 'HTG',
  HU: 'HUF',
  ID: 'IDR',
  IE: 'EUR',
  IL: 'ILS',
  IM: 'GBP',
  IN: 'INR',
  IO: 'USD',
  IQ: 'IQD',
  IR: 'IRR',
  IS: 'ISK',
  IT: 'EUR',
  JE: 'GBP',
  JM: 'JMD',
  JO: 'JOD',
  JP: 'JPY',
  KE: 'KES',
  KG: 'KGS',
  KH: 'KHR',
  KI: 'AUD',
  KM: 'KMF',
  KN: 'XCD',
  KP: 'KPW',
  KR: 'KRW',
  KW: 'KWD',
  KY: 'KYD',
  KZ: 'KZT',
  LA: 'LAK',
  LB: 'LBP',
  LC: 'XCD',
  LI: 'CHF',
  LK: 'LKR',
  LR: 'LRD',
  LS: 'LSL',
  LT: 'EUR',
  LU: 'EUR',
  LV: 'EUR',
  LY: 'LYD',
  MA: 'MAD',
  MC: 'EUR',
  MD: 'MDL',
  ME: 'EUR',
  MF: 'EUR',
  MG: 'MGA',
  MK: 'MKD',
  ML: 'XOF',
  MM: 'MMK',
  MN: 'MNT',
  MO: 'MOP',
  MP: 'USD',
  MQ: 'EUR',
  MR: 'MRU', // oder MRO, je nach Zeitraum
  MS: 'XCD',
  MT: 'EUR',
  MU: 'MUR',
  MV: 'MVR',
  MW: 'MWK',
  MX: 'MXN',
  MY: 'MYR',
  MZ: 'MZN',
  NA: 'NAD',
  NC: 'XPF',
  NE: 'XOF',
  NF: 'AUD',
  NG: 'NGN',
  NI: 'NIO',
  NL: 'EUR',
  NO: 'NOK',
  NP: 'NPR',
  NR: 'AUD',
  NU: 'NZD',
  NZ: 'NZD',
  OM: 'OMR',
  PA: 'PAB',
  PE: 'PEN',
  PF: 'XPF',
  PG: 'PGK',
  PH: 'PHP',
  PK: 'PKR',
  PL: 'PLN',
  PM: 'EUR',
  PN: 'NZD',
  PR: 'USD',
  PS: 'ILS',
  PT: 'EUR',
  PW: 'USD',
  PY: 'PYG',
  QA: 'QAR',
  RE: 'EUR',
  RO: 'RON',
  RS: 'RSD',
  RU: 'RUB',
  RW: 'RWF',
  SA: 'SAR',
  SB: 'SBD',
  SC: 'SCR',
  SD: 'SDG',
  SE: 'SEK',
  SG: 'SGD',
  SH: 'SHP',
  SI: 'EUR',
  SJ: 'NOK',
  SK: 'EUR',
  SL: 'SLE',
  SM: 'EUR',
  SN: 'XOF',
  SO: 'SOS',
  SR: 'SRD',
  SV: 'USD',
  SX: 'ANG',
  SY: 'SYP',
  SZ: 'SZL',
  TC: 'USD',
  TD: 'XAF',
  TF: 'EUR',
  TG: 'XOF',
  TH: 'THB',
  TJ: 'TJS',
  TK: 'NZD',
  TL: 'USD',
  TM: 'TMT',
  TN: 'TND',
  TO: 'TOP',
  TR: 'TRY',
  TT: 'TTD',
  TV: 'AUD',
  TW: 'TWD',
  TZ: 'TZS',
  UA: 'UAH',
  UG: 'UGX',
  UM: 'USD',
  US: 'USD',
  UY: 'UYU',
  UZ: 'UZS',
  VA: 'EUR',
  VC: 'XCD',
  VE: 'VES',
  VG: 'USD',
  VI: 'USD',
  VN: 'VND',
  VU: 'VUV',
  WF: 'XPF',
  WS: 'WST',
  YE: 'YER',
  YT: 'EUR',
  ZA: 'ZAR',
  ZM: 'ZMW',
  ZW: 'ZWL',
}

export const currencies: Record<keyof typeof CurrencyCode, CurrencyInfo> = {
  EUR: {
    i18nKey: 'EUR',
    currencySymbol: '€',
    decimalSeparator: altDecimal,
    thousandSeparator: altThousand,
    symbolPosition: 'suffix', // in Deutschland üblich: "1.234,56 €"
    formatter: createFormatter('€', altDecimal, altThousand, 'suffix'),
  },
  USD: {
    i18nKey: 'USD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  AED: {
    i18nKey: 'AED',
    currencySymbol: 'د.إ',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'د.إ',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  AFN: {
    i18nKey: 'AFN',
    currencySymbol: '؋',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // in Afghanistan wird das Symbol oft nachgestellt
    formatter: createFormatter('؋', defaultDecimal, defaultThousand, 'suffix'),
  },
  ALL: {
    i18nKey: 'ALL',
    currencySymbol: 'L',
    decimalSeparator: altDecimal,
    thousandSeparator: altThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('L', altDecimal, altThousand, 'prefix'),
  },
  AMD: {
    i18nKey: 'AMD',
    currencySymbol: '֏',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('֏', defaultDecimal, defaultThousand, 'prefix'),
  },
  ANG: {
    i18nKey: 'ANG',
    currencySymbol: 'ƒ',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('ƒ', defaultDecimal, defaultThousand, 'prefix'),
  },
  AOA: {
    i18nKey: 'AOA',
    currencySymbol: 'Kz',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Kz', defaultDecimal, defaultThousand, 'prefix'),
  },
  ARS: {
    i18nKey: 'ARS',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  AUD: {
    i18nKey: 'AUD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  AWG: {
    i18nKey: 'AWG',
    currencySymbol: 'ƒ',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('ƒ', defaultDecimal, defaultThousand, 'prefix'),
  },
  AZN: {
    i18nKey: 'AZN',
    currencySymbol: '₼',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₼', defaultDecimal, defaultThousand, 'prefix'),
  },
  BAM: {
    i18nKey: 'BAM',
    currencySymbol: 'KM',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('KM', defaultDecimal, defaultThousand, 'prefix'),
  },
  BBD: {
    i18nKey: 'BBD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  BDT: {
    i18nKey: 'BDT',
    currencySymbol: '৳',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('৳', defaultDecimal, defaultThousand, 'prefix'),
  },
  BGN: {
    i18nKey: 'BGN',
    currencySymbol: 'лв',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('лв', defaultDecimal, defaultThousand, 'prefix'),
  },
  BHD: {
    i18nKey: 'BHD',
    currencySymbol: '.د.ب',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // Häufig nachgestellt
    formatter: createFormatter(
      '.د.ب',
      defaultDecimal,
      defaultThousand,
      'suffix'
    ),
  },
  BIF: {
    i18nKey: 'BIF',
    currencySymbol: 'FBu',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'FBu',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  BMD: {
    i18nKey: 'BMD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  BND: {
    i18nKey: 'BND',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  BOB: {
    i18nKey: 'BOB',
    currencySymbol: 'Bs',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Bs', defaultDecimal, defaultThousand, 'prefix'),
  },
  BRL: {
    i18nKey: 'BRL',
    currencySymbol: 'R$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('R$', defaultDecimal, defaultThousand, 'prefix'),
  },
  BSD: {
    i18nKey: 'BSD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  BTC: {
    i18nKey: 'BTC',
    currencySymbol: '₿',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₿', defaultDecimal, defaultThousand, 'prefix'),
  },
  BTN: {
    i18nKey: 'BTN',
    currencySymbol: 'Nu.',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'Nu.',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  BWP: {
    i18nKey: 'BWP',
    currencySymbol: 'P',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('P', defaultDecimal, defaultThousand, 'prefix'),
  },
  BYN: {
    i18nKey: 'BYN',
    currencySymbol: 'Br',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Br', defaultDecimal, defaultThousand, 'prefix'),
  },
  BYR: {
    i18nKey: 'BYR',
    currencySymbol: 'Br',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Br', defaultDecimal, defaultThousand, 'prefix'),
  },
  BZD: {
    i18nKey: 'BZD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  CAD: {
    i18nKey: 'CAD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  CDF: {
    i18nKey: 'CDF',
    currencySymbol: 'FC',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('FC', defaultDecimal, defaultThousand, 'prefix'),
  },
  CHF: {
    i18nKey: 'CHF',
    currencySymbol: 'Fr',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Fr', defaultDecimal, defaultThousand, 'prefix'),
  },
  CLF: {
    i18nKey: 'CLF',
    currencySymbol: 'UF',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('UF', defaultDecimal, defaultThousand, 'prefix'),
  },
  CLP: {
    i18nKey: 'CLP',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  CNH: {
    i18nKey: 'CNH',
    currencySymbol: 'CN¥',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'CN¥',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  CNY: {
    i18nKey: 'CNY',
    currencySymbol: '¥',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('¥', defaultDecimal, defaultThousand, 'prefix'),
  },
  COP: {
    i18nKey: 'COP',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  CRC: {
    i18nKey: 'CRC',
    currencySymbol: '₡',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₡', defaultDecimal, defaultThousand, 'prefix'),
  },
  CUC: {
    i18nKey: 'CUC',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  CUP: {
    i18nKey: 'CUP',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  CVE: {
    i18nKey: 'CVE',
    currencySymbol: 'Esc',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'Esc',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  CZK: {
    i18nKey: 'CZK',
    currencySymbol: 'Kč',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // üblich: "1 234,56 Kč"
    formatter: createFormatter('Kč', defaultDecimal, defaultThousand, 'suffix'),
  },
  DJF: {
    i18nKey: 'DJF',
    currencySymbol: 'Fdj',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'Fdj',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  DKK: {
    i18nKey: 'DKK',
    currencySymbol: 'kr',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // z. B. "1.234,56 kr"
    formatter: createFormatter('kr', defaultDecimal, defaultThousand, 'suffix'),
  },
  DOP: {
    i18nKey: 'DOP',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  DZD: {
    i18nKey: 'DZD',
    currencySymbol: 'دج',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('دج', defaultDecimal, defaultThousand, 'prefix'),
  },
  EGP: {
    i18nKey: 'EGP',
    currencySymbol: '£',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // z. B. "1,234.56 £"
    formatter: createFormatter('£', defaultDecimal, defaultThousand, 'suffix'),
  },
  ERN: {
    i18nKey: 'ERN',
    currencySymbol: 'Nkf',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'Nkf',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  ETB: {
    i18nKey: 'ETB',
    currencySymbol: 'Br',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Br', defaultDecimal, defaultThousand, 'prefix'),
  },
  FJD: {
    i18nKey: 'FJD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  FKP: {
    i18nKey: 'FKP',
    currencySymbol: '£',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('£', defaultDecimal, defaultThousand, 'prefix'),
  },
  GBP: {
    i18nKey: 'GBP',
    currencySymbol: '£',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('£', defaultDecimal, defaultThousand, 'prefix'),
  },
  GEL: {
    i18nKey: 'GEL',
    currencySymbol: '₾',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₾', defaultDecimal, defaultThousand, 'prefix'),
  },
  GGP: {
    i18nKey: 'GGP',
    currencySymbol: '£',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('£', defaultDecimal, defaultThousand, 'prefix'),
  },
  GHS: {
    i18nKey: 'GHS',
    currencySymbol: '₵',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₵', defaultDecimal, defaultThousand, 'prefix'),
  },
  GIP: {
    i18nKey: 'GIP',
    currencySymbol: '£',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('£', defaultDecimal, defaultThousand, 'prefix'),
  },
  GMD: {
    i18nKey: 'GMD',
    currencySymbol: 'D',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('D', defaultDecimal, defaultThousand, 'prefix'),
  },
  GNF: {
    i18nKey: 'GNF',
    currencySymbol: 'FG',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('FG', defaultDecimal, defaultThousand, 'prefix'),
  },
  GTQ: {
    i18nKey: 'GTQ',
    currencySymbol: 'Q',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Q', defaultDecimal, defaultThousand, 'prefix'),
  },
  GYD: {
    i18nKey: 'GYD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  HKD: {
    i18nKey: 'HKD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  HNL: {
    i18nKey: 'HNL',
    currencySymbol: 'L',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('L', defaultDecimal, defaultThousand, 'prefix'),
  },
  HRK: {
    i18nKey: 'HRK',
    currencySymbol: 'kn',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // üblich: "1.234,56 kn"
    formatter: createFormatter('kn', defaultDecimal, defaultThousand, 'suffix'),
  },
  HTG: {
    i18nKey: 'HTG',
    currencySymbol: 'G',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('G', defaultDecimal, defaultThousand, 'prefix'),
  },
  HUF: {
    i18nKey: 'HUF',
    currencySymbol: 'Ft',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // in Ungarn wird "Ft" oft nachgestellt
    formatter: createFormatter('Ft', defaultDecimal, defaultThousand, 'suffix'),
  },
  IDR: {
    i18nKey: 'IDR',
    currencySymbol: 'Rp',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Rp', defaultDecimal, defaultThousand, 'prefix'),
  },
  ILS: {
    i18nKey: 'ILS',
    currencySymbol: '₪',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₪', defaultDecimal, defaultThousand, 'prefix'),
  },
  IMP: {
    i18nKey: 'IMP',
    currencySymbol: '£',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('£', defaultDecimal, defaultThousand, 'prefix'),
  },
  INR: {
    i18nKey: 'INR',
    currencySymbol: '₹',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₹', defaultDecimal, defaultThousand, 'prefix'),
  },
  IQD: {
    i18nKey: 'IQD',
    currencySymbol: 'ع.د',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // häufig nachgestellt
    formatter: createFormatter(
      'ع.د',
      defaultDecimal,
      defaultThousand,
      'suffix'
    ),
  },
  IRR: {
    i18nKey: 'IRR',
    currencySymbol: '﷼',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // häufig nachgestellt
    formatter: createFormatter('﷼', defaultDecimal, defaultThousand, 'suffix'),
  },
  ISK: {
    i18nKey: 'ISK',
    currencySymbol: 'kr',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // in Island üblich
    formatter: createFormatter('kr', defaultDecimal, defaultThousand, 'suffix'),
  },
  JEP: {
    i18nKey: 'JEP',
    currencySymbol: '£',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('£', defaultDecimal, defaultThousand, 'prefix'),
  },
  JMD: {
    i18nKey: 'JMD',
    currencySymbol: 'J$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('J$', defaultDecimal, defaultThousand, 'prefix'),
  },
  JOD: {
    i18nKey: 'JOD',
    currencySymbol: 'د.ا',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'د.ا',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  JPY: {
    i18nKey: 'JPY',
    currencySymbol: '¥',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('¥', defaultDecimal, defaultThousand, 'prefix'),
  },
  KES: {
    i18nKey: 'KES',
    currencySymbol: 'KSh',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'KSh',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  KGS: {
    i18nKey: 'KGS',
    currencySymbol: 'som',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'som',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  KHR: {
    i18nKey: 'KHR',
    currencySymbol: '៛',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('៛', defaultDecimal, defaultThousand, 'prefix'),
  },
  KMF: {
    i18nKey: 'KMF',
    currencySymbol: 'CF',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('CF', defaultDecimal, defaultThousand, 'prefix'),
  },
  KPW: {
    i18nKey: 'KPW',
    currencySymbol: '₩',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₩', defaultDecimal, defaultThousand, 'prefix'),
  },
  KRW: {
    i18nKey: 'KRW',
    currencySymbol: '₩',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₩', defaultDecimal, defaultThousand, 'prefix'),
  },
  KWD: {
    i18nKey: 'KWD',
    currencySymbol: 'د.ك',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'د.ك',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  KYD: {
    i18nKey: 'KYD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  KZT: {
    i18nKey: 'KZT',
    currencySymbol: '₸',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₸', defaultDecimal, defaultThousand, 'prefix'),
  },
  LAK: {
    i18nKey: 'LAK',
    currencySymbol: '₭',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₭', defaultDecimal, defaultThousand, 'prefix'),
  },
  LBP: {
    i18nKey: 'LBP',
    currencySymbol: 'ل.ل',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // üblicherweise nachgestellt
    formatter: createFormatter(
      'ل.ل',
      defaultDecimal,
      defaultThousand,
      'suffix'
    ),
  },
  LKR: {
    i18nKey: 'LKR',
    currencySymbol: 'Rs',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Rs', defaultDecimal, defaultThousand, 'prefix'),
  },
  LRD: {
    i18nKey: 'LRD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  LSL: {
    i18nKey: 'LSL',
    currencySymbol: 'L',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('L', defaultDecimal, defaultThousand, 'prefix'),
  },
  LTL: {
    i18nKey: 'LTL',
    currencySymbol: 'Lt',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Lt', defaultDecimal, defaultThousand, 'prefix'),
  },
  LVL: {
    i18nKey: 'LVL',
    currencySymbol: 'Ls',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Ls', defaultDecimal, defaultThousand, 'prefix'),
  },
  LYD: {
    i18nKey: 'LYD',
    currencySymbol: 'ل.د',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'ل.د',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  MAD: {
    i18nKey: 'MAD',
    currencySymbol: 'د.م.',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'د.م.',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  MDL: {
    i18nKey: 'MDL',
    currencySymbol: 'L',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('L', defaultDecimal, defaultThousand, 'prefix'),
  },
  MGA: {
    i18nKey: 'MGA',
    currencySymbol: 'Ar',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Ar', defaultDecimal, defaultThousand, 'prefix'),
  },
  MKD: {
    i18nKey: 'MKD',
    currencySymbol: 'ден',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // z. B. "1.234,56 ден"
    formatter: createFormatter(
      'ден',
      defaultDecimal,
      defaultThousand,
      'suffix'
    ),
  },
  MMK: {
    i18nKey: 'MMK',
    currencySymbol: 'K',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('K', defaultDecimal, defaultThousand, 'prefix'),
  },
  MNT: {
    i18nKey: 'MNT',
    currencySymbol: '₮',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₮', defaultDecimal, defaultThousand, 'prefix'),
  },
  MOP: {
    i18nKey: 'MOP',
    currencySymbol: 'MOP$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'MOP$',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  MRO: {
    i18nKey: 'MRO',
    currencySymbol: 'UM',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('UM', defaultDecimal, defaultThousand, 'prefix'),
  },
  MRU: {
    i18nKey: 'MRU',
    currencySymbol: 'UM',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('UM', defaultDecimal, defaultThousand, 'prefix'),
  },
  MUR: {
    i18nKey: 'MUR',
    currencySymbol: '₨',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₨', defaultDecimal, defaultThousand, 'prefix'),
  },
  MVR: {
    i18nKey: 'MVR',
    currencySymbol: 'Rf',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Rf', defaultDecimal, defaultThousand, 'prefix'),
  },
  MWK: {
    i18nKey: 'MWK',
    currencySymbol: 'MK',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('MK', defaultDecimal, defaultThousand, 'prefix'),
  },
  MXN: {
    i18nKey: 'MXN',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  MYR: {
    i18nKey: 'MYR',
    currencySymbol: 'RM',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('RM', defaultDecimal, defaultThousand, 'prefix'),
  },
  MZN: {
    i18nKey: 'MZN',
    currencySymbol: 'MT',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('MT', defaultDecimal, defaultThousand, 'prefix'),
  },
  NAD: {
    i18nKey: 'NAD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  NGN: {
    i18nKey: 'NGN',
    currencySymbol: '₦',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₦', defaultDecimal, defaultThousand, 'prefix'),
  },
  NIO: {
    i18nKey: 'NIO',
    currencySymbol: 'C$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('C$', defaultDecimal, defaultThousand, 'prefix'),
  },
  NOK: {
    i18nKey: 'NOK',
    currencySymbol: 'kr',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // z. B. "1.234,56 kr"
    formatter: createFormatter('kr', defaultDecimal, defaultThousand, 'suffix'),
  },
  NPR: {
    i18nKey: 'NPR',
    currencySymbol: 'Rs',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Rs', defaultDecimal, defaultThousand, 'prefix'),
  },
  NZD: {
    i18nKey: 'NZD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  OMR: {
    i18nKey: 'OMR',
    currencySymbol: 'ر.ع.',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // z. B. "1,234.56 ر.ع."
    formatter: createFormatter(
      'ر.ع.',
      defaultDecimal,
      defaultThousand,
      'suffix'
    ),
  },
  PAB: {
    i18nKey: 'PAB',
    currencySymbol: 'B/.',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'B/.',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  PEN: {
    i18nKey: 'PEN',
    currencySymbol: 'S/.',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'S/.',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  PGK: {
    i18nKey: 'PGK',
    currencySymbol: 'K',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('K', defaultDecimal, defaultThousand, 'prefix'),
  },
  PHP: {
    i18nKey: 'PHP',
    currencySymbol: '₱',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₱', defaultDecimal, defaultThousand, 'prefix'),
  },
  PKR: {
    i18nKey: 'PKR',
    currencySymbol: '₨',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₨', defaultDecimal, defaultThousand, 'prefix'),
  },
  PLN: {
    i18nKey: 'PLN',
    currencySymbol: 'zł',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // in Polen üblich: "1 234,56 zł"
    formatter: createFormatter('zł', defaultDecimal, defaultThousand, 'suffix'),
  },
  PYG: {
    i18nKey: 'PYG',
    currencySymbol: '₲',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₲', defaultDecimal, defaultThousand, 'prefix'),
  },
  QAR: {
    i18nKey: 'QAR',
    currencySymbol: 'ر.ق',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'ر.ق',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  RON: {
    i18nKey: 'RON',
    currencySymbol: 'lei',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // üblich: "1.234,56 lei"
    formatter: createFormatter(
      'lei',
      defaultDecimal,
      defaultThousand,
      'suffix'
    ),
  },
  RSD: {
    i18nKey: 'RSD',
    currencySymbol: 'Дин.',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'Дин.',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  RUB: {
    i18nKey: 'RUB',
    currencySymbol: '₽',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₽', defaultDecimal, defaultThousand, 'prefix'),
  },
  RWF: {
    i18nKey: 'RWF',
    currencySymbol: 'FRw',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'FRw',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  SAR: {
    i18nKey: 'SAR',
    currencySymbol: '﷼',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('﷼', defaultDecimal, defaultThousand, 'prefix'),
  },
  SBD: {
    i18nKey: 'SBD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  SCR: {
    i18nKey: 'SCR',
    currencySymbol: '₨',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₨', defaultDecimal, defaultThousand, 'prefix'),
  },
  SDG: {
    i18nKey: 'SDG',
    currencySymbol: 'ج.س.',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'ج.س.',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  SEK: {
    i18nKey: 'SEK',
    currencySymbol: 'kr',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // z. B. "1.234,56 kr"
    formatter: createFormatter('kr', defaultDecimal, defaultThousand, 'suffix'),
  },
  SGD: {
    i18nKey: 'SGD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  SHP: {
    i18nKey: 'SHP',
    currencySymbol: '£',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('£', defaultDecimal, defaultThousand, 'prefix'),
  },
  SLE: {
    i18nKey: 'SLE',
    currencySymbol: 'Le',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Le', defaultDecimal, defaultThousand, 'prefix'),
  },
  SLL: {
    i18nKey: 'SLL',
    currencySymbol: 'Le',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Le', defaultDecimal, defaultThousand, 'prefix'),
  },
  SOS: {
    i18nKey: 'SOS',
    currencySymbol: 'Sh.',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'Sh.',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  SRD: {
    i18nKey: 'SRD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  STD: {
    i18nKey: 'STD',
    currencySymbol: 'Db',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Db', defaultDecimal, defaultThousand, 'prefix'),
  },
  SVC: {
    i18nKey: 'SVC',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  SYP: {
    i18nKey: 'SYP',
    currencySymbol: '£',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('£', defaultDecimal, defaultThousand, 'prefix'),
  },
  SZL: {
    i18nKey: 'SZL',
    currencySymbol: 'E',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('E', defaultDecimal, defaultThousand, 'prefix'),
  },
  THB: {
    i18nKey: 'THB',
    currencySymbol: '฿',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('฿', defaultDecimal, defaultThousand, 'prefix'),
  },
  TJS: {
    i18nKey: 'TJS',
    currencySymbol: 'SM',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('SM', defaultDecimal, defaultThousand, 'prefix'),
  },
  TMT: {
    i18nKey: 'TMT',
    currencySymbol: 'm',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('m', defaultDecimal, defaultThousand, 'prefix'),
  },
  TND: {
    i18nKey: 'TND',
    currencySymbol: 'د.ت',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'د.ت',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  TOP: {
    i18nKey: 'TOP',
    currencySymbol: 'T$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('T$', defaultDecimal, defaultThousand, 'prefix'),
  },
  TRY: {
    i18nKey: 'TRY',
    currencySymbol: '₺',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('₺', defaultDecimal, defaultThousand, 'prefix'),
  },
  TTD: {
    i18nKey: 'TTD',
    currencySymbol: 'TT$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'TT$',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  TWD: {
    i18nKey: 'TWD',
    currencySymbol: 'NT$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'NT$',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  TZS: {
    i18nKey: 'TZS',
    currencySymbol: 'TSh',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'TSh',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  UAH: {
    i18nKey: 'UAH',
    currencySymbol: '₴',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // z. B. "1 234,56 ₴"
    formatter: createFormatter('₴', defaultDecimal, defaultThousand, 'suffix'),
  },
  UGX: {
    i18nKey: 'UGX',
    currencySymbol: 'USh',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'USh',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  UYU: {
    i18nKey: 'UYU',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  UZS: {
    i18nKey: 'UZS',
    currencySymbol: 'сўм',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'сўм',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  VEF: {
    i18nKey: 'VEF',
    currencySymbol: 'Bs',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Bs', defaultDecimal, defaultThousand, 'prefix'),
  },
  VES: {
    i18nKey: 'VES',
    currencySymbol: 'Bs',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Bs', defaultDecimal, defaultThousand, 'prefix'),
  },
  VND: {
    i18nKey: 'VND',
    currencySymbol: '₫',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // in Vietnam üblich: "1.234,56 ₫"
    formatter: createFormatter('₫', defaultDecimal, defaultThousand, 'suffix'),
  },
  VUV: {
    i18nKey: 'VUV',
    currencySymbol: 'Vt',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Vt', defaultDecimal, defaultThousand, 'prefix'),
  },
  WST: {
    i18nKey: 'WST',
    currencySymbol: 'WS$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'WS$',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  XAF: {
    i18nKey: 'XAF',
    currencySymbol: 'Fr',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Fr', defaultDecimal, defaultThousand, 'prefix'),
  },
  XAG: {
    i18nKey: 'XAG',
    currencySymbol: 'XAG',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'XAG',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  XAU: {
    i18nKey: 'XAU',
    currencySymbol: 'XAU',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'XAU',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  XCD: {
    i18nKey: 'XCD',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
  XDR: {
    i18nKey: 'XDR',
    currencySymbol: 'XDR',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter(
      'XDR',
      defaultDecimal,
      defaultThousand,
      'prefix'
    ),
  },
  XOF: {
    i18nKey: 'XOF',
    currencySymbol: 'Fr',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Fr', defaultDecimal, defaultThousand, 'prefix'),
  },
  XPF: {
    i18nKey: 'XPF',
    currencySymbol: 'Fr',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('Fr', defaultDecimal, defaultThousand, 'prefix'),
  },
  YER: {
    i18nKey: 'YER',
    currencySymbol: '﷼',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'suffix', // z. B. "1,234.56 ﷼"
    formatter: createFormatter('﷼', defaultDecimal, defaultThousand, 'suffix'),
  },
  ZAR: {
    i18nKey: 'ZAR',
    currencySymbol: 'R',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('R', defaultDecimal, defaultThousand, 'prefix'),
  },
  ZMK: {
    i18nKey: 'ZMK',
    currencySymbol: 'ZK',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('ZK', defaultDecimal, defaultThousand, 'prefix'),
  },
  ZMW: {
    i18nKey: 'ZMW',
    currencySymbol: 'ZK',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('ZK', defaultDecimal, defaultThousand, 'prefix'),
  },
  ZWL: {
    i18nKey: 'ZWL',
    currencySymbol: '$',
    decimalSeparator: defaultDecimal,
    thousandSeparator: defaultThousand,
    symbolPosition: 'prefix',
    formatter: createFormatter('$', defaultDecimal, defaultThousand, 'prefix'),
  },
}
