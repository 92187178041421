<template>
  <DeleteConfirmDialog
    v-model:visible="disconnectDialogVisibility"
    :loading="deleteLoading"
    :title="i18n.t('disconnectDataSource')"
    :sub-title="
      i18n.t('disconnectDataSourceDescription', {
        name: dataSource.name,
      })
    "
    @cancel="disconnectDialogVisibility = false"
    @delete="disconnectDatasource" />
  <SmDrawer
    v-model:visibility="visibility"
    :level="2"
    :title="props.dataSource.name">
    <template #close>
      <div class="dropdown-item delete-user" @click="handleDisconnectClick">
        <v-icon name="md-delete-round" class="trash-icon" scale="1.1" />
      </div>
      <SmButtonClose @close="visibility = false" />
    </template>

    <el-form
      ref="formRef"
      v-model="formData"
      :model="formData"
      :rules="formRules"
      label-position="top"
      require-asterisk-position="right">
      <el-form-item prop="name" label="Name:">
        <el-input v-model="formData.name" />
      </el-form-item>

      <BaseSoftwareRequirements
        v-model:data-source="formData"
        is-software-already-existing />

      <div class="button-wrapper">
        <el-button @click="handleCancel">
          {{ i18n.t('general.cancel') }}
        </el-button>
        <el-button
          type="primary"
          :loading="saveLoading"
          data-tutorial-id="application-create-button"
          @click="handleSave">
          {{ i18n.t('general.save') }}
        </el-button>
      </div>
    </el-form>
  </SmDrawer>
</template>

<script setup lang="ts">
  import { DataSource, DataSourcesService, DataSourceIn } from '@/client'
  import { useI18n } from 'vue-i18n'
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'

  const props = defineProps<{ dataSource: DataSource }>()
  const emit = defineEmits<{ refetchDataSource: [] }>()
  const i18n = useI18n()
  const disconnectDialogVisibility = ref(false)
  const visibility = defineModel<boolean>('visibility')
  const router = useRouter()
  const deleteLoading = ref(false)
  const saveLoading = ref(false)
  const formData = ref<
    DataSourceIn & {
      type: string
    }
  >({
    name: props.dataSource.name,
    tags: props.dataSource.tags,
    config: props.dataSource.config,
    type: 'token',
  })

  function handleDisconnectClick() {
    disconnectDialogVisibility.value = true
  }

  function handleSave() {
    saveLoading.value = true
    DataSourcesService.putDataSourcesApiV1DataSourceDataSourcesDataSourceIdPut({
      dataSourceId: props.dataSource._id,
      requestBody: formData.value,
    })
      .then(() => {
        visibility.value = false
        emit('refetchDataSource')
      })
      .finally(() => {
        saveLoading.value = false
      })
  }

  function disconnectDatasource() {
    DataSourcesService.deleteDataSourceApiV1DataSourceDataSourcesDataSourceIdDelete(
      {
        dataSourceId: props.dataSource._id,
      }
    ).then(() => {
      visibility.value = false
      router.push({
        name: 'Data-Sources',
      })
    })
  }
  function handleCancel() {
    visibility.value = false
  }

  const formRules = {
    name: [
      { required: true, message: i18n.t('fieldIsRequired'), trigger: 'blur' },
    ],
  }
</script>

<style scoped lang="scss">
  .button-wrapper {
    float: right;
  }
</style>
