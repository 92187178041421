<template>
  <DatevImportDialog v-model:visibility="datevImportDialogVisible" />

  <div class="home datasource">
    <div class="fade-out" />

    <div class="datasources-header">
      <ViewHeader :title-i18n-code="'dataSources'" />
      <div class="data-source-stats">
        <div class="sm-card data-source-stats-card">
          <StatsElement
            display-i18n-key="dataSource.connected"
            icon="md-link"
            :display-number="connectedDataSources?.length"
            color="yellow" />
        </div>

        <div class="sm-card data-source-stats-card">
          <StatsElement
            display-i18n-key="employeesDetected"
            icon="md-group"
            :display-number="employees?.length"
            color="red" />
        </div>

        <div class="sm-card data-source-stats-card">
          <StatsElement
            display-i18n-key="datev.import"
            icon="md-receiptlong"
            :display-number="datevImports?.length"
            color="purple" />
        </div>

        <div class="sm-card data-source-stats-card">
          <StatsElement
            display-i18n-key="datev.inProgress"
            icon="md-sync"
            :display-number="
              datevImports?.filter((a) => a.status === 'in_progress').length
            "
            color="primary" />
        </div>
      </div>
    </div>

    <div class="datasource-content">
      <div class="datasource-header">
        <h2>{{ i18n.t('ssoProvider') }}</h2>
        <div class="data-source-description">
          <p>
            {{ i18n.t('ssoProviderDescription') }}
          </p>
        </div>
      </div>

      <div class="data-source-wrapper datev">
        <template v-if="loading">
          <SmSkeleton
            v-for="i in 3"
            :key="i"
            height="200px"
            width="100%"
            class="mb-4 !rounded-lg !bg-gray-100 dark:!bg-gray-800" />
        </template>
        <DatasourceCard
          v-for="ssoProvider in availableSSOProviders"
          v-else
          :key="ssoProvider.name"
          :loading="loading"
          :datasource="ssoProvider"
          :connected="connectedSSOProvidersNames.includes(ssoProvider.name)"
          :connection="getSSOConnection(ssoProvider)" />
      </div>

      <h2>{{ i18n.t('hrTools') }}</h2>
      <div class="data-source-description">
        <p>
          {{ i18n.t('hrToolDescription') }}
        </p>
      </div>

      <div class="data-source-wrapper">
        <template v-if="loading">
          <SmSkeleton
            v-for="i in 12"
            :key="i"
            height="200px"
            width="100%"
            class="!rounded-lg !bg-gray-100 dark:!bg-gray-800" />
        </template>
        <DatasourceCard
          v-for="dataSource in dataSourcesSorted"
          v-else
          :key="dataSource.integration_id"
          :loading="loading"
          :datasource="dataSource"
          :connected="isDataSourceConnected(dataSource)"
          :connection="getDataSourceConnection(dataSource) as DataSource" />
      </div>

      <!-- TODO: Refactor this into a separate component -->
      <h2>{{ i18n.t('datevImport') }}</h2>
      <div class="data-source-description">
        <p>
          {{ i18n.t('datevImportDescription') }}
        </p>
      </div>

      <div class="data-source-wrapper datev">
        <!-- Datev Card -->
        <div class="data-source-card sm-card">
          <div class="data-source-header">
            <h3>{{ i18n.t('DATEV') }}</h3>
            <div class="data-source-media" style="background-color: #93bd55">
              <img
                src="https://logos.saasmetrix.io/Datev_white.png"
                :alt="i18n.t('logo')" />
            </div>
          </div>
          <p>{{ i18n.t('datevUploadDescription') }}</p>

          <div class="data-source-footer">
            <SmButton
              v-require-permission="CompanyAccessRole.ADMIN"
              style="width: 100%"
              @click="datevImportDialogVisible = true">
              {{ i18n.t('datev.upload') }}
            </SmButton>
          </div>
        </div>

        <!-- Imports -->
        <div
          v-for="datevImport in datevImports"
          :key="datevImport._id"
          class="sm-card data-source-stats-card">
          <!-- Icon -->
          <div
            class="sm-icon"
            :class="{ danger: datevImport.status === 'error' }">
            <v-icon
              v-if="datevImport.status === 'in_progress'"
              name="md-sync"
              scale="1.4"
              style="fill: #93bd55" />
            <v-icon
              v-else-if="datevImport.status === 'done'"
              name="md-check-round"
              scale="1.4"
              style="fill: #93bd55" />
            <v-icon
              v-else-if="datevImport.status === 'error'"
              name="md-close"
              scale="1.4"
              class="fill-red" />
          </div>
          <div style="display: flex; flex-direction: column">
            <TextMultiline>
              <b>{{ datevImport.name || datevImport.filename }}</b>
            </TextMultiline>
            <span v-if="datevImport.date"
              >{{ i18n.t('created') }}:
              {{
                displayDate(new Date(datevImport.date), { dateStyle: 'short' })
              }}</span
            >
            <span v-if="datevImport.status"
              >{{ i18n.t('status') }}:
              {{ i18n.t(datevImport.status?.toString()) }}</span
            >
          </div>
          <div>
            <SmDropdown>
              <SmDropdownItem
                v-if="datevImport._id"
                icon="md-clouddownload-round"
                @click="downloadDatevImportCSV(datevImport._id)">
                {{ i18n.t('download') }}
              </SmDropdownItem>

              <SmDropdownItem
                v-if="datevImport._id"
                icon="md-delete-round"
                hover-color="var(--sm-magenta)"
                @click="deleteDatevImport(datevImport._id)">
                {{ i18n.t('delete') }}
              </SmDropdownItem>
            </SmDropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useDocumentVisibility } from '@vueuse/core'

  import { useI18n } from 'vue-i18n'

  import {
    AccountingImportOut,
    AccountingService,
    CompanyAccessRole,
    DataSource,
    DataSourceInformation,
    DataSourceIntegrationsService,
    DataSourcesService,
    Employee,
    EmployeesService,
    OpenAPI,
    SoftwareInformation,
    SoftwareIntegrationsService,
    SoftwareOut,
  } from '@/client'
  import { sendToast } from '@/components/sm/SmNotification'
  import { ApplicationStore } from '@/stores/application.store'
  import { displayDate } from '@/common/util/timeUtils'

  const i18n = useI18n()

  const employees = ref<null | Employee[]>(null)

  // ##############################
  // #  Data Sources
  // ##############################

  const dataSources = ref<null | DataSourceInformation[]>(null)
  const connectedDataSources = ref<null | DataSource[]>(null)

  onMounted(async () => {
    getAvailableDataSources()
    reloadConnectedDataSources()
    loadEmployees()
  })

  const connectedDataSourcesIntegrationIds = computed(() => {
    return connectedDataSources.value?.map(
      (dataSource) => dataSource.integration_id
    )
  })

  function getAvailableDataSources() {
    // Get all available data sources
    DataSourceIntegrationsService.getAvailableDataSourcesApiV1DataSourceAvailableGet(
      {
        excludeConnected: false,
      }
    ).then((res) => {
      dataSources.value = res
    })
  }

  // When return to this page, refresh the data sources
  const visibility = useDocumentVisibility()
  watch(visibility, async (current, previous) => {
    if (current === 'visible' && previous === 'hidden') {
      reloadConnectedDataSources()
      getAvailableSSOProviders()
      getConnectedSSOProviders()
    }
  })

  const dataSourcesSorted = computed(() => {
    // First sort by connected and then by name
    const conntected: Array<DataSourceInformation> = []
    const notConnected: Array<DataSourceInformation> = []

    dataSources.value?.forEach((dataSource) => {
      if (isDataSourceConnected(dataSource)) {
        conntected.push(dataSource)
      } else {
        notConnected.push(dataSource)
      }
    })

    // Sort by name
    conntected.sort((a, b) => {
      return a.name.localeCompare(b.name)
    })

    notConnected.sort((a, b) => {
      return a.name.localeCompare(b.name)
    })

    return [...conntected, ...notConnected]
  })

  function reloadConnectedDataSources() {
    // Get all connected data sources
    DataSourcesService.getDataSourcesApiV1DataSourceDataSourcesGet({}).then(
      (res) => {
        connectedDataSources.value = res
      }
    )
  }

  function isDataSourceConnected(dataSource: DataSourceInformation) {
    return (
      connectedDataSourcesIntegrationIds.value?.includes(
        dataSource.integration_id
      ) || false
    )
  }

  function getDataSourceConnection(dataSource: DataSourceInformation) {
    return connectedDataSources.value?.find(
      (d) => d.integration_id === dataSource.integration_id
    )
  }

  // ##############################
  // #  SSO Providers
  // ##############################
  const loading = ref(false)
  const connectedSSOProviders = ref<SoftwareOut[]>([])
  const availableSSOProviders = ref<SoftwareInformation[]>([])

  onMounted(async () => {
    loading.value = true
    await getAvailableSSOProviders()
    await getConnectedSSOProviders()

    loading.value = false
  })

  function getSSOConnection(ssoProvider: SoftwareInformation) {
    return connectedSSOProviders.value.find(
      (s) => s.software_name === ssoProvider.name
    )
  }
  const connectedSSOProvidersNames = computed(() => {
    return connectedSSOProviders.value.map((s) => s.software_name)
  })

  async function getConnectedSSOProviders() {
    await ApplicationStore.getApplications().then((res) => {
      connectedSSOProviders.value = (res as SoftwareOut[]).filter(
        (s) => s.information.sso_provider
      )
    })
  }

  async function getAvailableSSOProviders() {
    await SoftwareIntegrationsService.getIntegrationsApiV1SoftwareIntegrationsGet(
      {}
    ).then((res) => {
      availableSSOProviders.value = res.filter((s) => s.sso_provider)
    })
  }

  // ##############################
  // #  Datev
  // ##############################
  const datevImports = ref<null | AccountingImportOut[]>(null)
  const datevImportDialogVisible = ref(false)

  onMounted(async () => {
    reloadDatevImports()
  })

  function reloadDatevImports() {
    AccountingService.getAccountingImportsApiV1AccountingAccountingImportsGet(
      {}
    ).then((res) => {
      datevImports.value = res
    })
  }

  function loadEmployees() {
    EmployeesService.getEmployeesApiV1DataSourceEmployeesGet({}).then((res) => {
      employees.value = res
    })
  }

  function downloadDatevImportCSV(id: string) {
    const urlSuffix = `/api/v1/accounting/accounting-imports/${id}/download`
    const urlBase = OpenAPI.BASE
    const url = urlBase + urlSuffix

    const headers = {
      Authorization: `Bearer ${OpenAPI.TOKEN}`,
    }

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((response) => response.blob())
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', 'datev.csv')
        document.body.appendChild(link)
        link.click()
      })
  }

  function deleteDatevImport(id: string) {
    AccountingService.deleteAccountingImportApiV1AccountingAccountingImportsAccountingImportIdDelete(
      {
        accountingImportId: id,
      }
    ).then(
      () => {
        reloadDatevImports()
        sendToast(i18n.t('datev.deleteSuccess'), undefined, 'success')
      },
      () => {
        sendToast(i18n.t('datev.deleteError'), undefined, 'error')
      }
    )
  }
</script>

<style lang="scss" scoped>
  .data-source-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
    margin-top: 20px;
  }

  .sm-card {
    background-color: var(--sm-elevation);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    padding: 16px;
    margin-bottom: 20px;
  }

  .data-source-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .data-source-stats-card {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .data-source-media {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    width: 43px;
    height: 43px;

    padding: 7px;

    box-shadow: var(--shadow);

    & > img {
      display: block;
      max-width: 28px;
      max-height: 28px;
      width: auto;
      height: auto;

      border-radius: 10%;
    }
  }

  .data-source-header {
    display: flex;
    gap: 5px;

    justify-content: space-between;
    align-items: center;
  }

  .data-source-footer {
    margin-top: auto;
    padding-top: 10px;

    display: flex;
    justify-content: center;
  }

  .data-source-stats {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  .datev .sm-icon {
    &::before {
      background-color: #93bd55;
    }

    &.danger::before {
      background-color: var(--sm-red);
    }
  }

  .datev .data-source-card {
    grid-row: span 2;
  }

  p {
    font-size: 14px;
    color: grey;
    margin-top: 0;
  }

  .datasource {
    display: grid;
    grid-template-rows: auto 1fr;

    .datasource-content {
      overflow-y: auto;
    }

    .fade-out {
      position: absolute;
      width: 100%;
      height: 1.5rem;

      background: linear-gradient(
        0deg,
        var(--sm-background) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      bottom: 20px;
    }
  }

  // Prevent the shadow from being cut off
  .home {
    padding-left: 0;
    padding-right: 0;

    > div {
      padding-left: var(--home-padding);
      padding-right: var(--home-padding);
    }
  }
</style>
