<template>
  <SmDialog
    v-model:visibility="visible"
    :title="i18n.t('createLicense')"
    class="dialog"
    size="medium">
    <SmSteps
      v-model:active-step-key="activeStepKey"
      content-height="calc(70vh - 4rem)"
      :steps="steps"
      class="steps-wrapper">
      <template #step-license-model-form>
        <LicenseModelForm
          ref="licenseModelForm"
          v-model:software-license="model"
          label-width="12rem"
          :license-model-id="model.license._id"
          :custom-validators="customValidators" />
      </template>

      <template #step-price-information-form>
        <PriceInformationForm
          ref="priceInfoForm"
          v-model:price-information="emptyForm"
          :is-fallback="false" />
      </template>
      <template #finish-button>
        <SmButton :loading="finishLoading" @click="handleFinish()">
          {{ i18n.t('create') }}
        </SmButton>
      </template>
    </SmSteps>
  </SmDialog>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import {
    PriceInformation,
    PriceInformationPostIn,
    SoftwareLicense,
    SoftwareLicensePostIn,
  } from '@/client'
  import { CustomValidator } from '@/common/util/licenseModel'
  import { sendToast } from './sm/SmNotification'

  // import { usePriceInformationStore } from '@/stores/priceInformationStore'

  import LicenseModelForm from './LicenseModelForm.vue'
  import PriceInformationForm from './PriceInformationForm.vue'
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { usePriceInformationStore } from '@/stores/priceInformationStore'
  import { saveNewSoftwareLicense } from '@/common/license'
  import { useApplicationDetailsStore } from '@/views/ApplicationsDetail/ApplicationDetailsView.store'
  import { usePreferenceStore } from '@/stores/preferenceStore'

  const props = defineProps<{
    visible: boolean
    customValidators?: CustomValidator[]
    preventSubmit?: boolean
  }>()

  const emit = defineEmits<{
    add: []
    'update:visible': [visible: boolean]
    cancelled: []
  }>()

  const applicationDetailsStore = useApplicationDetailsStore()
  const priceInformationStore = usePriceInformationStore()
  const preferenceStore = usePreferenceStore()
  const i18n = useI18n()
  const licenseModelForm = ref<ComponentExposed<
    typeof LicenseModelForm
  > | null>()
  const priceInfoForm = ref<ComponentExposed<
    typeof PriceInformationForm
  > | null>()

  const activeStepKey = ref('license-model-form')
  const visible = computed({
    get: () => props.visible,
    set: (val) => emit('update:visible', val),
  })
  const finishLoading = ref(false)

  const licenseModelRequestBody = ref<SoftwareLicensePostIn>()
  const priceInfoRequestBody = ref<PriceInformationPostIn>()

  const model = ref<{
    license: SoftwareLicense
  }>({
    license: {} as SoftwareLicense,
  })

  const emptyForm = ref<PriceInformation>({
    _id: '',
    description: '',
    currency: preferenceStore.preferences?.currency_code ?? 'EUR',
  } as PriceInformation)

  const steps = ref([
    {
      key: 'license-model-form',
      nextText: i18n.t('createPriceModel'),
      nextStepValidator: () => {
        return new Promise<boolean>((resolve) => {
          const result = licenseModelForm.value?.validate()

          if (result && licenseModelForm.value) {
            licenseModelRequestBody.value = licenseModelForm.value?.form.fields
          }
          resolve(result || false)
        })
      },
    },
    {
      key: 'price-information-form',
      backText: i18n.t('editLicense'),
    },
  ])

  async function handleFinish() {
    finishLoading.value = true
    const validatePriceInfoForm = priceInfoForm.value?.form.validate()
    const softwareId = applicationDetailsStore?.software?._id
    const licenseId = ref('')

    if (!validatePriceInfoForm) {
      sendToast(i18n.t('validationFailed'), undefined, 'error')
      finishLoading.value = false
      return
    } else {
      priceInfoRequestBody.value = priceInfoForm.value?.form.fields
    }

    if (
      !licenseModelRequestBody.value ||
      !priceInfoRequestBody.value ||
      !softwareId
    )
      return

    // create license model
    await saveNewSoftwareLicense(licenseModelRequestBody.value, softwareId)
      .then((res) => {
        licenseId.value = (res as SoftwareLicense)._id
      })
      .catch(() => {
        return
      })

    // create price information
    await priceInformationStore
      .createPriceInformation(licenseId.value, priceInfoRequestBody.value)
      .catch(() => {
        return
      })

    sendToast(i18n.t('licenseSaved'), undefined, 'success')
    emit('add')
    finishLoading.value = false
    visible.value = false
  }
</script>

<style scoped lang="scss">
  .steps-wrapper ::v-deep(.step-buttons--back) {
    transition: all 0.1s ease-in-out;
    margin-left: 0.25rem;

    &:hover {
      scale: 1;
      transform: translateX(2px);
    }
  }

  .steps-wrapper ::v-deep(.step-buttons--next) {
    transition: all 0.1s ease-in-out;
    margin-right: 0.25rem;
    &:hover {
      scale: 1;
      transform: translateX(-2px);
    }
  }
</style>
