<template>
  <div class="sidebarItem flex cursor-pointer items-center gap-3 p-3 pl-5 pr-5">
    <component :is="props.icon" />
    <span>{{ props.text }}</span>
  </div>
</template>

<script setup lang="ts">
  import { Paintbrush } from 'lucide-vue-next'

  interface Props {
    icon: Component
    text: string
  }

  const props = withDefaults(defineProps<Props>(), {
    icon: Paintbrush,
  })
</script>
