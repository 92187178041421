/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum BackgroundJobStatus {
    ENQUEUED = 'enqueued',
    ERROR = 'error',
    PAUSED = 'paused',
    USER_ACTION_REQUIRED = 'user_action_required',
    AWAITS_SOFTWARE_AUTHORIZATION = 'awaits_software_authorization',
    ABORTED = 'aborted',
    COMPLETED = 'completed',
    DEPRECATED = 'deprecated',
}
