import {
  Account,
  ActivityBaseModel,
  SoftwareLicense,
  UserProfilePublic,
  PriceInformation,
} from '@/client'
import { AccountStore } from '@/stores/account.store'
import { ApplicationStore } from '@/stores/application.store'

async function getApplicationNameById(id: string) {
  const app = await ApplicationStore.getApplicationById(id, undefined, {
    refetchFromApplication: false,
  })
  if (app === null) throw new Error(`Application not found for ID: ${id}`)
  return app.software_name
}

export async function getI18nArgs(
  activity: ActivityBaseModel,
  users: Account[],
  softwareLicenses: SoftwareLicense[],
  allPriceInformations: PriceInformation[],
  userProfile: UserProfilePublic
) {
  let textName: string | undefined
  let softwareName: string | undefined

  const crud_id =
    activity.data.created_id ||
    activity.data.deleted_id ||
    activity.data.updated_id

  switch (activity.action) {
    case 'licence.create':
    case 'licence.update':
    case 'licence.update_resend_invitation':
    case 'licence.update_assign_account':
    case 'licence.update_unassign_account':
    case 'licence.delete':
      if (!crud_id) break
      await AccountStore.getAccountById(crud_id).then(async (account) => {
        if (!account) return
        const license_account = users.find((u) => u._id == account?.account_id)
        textName = license_account?.name || account?.email
        softwareName = await getApplicationNameById(account?.software_id)
      })

      break

    case 'account.create':
    case 'account.create_from_alias':
    case 'account.update':
    case 'account.delete':
    case 'account.deactivate':
    case 'account.password.change':
    case 'account.password.reset':
    case 'account.alias.create':
    case 'account.alias.update':
    case 'account.alias.merge':
    case 'account.alias.delete': {
      const user = users.find((u) => u._id == crud_id)
      textName = user?.name || user?.email
      break
    }
    case 'login.password':
      break
    case 'software.create':
    case 'software.create_oauth':
    case 'software.re_authorize':
    case 'software.re_authorize_oauth':
    case 'software.update':
    case 'software.delete':
      if (crud_id) {
        softwareName = await getApplicationNameById(crud_id)
      }
      break
    case 'software.update_software_license.create':
    case 'software.update_software_license.update':
    case 'software.update_software_license.delete':
    case 'software_license.create':
    case 'software_license.update':
    case 'software_license.delete': {
      const license = softwareLicenses.find((s) => s._id == crud_id)
      if (license?.software_id) {
        softwareName = await getApplicationNameById(license.software_id)
      }
      break
    }
    case 'software_license_price_information.create':
    case 'software_license_price_information.update':
    case 'software_license_price_information.delete': {
      const priceInfo = allPriceInformations.find((s) => s._id == crud_id)
      const softwareLicense = softwareLicenses.find(
        (s) => s._id == priceInfo?.software_license_id
      )
      if (softwareLicense?.software_id) {
        softwareName = await getApplicationNameById(softwareLicense.software_id)
      }
      break
    }
  }

  return {
    email: textName,
    softwareName: softwareName,
    updated_licences: '',
    performedBy: userProfile.display_name,
  }
}
