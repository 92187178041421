import { Account, AccountUpdateIn, UsersService } from '@/client'
import { useFetch } from '@/common/useFetch'

export async function _getPersons(
  args: Parameters<typeof UsersService.getUsersApiV1ManagementUsersGet>[0] // Correctly type the argument
) {
  const { data, error, fetchData, loading } = useFetch(
    () => UsersService.getUsersApiV1ManagementUsersGet(args),
    null,
    { sendToast: false }
  )

  await fetchData()

  return { data: data.value, error: error.value, loading: loading.value }
}

export async function _getPerson(emailOrId: string) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      UsersService.getUserApiV1ManagementUsersEmailOrIdGet({
        emailOrId: emailOrId,
      }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _deletePerson(emailOrId: string) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      UsersService.deleteUserApiV1ManagementUsersEmailOrIdDelete({
        emailOrId: emailOrId,
      }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _postPerson(person: Account) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      UsersService.addUserApiV1ManagementUsersPost({
        requestBody: person,
      }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}

export async function _patchPerson(person: AccountUpdateIn) {
  const { data, error, fetchData, loading } = useFetch(
    () =>
      UsersService.putUserApiV1ManagementUsersPut({
        requestBody: person,
      }),
    null,
    { sendToast: true }
  )
  return fetchData().then(() => {
    return { data: data.value, error: error.value, loading: loading.value }
  })
}
