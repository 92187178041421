import { Account } from '@/client/index.js'
import { db } from './database.js'

export interface PersonTable {
  tags: string // JSON string representing an array of strings
  created: string // ISO 8601 formatted date-time string
  updated: string // ISO 8601 formatted date-time string
  deleted: number // Boolean stored as integer (0 or 1)
  deleted_at: string | null // Nullable ISO 8601 date-time string
  _id: string // Unique identifier, possibly a UUID
  email: string // Email
  name: string | null
  first_name: string | null
  last_name: string | null
  phone: string | null
  employee_id: string | null
  status: string | null
  aliases: string // JSON string representing a Record<string, Alias_Output>
}

export async function createPersonTable() {
  await db.schema
    .createTable('person')
    .ifNotExists()
    .addColumn('_id', 'text', (col) => col.primaryKey().notNull())
    .addColumn('tags', 'text', (col) => col.notNull()) // JSON string
    .addColumn('created', 'text', (col) => col.notNull()) // ISO 8601 string
    .addColumn('updated', 'text', (col) => col.notNull()) // ISO 8601 string
    .addColumn('deleted', 'integer', (col) => col.notNull()) // Boolean as integer
    .addColumn('deleted_at', 'text') // Nullable ISO 8601 string
    .addColumn('email', 'text')
    .addColumn('name', 'text')
    .addColumn('first_name', 'text')
    .addColumn('last_name', 'text')
    .addColumn('phone', 'text')
    .addColumn('employee_id', 'text')
    .addColumn('status', 'text')
    .addColumn('aliases', 'text', (col) => col.notNull()) // JSON string
    .execute()

  // Index auf der Spalte 'email' erstellen
  await db.schema
    .createIndex('idx_person_email')
    .ifNotExists()
    .on('person')
    .column('email')
    .execute()
}

// Helper function to serialize Account for database storage
export function serializePerson(person: Account) {
  return {
    ...person,
    tags: JSON.stringify(person.tags),
    deleted: person.deleted ? 1 : 0,
    deleted_at: person.deleted_at ?? null,
    aliases: JSON.stringify(person.aliases),
    name: person.name ?? null,
    first_name: person.first_name ?? null,
    last_name: person.last_name ?? null,
    phone: person.phone ?? null,
    employee_id: person.employee_id ?? null,
    status: person.status ?? null,
  }
}

// Helper function to deserialize database record into Account
export function deserializePerson(record: PersonTable): Account {
  return {
    ...record,
    tags: JSON.parse(record.tags),
    deleted: Boolean(record.deleted),
    deleted_at: record.deleted_at ?? null,
    name: record.name ?? null,
    first_name: record.first_name ?? null,
    last_name: record.first_name ?? null,
    phone: record.phone ?? null,
    employee_id: record.employee_id ?? null,
    status: record.status ?? null,
    aliases: JSON.parse(record.aliases),
  }
}
