<template>
  <SmDialog v-model:visibility="visibility" :title="i18n.t('datev.newImport')">
    <div class="datev">
      <div class="datev-description">
        <p>
          {{ i18n.t('datev.uploadDescription') }}
        </p>
      </div>

      <div class="datev-upload-form">
        <span>{{ i18n.t('name') }}</span>
        <SmInput
          v-model="form.name"
          size="small"
          :disabled="status == 'loading' || status == 'success'"
          outline
          :label="i18n.t('datev.nameExample')" />
        <span>{{ i18n.t('description') }}</span>
        <SmInput
          v-model="form.description"
          size="small"
          :disabled="status == 'loading' || status == 'success'"
          outline
          :label="i18n.t('datev.descriptionExample')" />
        <span>{{ i18n.t('date') }}</span>
        <el-date-picker
          v-model="form.date"
          :disabled="status == 'loading' || status == 'success'"
          type="date"
          placeholder="Select date" />
      </div>

      <div class="datev-upload">
        <el-upload
          ref="uploadRef"
          v-model:file-list="fileList"
          class="upload-demo"
          :auto-upload="false"
          :on-success="handleSuccess"
          :on-error="() => (status = 'error')"
          :on-progress="() => (status = 'loading')"
          :limit="1"
          drag
          accept="text/csv"
          :action="`${OpenAPI.BASE}/api/v1/accounting/accounting-imports`"
          :headers="{
            Authorization: `Bearer ${OpenAPI.TOKEN}`,
          }"
          :data="{
            name: form.name,
            description: form.description,
            date: timeStampToUnix(form.date),
            filename: fileList[0]?.name,
          }"
          multiple>
          <v-icon name="md-cloudupload" scale="3" />
          <div>{{ i18n.t('datev.uploadHint') }}</div>
          <template #tip>
            <div class="el-upload__tip">
              {{ i18n.t('datevMaxFilesizeHint') }}
            </div>
          </template>
        </el-upload>
      </div>
    </div>

    <template #footer>
      <div
        v-if="status == 'idle' || status == 'loading'"
        style="display: flex; gap: 0.5rem">
        <SmButton
          outline
          :disabled="status == 'loading'"
          @click="visibility = false">
          {{ i18n.t('cancel') }}
        </SmButton>

        <SmButton type="primary" :loading="status == 'loading'" @click="upload">
          {{ i18n.t('upload') }}
        </SmButton>
      </div>

      <div v-else>
        <SmButton type="primary" @click="visibility = false">
          {{ i18n.t('close') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script lang="ts" setup>
  import { UploadInstance, UploadUserFile } from 'element-plus'

  import { useI18n } from 'vue-i18n'

  import { OpenAPI } from '@/client'
  import { sendToast } from '@/components/sm/SmNotification'

  const i18n = useI18n()

  const status = ref<'idle' | 'loading' | 'success' | 'error'>('idle')

  const uploadRef = ref<UploadInstance>()
  const fileList = ref<UploadUserFile[]>([])

  const props = defineProps<{
    visibility: boolean
  }>()

  const emit = defineEmits<{
    (event: 'update:visibility', ...args: unknown[]): void
  }>()

  const visibility = computed({
    get: () => props.visibility,
    set: (value) => emit('update:visibility', value),
  })

  const form = ref({
    name: '',
    description: '',
    date: Date.now(),
  })

  function timeStampToUnix(timeStamp: number | Date) {
    // Check if number --> return
    if (typeof timeStamp === 'number') return timeStamp

    // If not number, convert to ISO string and return
    return timeStamp.toISOString()
  }

  //   Reset the form when the dialog is closed
  watch(
    visibility,
    (value: boolean) => {
      if (!value) {
        form.value.name = ''
        form.value.description = ''
        form.value.date = 0
        fileList.value = []
        status.value = 'idle'
      }
    },
    { immediate: true }
  )

  function upload() {
    uploadRef.value?.submit()
  }

  function handleSuccess() {
    status.value = 'success'
    sendToast(i18n.t('datev.uploadSuccess'), undefined, 'success')
  }
</script>

<style lang="scss" scoped>
  .datev-upload-form {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .datev-description {
    margin-bottom: 1rem;
  }
</style>
