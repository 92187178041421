<template>
  <SmDialog
    v-model:visibility="visibility"
    :title="i18n.t('createTag')"
    size="small">
    <TagCreateContent
      ref="tagCreateContent"
      :tag="props.tag"
      :edit-mode="props.editMode"
      @saved="(response) => handleSaved(response)" />

    <template #footer>
      <div class="buttons">
        <SmButton v-if="!actionsDone" outline @click="visibility = false">
          {{ i18n.t('cancel') }}
        </SmButton>
        <SmButton v-if="!actionsDone" @click="handleSave">
          {{ editMode ? i18n.t('save') : i18n.t('create') }}
        </SmButton>

        <!-- Close -->
        <SmButton v-if="actionsDone" outline @click="visibility = false">
          {{ i18n.t('close') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'

  import { Tag } from '@/client'

  import TagCreateContent from './TagCreateContent.vue'

  const actionsDone = ref(false)

  const emit = defineEmits(['saved', 'closed'])

  const i18n = useI18n()
  const tagCreateContent: Ref<ComponentExposed<
    typeof TagCreateContent
  > | null> = ref(null)

  const props = defineProps({
    tag: {
      type: Object as PropType<Tag>,
      required: false,
      default: null,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  })

  const visibility = defineModel('visibility', {
    type: Boolean,
    default: false,
  })

  watch(visibility, () => {
    actionsDone.value = false

    if (!visibility.value) {
      emit('closed')
    }
  })

  function handleSaved(newTag: Tag) {
    actionsDone.value = true
    emit('saved', newTag)
  }

  function handleSave() {
    if (!tagCreateContent.value) return
    tagCreateContent.value.handleSave()
  }
</script>
