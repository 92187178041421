import { LicenceOut, LicenceStatus } from '@/client/index.js'
import { db } from './database.js'

export interface LicenceOutTable {
  tags: string // JSON string representing an array of strings
  created: string // ISO 8601 formatted date-time string
  updated: string // ISO 8601 formatted date-time string
  deleted: number // Boolean stored as integer (0 or 1)
  deleted_at: string | null // Nullable ISO 8601 date-time string
  _id: string // Unique identifier, possibly a UUID
  id: string | null // Nullable string
  email: string // Email or username
  software_id: string
  first_name: string | null
  last_name: string | null
  name: string | null
  status: string // LicenceStatus is a string enum
  icon: string | null
  software_licenses: string // JSON string representing a Record<string, SoftwareLicenseMini>
  application_specific: string // JSON string representing a Record<string, any>
  is_guest: number // Boolean stored as integer (0 or 1)
  aliases: string // JSON string representing an array of strings
  data: string // JSON string representing a Record<string, any>
  role: string | null
  l_roles: string | null
  created_external: string | null // Nullable ISO 8601 date-time string
  licence_groups: string // JSON string representing an array of strings
  last_login_time: string | null // Nullable ISO 8601 date-time string
  company: string | null
  sub_account_id: string
  account_id: string | null
  statistics: string // JSON string representing a Record<string, any> or MicrosoftLicenceStatistics
}

export async function createLicenceOutTable() {
  await db.schema
    .createTable('licence_out')
    .ifNotExists()
    .addColumn('_id', 'text', (col) => col.primaryKey().notNull())
    .addColumn('tags', 'text', (col) => col.notNull()) // JSON string
    .addColumn('created', 'text', (col) => col.notNull()) // ISO 8601 string
    .addColumn('updated', 'text', (col) => col.notNull()) // ISO 8601 string
    .addColumn('deleted', 'integer', (col) => col.notNull()) // Boolean as integer
    .addColumn('deleted_at', 'text') // Nullable ISO 8601 string
    .addColumn('id', 'text') // Nullable string
    .addColumn('email', 'text', (col) => col.notNull())
    .addColumn('software_id', 'text', (col) => col.notNull())
    .addColumn('first_name', 'text')
    .addColumn('last_name', 'text')
    .addColumn('name', 'text')
    .addColumn('status', 'text', (col) => col.notNull())
    .addColumn('icon', 'text')
    .addColumn('software_licenses', 'text', (col) => col.notNull()) // JSON string
    .addColumn('application_specific', 'text', (col) => col.notNull()) // JSON string
    .addColumn('is_guest', 'integer', (col) => col.notNull()) // Boolean as integer
    .addColumn('aliases', 'text', (col) => col.notNull()) // JSON string
    .addColumn('data', 'text', (col) => col.notNull()) // JSON string
    .addColumn('role', 'text')
    .addColumn('l_roles', 'text')
    .addColumn('created_external', 'text') // Nullable ISO 8601 string
    .addColumn('licence_groups', 'text', (col) => col.notNull()) // JSON string
    .addColumn('last_login_time', 'text') // Nullable ISO 8601 string
    .addColumn('company', 'text')
    .addColumn('sub_account_id', 'text', (col) => col.notNull())
    .addColumn('account_id', 'text')
    .addColumn('statistics', 'text', (col) => col.notNull()) // JSON string
    .execute()

  // Index auf der Spalte 'email' erstellen
  await db.schema
    .createIndex('idx_licence_out_email')
    .ifNotExists()
    .on('licence_out')
    .column('email')
    .execute()
}

// Helper function to serialize LicenceOut for database storage
export function serializeLicenceOut(licence: LicenceOut) {
  delete licence?.notes
  delete licence?.phone_numbers
  return {
    ...licence,
    tags: JSON.stringify(licence.tags),
    deleted: licence.deleted ? 1 : 0,
    deleted_at: licence.deleted_at ?? null,
    software_licenses: JSON.stringify(licence.software_licenses),
    application_specific: JSON.stringify(licence.application_specific),
    is_guest: licence.is_guest ? 1 : 0,
    aliases: JSON.stringify(licence.aliases),
    data: JSON.stringify(licence.data),
    licence_groups: JSON.stringify(licence.licence_groups),
    statistics: JSON.stringify(licence.statistics),
  }
}

// Helper function to deserialize database record into LicenceOut
export function deserializeLicenceOut(record: LicenceOutTable): LicenceOut {
  return {
    ...record,
    status: record.status as LicenceStatus,
    tags: JSON.parse(record.tags),
    deleted: Boolean(record.deleted),
    deleted_at: record.deleted_at ?? null,
    software_licenses: JSON.parse(record.software_licenses),
    application_specific: JSON.parse(record.application_specific),
    is_guest: Boolean(record.is_guest),
    aliases: JSON.parse(record.aliases),
    data: JSON.parse(record.data),
    licence_groups: JSON.parse(record.licence_groups),
    statistics: JSON.parse(record.statistics),
  }
}
