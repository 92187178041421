<template>
  <div class="bg-background">
    <div
      class="sidebar-wrapper no-print px-10"
      :class="{ collapsed: isCollapse }">
      <div class="no-print flex flex-col justify-center">
        <div class="logo-wrapper" @click="isCollapse = !isCollapse">
          <LogoIcon
            v-show="isCollapse"
            class="logo"
            :is-dark="true"
            style="width: 40px" />
          <LogoText
            v-show="!isCollapse"
            class="logo"
            :is-dark="true"
            style="width: 90%" />
        </div>

        <div class="mt-4 flex flex-col gap-10 text-white">
          <RouterLink
            v-for="item in menu"
            :key="item.name"
            :to="item.route"
            class="link flex gap-3"
            :class="{ collapsed: isCollapse }">
            <v-icon :name="item.icon" scale="1.15" />
            <span v-if="!isCollapse">
              {{ item.displayName }}
            </span>
          </RouterLink>
        </div>
      </div>
      <!-- This v-if checks if the the sidebar is not collapsed and -->
      <!-- If the user not exists or the trail_expire_date not exists it will return false -->
      <!-- If both exists, the trail_expire_date is not after the current date and the user -->
      <!-- is in trail mode than the content will be shown -->
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useWindowSize } from '@vueuse/core'

  import { useI18n } from 'vue-i18n'

  import LogoIcon from '@/assets/img/LogoIcon.vue'
  import LogoText from '@/assets/img/LogoText.vue'
  import { Account, CompanyData, CompanyManagementService } from '@/client'
  import { addPreference, updatePreferences } from '@/common/preferences'
  import { useGlobalStore } from '@/stores/globalStore'

  const i18n = useI18n()
  const store = useGlobalStore()

  type MenuItem = {
    displayName: string
    name: string
    route: string
    icon: string
    disabled: boolean
    children?: MenuItem[]
  }

  const menu = computed((): MenuItem[] => [
    {
      displayName: i18n.t('sidebar.dashboard'),
      name: 'Dashboard',
      route: '/dashboard',
      icon: 'md-home-round',
      disabled: false,
    },
    {
      displayName: i18n.t('sidebar.applications'),
      name: 'Applications',
      route: '/applications',
      icon: 'md-gridview-round',
      disabled: false,
    },
    {
      displayName: i18n.t('sidebar.accounts'),
      name: 'Accounts',
      route: '/accounts',
      icon: 'md-description-round',
      disabled: false,
    },
    {
      displayName: i18n.t('sidebar.external-data-sources'),
      name: 'External-Data-Sources',
      route: '/data-sources',
      icon: 'md-dns-round',
      disabled: false,
    },

    {
      displayName: i18n.t('sidebar.users'),
      name: 'Users',
      route: '/users',
      icon: 'md-person-round',
      disabled: false,
    },
    {
      displayName: i18n.t('groups'),
      name: 'Groups',
      route: '/groups',
      icon: 'md-groups-round',
      disabled: false,
    },

    {
      displayName: i18n.t('sidebar.automations'),
      name: 'Automations',
      route: '/automations',
      icon: 'oi-workflow',
      disabled: false,
    },
    {
      displayName: i18n.t('sidebar.documents'),
      name: 'Documents',
      route: '/documents',
      icon: 'md-foldercopy-round',
      disabled: false,
    },
  ])

  const { width } = useWindowSize()
  const isCollapse = ref<boolean>(
    sessionStorage.getItem('sidebar-collapse') !== null
      ? sessionStorage.getItem('sidebar-collapse') === 'true'
      : false
  )
  const collapsedByWatch = ref<boolean>(false)

  watch(
    () => width.value,
    () => {
      if (width.value <= 1400) {
        isCollapse.value = true
      } else isCollapse.value = false

      collapsedByWatch.value = true
    }
  )

  watch(
    () => isCollapse.value,
    () => {
      if (store.getJWT() && user.value && !collapsedByWatch.value) {
        updatePreferences(store.getIp, store.getJWT(), user.value?.email, {
          'sidebar-collapse': isCollapse.value,
        })
          .catch(() => {
            addPreference(
              store.getIp,
              store.getJWT(),
              user.value?.email || '',
              {
                'sidebar-collapse': isCollapse.value,
              }
            )
          })
          .finally(() => {
            sessionStorage.setItem(
              'sidebar-collapse',
              isCollapse.value.toString()
            )

            // This event needs to be triggered to listen to this event at every
            // point of the app. For example, the dashboard charts need to be resized if the
            // sidebar state changes.
            document.dispatchEvent(
              new CustomEvent('sidebar-collapse-state-changed', {
                detail: {
                  state: isCollapse.value.toString(),
                },
              })
            )
          })
      }

      collapsedByWatch.value = false
    }
  )

  const user = ref<Account | null>(null)
  const companyData = ref<CompanyData | null>(null)

  const logoWidth = computed(() => (!isCollapse.value ? '180px' : '40px'))
  const sidebarPadding = computed(() =>
    !isCollapse.value ? '40px 30px' : '10px'
  )
  const logoMarginTop = computed(() => (!isCollapse.value ? '0px' : '40px'))
  const logoMarginBottom = computed(() => (!isCollapse.value ? '40px' : '30px'))

  onMounted(() => {
    CompanyManagementService.getCompanyInfoApiV1CompanyInfoGet({}).then(
      (res) => {
        companyData.value = res
      }
    )
  })
</script>

<style lang="scss" scoped>
  .logo-wrapper {
    align-self: center;
    margin-bottom: v-bind(logoMarginBottom);
    margin-top: v-bind(logoMarginTop);
    height: 40px;
    width: v-bind(logoWidth);
    transition: all 0.2s ease-in-out;
  }

  .link {
    svg {
      fill: white;
    }
  }

  .router-link-active {
    color: var(--sm-primary);
    font-weight: bold;

    svg {
      fill: var(--sm-primary);
    }
  }

  .sidebar-wrapper.collapsed {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .collapsed {
    justify-content: center;
  }
</style>
<style lang="scss">
  .sidebar-wrapper {
    position: sticky !important;
    margin: 20px 0 20px 20px;
    background-color: var(--sm-sidebar);
    padding: v-bind(sidebarPadding);
    border-radius: 25px;
    box-shadow: var(--shadow);
    height: calc(100% - 40px);
    transition: all 0.2s ease-in-out;

    .menu-wrapper {
      display: flex;
      flex-direction: column;
      margin: 0px 10px 0px 10px;
    }
  }
</style>
