<!--
    This component is used to select an alias from a list of emails.

    Following options are available:
    - assign an alias from a list of emails
    - remove an alias

    All requests are made to the backend.

    TODO:
    - add a loading state
    - loading notification
 -->

<template>
  <div class="py-4">
    <SmSelect
      v-model:selection="selection"
      :options="options"
      :label="props.license.email"
      trigger-style="round"
      trigger-size="small"
      dropdown-min-width="300px"
      @change="(newVal) => handleAliasChange(newVal)" />
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { Account, LicenceOut, UserAliasesService } from '@/client'

  import { sendToast } from './sm/SmNotification'
  import { Option } from './sm/SmSelect.vue'

  const i18n = useI18n()

  const props = defineProps({
    users: {
      type: Object as PropType<Account[]>,
      required: true,
    },

    license: {
      type: Object as PropType<LicenceOut>,
      required: true,
    },
  })

  //   Events
  // - licenseChanged: Emitted when an alias is added to an account
  const emit = defineEmits(['update:selection', 'licenseChanged'])

  const selection = ref<Option<string>[]>([])

  const options: ComputedRef<Option<string>[]> = computed(() =>
    props.users.map((user) => ({ value: user.email, label: user.email }))
  )

  function handleAliasChange(newAlias: Option<string>[] | undefined) {
    if (!newAlias) return
    UserAliasesService.addAliasApiV1ManagementUsersEmailOrIdAliasPost({
      // Current email
      emailOrId: newAlias[0].value,
      requestBody: {
        // Username that should be added to the account
        user_name: props.license.email,
      },
    }).then(async () => {
      emit('licenseChanged')
      sendToast(i18n.t('alias.addAliasSuccess'), undefined, 'success')
    })
  }
</script>
