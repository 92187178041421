<template>
  <SmDialog
    v-model:visibility="visible"
    :title="i18n.t('jobtype.offboardUser.label')"
    size="medium">
    <el-form
      v-model="form.fields"
      :model="form.fields"
      label-width="150px"
      label-position="top"
      require-asterisk-position="right">
      <el-form-item
        :label="i18n.t('user')"
        prop="account"
        required
        :error="form.getErrorMessage('account')">
        <SmSelect
          v-model:selection="form.fields.account"
          :options="accountSelection"
          :label="i18n.t('selectUser')"
          :item-size="66"
          :loading="loadingAccountSelection"
          class="w-full"
          searchable>
          <template #item="{ item }">
            <div class="flex flex-col leading-6">
              <span>{{ item.value.name }}</span>
              <span class="text-contrast-muted">{{ item.value.email }}</span>
            </div>
          </template>
        </SmSelect>
      </el-form-item>

      <template v-if="form.fields.account && form.fields.account.length === 1">
        <h3 class="mt-8 pb-1">{{ i18n.t('licenses') }}</h3>
        <p class="mb-4">
          {{ i18n.t('userDeleteDescription') }}
        </p>

        <UserDeleteLicenceTable
          ref="userDeleteLicenseTable"
          :selectable="false"
          :user="form.fields.account[0]?.value"
          class="mb-8" />
      </template>

      <div class="flex gap-4">
        <!-- Date -->
        <el-form-item class="w-1/2">
          <template #label>
            <div class="flex gap-2">
              {{ i18n.t('jobExecutionInput.label') }}
              <SmTooltip>
                <template #content>
                  {{ i18n.t('jobExecutionInput.tooltip') }}
                </template>
                <v-icon
                  name="md-help-round"
                  scale="0.9"
                  class="fill-contrast-muted" />
              </SmTooltip>
            </div>
          </template>
          <SmDatepicker
            v-model:date="form.fields.enqueue_date"
            class="w-full" />
        </el-form-item>

        <el-form-item
          :label="i18n.t('time')"
          class="w-1/2"
          :error="form.getErrorMessage('enqueue_in')">
          <SmTimeInput
            v-model:time="form.fields.enqueue_time"
            :disabled="!form.fields.enqueue_date"
            :placeholder="
              !form.fields.enqueue_date ? i18n.t('pleaseSelectDate') : undefined
            "
            class="w-full" />
        </el-form-item>
      </div>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <SmButton outline @click="visible = false"
          >{{ i18n.t('general.cancel') }}
        </SmButton>
        <SmButton type="primary" @click="createRequests()">{{
          i18n.t('createJobs')
        }}</SmButton>
      </span>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'
  import {
    Account,
    BackgroundJobSoftwareUserRemoveData,
    BackgroundJobType,
  } from '@/client'
  import { Option } from './sm/SmSelect.vue'
  import UserDeleteLicenceTable from './UserDeleteLicenceTable.vue'
  import { isDateAfterNow, isRequired } from './sm/SmInput/SmInputValidator'
  import { Request } from './TaskEditDialog.vue'
  import dayjs from 'dayjs'
  import useForm from '@/common/form'
  import { PersonStore } from '@/stores/person.store'

  const i18n = useI18n()

  const accountSelection = ref<Option<Account>[] | undefined>([])
  const loadingAccountSelection = ref<boolean>(false)
  const visible = defineModel<boolean>('visible', {
    default: true,
  })
  const emit = defineEmits(['save'])
  const userDeleteLicenseTable =
    ref<ComponentExposed<typeof UserDeleteLicenceTable>>()
  const fallBackTime = '0600'
  const tomorrowDate = dayjs().add(1, 'day').toDate()

  onMounted(() => {
    loadAccountSelection()
  })

  /**
   * Form
   */

  const form = useForm(
    {
      account: [] as Array<Option<Account>>,
      enqueue_in: tomorrowDate as Date | null,
      enqueue_date: tomorrowDate,
      enqueue_time: fallBackTime,
    },
    // Form Hooks
    {
      onReset: () => {},
    },

    // Form Validators
    {
      account: [isRequired],
      enqueue_in: [isDateAfterNow(600, true)],
    },
    {}
  )

  /**
   * Data
   */

  async function loadAccountSelection() {
    loadingAccountSelection.value = true

    const persons = await PersonStore.getPersons(undefined, undefined, {
      column: 'email',
      direction: 'asc',
    })

    accountSelection.value = persons.map((person) => ({
      value: person,
      label: person.name as string,
      key: person._id,
    }))

    loadingAccountSelection.value = false
  }

  /**
   * Functions
   */

  const selectedDateTime = computed(() => {
    if (!form.fields.enqueue_date) return null

    const date = dayjs(form.fields.enqueue_date)
    const time = form.fields.enqueue_time || fallBackTime

    const dateTime = date
      .hour(parseInt(time.slice(0, 2)))
      .minute(parseInt(time.slice(2, 4)))

    return dateTime.toDate()
  })

  async function createRequests() {
    const validate = form.validate()

    if (!validate) return

    // todo: prevent submit if not everything is filled
    const idMap = userDeleteLicenseTable.value?.createRequestBody()

    const enqueue_in = dayjs(selectedDateTime.value).diff(dayjs(), 's')
    const type = BackgroundJobType.SOFTWARE_USER_REMOVE

    if (!idMap) {
      return
    }

    const requests: Request<BackgroundJobSoftwareUserRemoveData>[] =
      Object.entries(idMap)
        .filter(([, job]) => job !== 'none') // Exclude 'none' jobs
        .map(([licenceId, job]) => {
          return {
            type,
            enqueue_in,
            data: {
              licence_id: licenceId,
              disable: job === 'deactivate',
              delete: job === 'delete',
            },
          }
        })

    // todo: add option to delete the user when no more license is assigned
    // currently no job api endpoint given to provide this feature
    // for further informations: https://github.com/saasmetrix/saasmetrix/pull/5965

    emit('save', requests)
    visible.value = false
    return requests
  }

  watch(visible, (val) => {
    if (!val) {
      form.fields.account = []
    }
  })
</script>
