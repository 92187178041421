<template>
  <div class="container">
    <SmDialogMessage
      :message="error.message"
      :visible="error.visible"
      :type="error.type" />
    <el-form label-width="8rem">
      <!-- Name -->
      <el-form-item label="Name">
        <el-input v-model="testTag.name" />
      </el-form-item>

      <!-- Color -->
      <el-form-item label="Farbe">
        <div class="form-item-color">
          <div class="color-select">
            <div class="color-selector"></div>
          </div>
          <div class="tag-wrapper">
            <TagComponent :tag="testTag" />
          </div>
        </div>
      </el-form-item>

      <!-- Description -->
      <el-form-item label="Beschreibung">
        <el-input
          v-model="testTag.description"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup lang="ts">
  import '@simonwep/pickr/dist/themes/nano.min.css' // 'nano' theme

  import { generateFallbackColor } from '@/common/colors'
  import Pickr from '@simonwep/pickr'

  import { Tag, TagsService } from '@/client'

  const emit = defineEmits(['saved'])

  const props = defineProps({
    tag: {
      type: Object as PropType<Tag>,
      required: false,
      default: null,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  })

  interface InitalTag {
    name: string
    color: {
      r: number
      g: number
      b: number
    }
    description: string
  }

  const testTag = ref<InitalTag | Tag>({
    name: 'New Tag',
    color: generateFallbackColor(),
    description: '',
  })

  let pickr: Pickr | null = null
  onMounted(() => {
    pickr = Pickr.create({
      el: '.color-selector',
      theme: 'nano', // or 'monolith', or 'nano'
      default: props.tag ? toRGBString(props.tag.color) : '#409EFF',
      lockOpacity: true,
      comparison: false,
      swatches: [
        '#A17FFF',
        '#06D6A0',
        '#1B9AAA',
        '#409EFF',
        '#EF476F',
        'FFC43D',
      ],
      components: {
        // Main components
        preview: true,
        opacity: false,
        hue: true,
        // Input / output Options
        interaction: {
          hex: true,
          rgba: true,
          hsla: true,
          hsva: true,
          cmyk: true,
          input: true,
          clear: false,
          save: false,
        },
      },
    })

    pickr.on('change', (color: { toRGBA: () => number[] }) => {
      testTag.value.color = {
        r: Math.floor(color.toRGBA()[0]),
        g: Math.floor(color.toRGBA()[1]),
        b: Math.floor(color.toRGBA()[2]),
      }
    })
  })

  const error = ref({
    message: '',
    visible: false,
    type: 'success' as 'success' | 'error',
  })

  function handleSave() {
    if (testTag.value.name.length < 1) {
      error.value = {
        message: 'Der Name muss mindestens 3 Zeichen lang sein.',
        visible: true,
        type: 'error',
      }
    } else {
      // Update tag
      if (props.editMode) {
        TagsService.updateTagApiV1ManagementTagsTagIdPatch({
          tagId: props.tag._id,
          requestBody: testTag.value,
        }).then(() => {
          emit('saved')
          error.value = {
            message: 'Tag erfolgreich aktualisiert.',
            visible: true,
            type: 'success',
          }
        })
        return
      }

      // Create new tag
      TagsService.addTagApiV1ManagementTagsPost({
        requestBody: testTag.value,
      }).then((response) => {
        emit('saved', response)
        error.value = {
          message: 'Tag erfolgreich erstellt.',
          visible: true,
          type: 'success',
        }
      })
    }
  }

  defineExpose({
    handleSave,
  })

  watch(
    () => props.tag,
    (value) => {
      if (!value) return

      testTag.value = value

      if (!pickr) return
      pickr.setColor(
        toRGBString({
          r: value.color.r,
          g: value.color.g,
          b: value.color.b,
        })
      )
    },
    {
      immediate: true,
    }
  )

  function toRGBString(color: Tag['color']) {
    return `rgba(${color.r}, ${color.g}, ${color.b}, 1)`
  }
</script>

<style scoped lang="scss">
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .form-item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 100%;
  }

  .color-select {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }

  .form-item-color {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .color-item {
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    opacity: 0.5;

    &--active {
      transform: scale(1.09);
      opacity: 0.7;
    }
  }

  .tag-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
  }
</style>
