<!--
  This component is used to add a new license to a software.
  It is used in the software detail view.
  It is a dialog that has two views:
  - User selection grid
  - License create/edit view
 -->

<template>
  <SmDialog
    v-model:visibility="visibility"
    style="overflow: initial"
    :size="view === 'userGrid' ? 'large' : 'medium'">
    <template #title>
      <h2>{{ title }}</h2>
    </template>

    <!-- User selection Grid -->
    <SmSelectionGrid
      v-if="view === 'userGrid'"
      id="id"
      v-model:selection="selection"
      label-key="label"
      :items="gridUsers">
      <!-- User grid item -->
      <template #options>
        <!-- Add group dropdown -->
        <SmDropdown
          ref="groupDropdown"
          :close-on-click-inside="false"
          dropdown-class="w-[32rem] max-w-7xl">
          <!-- Trigger -->
          <template #trigger>
            <SmTooltip :content="i18n.t('selectGroups')">
              <v-icon name="md-groups-round" scale="1.2" />
            </SmTooltip>
          </template>

          <!-- Dropdown content -->
          <div class="flex flex-col gap-2 p-2">
            <SmSelect
              v-model:selection="groupSelection"
              :label="i18n.t('chooseGroups')"
              :multiselect="true"
              searchable
              :options="groupFilterOptions" />

            <SmButton
              style="margin-left: auto"
              :disabled="!groupSelection.length"
              @click="handleGroupSelection">
              {{ i18n.t('selectGroups') }} ({{ groupSelection.length }})
            </SmButton>
          </div>
        </SmDropdown>
      </template>

      <template #item="{ item, selected }">
        <UserGridItem :item="item" :selected="selected" />
      </template>
    </SmSelectionGrid>

    <!-- Licence create/edit view  -->
    <div v-else>
      <ContentLicenseEdit
        :software-licenses="softwareLicenses"
        :user="selectedUsers"
        :software="props.software"
        :sub-account="props.subAccount"
        @user-added="emit('userAdded')"
        @close="visibility = false" />
    </div>

    <!-- Footer -->
    <template #footer>
      <div
        v-if="view === 'userGrid'"
        style="display: flex; justify-content: flex-end; width: 100%">
        <SmButton :disabled="!selection.length" @click="handleUserSelection">
          {{ i18n.t('selectUser') }} ({{ selection.length }})
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script lang="ts" setup>
  import {
    Account,
    AccountGroup,
    SoftwareLicense,
    SoftwareOut,
    SubAccount_Output,
    UserGroupsService,
  } from '@/client'
  import SmSelectionGrid from '@/components/sm/SmSelectionGrid.vue'

  import ContentLicenseEdit from './ContentLicenseEdit.vue'
  import { selectionGridItem } from './sm/SmSelectionGrid.types'
  import { useI18n } from 'vue-i18n'
  import { Option } from '@/components/sm/SmSelect.vue'
  import { ComponentExposed } from 'vue-component-type-helpers'
  import SmDropdown from './sm/SmDropdown.vue'
  import { PropType } from 'vue'
  import { applyAccountFilters } from '@/stores/account.filter'
  import { AccountStore } from '@/stores/account.store'
  import { PersonStore } from '@/stores/person.store'

  const i18n = useI18n()

  const gridUsers: Ref<selectionGridItem[]> = ref([])
  const selection = ref<(keyof selectionGridItem)[]>([])
  const selectedUsers: Ref<Account[] | null> = ref(null)
  // Views
  type views = 'userGrid' | 'licenseCreate'
  const view: Ref<views> = ref('userGrid') // This is used to switch between the two views

  // Props
  const props = defineProps({
    mode: {
      type: String as PropType<'create' | 'edit'>,
      required: false,
      default: 'create',
    },
    visibility: {
      type: Boolean,
      default: true,
    },
    software: {
      type: Object as PropType<SoftwareOut>,
      required: true,
    },
    softwareLicenses: {
      type: Object as PropType<SoftwareLicense[]>,
      required: true,
    },
    subAccount: {
      type: Object as PropType<SubAccount_Output>,
      required: false,
      default: null,
    },
  })

  // Emits
  const emit = defineEmits(['update:visibility', 'userAdded'])

  // Model values
  const visibility = computed({
    get: () => props.visibility,
    set: (value) => emit('update:visibility', value),
  })

  // ### Both views ###
  const title = computed(() => {
    switch (view.value) {
      case 'userGrid':
        return i18n.t('addAccount')
      case 'licenseCreate':
        return props.software.display_name || props.software.software_name
      default:
        return i18n.t('addAccount')
    }
  })

  // ### Grid view ###
  // When a user is selected, the user is set to the selectedUser ref
  async function handleUserSelection() {
    const items = selection.value

    if (!items.length) return

    selectedUsers.value = []

    for (const item of items) {
      const _user = await PersonStore.getPersonById(item)
      if (!_user) return
      selectedUsers.value.push(_user)
    }

    view.value = 'licenseCreate'
  }

  // PERF: This could be damaticly improved when SQL user/people store is available
  async function setGridUsers() {
    const users = await PersonStore.getPersons()
    if (!users) return []

    // Laod existing accounts for this softwares
    let query = AccountStore.getDatabase().select('account_id').distinct()
    query = applyAccountFilters(query, { softwareId: props.software._id })
    const accountIdsWithLicenses = new Set(
      (await query.execute()).map((s) => s.account_id)
    )

    gridUsers.value = users
      .filter((user) => {
        // Filter out users that already have a license
        return !accountIdsWithLicenses.has(user._id)
      })
      .map((user) => ({
        id: user._id,
        label: user.name || user.email,
        additionalSearchTerms: [user.email, ...Object.keys(user.aliases)],
      }))
  }

  // reset view on visibility change
  watch(
    visibility,
    (value) => {
      if (value) {
        setGridUsers()
      } else {
        view.value = 'userGrid'
        selectedUsers.value = null
        selection.value = []
      }
    },
    { immediate: true }
  )

  // Group selection
  const groupDropdown = ref<ComponentExposed<typeof SmDropdown>>()
  const groupFilterOptions = ref<Option<AccountGroup>[]>([])
  const groupSelection = ref<Option<AccountGroup>[]>([])

  // Load groups
  async function loadGroups() {
    const groups =
      await UserGroupsService.getAccountGroupsApiV1ManagementGroupsGet({})

    for (const group of groups) {
      groupFilterOptions.value.push({
        label: `${group.name} (${group.accounts.length} Accounts)`,
        value: group,
        key: group._id,
      })
    }
  }

  function handleGroupSelection() {
    for (const group of groupSelection.value) {
      // Get accounts from group
      const accounts = group.value.accounts

      for (const account of accounts) {
        // Fix account type
        const _account = account as keyof selectionGridItem
        // Check if the account is already selected
        if (selection.value.includes(_account)) continue

        // Add the account to the selection
        selection.value.push(_account)
      }
    }

    groupSelection.value = []
    groupDropdown.value?.setVisibility(false)
  }

  onMounted(async () => {
    await loadGroups()
  })
</script>
