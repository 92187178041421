import { usePreferenceStore } from '@/stores/preferenceStore'
import { currencies } from './util/currency'

export function getCorrectedPrice(
  price: string | number,
  currency: keyof typeof currencies | undefined = undefined
) {
  const preferenceStore = usePreferenceStore()

  const parsedPrice = typeof price === 'string' ? parseFloat(price) : price
  if (isNaN(parsedPrice)) throw new Error('Invalid price value')

  if (!preferenceStore.preferences?.currency_code) return

  const currencyInformation =
    currencies[currency ?? preferenceStore.preferences?.currency_code]
  return currencyInformation.formatter(parsedPrice)
}
