/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum BackgroundJobType {
    ACCOUNT_GROUP_ACCOUNTS_ADDED = 'account_group_accounts_added',
    ACCOUNT_GROUP_ACCOUNTS_REMOVED = 'account_group_accounts_removed',
    DATA_SOURCE_ACCESS_TOKEN_UPDATE = 'data_source_access_token_update',
    DATA_SOURCE_EMPLOYEES_FETCH_AND_UPDATE = 'data_source_employees_fetch_and_update',
    DATA_SOURCE_FETCH_AND_UPDATE = 'data_source_fetch_and_update',
    GENERATE_COMPANY_STATISTICS = 'generate_company_statistics',
    SOFTWARE_ACCESS_TOKEN_UPDATE = 'software_access_token_update',
    SOFTWARE_EXTERNAL_EVENTS_FETCH_AND_UPDATE = 'software_external_events_fetch_and_update',
    SOFTWARE_FETCH_AND_UPDATE = 'software_fetch_and_update',
    SOFTWARE_LICENCE_STATISTICS_FETCH_AND_UPDATE = 'software_licence_statistics_fetch_and_update',
    SOFTWARE_LICENCES_FETCH_AND_UPDATE = 'software_licences_fetch_and_update',
    SOFTWARE_REAUTHORIZE_INTEGRATION_TYPES = 'software_reauthorize_integration_types',
    SOFTWARE_USER_ADD = 'software_user_add',
    SOFTWARE_USER_ENABLE = 'software_user_enable',
    SOFTWARE_USER_LICENSE_ADD = 'software_user_license_add',
    SOFTWARE_USER_LICENSE_REMOVE = 'software_user_license_remove',
    SOFTWARE_USER_REMOVE = 'software_user_remove',
    _DEBUG_EXCEPTION = '_debug_exception',
    _DEBUG_SLEEP = '_debug_sleep',
}
