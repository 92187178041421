<template>
  <!-- settings-container defined in parent -->
  <div class="flex flex-col gap-5 p-4 pb-5 pt-5">
    <div v-if="props.showHeadline" class="float-left">
      <h3 v-if="!isBugReport" style="margin: 0">
        {{ i18n.t('views.settings.help.header.help') }}
      </h3>
      <h3 v-else style="margin: 0">
        {{ i18n.t('feedback') }}
      </h3>
    </div>
    <!-- Email, Name, Telefonnummer -->
    <div>
      <el-form
        ref="formRef"
        label-width="170px"
        label-position="left"
        :model="formData"
        :rules="formRules"
        require-asterisk-position="right"
        @submit.prevent="submitFeedback">
        <el-form-item
          :label="i18n.t('views.settings.help.form.subject.label')"
          prop="subject">
          <el-input v-if="!isBugReport" v-model="formData.subject" />
          <el-input v-if="isBugReport" v-model="formData.subject"> </el-input>
        </el-form-item>
        <el-form-item
          :label="i18n.t('views.settings.help.form.message.label')"
          prop="message">
          <el-input
            v-model="formData.message"
            :autosize="{ minRows: 6 }"
            type="textarea" />
        </el-form-item>
        <SmButton
          type="primary"
          style="float: right"
          :loading="isSubmitLoading"
          @click="submitFeedback">
          {{ i18n.t('views.settings.help.form.submitButton') }}
        </SmButton>
      </el-form>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { FormInstance } from 'element-plus'

  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'

  import { FeedbackService } from '@/client'
  import { sendToast } from '@/components/sm/SmNotification'
  import validate from '@/middlewares/validate'

  const i18n = useI18n()
  const route = useRoute()

  const isBugReport = ref(
    route.query.bugReport ? route.query.bugReport === 'true' : false
  )

  const emit = defineEmits(['feedbackSend'])

  const props = defineProps({
    showHeadline: {
      type: Boolean,
      default: true,
    },
  })

  type FormData = {
    subject: string
    message: string
  }
  const formRef = ref<FormInstance>()
  const formData = ref<FormData>({
    subject: '',
    message: '',
  })
  const formRules = {
    subject: [
      {
        required: true,
        message: i18n.t('views.settings.help.form.subject.errorMessage'),
        trigger: 'blur',
      },
    ],
    message: [
      {
        required: true,
        message: i18n.t(
          'views.settings.help.form.message.errorMessage.required'
        ),
        trigger: 'blur',
      },
      {
        min: 5,
        message: i18n.t(
          'views.settings.help.form.message.errorMessage.minLength'
        ),
      },
    ],
  }

  const isSubmitLoading = ref<boolean>(false)
  async function submitFeedback() {
    if (!formRef.value) return
    if (!(await validate(formRef.value))) return

    isSubmitLoading.value = true
    FeedbackService.sendFeedbackApiV1InternFeedbackPost({
      requestBody: {
        message: formData.value.message,
        subject: formData.value.subject,
      },
    }).then((response) => {
      isSubmitLoading.value = false
      if (response.success) {
        formData.value = {
          subject: '',
          message: '',
        }
        emit('feedbackSend')
        return sendToast(
          i18n.t('notifications.settings.help.requestSuccess.title'),
          i18n.t('notifications.settings.help.requestSuccess.message'),
          'success'
        )
      } else {
        return sendToast(
          i18n.t('notifications.settings.help.requestFailed.title'),
          i18n.t('notifications.settings.help.requestFailed.message'),
          'success'
        )
      }
    })
  }
</script>

<style scoped>
  .float-left {
    float: left;
  }
</style>
