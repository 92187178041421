<template>
  <PriceInformationEditDialog
    v-if="priceToEdit"
    v-model:visible="priceEditDialogVisible"
    :price-information="priceToEdit"
    :license-model-id="license?._id"
    :method="priceEditMethod"
    :is-fallback="isFallback"
    @cancelled="priceToEdit = null"
    @saved="handleContractSave()" />

  <DeleteConfirmDialog
    v-if="priceToDelete"
    v-model:visible="priceDeleteDialogVisible"
    :loading="priceDeleteLoading"
    :title="
      i18n.t('dialogs.licenceModelEditDialog.licenseModelForm.deletePriceModel')
    "
    :sub-title="
      i18n.t(
        'dialogs.licenceModelEditDialog.licenseModelForm.deletePriceModelWarning',
        { description: priceToDelete?.description }
      )
    "
    @cancel="priceDeleteDialogVisible = false"
    @delete="removePriceInformation(priceToDelete!)" />

  <SmDrawer
    v-model:visibility="localVisibility"
    :title="i18n.t('licensemodel')">
    <!-- Header -->
    <template #close>
      <div class="header">
        <div
          v-if="license"
          class="mr-2 flex items-center"
          @click="handleLicenseDelete">
          <v-icon name="md-delete-round" scale="1.1" class="trash-icon" />
        </div>
        <SmButtonClose
          data-test-id="user-detail-drawer-close-button"
          @click="localVisibility = false" />
      </div>
    </template>

    <!-- Drawer Content -->
    <template #default>
      <!-- Information Card -->
      <LicenseModelInformations
        v-if="license"
        class="mb-3"
        :license="license"
        @edit="emit('edit', license)" />

      <!-- Analytics Card -->
      <LicenseModelAnalytics
        v-if="license && softwareLicenseCosts"
        class="mb-3"
        :active-contracts="activePriceInformations"
        :software-id="props.softwareId"
        :license-id="license._id"
        :total-quota="totalQuota"
        :external-quota="externalQuota"
        :manual-active-quota="manualActiveQuota"
        :costs="softwareLicenseCosts"
        @create-fallback="openCreateFallbackPriceModelDialog()" />

      <!--Alerts-->
      <SmDialogMessage
        class="mb-3"
        type="warning"
        :message="i18n.t('pricemodelMessageFallbackExceeded')"
        :visible="showAlertExternalExceeded" />

      <!-- External Quota Contract -->
      <LicenseModelFallbackSelect
        v-if="
          !showAlertExternalExceeded &&
          priceInformations &&
          softwareLicenseCosts &&
          externalQuota > 0
        "
        class="mb-3"
        :external-quota="externalQuota"
        :contracts="activePriceInformations"
        :costs="softwareLicenseCosts"
        @create-fallback="openCreateFallbackPriceModelDialog()" />

      <!-- Contract List -->
      <div>
        <div class="mb-4 mt-8 flex items-center justify-between">
          <h2>{{ i18n.t('priceModel') }}</h2>
          <SmButton outline @click="openCreatePriceModelDialog">
            <v-icon name="md-add-round" class="mr-2 !text-contrast" />
            {{ i18n.t('createPriceModel') }}
          </SmButton>
        </div>
        <div v-if="priceInformations && priceInformations.length > 0">
          <div
            v-for="priceInformation in priceInformations"
            :key="priceInformation._id"
            class="price-model-item mb-4 flex items-center justify-between gap-2 border-b pb-4">
            <div>
              <TextMultiline
                v-if="priceInformation.description"
                class="font-bold"
                :show-tool-tip="true">
                <h3>{{ priceInformation.description }}</h3>
              </TextMultiline>
              <div class="flex items-center">
                <SmTag
                  v-if="contractIsActive(priceInformation) === 'deprecated'"
                  :label="i18n.t('deprecated')"
                  class="mr-2 text-sm" />
                <SmTag
                  v-else-if="contractIsActive(priceInformation) === 'upcoming'"
                  :label="i18n.t('upcoming')"
                  class="mr-2 text-sm" />
                <p>{{ priceInfoDetailText(priceInformation) }}</p>
              </div>
            </div>
            <div class="flex gap-2">
              <v-icon
                name="md-modeedit-round"
                scale="1"
                class="hover:cursor-pointer hover:text-gray-900 hover:dark:text-gray-400"
                @click="handleEditPricemodel(priceInformation)" />
              <v-icon
                name="md-delete-round"
                scale="1.1"
                class="trash-icon"
                @click="handleDeletePricemodel(priceInformation)" />
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex h-36 items-center justify-center rounded border-2 border-dashed border-gray-300">
          <!-- Empty State -->
          <p class="text-contrast-muted">
            {{ i18n.t('drawers.licenceDetailDrawer.noPriceFound') }}
          </p>
        </div>
      </div>
    </template>
  </SmDrawer>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'
  import {
    SoftwareLicense,
    BillingUnit,
    BillingPeriod,
    PriceInformation,
    PriceInformationPostIn,
    SoftwareLicenseCost,
  } from '@/client'
  import { usePriceInformationStore } from '@/stores/priceInformationStore'
  import { useLicenseStore } from '@/stores/license.store'
  import { getCorrectedPrice } from '@/common/licenceModel'
  import { useApplicationDetailsStore } from '@/views/ApplicationsDetail/ApplicationDetailsView.store'
  import dayjs from 'dayjs'
  import { usePreferenceStore } from '@/stores/preferenceStore'

  const i18n = useI18n()
  const priceInformationStore = usePriceInformationStore()
  const licenseStore = useLicenseStore()
  const preferenceStore = usePreferenceStore()
  const applicationDetailStore = useApplicationDetailsStore()

  const license = defineModel<SoftwareLicense>('license')
  const softwareLicenseCosts = ref<SoftwareLicenseCost>()

  const priceToEdit = ref<PriceInformation | null>()
  const priceToDelete = ref<PriceInformation | null>()

  const priceEditDialogVisible = ref(false)
  const priceDeleteDialogVisible = ref(false)
  const priceDeleteLoading = ref(false)
  const priceEditMethod = ref<'create' | 'edit'>('edit')
  const isFallback = ref<boolean>(false)

  const emptyForm = ref<PriceInformationPostIn>({
    _id: '',
    description: '',
    currency: preferenceStore.preferences?.currency_code ?? 'EUR',
  } as PriceInformationPostIn)

  const props = defineProps({
    drawerVisibility: {
      type: Boolean,
      required: true,
    },
    softwareId: {
      type: String,
      required: true,
    },
  })

  const emit = defineEmits(['update:drawerVisibility', 'delete', 'edit'])

  onMounted(() => {
    reloadCosts()
  })

  function handleContractSave() {
    priceEditDialogVisible.value = false
    reloadCosts()
  }

  function reloadCosts() {
    licenseStore
      .getSoftwareLicenseCosts({
        softwareId: props.softwareId,
        useCached: false,
      })
      .then((result) => {
        if (result && license.value)
          softwareLicenseCosts.value = result[license.value._id]
      })
  }

  const localVisibility = computed({
    get: () => props.drawerVisibility,
    set: (value) => emit('update:drawerVisibility', value),
  })

  watch(
    () => localVisibility.value,
    (newValue) => {
      if (newValue) reloadCosts()
    }
  )

  function handleLicenseDelete() {
    emit('delete', license)
  }

  const availableBillingPeriods = computed(() => {
    const periods: Record<string, string> = {}
    Object.values(BillingPeriod).forEach((enumValue) => {
      const label = i18n.te(`billingPeriodValues.${enumValue}`)
        ? i18n.t(`billingPeriodValues.${enumValue}`)
        : enumValue
      periods[enumValue] = label
    })
    return periods
  })

  /*
   * Case Management
   */

  const totalQuota = computed(() => {
    if (license.value && applicationDetailStore.quota[license.value?._id]) {
      const value = applicationDetailStore.quota[license.value?._id].quota
      return value ?? 0
    }

    return 0
  })

  const manualActiveQuota = computed(() => {
    return activePriceInformations.value.reduce((acc, item) => {
      return acc + (item.quota ?? 0)
    }, 0)
  })

  const externalQuota = computed(() => {
    return license.value?.quota_information?.quota ?? 0
  })

  const showAlertExternalExceeded = computed(() => {
    if (externalQuota.value && manualActiveQuota.value >= externalQuota.value)
      return true
    else return false
  })

  /*
   * Price Information / Contract Functions
   */

  const priceInformations = computed(() => {
    if (license.value) {
      return priceInformationStore.getPriceInformation(license.value._id)
    }
    return undefined
  })

  const activePriceInformations = computed(() => {
    if (!priceInformations.value) return []
    return priceInformations.value.filter(
      (item) => contractIsActive(item) === true
    )
  })

  function openCreatePriceModelDialog() {
    // open dialog
    priceEditMethod.value = 'create'
    isFallback.value = false
    priceToEdit.value = emptyForm.value as PriceInformation
    priceEditDialogVisible.value = true
  }

  function openCreateFallbackPriceModelDialog() {
    // open dialog
    priceEditMethod.value = 'create'
    isFallback.value = true
    priceToEdit.value = emptyForm.value as PriceInformation
    priceEditDialogVisible.value = true
    isFallback.value = true
  }

  function handleEditPricemodel(priceInformation: PriceInformation) {
    priceEditMethod.value = 'edit'
    isFallback.value = priceInformation.quota === 0 ? true : false
    priceToEdit.value = priceInformation
    priceEditDialogVisible.value = true
  }

  function handleDeletePricemodel(priceInformation: PriceInformation) {
    priceToDelete.value = priceInformation
    priceDeleteDialogVisible.value = true
  }

  async function removePriceInformation(PriceInformation: PriceInformation) {
    const priceInformationId = (PriceInformation as PriceInformation)._id

    if (license.value?._id) {
      await priceInformationStore.deletePriceInformation(
        license.value._id,
        priceInformationId
      )
    }

    priceDeleteDialogVisible.value = false

    reloadCosts()
  }

  function priceInfoDetailText(priceInformation: PriceInformation) {
    const i18n = useI18n()

    // Handle Billing Unit
    let billingUnitText = ''
    if (
      priceInformation.billing_unit &&
      priceInformation.billing_unit !== BillingUnit.UNKNOWN
    ) {
      if (
        priceInformation.billing_unit === BillingUnit.QUOTA &&
        priceInformation.quota === 0
      ) {
        billingUnitText += `${i18n.t('externalQuota')}`
      } else {
        billingUnitText = i18n.t(
          `billingUnitValues.${priceInformation.billing_unit}`
        )
        if (priceInformation.quota) {
          billingUnitText += ` (${i18n.t('general.licenses', priceInformation.quota)})`
        }
      }
    }

    // Handle Pricing
    let pricingText = ''
    if (
      (priceInformation.price || priceInformation.price === 0) &&
      priceInformation.currency
    ) {
      pricingText = getCorrectedPrice(
        priceInformation.price,
        priceInformation.currency
      )
      if (
        priceInformation.billing_period &&
        priceInformation.billing_period !== BillingPeriod.UNKNOWN
      ) {
        pricingText += ` / ${availableBillingPeriods.value[priceInformation.billing_period]}`
      }
    }

    // Combine Texts
    return [billingUnitText, pricingText].filter(Boolean).join(' • ')
  }

  /*
   * Utils
   */

  function contractIsActive(
    item: PriceInformation
  ): 'deprecated' | 'upcoming' | boolean {
    const isBetween = dayjs().isBetween(item.from_date, item.end_date)
    const isAfter = dayjs().isAfter(item.end_date)
    const isBefore = dayjs().isBefore(item.from_date)

    if (isBetween || !item.from_date) {
      return true
    }

    if (isAfter) {
      return 'deprecated'
    }

    if (isBefore) {
      return 'upcoming'
    }

    return false
  }
</script>

<style scoped>
  .price-model-item:last-of-type {
    border-bottom: unset;
  }
</style>
