<template>
  <SmDialog
    v-model:visibility="showAwaitAuthDialog"
    :title="i18n.t('authPending.title')">
    <p>
      {{
        i18n.t('authPending.description', {
          software: software?.display_name,
          time: 24,
        })
      }}
    </p>
    <template #footer>
      <SmButton outline @click="handleBackToApplications">
        {{ i18n.t('buttonBackToApplication') }}
      </SmButton>
    </template>
  </SmDialog>

  <ExportDialog
    v-model:visibility="visibilityExportDialog"
    :sub-account-id="isSSO ? currentSubAccount?.id : undefined"
    :software-id="softwareId" />

  <SmDialog
    v-model:visibility="showLicenseImportDialog"
    :title="i18n.t('licenseCsvImport.csvImportHeading')">
    <LicenseCsvImportContent
      v-if="software"
      :software-id="software._id"
      @successful-import="handleSuccessfulImport" />
  </SmDialog>

  <LicenseAddDialog
    v-if="software"
    v-model:visibility="visibilityLicenseAddDialog"
    :software-licenses="softwareLicensesWithSelectedSubAccount"
    :software="software"
    :sub-account="currentSubAccount"
    @user-added="handleRefetchLicences()" />

  <ApplicationDetailDrawer
    v-if="software"
    v-model:show-drawer="showApplicationEditDialog"
    v-model:tab="licenceViewMode"
    v-model:software="software"
    :current-licences="licenses"
    :selected-sub-account-id="
      applicationDetailsStore.$state.selectedSubAccount?.id || null
    "
    @software-changed="handleRefetchSoftware()"
    @user-saved="handleRefetchLicences()" />

  <div class="home">
    <ViewHeader
      back-arrow-route="/applications"
      title-i18n-code="views.applicationDetails.title" />
    <div class="application-details-grid">
      <ApplicationDetailsHeader
        ref="statsCard"
        data-tutorial-id="application-details-stats-card"
        class="header-card"
        :software="software"
        @open-application-details-drawer="
          (tabName: string) => (
            (showApplicationEditDialog = true), (licenceViewMode = tabName)
          )
        " />

      <!-- License Table -->
      <ApplicationDetailsLicencesTable
        v-if="software && currentSubAccount"
        ref="licenseTable"
        v-model:sub-account="currentSubAccount"
        data-tutorial-id="application-details-licences-card"
        class="licenses-card"
        :software="software"
        :software-licenses="allSoftwareLicenses"
        @export-licenses="visibilityExportDialog = true"
        @licenses-changed="handleLicensesChanged"
        @open-add-user="visibilityLicenseAddDialog = true"
        @import-licenses="showLicenseImportDialog = true"
        @open-application-details-drawer="
          (tabName: string) => (
            (showApplicationEditDialog = true), (licenceViewMode = tabName)
          )
        " />

      <SavingsCard
        v-if="software"
        data-tutorial-id="application-details-chart-card"
        :software-id="software._id"
        class="savings-card"
        @empty-state-button-clicked="
          () => (
            (showApplicationEditDialog = true), (licenceViewMode = 'Select')
          )
        " />

      <!-- Combo Card -->
      <el-card
        class="combo-card"
        :body-style="{
          height: '100%',
          'padding-top': '10px',
        }">
        <el-tabs
          v-model="comboCardActiveName"
          class="demo-tabs"
          style="height: 100%">
          <!-- Optimizations -->
          <el-tab-pane
            :label="i18n.t('optimizations')"
            name="first"
            lazy
            :style="tabPaneStyle"
            class="h-full">
            <OptimizationsList
              v-if="
                Object.keys(optimizations).length > 0 || loadingOptimizations
              "
              :all-software-licenses="allSoftwareLicenses"
              :loading="loadingOptimizations"
              :optimizations="optimizations" />

            <div
              v-else-if="
                !loadingOptimizations && Object.keys(optimizations).length === 0
              "
              class="h-full">
              <EmptyState
                class="h-full"
                icon="oi-graph"
                :title="i18n.t('views.dashboard.optimizations.optimized')"
                :show-create-btn="false"
                :show-docs-btn="false" />
            </div>
          </el-tab-pane>

          <!-- License Model Edit -->
          <el-tab-pane
            v-for="item in availableSubTypes"
            :key="item"
            :label="item"
            :name="item"
            lazy
            :style="tabPaneStyle">
            <div
              v-show="
                Object.keys(allSoftwareLicenses).length > 0 || loadingLicences
              ">
              <LicenseModelEditTable
                ref="licenseModelEditTable"
                :software-licenses="
                  allSoftwareLicenses.filter((r) => {
                    if (item === 'License' && r.subtype === null) {
                      return true
                    }
                    return r.subtype === item
                  })
                "
                hide-header
                :software="software"
                :selectable="false"
                licenses-editable
                show-add-row
                :loading="loadingLicences"
                :sub-account-id="currentSubAccount?.id"
                @software-changed="handleRefetchSoftware()"
                @licenses-changed="fetchData()" />
            </div>

            <div
              v-if="
                Object.keys(allSoftwareLicenses).length === 0 &&
                !loadingLicences
              "
              class="mb-3 flex h-40 items-center justify-center rounded border-2 border-dashed border-gray-300 p-8">
              <SmButton outline @click="handleAddLicenseClick">
                <v-icon name="md-add-round" class="mr-2 fill-contrast" />
                {{ i18n.t('addLicense') }}
              </SmButton>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="i18n.t('documents')"
            name="third"
            lazy
            :style="tabPaneStyle">
            <DocumentCardDocumentsList
              v-if="software"
              show-add-row
              :software-id="software._id" />
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { getSoftwareLicenses } from '@/common/license'
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'

  import {
    Optimization,
    OptimizationStatus,
    OptimizationsService,
    SoftwareLicense,
    SubAccount_Output,
  } from '@/client'
  import LicenseAddDialog from '@/components/LicenseAddDialog.vue'
  import OptimizationsList from '@/components/OptimizationsList.vue'
  import ViewHeader from '@/components/ViewHeader.vue'

  import LicenseModelEditTable from '../../components/LicenseModelEditTable.vue'
  import { useApplicationDetailsStore } from './ApplicationDetailsView.store'
  import ApplicationDetailDrawer from './components/ApplicationDetailDrawer/ApplicationDetailDrawer.vue'
  import DocumentCardDocumentsList from './components/ApplicationDetailsDocumentCard/components/DocumentCardDocumentsList.vue'
  import ApplicationDetailsHeader from './components/ApplicationDetailsHeader.vue'
  import ApplicationDetailsLicencesTable from './components/ApplicationDetailsLicencesTable/ApplicationDetailsLicencesTable.vue'
  import SavingsCard from './components/ApplicationDetailsSavingsCard/ApplicationDetailsSavingsCard.vue'
  import { ApplicationStore } from '@/stores/application.store'

  const i18n = useI18n()
  const isSSO = computed(() => route.query.sso === 'true')

  const applicationDetailsStore = useApplicationDetailsStore()
  const route = useRoute()
  const router = useRouter()
  const showLicenseImportDialog = ref(false)
  const showAwaitAuthDialog = ref(false)
  const statsCard = ref<ComponentExposed<typeof ApplicationDetailsHeader>>()

  const licenseTable =
    ref<ComponentExposed<typeof ApplicationDetailsLicencesTable>>()
  const licenseModelEditTable =
    ref<ComponentExposed<typeof LicenseModelEditTable>>()
  const visibilityLicenseAddDialog = ref(false)
  const currentSubAccount = ref<SubAccount_Output>()
  const visibilityExportDialog = ref<boolean>(false)

  const optimizations = ref<Optimization[]>([])
  const loadingOptimizations = ref<boolean>(true)
  const loadingLicences = ref<boolean>(true)

  // Combo Card
  const comboCardActiveName = ref('License')

  function handleAddLicenseClick() {
    licenseModelEditTable.value[0].openAddLicense()
  }

  const tabPaneStyle = {
    'max-height': '100%',
    'overflow-y': 'auto',
  }

  // ******************************* SOFTWARE *******************************
  // Get the Softwarename
  const softwareId = ref(route.params.id as string)
  const softwareRef = ApplicationStore.useApplications({
    id: softwareId.value,
  }).data

  const software = computed(() => softwareRef.value[0])

  const softwareLicenses = ref<
    {
      license: SoftwareLicense
    }[]
  >([])

  const availableSubTypes = computed(() => {
    return new Set(
      softwareLicenses.value
        .map((r) => r.license.subtype)
        .filter((r) => r !== null)
    ).add('License')
  })

  // Refetch the software data
  watch(
    software,
    async (newId) => {
      if (newId) {
        if (software.value?.sub_accounts) {
          setDefaultSubAccount()
        }

        applicationDetailsStore.software = software.value
      }
    },
    { immediate: true }
  )

  const allSoftwareLicenses = computed<SoftwareLicense[]>(() => {
    return softwareLicenses.value.map((s) => s.license)
  })

  const softwareLicensesWithSelectedSubAccount = computed(() => {
    return softwareLicenses.value
      .filter(
        (s) =>
          s.license.sub_account_id === currentSubAccount.value?.id ||
          s.license.sub_account_id === null
      )
      .map((s) => s.license)
  })

  // ******************************* USER *******************************

  const showApplicationEditDialog = ref(route.query.edit === 'true' || false)
  const licenceViewMode = ref('EditSoftware')

  function handleSuccessfulImport() {
    showLicenseImportDialog.value = false
    handleRefetchLicences()
  }

  // reload softwareData to update the displayed values in the basic-information
  async function handleRefetchLicences() {
    licenseTable.value?.handleLicencesRefetch({ cached: false })
  }

  async function fetchData() {
    if (licenseTable.value) licenseTable.value.refetchSoftwareLicenses()

    // check if auth is pending
    if (software.value?.awaits_authorization) {
      showAwaitAuthDialog.value = true
    }

    if (software.value) {
      applicationDetailsStore.initSoftware(software.value)
    }

    if (software.value?.sub_accounts) {
      setDefaultSubAccount()
    }

    // update all software licenses with price

    softwareLicenses.value = await getSoftwareLicenses(
      softwareId.value
    ).finally(() => {
      loadingLicences.value = false
    })

    if (software.value) {
      applicationDetailsStore.initLicenseModels(softwareLicenses.value)
    }
    // ******************************* OPTIMIZATIONS *******************************
    OptimizationsService.getOptimizationsApiV1ManagementOptimizationsGet({
      softwareId: softwareId.value,
      status: OptimizationStatus.OPEN,
      softwareRequiresAction: false,
    }).then((response) => {
      optimizations.value = response
      loadingOptimizations.value = false
    })
  }

  // Update domain
  watch([software, currentSubAccount], async () => {
    if (software.value) {
      const query = route.query

      // Replace route without reloading
      router.replace({
        name: 'Application',
        params: {
          id: software.value._id,
          subAccountId: currentSubAccount.value?.id || '',
        },
        query,
      })
    }
  })

  // ******************************* Hooks *******************************
  onBeforeUnmount(() => {
    applicationDetailsStore.$reset()
  })

  onMounted(async () => {
    fetchData()

    watch(
      [softwareId],
      () => {
        // Load application uncached
        ApplicationStore.getApplicationById(softwareId.value, false, {
          refetchFromApplication: true,
        })
      },
      { immediate: true }
    )
  })

  // ******************************* Functions *******************************
  async function handleRefetchSoftware() {
    softwareLicenses.value = await getSoftwareLicenses(softwareId.value)
  }

  function handleLicensesChanged() {
    // Refetch the statistics
    statsCard.value?.fetchStatistics(softwareId.value)
  }

  function handleBackToApplications() {
    showAwaitAuthDialog.value = false
    router.push({ path: '/applications' })
  }

  const licenses = computed(() => {
    return licenseTable.value?.lincenses
  })

  function setDefaultSubAccount() {
    if (!software.value?.sub_accounts) return

    // Check if subaccounts is in route (/applications/:id/:subAccountId)
    if (
      route.params.subAccountId &&
      typeof route.params.subAccountId === 'string'
    ) {
      currentSubAccount.value =
        software.value?.sub_accounts?.[
          decodeURIComponent(route.params.subAccountId)
        ]
    } else {
      currentSubAccount.value =
        software.value?.sub_accounts![
          Object.keys(software.value?.sub_accounts)[0]
        ]
    }
  }
</script>

<style lang="scss" scoped>
  /* Header */
  .with-back-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  .back-arrow {
    transition: all 0.4s ease-in-out;
  }
  .with-back-arrow:hover .back-arrow {
    color: var(--el-color-primary);
    animation: back-button-hover;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  @keyframes back-button-hover {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }

  .application-details-grid {
    display: grid;
    width: 100%;
    gap: 20px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows:
      auto minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
      minmax(0, 1fr) minmax(0, 1fr);
    grid-template-areas:
      'headerCard headerCard headerCard headerCard headerCard headerCard'
      'licensesCard licensesCard licensesCard licensesCard savingsCard savingsCard'
      'licensesCard licensesCard licensesCard licensesCard savingsCard savingsCard'
      'licensesCard licensesCard licensesCard licensesCard comboCard comboCard'
      'licensesCard licensesCard licensesCard licensesCard comboCard comboCard'
      'licensesCard licensesCard licensesCard licensesCard comboCard comboCard'
      'licensesCard licensesCard licensesCard licensesCard comboCard comboCard';

    .header-card {
      grid-area: headerCard;
    }

    .licenses-card {
      grid-area: licensesCard;
    }

    .savings-card {
      grid-area: savingsCard;
    }

    .combo-card {
      grid-area: comboCard;
    }
  }
</style>

<style lang="scss">
  .application-details-grid {
    .el-tabs__content {
      height: 100%;
    }
  }
</style>
